import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../../components/Message";
import Loader from "../../../../components/Loader";
import {
  deleteAnOrder,
  listVendorOrders,
} from "../../../../actions/orderActions";
import Paginate from "../../../../components/Paginate";
import "./VendorOrderListScreen.css";
import {
  ORDER_CREATE_RESET,
  VENDORORDER_UPDATE_RESET,
} from "../../../../constants/orderConstants";
import { VENDOR_ORDER_ADDRESS_RESET } from "../../../../constants/addressConstants";

const VendorOrderListScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;

  const vendorOrderList = useSelector((state) => state.vendorOrderList);
  const { loading, error, orders, pages, page } = vendorOrderList;

  const orderDelete = useSelector((state) => state.orderDelete);
  const { success: orderDeleteSuccess } = orderDelete;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const createOrderHandler = () => {
    history.push("/create/order");
  };
  const deleteOrder = (id) => {
    dispatch(deleteAnOrder(id));
  };
  useEffect(() => {
    if (userInfo && userInfo?.type === "vendor") {
      if (shop) {
        dispatch(listVendorOrders(pageNumber, shop._id));
      }
      if (orderDeleteSuccess) {
        dispatch(listVendorOrders(pageNumber, shop._id));
        dispatch({ type: VENDORORDER_UPDATE_RESET });
      }
    } else {
      history.push("/login");
    }
  }, [history, dispatch, userInfo, pageNumber, shop, orderDeleteSuccess]);

  useEffect(() => {
    dispatch({ type: VENDORORDER_UPDATE_RESET });

    dispatch({ type: ORDER_CREATE_RESET });
    dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
  }, [dispatch]);
  return (
    <>
      <h1 className="VendorOrderListHeading">Orders</h1>
      <Row className="mb-2">
        <Col md={10}></Col>

        <Col md={2} className="addProduct">
          <Button onClick={createOrderHandler} className="addProductBtn">
            <i className="far fa-plus-square productCreateIcon"></i> Create
          </Button>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table
          hover
          responsive
          striped
          className="VendorOrderListTable table-sm"
        >
          <thead className="VendorOrderTableHeading">
            <tr className="VendorOrderTableRow">
              <th>ORDER LIST</th>
              <th>CUSTOMER</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th className="VendorOrderItemCenter">INVOICE</th>
              <th className="VendorOrderItemCenter">RECEIPT</th>

              <th className="VendorOrderItemCenter">DELIVERY LABEL</th>
              <th className="VendorOrderItemCenter">STATUS</th>

              <th>Edit/Delete</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {orders?.map((order) => (
              <tr key={order._id} className="VendorOrderData">
                <td className="VendorOrderNameImage">
                  {order.orderItems.map((item) => (
                    <Row>
                      <Col md={3}>
                        <img
                          className="VendorOrderImage"
                          style={{
                            marginRight: "10px",
                            height: "40px",
                            width: "40px",
                            objectFit: "contain",
                          }}
                          src={item.image}
                          alt={item.name}
                        />
                      </Col>
                      <Col md={9}>
                        <p>{item.name}</p>
                      </Col>
                    </Row>
                  ))}
                </td>
                <td>{order?.user?.name}</td>
                <td>{new Date(order.createdAt).toLocaleString()}</td>
                <td>₱ {order.totalPrice}</td>
                <td className="VendorOrderItemCenter">
                  <Link
                    onClick={() => {
                      history.push(`/invoice/${order._id}`);
                    }}
                  >
                    <i className="fas fa-file-invoice-dollar VendorOrderIcons"></i>
                  </Link>
                </td>
                <td className="VendorOrderItemCenter">
                  {order?.isPaid === "Completed" && (
                    <Link
                      onClick={() => {
                        history.push(`/receipt/${order?._id}`);
                      }}
                    >
                      <i className="fas fa-file-invoice-dollar VendorOrderIcons"></i>
                    </Link>
                  )}
                </td>
                <td className="VendorOrderItemCenter">
                  <Link
                    onClick={() => {
                      history.push(`/deliverylabel/${order?._id}`);
                    }}
                  >
                    <i className="fas fa-print VendorOrderIcons"></i>
                  </Link>
                </td>
                <td>
                  Payment:{order.isPaid}
                  <br />
                  Delivery:{order.delivery.isDelivered}
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    <Link to={`/edit/order/${order?._id}`}>
                      <i className="fas fa-edit"></i>
                    </Link>
                    <div
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                      onClick={() => deleteOrder(order?._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </div>
                  </div>
                </td>
                <td>
                  <LinkContainer to={`/order/vendor/${order._id}`}>
                    <Button variant="light" className="VendorOrderBtn btn-sm">
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Paginate url="/vendor/orderlist" pages={pages} page={page} />
    </>
  );
};

export default VendorOrderListScreen;
