import React from "react";
import { Switch, Route, Link } from "react-router-dom";

// components

import "../../screens/assets/styles/tailwind.css";
import ShopBannerScreen from "../User/ShopBannerScreen";
import Dashboard from "./VendorDashboard/Dashboard";
import FooterAdmin from "../../components/Dashboard/Footer/FooterAdmin";
import ProductCreateScreen from "./VendorDashboard/Products/ProductCreateScreen";
import ProductListScreen from "./VendorDashboard/Products/ProductlistScreen";
import VendorOrderListScreen from "./VendorDashboard/Orders/VendorOrderListScreen";
import Chat from "./Chat";
import ManageStaffs from "./ManageStaffs";
import VendorRegistrationScreen from "../Register/VendorRegistrationScreen";
import ProductEditScreen from "../Product/ProductEditScreen";
import ProfileScreen from "../User/ProfileScreens";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsStaff } from "../../actions/staffActions";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";
import Message from "../../components/Message";
import ShopProfileScreen from "../User/ShopProfileScreen";
import EditStaff from "./EditStaff";

const VendorDashboard = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user } = vendorDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);
  useEffect(() => {
    if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler")
      dispatch(detailsStaff(userInfo?._id));
  }, [dispatch, userInfo?._id, userInfo?.type]);
  return (
    <>
      {user && user?.status === "Not Registered" && (
        <Message variant="info" style={{ marginTop: "100px" }}>
          You need to register your shop to put your products for sale. Please
          follow the link to register the shop.
          <Link
            to="/vendor/registration"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Register
          </Link>
        </Message>
      )}
      <div
        className="px-4 md:px-10 mx-auto w-full m-10"
        style={{ minHeight: "70vh", maxHeight: "fitContent" }}
      >
        <Switch>
          <Route
            path="/vendor/:shopName/dashboard"
            exact
            component={Dashboard}
          />
          <Route
            path="/vendor/product/create"
            exact
            component={ProductCreateScreen}
          />
          <Route
            path="/vendor/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/vendor/productlist/search/:keyword"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/productlist/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/productlist/search/:keyword/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/productlist/:unpublished"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/vendor/productlist/:unpublished/search/:keyword"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/productlist/:unpublished/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/productlist/:unpublished/search/:keyword/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/vendor/orderlist"
            component={VendorOrderListScreen}
            exact
          />
          <Route path="/vendor/dashboard/Chat" component={Chat} exact />
          <Route
            path="/vendor/dashboard/ManageStaffs"
            component={ManageStaffs}
            exact
          />
          <Route
            path="/vendor/orderlist/page/:pageNumber"
            component={VendorOrderListScreen}
            exact
          />
          <Route
            path="/vendor/registration"
            component={VendorRegistrationScreen}
            exact
          />
          <Route path="/vendor/profile" component={ProfileScreen} exact />
          <Route
            path="/vendor/product/:id/:userId/edit"
            component={ProductEditScreen}
            exact
          />
          <Route path="/vendor/shopprofile" component={ShopProfileScreen} />
          <Route path="/vendor/staff/:id/edit" component={EditStaff} />
          <Route path="/vendor/banner" component={ShopBannerScreen} />
        </Switch>
      </div>
      <FooterAdmin />
      {/* </div> */}
    </>
  );
};

export default VendorDashboard;
