import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import {
  listBestOffersProducts,
  listMostPopularProducts,
  listNewProducts,
  listProducts,
} from "../../actions/productActions";
import ProductCarousel from "../../components/ProductCarousel.js";
import Meta from "../../components/Meta.js";
import { Row, Col, Toast, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

const Homescreen = ({ history }) => {
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const newProductList = useSelector((state) => state.newProductList);
  const { products: newProducts, loading: newProductsLoading } = newProductList;

  const mostPopularProductList = useSelector(
    (state) => state.mostPopularProductList
  );
  const { products: mostPopularProducts, loading: mostPopularProductsLoading } =
    mostPopularProductList;

  const bestOffersProductList = useSelector(
    (state) => state.bestOffersProductList
  );
  const { products: bestOffersProducts, loading: bestOffersProductsLoading } =
    bestOffersProductList;

  const categoryHeadingsList = useSelector(
    (state) => state.categoryHeadingsList
  );
  const { categories } = categoryHeadingsList;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  const [items, setItems] = useState(12);
  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const moreProducts = () => {
    dispatch(listProducts(items + 12));
    setItems(items + 12);
  };
  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };
  useEffect(() => {
    dispatch(listNewProducts());
    dispatch(listBestOffersProducts());
    dispatch(listMostPopularProducts());
  }, [dispatch, shop, shopInfo]);
  useEffect(() => {
    dispatch(listProducts(items));
  }, [dispatch, items]);
  return (
    <div className="container mx-auto">
      {shop && shop.status === "Not Registered" && (
        <Message variant="info">
          You need to register your shop to put your products for sale. Please
          follow the link to register the shop.
          <Link
            to="/vendor/registration"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Register
          </Link>
        </Message>
      )}
      {shopInfo && shopInfo.status === "Not Registered" && (
        <Message variant="info">
          You need to register your shop to put your products for sale. Please
          follow the link to register the shop.
          <Link
            to="/wholesaler/registration"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Register
          </Link>
        </Message>
      )}

      <Meta />
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}

      <ProductCarousel />

      {bestOffersProductsLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <div className="mt-4 " style={{ display: "flex" }}>
              <div className="col-md-9 col-sm-9 col-9">
                <h3
                  className="homepage-header"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 36,
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  Best Offer Product
                </h3>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <Link to="/best-offer">
                  <button
                    type="button"
                    className="homepage-seemore-btn"
                    style={{
                      float: "right",
                      margin: "4rem 1rem 1rem 1rem",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <span
                      className="homepage-seemore"
                      style={{
                        color: "#FF7A00",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                      <i
                        className="fas fa-arrow-alt-circle-right"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </Row>
          <Row className="px-3" style={{ width: "100%" }}>
            {bestOffersProducts?.map((product) => {
              return (
                <Col md={2} xl={2} xs={6} sm={6} key={product._id}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={product}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      {newProductsLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <div className="mt-4 " style={{ display: "flex" }}>
              <div className="col-md-9 col-sm-9 col-9">
                <h3
                  className="homepage-header"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 36,
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  New Product
                </h3>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <Link to="/new">
                  <button
                    type="button"
                    className="homepage-seemore-btn"
                    style={{
                      float: "right",
                      margin: "4rem 1rem 1rem 1rem",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <span
                      className="homepage-seemore"
                      style={{
                        color: "#FF7A00",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                      <i
                        className="fas fa-arrow-alt-circle-right"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </Row>
          <Row className="px-3" style={{ width: "100%" }}>
            {newProducts?.map((product) => {
              return (
                <Col md={2} xl={2} xs={6} sm={6} key={product._id}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={product}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}

      {mostPopularProductsLoading ? (
        <Loader />
      ) : (
        <>
          <Row>
            <div className="mt-4" style={{ display: "flex" }}>
              <div className="col-md-9 col-sm-9 col-9">
                <h3
                  className="homepage-header"
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: 36,
                    color: "black",
                    textTransform: "uppercase",
                  }}
                >
                  Most Popular Product
                </h3>
              </div>
              <div className="col-md-3 col-sm-3 col-3">
                <Link to="/most-popular">
                  <button
                    type="button"
                    className="homepage-seemore-btn"
                    style={{
                      float: "right",
                      margin: "4rem 1rem 1rem 1rem",
                      background: "none",
                      border: "none",
                    }}
                  >
                    <span
                      className="homepage-seemore"
                      style={{
                        color: "#FF7A00",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      See More
                      <i
                        className="fas fa-arrow-alt-circle-right"
                        style={{ marginLeft: "10px" }}
                      ></i>
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </Row>
          <Row className="px-3" style={{ width: "100%" }}>
            {mostPopularProducts?.map((product) => {
              return (
                <Col md={2} xl={2} xs={6} sm={6} key={product._id}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={product}
                  />
                </Col>
              );
            })}
          </Row>
        </>
      )}
      <div className="mt-4" style={{ display: "flex" }}>
        <div className="col-md-9 col-sm-9 col-9">
          <h3
            className="homepage-header"
            style={{
              fontFamily: "Montserrat",
              fontSize: 36,
              color: "black",
              textTransform: "uppercase",
            }}
          >
            Our Categories{" "}
          </h3>
        </div>
        <div className="col-md-3 col-sm-3 col-3">
          <Link to="/allcategories">
            <button
              type="button"
              className="homepage-seemore-btn"
              style={{
                float: "right",
                margin: "4rem 1rem 1rem 1rem",
                background: "none",
                border: "none",
              }}
            >
              <span
                className="homepage-seemore"
                style={{
                  color: "#FF7A00",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
              >
                See More
                <i
                  className="fas fa-arrow-alt-circle-right"
                  style={{ marginLeft: "10px" }}
                ></i>
              </span>
            </button>
          </Link>
        </div>
      </div>
      <Row className="px-3" style={{ width: "100%" }}>
        {categories?.slice(0, 12).map((category) => {
          return (
            <Col
              xs={6}
              sm={6}
              md={2}
              className="mr-md-3 px-3"
              key={category._id}
            >
              <Link
                to={`/category/${category.name}`}
                style={{ textDecoration: "none" }}
              >
                <div
                  className="category-div"
                  style={{
                    height: "130px",
                    width: "100%",
                    display: "flex",
                    margin: "20px",
                    flexDirection: "column",
                    padding: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#F9F9F9",
                  }}
                >
                  <div
                    className="imageContainer"
                    style={{
                      height: "60%",
                      width: "60%",
                      padding: "5px",
                    }}
                  >
                    <img
                      src={category.image}
                      alt="Category"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                </div>
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                  }}
                >
                  {category.name}
                </span>
              </Link>
            </Col>
          );
        })}
      </Row>
      <Row>
        <div className="mt-4" style={{ display: "flex" }}>
          <div className="col-md-12 col-sm-12 col-12">
            <h3
              className="homepage-header"
              style={{
                fontFamily: "Montserrat",
                fontSize: 36,
                color: "black",
                textTransform: "uppercase",
              }}
            >
              All Product{" "}
            </h3>
          </div>
        </div>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Row className="px-3" style={{ width: "100%" }}>
            {products?.map((product) => {
              return (
                <Col md="2" xl="2" xs={6} sm={6} key={product._id}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={product}
                  />
                </Col>
              );
            })}
          </Row>
          <div className="text-center my-3">
            <button
              type="button"
              onClick={moreProducts}
              style={{ width: 250 }}
              className="btn btn-outline-primary"
            >
              Load More{" "}
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Homescreen;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Product from "../../components/Product.js";
// import Message from "../../components/Message.js";
// import Loader from "../../components/Loader.js";
// import {
//   listBestOffersProducts,
//   listMostPopularProducts,
//   listNewProducts,
//   listProducts,
// } from "../../actions/productActions";
// import ProductCarousel from "../../components/ProductCarousel.js";
// import Meta from "../../components/Meta.js";
// import { Row, Col, Toast, Alert } from "react-bootstrap";
// import { Link } from "react-router-dom";

// const Homescreen = ({ history }) => {
//   const dispatch = useDispatch();

//   const productList = useSelector((state) => state.productList);
//   const { loading, error, products } = productList;

//   const newProductList = useSelector((state) => state.newProductList);
//   const { products: newProducts, loading: newProductsLoading } = newProductList;

//   const mostPopularProductList = useSelector(
//     (state) => state.mostPopularProductList
//   );
//   const { products: mostPopularProducts, loading: mostPopularProductsLoading } =
//     mostPopularProductList;

//   const bestOffersProductList = useSelector(
//     (state) => state.bestOffersProductList
//   );
//   const { products: bestOffersProducts, loading: bestOffersProductsLoading } =
//     bestOffersProductList;

//   const categoryHeadingsList = useSelector(
//     (state) => state.categoryHeadingsList
//   );
//   const { categories } = categoryHeadingsList;

//   const vendorDetails = useSelector((state) => state.vendorDetails);
//   const { user: shop } = vendorDetails;

//   const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
//   const { user: shopInfo } = wholesalerDetails;

//   const [items, setItems] = useState(12);
//   const [showToast, setShowToast] = useState(false);
//   const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

//   const moreProducts = () => {
//     dispatch(listProducts(items + 12));
//     setItems(items + 12);
//   };
//   const toastToggler = (productOutOfStock) => {
//     setShowToast(true);
//     setOutOfStockToastMessage(productOutOfStock);
//   };
//   useEffect(() => {
//     dispatch(listNewProducts());
//     dispatch(listBestOffersProducts());
//     dispatch(listMostPopularProducts());
//   }, [dispatch, shop, shopInfo]);
//   useEffect(() => {
//     dispatch(listProducts(items));
//   }, [dispatch, items]);
//   return (
//     <div className="container mx-auto">
//       {shop && shop.status === "Not Registered" && (
//         <Message variant="info">
//           You need to register your shop to put your products for sale. Please
//           follow the link to register the shop.
//           <Link
//             to="/vendor/registration"
//             style={{ textDecoration: "underline", color: "blue" }}
//           >
//             Register
//           </Link>
//         </Message>
//       )}
//       {shopInfo && shopInfo.status === "Not Registered" && (
//         <Message variant="info">
//           You need to register your shop to put your products for sale. Please
//           follow the link to register the shop.
//           <Link
//             to="/wholesaler/registration"
//             style={{ textDecoration: "underline", color: "blue" }}
//           >
//             Register
//           </Link>
//         </Message>
//       )}

//       <Meta />
//       {showToast && (
//         <div
//           position="top-right"
//           style={{
//             position: "relative",
//             zIndex: 2222,
//             float: "right",
//             right: "250px",
//             top: "-75px",
//           }}
//         >
//           <Toast
//             position="top-end"
//             onClose={() => setShowToast(false)}
//             delay={3000}
//             autohide
//             style={{
//               position: "fixed",
//               float: "right",
//             }}
//           >
//             <Toast.Body
//               style={{
//                 padding: "0px",
//                 margin: "0px",
//               }}
//             >
//               <div>
//                 {outOfStockToastMessage ? (
//                   <Alert
//                     variant="danger"
//                     style={{
//                       margin: "0px",
//                     }}
//                   >
//                     Oops! Product is out of Stock
//                   </Alert>
//                 ) : (
//                   <Alert
//                     variant="success"
//                     style={{
//                       margin: "0px",
//                     }}
//                   >
//                     Your Product has been added.
//                   </Alert>
//                 )}
//               </div>
//             </Toast.Body>
//           </Toast>
//         </div>
//       )}

//       <ProductCarousel />

//       {bestOffersProductsLoading ? (
//         <Loader />
//       ) : (
//         <>
//           <div className="mt-4 " style={{ display: "flex" }}>
//             <div style={{ marginRight: "56rem" }}>
//               <h3
//                 className="homepage-header"
//                 style={{
//                   fontFamily: "Montserrat",
//                   fontSize: 36,
//                   color: "black",
//                   textTransform: "uppercase",
//                 }}
//               >
//                 Best Offer Product
//               </h3>
//             </div>
//             <div>
//               <Link to="/best-offer">
//                 <button
//                   type="button"
//                   style={{
//                     float: "right",
//                     margin: "4rem 1rem 1rem 1rem",
//                     background: "none",
//                     border: "none",
//                   }}
//                 >
//                   <span
//                     style={{
//                       color: "#FF7A00",
//                       fontSize: "18px",
//                       fontWeight: "600",
//                     }}
//                   >
//                     See More
//                     <i
//                       className="fas fa-arrow-alt-circle-right"
//                       style={{ marginLeft: "10px" }}
//                     ></i>
//                   </span>
//                 </button>
//               </Link>
//             </div>
//           </div>
//           <Row className="px-3" style={{ width: "100%" }}>
//             {bestOffersProducts?.map((product) => {
//               return (
//                 <Col md={2} xl={2} sm={2} key={product._id}>
//                   <Product
//                     toastToggler={toastToggler}
//                     history={history}
//                     product={product}
//                   />
//                 </Col>
//               );
//             })}
//           </Row>
//         </>
//       )}
//       {newProductsLoading ? (
//         <Loader />
//       ) : (
//         <>
//           <div className="mt-4 " style={{ display: "flex" }}>
//             <div style={{ marginRight: "65rem" }}>
//               <h3
//                 className="homepage-header"
//                 style={{
//                   fontFamily: "Montserrat",
//                   fontSize: 36,
//                   color: "black",
//                   textTransform: "uppercase",
//                 }}
//               >
//                 New Product
//               </h3>
//             </div>
//             <div>
//               <Link to="/new">
//                 <button
//                   type="button"
//                   style={{
//                     float: "right",
//                     margin: "4rem 1rem 1rem 1rem",
//                     background: "none",
//                     border: "none",
//                   }}
//                 >
//                   <span
//                     style={{
//                       color: "#FF7A00",
//                       fontSize: "18px",
//                       fontWeight: "600",
//                     }}
//                   >
//                     See More
//                     <i
//                       className="fas fa-arrow-alt-circle-right"
//                       style={{ marginLeft: "10px" }}
//                     ></i>
//                   </span>
//                 </button>
//               </Link>
//             </div>
//           </div>

//           <Row className="px-3" style={{ width: "100%" }}>
//             {newProducts?.map((product) => {
//               return (
//                 <Col md={2} xl={2} sm={2} key={product._id}>
//                   <Product
//                     toastToggler={toastToggler}
//                     history={history}
//                     product={product}
//                   />
//                 </Col>
//               );
//             })}
//           </Row>
//         </>
//       )}

//       {mostPopularProductsLoading ? (
//         <Loader />
//       ) : (
//         <>
//           <div className="mt-4" style={{ display: "flex" }}>
//             <div style={{ marginRight: "52rem" }}>
//               <h3
//                 className="homepage-header"
//                 style={{
//                   fontFamily: "Montserrat",
//                   fontSize: 36,
//                   color: "black",
//                   textTransform: "uppercase",
//                 }}
//               >
//                 Most Popular Product
//               </h3>
//             </div>
//             <div>
//               <Link to="/most-popular">
//                 <button
//                   type="button"
//                   style={{
//                     float: "right",
//                     margin: "4rem 1rem 1rem 1rem",
//                     background: "none",
//                     border: "none",
//                   }}
//                 >
//                   <span
//                     style={{
//                       color: "#FF7A00",
//                       fontSize: "18px",
//                       fontWeight: "600",
//                     }}
//                   >
//                     See More
//                     <i
//                       className="fas fa-arrow-alt-circle-right"
//                       style={{ marginLeft: "10px" }}
//                     ></i>
//                   </span>
//                 </button>
//               </Link>
//             </div>
//           </div>

//           <Row className="px-3" style={{ width: "100%" }}>
//             {mostPopularProducts?.map((product) => {
//               return (
//                 <Col md={2} xl={2} sm={2} key={product._id}>
//                   <Product
//                     toastToggler={toastToggler}
//                     history={history}
//                     product={product}
//                   />
//                 </Col>
//               );
//             })}
//           </Row>
//         </>
//       )}
//       <div className="mt-4" style={{ display: "flex" }}>
//         <div style={{ marginRight: "62rem" }}>
//           <h3
//             className="homepage-header"
//             style={{
//               fontFamily: "Montserrat",
//               fontSize: 36,
//               color: "black",
//               textTransform: "uppercase",
//             }}
//           >
//             Our Categories{" "}
//           </h3>
//         </div>
//         <div>
//           <Link to="/allcategories">
//             <button
//               type="button"
//               style={{
//                 float: "right",
//                 margin: "4rem 1rem 1rem 1rem",
//                 background: "none",
//                 border: "none",
//               }}
//             >
//               <span
//                 style={{
//                   color: "#FF7A00",
//                   fontSize: "18px",
//                   fontWeight: "600",
//                 }}
//               >
//                 See More
//                 <i
//                   className="fas fa-arrow-alt-circle-right"
//                   style={{ marginLeft: "10px" }}
//                 ></i>
//               </span>
//             </button>
//           </Link>
//         </div>
//       </div>
//       <Row className="px-3" style={{ width: "100%" }}>
//         {categories?.slice(0, 14).map((category) => {
//           return (
//             <Col className="mx-3" key={category._id}>
//               <Link
//                 to={`/category/${category.name}`}
//                 style={{ textDecoration: "none" }}
//               >
//                 <div
//                   style={{
//                     height: "130px",
//                     width: "130px",
//                     display: "flex",
//                     margin: "20px 22.5px",
//                     flexDirection: "column",
//                     padding: "5px",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     background: "#F9F9F9",
//                   }}
//                 >
//                   <div
//                     className="imageContainer"
//                     style={{
//                       height: "60%",
//                       width: "60%",
//                       padding: "5px",
//                     }}
//                   >
//                     <img
//                       src={category.image}
//                       alt="Category"
//                       style={{
//                         height: "100%",
//                         width: "100%",
//                         objectFit: "contain",
//                       }}
//                     />
//                   </div>
//                 </div>
//                 <span
//                   style={{
//                     display: "flex",
//                     justifyContent: "center",
//                     alignContent: "center",
//                   }}
//                 >
//                   {category.name}
//                 </span>
//               </Link>
//             </Col>
//           );
//         })}
//       </Row>
//       <div className="mt-4" style={{ display: "flex" }}>
//         <div style={{ marginRight: "73rem" }}>
//           <h3
//             className="homepage-header"
//             style={{
//               fontFamily: "Montserrat",
//               fontSize: 36,
//               color: "black",
//               textTransform: "uppercase",
//             }}
//           >
//             All Product{" "}
//           </h3>
//         </div>
//       </div>
//       {loading ? (
//         <Loader />
//       ) : error ? (
//         <Message variant="danger">{error}</Message>
//       ) : (
//         <>
//           <Row className="px-3" style={{ width: "100%" }}>
//             {products?.map((product) => {
//               return (
//                 <Col md="2" xl="2" sm="2" key={product._id}>
//                   <Product
//                     toastToggler={toastToggler}
//                     history={history}
//                     product={product}
//                   />
//                 </Col>
//               );
//             })}
//           </Row>
//           <div className="text-center my-3">
//             <button
//               type="button"
//               onClick={moreProducts}
//               style={{ width: 250 }}
//               className="btn btn-outline-primary"
//             >
//               Load More{" "}
//               <i className="fa fa-chevron-down" aria-hidden="true"></i>
//             </button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default Homescreen;
