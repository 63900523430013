import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { detailsPromotion } from "../../actions/promotionActions";

const PromotionRequests = ({ match }) => {
  const dispatch = useDispatch();
  const promotionDetails = useSelector((state) => state.promotionDetails);
  const { promotion } = promotionDetails;
  const promotionId = match.params.id;

  useEffect(() => {
    dispatch(detailsPromotion(promotionId));
  }, [dispatch, promotionId]);
  return (
    <div>
      {promotion?.products?.map((product) => {
        return (
          <>
            "product"
            <Button>Approve For Sale</Button>
          </>
        );
      })}
    </div>
  );
};

export default PromotionRequests;
