export const PROMOTION_CREATE_REQUEST = "PROMOTION_CREATE_REQUEST";
export const PROMOTION_CREATE_SUCCESS = "PROMOTION_CREATE_SUCCESS";
export const PROMOTION_CREATE_FAIL = "PROMOTION_CREATE_FAIL";
export const PROMOTION_CREATE_RESET = "PROMOTION_CREATE_RESET";

export const PROMOTION_GET_REQUEST = "PROMOTION_GET_REQUEST";
export const PROMOTION_GET_SUCCESS = "PROMOTION_GET_SUCCESS";
export const PROMOTION_GET_FAIL = "PROMOTION_GET_FAIL";

export const DISPLAY_PROMOTION_GET_REQUEST = "DISPLAY_PROMOTION_GET_REQUEST";
export const DISPLAY_PROMOTION_GET_SUCCESS = "DISPLAY_PROMOTION_GET_SUCCESS";
export const DISPLAY_PROMOTION_GET_FAIL = "DISPLAY_PROMOTION_GET_FAIL";

export const DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST =
  "DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST";
export const DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS =
  "DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS";
export const DISPLAY_WHOLESALER_PROMOTION_GET_FAIL =
  "DISPLAY_WHOLESALER_PROMOTION_GET_FAIL";

export const PROMOTION_UPDATE_REQUEST = "PROMOTION_UPDATE_REQUEST";
export const PROMOTION_UPDATE_SUCCESS = "PROMOTION_UPDATE_SUCCESS";
export const PROMOTION_UPDATE_FAIL = "PROMOTION_UPDATE_FAIL";
export const PROMOTION_UPDATE_RESET = "PROMOTION_UPDATE_RESET";

export const PROMOTION_DELETE_REQUEST = "PROMOTION_DELETE_REQUEST";
export const PROMOTION_DELETE_SUCCESS = "PROMOTION_DELETE_SUCCESS";
export const PROMOTION_DELETE_FAIL = "PROMOTION_DELETE_FAIL";
export const PROMOTION_DELETE_RESET = "PROMOTION_DELETE_RESET";

export const PROMOTION_DETAILS_REQUEST = "PROMOTION_DETAILS_REQUEST";
export const PROMOTION_DETAILS_SUCCESS = "PROMOTION_DETAILS_SUCCESS";
export const PROMOTION_DETAILS_FAIL = "PROMOTION_DETAILS_FAIL";

export const PROMOTION_JOIN_REQUEST = "PROMOTION_JOIN_REQUEST";
export const PROMOTION_JOIN_SUCCESS = "PROMOTION_JOIN_SUCCESS";
export const PROMOTION_JOIN_FAIL = "PROMOTION_JOIN_FAIL";
export const PROMOTION_JOIN_RESET = "PROMOTION_JOIN_FAIL";
