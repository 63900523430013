import axios from "axios";

import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_LIST_MY_SUCCESS,
  PRODUCT_LIST_MY_REQUEST,
  PRODUCT_LIST_MY_FAIL,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL,
  PRODUCT_PUBLISH_REQUEST,
  PRODUCT_PUBLISH_SUCCESS,
  PRODUCT_PUBLISH_FAIL,
  PRODUCT_LIST_MY_PUBLISHED_REQUEST,
  PRODUCT_LIST_MY_PUBLISHED_SUCCESS,
  PRODUCT_LIST_MY_PUBLISHED_FAIL,
  PRODUCT_WHOLESALEONLY_LIST_REQUEST,
  PRODUCT_WHOLESALEONLY_LIST_SUCCESS,
  PRODUCT_WHOLESALEONLY_LIST_FAIL,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_SUCCESS,
  PRODUCT_WISHLIST_FAIL,
  CREATE_WISHLIST_REQUEST,
  CREATE_WISHLIST_SUCCESS,
  CREATE_WISHLIST_FAIL,
  REMOVE_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAIL,
  REMOVE_WISHLIST_REQUEST,
  MOST_POPULAR_PRODUCT_LIST_FAIL,
  MOST_POPULAR_PRODUCT_LIST_SUCCESS,
  MOST_POPULAR_PRODUCT_LIST_REQUEST,
  BEST_OFFERS_PRODUCT_LIST_REQUEST,
  BEST_OFFERS_PRODUCT_LIST_SUCCESS,
  BEST_OFFERS_PRODUCT_LIST_FAIL,
  RECOMMENDED_PRODUCT_LIST_REQUEST,
  RECOMMENDED_PRODUCT_LIST_SUCCESS,
  RECOMMENDED_PRODUCT_LIST_FAIL,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  ADD_PRODUCT_FAQ_REQUEST,
  ADD_PRODUCT_FAQ_SUCCESS,
  ADD_PRODUCT_FAQ_FAIL,
  PRODUCT_LIST_ADMIN_REQUEST,
  PRODUCT_LIST_ADMIN_SUCCESS,
  PRODUCT_LIST_ADMIN_FAIL,
  SEARCH_VENDOR_PRODUCT_LIST_REQUEST,
  SEARCH_VENDOR_PRODUCT_LIST_SUCCESS,
  SEARCH_VENDOR_PRODUCT_LIST_FAIL,
  SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST,
  SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS,
  SEARCH_WHOLESALER_PRODUCT_LIST_FAIL,
} from "../constants/productConstants";

export const listProducts =
  (items, keyword = "") =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesale/items/${items}?keyword=${keyword}`,
          config
        );
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/items/${items}?keyword=${keyword}`
        );
        dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSearchVendorProducts =
  (
    keyword = "",
    pageNumber = 1,
    minPrice = 0,
    maxPrice = 999999999,
    warrantyType = "",
    services = "",
    stars = 0,
    brand = "",
    category = ""
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      dispatch({ type: SEARCH_VENDOR_PRODUCT_LIST_REQUEST });
      const { data } = await axios.get(
        `/api/products/vendor/search?pageNumber=${pageNumber}&brand=${brand}&stars=${stars}&category=${category}&keyword=${keyword}&minPrice=${minPrice}&maxPrice=${maxPrice}&warrantyType=${warrantyType}&services=${services}`,
        config
      );
      dispatch({ type: SEARCH_VENDOR_PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SEARCH_VENDOR_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listSearchWholesalerProducts =
  (
    keyword = "",
    pageNumber = 1,
    minPrice = 0,
    maxPrice = 999999999,
    warrantyType = "",
    services = "",
    stars = 0,
    brand = "",
    category = ""
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      dispatch({ type: SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST });

      const { data } = await axios.get(
        `/api/products/wholesale/search?pageNumber=${pageNumber}&brand=${brand}&stars=${stars}&category=${category}&keyword=${keyword}&minPrice=${minPrice}&maxPrice=${maxPrice}&warrantyType=${warrantyType}&services=${services}`,
        config
      );
      dispatch({ type: SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SEARCH_WHOLESALER_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listWholesaleProductsOnly =
  (keyword = "", pageNumber = "") =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_WHOLESALEONLY_LIST_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `/api/products/wholesaleproductsonly?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );

      dispatch({ type: PRODUCT_WHOLESALEONLY_LIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_WHOLESALEONLY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//List both published and unpublished products for admin
export const listAllProducts =
  (keyword, pageNumber, unpublished) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_LIST_ADMIN_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      if (unpublished) {
        const { data } = await axios.get(
          `/api/products/admin/unpublished/list?keyword=${keyword}&pageNumber=${pageNumber}`
        );
        dispatch({ type: PRODUCT_LIST_ADMIN_SUCCESS, payload: data });
      } else {
        const { data } = await axios.get(
          `/api/products/admin/list?keyword=${keyword}&pageNumber=${pageNumber}`
        );
        dispatch({ type: PRODUCT_LIST_ADMIN_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_ADMIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProductCategoryWise =
  (
    category,
    pageNumber = "",
    min = 0,
    max = 9999999,
    stars = 0,
    warrantyType = "",
    services = "",
    brand = ""
  ) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesalecategory/${category}?pageNumber=${pageNumber}&min=${min}&max=${max}&stars=${stars}&warrantyType=${warrantyType}&services=${services}&brand=${brand}`,
          config
        );
        dispatch({
          type: PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS,
          payload: data,
        });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/retailcategory/${category}?pageNumber=${pageNumber}&min=${min}&max=${max}&stars=${stars}&warrantyType=${warrantyType}&services=${services}&brand=${brand}`
        );

        dispatch({
          type: PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//List the products a vendor or wholesaler inserted
export const listMyProducts =
  (id, keyword, pageNumber, unpublished) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      dispatch({ type: PRODUCT_LIST_MY_REQUEST });
      if (unpublished) {
        const { data } = await axios.get(
          `/api/products/vendor/unpublished/${id}?keyword=${keyword}&pageNumber=${pageNumber}`,
          config
        );
        dispatch({ type: PRODUCT_LIST_MY_SUCCESS, payload: data });
      } else {
        const { data } = await axios.get(
          `/api/products/vendor/${id}?keyword=${keyword}&pageNumber=${pageNumber}`,
          config
        );
        dispatch({ type: PRODUCT_LIST_MY_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_MY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listMyPublishedProducts =
  (id, keyword = "", pageNumber = 1, vendorCategory = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_MY_PUBLISHED_REQUEST });
      const { data } = await axios.get(
        `/api/products/published/${id}?keyword=${keyword}&pageNumber=${pageNumber}&vendorCategory=${vendorCategory}`
      );
      dispatch({ type: PRODUCT_LIST_MY_PUBLISHED_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_MY_PUBLISHED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const listMyPublishedProductsWholesaler =
  (id, keyword = "", pageNumber = 1, vendorCategory = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: PRODUCT_LIST_MY_PUBLISHED_REQUEST });
      const { data } = await axios.get(
        `/api/products/published/wholesaler/${id}?keyword=${keyword}&pageNumber=${pageNumber}&vendorCategory=${vendorCategory}`
      );
      dispatch({ type: PRODUCT_LIST_MY_PUBLISHED_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: PRODUCT_LIST_MY_PUBLISHED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/products/${id}`);

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/products/${id}`, config);
    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const createProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/products`, product, config);
    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/products/${product._id}`, product, config);
    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateProductFAQ =
  (question, answer, productId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADD_PRODUCT_FAQ_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.put(
        `/api/products/FAQ/${productId}`,
        { question, answer },
        config
      );
      dispatch({
        type: ADD_PRODUCT_FAQ_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: ADD_PRODUCT_FAQ_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const publishTheProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_PUBLISH_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/products/publish/${id}`, {}, config);
    dispatch({
      type: PRODUCT_PUBLISH_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PRODUCT_PUBLISH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createProductReview =
  (productId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/products/${productId}/reviews`,
        review,

        config
      );
      dispatch({
        type: PRODUCT_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST });
    const { data } = await axios.get(`/api/products/top`);

    dispatch({ type: PRODUCT_TOP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWishlist = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_WISHLIST_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    if (userInfo) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`/api/products/wishlist`, config);

      dispatch({ type: PRODUCT_WISHLIST_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: PRODUCT_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addToWishlist = (product) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    dispatch({ type: CREATE_WISHLIST_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/products/wishlist`,
      {
        productId: product._id,
        wholesalerShop: product.wholesalerShop?._id,
        vendorShop: product.vendorShop?._id,
      },
      config
    );
    dispatch({ type: CREATE_WISHLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const removeFromWishlist = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REMOVE_WISHLIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    if (userInfo) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.delete(
        `/api/products/wishlist/${id}`,
        config
      );
      dispatch({ type: REMOVE_WISHLIST_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({
      type: REMOVE_WISHLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listNewProducts =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: NEW_PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesale/newproducts?pageNumber=${pageNumber}`,
          config
        );
        dispatch({ type: NEW_PRODUCT_LIST_SUCCESS, payload: data });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/newproducts?&pageNumber=${pageNumber}`
        );
        dispatch({ type: NEW_PRODUCT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: NEW_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listRecommendedForYou =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: RECOMMENDED_PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesale/recommendedforyou?pageNumber=${pageNumber}`,
          config
        );
        dispatch({ type: RECOMMENDED_PRODUCT_LIST_SUCCESS, payload: data });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/recommendedforyou?pageNumber=${pageNumber}`
        );
        dispatch({ type: RECOMMENDED_PRODUCT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: RECOMMENDED_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listBestOffersProducts =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: BEST_OFFERS_PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesale/bestoffers?pageNumber=${pageNumber}`,
          config
        );
        dispatch({ type: BEST_OFFERS_PRODUCT_LIST_SUCCESS, payload: data });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/bestoffers?pageNumber=${pageNumber}`
        );
        dispatch({ type: BEST_OFFERS_PRODUCT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: BEST_OFFERS_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listMostPopularProducts =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo?.token}`,
          },
        };
        dispatch({ type: MOST_POPULAR_PRODUCT_LIST_REQUEST });

        const { data } = await axios.get(
          `/api/products/wholesale/mostpopular?pageNumber=${pageNumber}`,
          config
        );

        dispatch({ type: MOST_POPULAR_PRODUCT_LIST_SUCCESS, payload: data });
      } else if (!userInfo || userInfo?.type === "customer") {
        const { data } = await axios.get(
          `/api/products/mostpopular?pageNumber=${pageNumber}`
        );
        dispatch({ type: MOST_POPULAR_PRODUCT_LIST_SUCCESS, payload: data });
      }
    } catch (error) {
      dispatch({
        type: MOST_POPULAR_PRODUCT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
