export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_VENDOR_REQUEST = "ORDER_VENDOR_REQUEST";
export const ORDER_VENDOR_SUCCESS = "ORDER_VENDOR_SUCCESS";
export const ORDER_VENDOR_FAIL = "ORDER_VENDOR_FAIL";
export const ORDER_VENDOR_RESET = "ORDER_VENDOR_RESET";

export const ORDER_wholesaler_REQUEST = "ORDER_wholesaler_REQUEST";
export const ORDER_wholesaler_SUCCESS = "ORDER_wholesaler_SUCCESS";
export const ORDER_wholesaler_FAIL = "ORDER_wholesaler_FAIL";
export const ORDER_wholesaler_RESET = "ORDER_wholesaler_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_VENDOR_DETAILS_REQUEST = "ORDER_VENDOR_DETAILS_REQUEST";
export const ORDER_VENDOR_DETAILS_SUCCESS = "ORDER_VENDOR_DETAILS_SUCCESS";
export const ORDER_VENDOR_DETAILS_FAIL = "ORDER_VENDOR_DETAILS_FAIL";

export const ORDER_wholesaler_DETAILS_REQUEST =
  "ORDER_wholesaler_DETAILS_REQUEST";
export const ORDER_wholesaler_DETAILS_SUCCESS =
  "ORDER_wholesaler_DETAILS_SUCCESS";
export const ORDER_wholesaler_DETAILS_FAIL = "ORDER_wholesaler_DETAILS_FAIL";

export const DELIVERY_LABEL_DETAILS_REQUEST = "DELIVERY_LABEL_DETAILS_REQUEST";
export const DELIVERY_LABEL_DETAILS_SUCCESS = "DELIVERY_LABEL_DETAILS_SUCCESS";
export const DELIVERY_LABEL_DETAILS_FAIL = "DELIVERY_LABEL_DETAILS_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_FAIL";

export const ORDER_VENDOR_PAY_REQUEST = "ORDER_VENDOR_PAY_REQUEST";
export const ORDER_VENDOR_PAY_SUCCESS = "ORDER_VENDOR_PAY_SUCCESS";
export const ORDER_VENDOR_PAY_FAIL = "ORDER_VENDOR_PAY_FAIL";
export const ORDER_VENDOR_PAY_RESET = "ORDER_VENDOR_PAY_FAIL";

export const ORDER_wholesaler_PAY_REQUEST = "ORDER_wholesaler_PAY_REQUEST";
export const ORDER_wholesaler_PAY_SUCCESS = "ORDER_wholesaler_PAY_SUCCESS";
export const ORDER_wholesaler_PAY_FAIL = "ORDER_wholesaler_PAY_FAIL";
export const ORDER_wholesaler_PAY_RESET = "ORDER_wholesaler_PAY_FAIL";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_WHOLESALE_LIST_REQUEST = "ORDER_WHOLESALE_LIST_REQUEST";
export const ORDER_WHOLESALE_LIST_SUCCESS = "ORDER_WHOLESALE_LIST_SUCCESS";
export const ORDER_WHOLESALE_LIST_FAIL = "ORDER_WHOLESALE_LIST_FAIL";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const ORDER_VENDOR_DELIVER_REQUEST = "ORDER_VENDOR_DELIVER_REQUEST";
export const ORDER_VENDOR_DELIVER_SUCCESS = "ORDER_VENDOR_DELIVER_SUCCESS";
export const ORDER_VENDOR_DELIVER_FAIL = "ORDER_VENDOR_DELIVER_FAIL";
export const ORDER_VENDOR_DELIVER_RESET = "ORDER_VENDOR_DELIVER_RESET";

export const ORDER_wholesaler_DELIVER_REQUEST =
  "ORDER_wholesaler_DELIVER_REQUEST";
export const ORDER_wholesaler_DELIVER_SUCCESS =
  "ORDER_wholesaler_DELIVER_SUCCESS";
export const ORDER_wholesaler_DELIVER_FAIL = "ORDER_wholesaler_DELIVER_FAIL";
export const ORDER_wholesaler_DELIVER_RESET = "ORDER_wholesaler_DELIVER_RESET";

export const INVOICE_INFORMATION_REQUEST = "INVOICE_INFORMATION_REQUEST";
export const INVOICE_INFORMATION_SUCCESS = "INVOICE_INFORMATION_SUCCESS";
export const INVOICE_INFORMATION_FAIL = "INVOICE_INFORMATION_FAIL";

export const WHOLESALERORDER_UPDATE_REQUEST = "WHOLESALERORDER_UPDATE_REQUEST";
export const WHOLESALERORDER_UPDATE_SUCCESS = "WHOLESALERORDER_UPDATE_SUCCESS";
export const WHOLESALERORDER_UPDATE_FAIL = "WHOLESALERORDER_UPDATE_FAIL";
export const WHOLESALERORDER_UPDATE_RESET = "WHOLESALERORDER_UPDATE_RESET";

export const VENDORORDER_UPDATE_REQUEST = "VENDORORDER_UPDATE_REQUEST";
export const VENDORORDER_UPDATE_SUCCESS = "VENDORORDER_UPDATE_SUCCESS";
export const VENDORORDER_UPDATE_FAIL = "VENDORORDER_UPDATE_FAIL";
export const VENDORORDER_UPDATE_RESET = "VENDORORDER_UPDATE_RESET";

export const ADD_ORDERITEM_REQUEST = "ADD_ORDERITEM_REQUEST";
export const ADD_ORDERITEM_SUCCESS = "ADD_ORDERITEM_SUCCESS";
export const ADD_ORDERITEM_FAIL = "ADD_ORDERITEM_FAIL";
export const ADD_ORDERITEM_RESET = "ADD_ORDERITEM_RESET";

export const DELETE_ORDERITEM_REQUEST = "DELETE_ORDERITEM_REQUEST";
export const DELETE_ORDERITEM_SUCCESS = "DELETE_ORDERITEM_SUCCESS";
export const DELETE_ORDERITEM_FAIL = "DELETE_ORDERITEM_FAIL";
export const DELETE_ORDERITEM_RESET = "DELETE_ORDERITEM_RESET";

export const DELETE_ORDER_REQUEST = "DELETE_ORDER_REQUEST";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL";
export const DELETE_ORDER_RESET = "DELETE_ORDER_RESET";
