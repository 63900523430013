import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import Paginate from "../../components/Paginate";
import "./ProductListScreen.css";
import {
  deleteProduct,
  listAllProducts,
  publishTheProduct,
} from "../../actions/productActions";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_RESET,
} from "../../constants/productConstants";
import SearchBox from "../../components/SearchBox";

const AdminProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const keyword = match.params.keyword || "";
  const unpublished = match.params.unpublished || "";

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const allProductList = useSelector((state) => state.allProductList);
  const { loading, error, products, page, pages } = allProductList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    success: successDelete,
    loading: loadingDelete,
    error: errorDelete,
  } = productDelete;

  const productPublish = useSelector((state) => state.productPublish);
  const { success: successPublish } = productPublish;
  dispatch({ type: PRODUCT_UPDATE_RESET });

  const userId = userInfo?._id;
  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo?.isAdmin) {
      history.push("/login");
    }

    dispatch(listAllProducts(keyword, pageNumber, unpublished));
  }, [
    history,
    dispatch,
    userInfo,
    successDelete,
    pageNumber,
    successPublish,
    keyword,
    unpublished,
  ]);
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteProduct(id));
    }
  };

  const createproducthandler = (e) => {
    if (userInfo?.isAdmin) {
      history.push("/admin/product/create");
    }
  };

  const publishProduct = (id) => {
    dispatch(publishTheProduct(id));
  };
  return (
    <>
      <h1 className="productCreateHeading">Products </h1>
      <Row className="align-items-center mt-2">
        <Col md={4}></Col>
        <Col md={8}>
          <Row>
            <Col md={10}>
              <SearchBox
                url={
                  unpublished
                    ? "admin/productlist/:unpublished"
                    : "admin/productlist"
                }
                history={history}
                placing="productlist"
              />
            </Col>
            <Col md={2} className="addProduct">
              <Button onClick={createproducthandler} className="addProductBtn">
                <i className="far fa-plus-square productCreateIcon"></i> Create
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <LinkContainer to="/admin/productlist">
        <Button
          className={
            unpublished ? "adminProductListBtn" : "adminProductListBtnActive"
          }
        >
          Published
        </Button>
      </LinkContainer>
      <LinkContainer to="/admin/productlist/unpublished">
        <Button
          className={
            unpublished ? "adminProductListBtnActive" : "adminProductListBtn"
          }
        >
          Unpublished
        </Button>
      </LinkContainer>
      <div className="adminProductsContainer">
        {loadingDelete && <Loader />}
        {errorDelete && <Message variant="danger">{errorDelete}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Row className="mt-5 mb-5">
            <Table responsive>
              <thead>
                <tr className="productListRowHeading">
                  <th>PRODUCT LIST</th>
                  <th>PRICE</th>
                  <th>STOCK</th>
                  <th>CATEGORY</th>
                  <th>UPLOADER</th>
                  <th>{unpublished ? "PUBLISH" : "UNPUBLISH"}</th>
                  <th>EDIT/DELETE</th>
                </tr>
              </thead>
              <tbody className="productListTableBody">
                {products?.map((product) => (
                  <tr key={product._id}>
                    <td className="adminProductListNameRow">
                      <Row>
                        <Col md={3}>
                          <div className="productImageContainer">
                            <img
                              src={product.image}
                              alt={product.name}
                              className="productImage"
                            />
                          </div>
                        </Col>
                        <Col md={9}>
                          <div>
                            <Link
                              to={`/product/${product.name}/${product._id}`}
                            >
                              {product.name}
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </td>
                    <td>₱ {product.price}</td>
                    <td>{product.countInStock}</td>
                    <td> {product.category}</td>
                    <td>
                      {product?.vendorShop?.shopName ||
                        product?.wholesalerShop?.shopName}
                    </td>
                    <td>
                      <Button
                        className="btn-sm productListeditButton"
                        onClick={() => publishProduct(product._id)}
                      >
                        {product.publish === true ? (
                          <i className="fa fa-download" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-upload" aria-hidden="true"></i>
                        )}

                        <span className="publishTooltip">
                          {product.publish === true ? "Unpublish" : "Publish"}
                        </span>
                      </Button>
                    </td>
                    <td>
                      <LinkContainer
                        to={`${
                          userInfo?.isAdmin
                            ? `/admin/product/${product._id}/${userId}/edit`
                            : `/vendor/product/${product._id}/${userId}/edit`
                        }`}
                      >
                        <Button className="btn-sm productListeditButton">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        className="btn-sm productListdeleteButton"
                        onClick={() => deleteHandler(product._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        )}
      </div>
      <Paginate
        keyword={keyword}
        url={
          unpublished ? `/admin/productlist/:unpublished` : `/admin/productlist`
        }
        pages={pages}
        page={page}
      />
    </>
  );
};
export default AdminProductListScreen;

// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { LinkContainer } from "react-router-bootstrap";
// import { Button } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";
// import Paginate from "../../components/Paginate";
// import "./ProductListScreen.css";
// import {
//   deleteProduct,
//   listAllProducts,
//   publishTheProduct,
// } from "../../actions/productActions";
// import {
//   PRODUCT_CREATE_RESET,
//   PRODUCT_UPDATE_RESET,
// } from "../../constants/productConstants";
// import SearchBox from "../../components/SearchBox";

// const AdminProductListScreen = ({ history, match }) => {
//   const pageNumber = match.params.pageNumber || 1;
//   const keyword = match.params.keyword || "";
//   const dispatch = useDispatch();

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const allProductList = useSelector((state) => state.allProductList);
//   const { loading, error, products, page, pages } = allProductList;

//   const productDelete = useSelector((state) => state.productDelete);
//   const {
//     success: successDelete,
//     loading: loadingDelete,
//     error: errorDelete,
//   } = productDelete;

//   const productPublish = useSelector((state) => state.productPublish);
//   const { success: successPublish } = productPublish;
//   dispatch({ type: PRODUCT_UPDATE_RESET });

//   const userId = userInfo?._id;
//   useEffect(() => {
//     dispatch({ type: PRODUCT_CREATE_RESET });

//     if (!userInfo?.isAdmin) {
//       history.push("/login");
//     }

//     dispatch(listAllProducts(keyword, pageNumber));
//   }, [
//     history,
//     dispatch,
//     userInfo,
//     successDelete,
//     pageNumber,
//     successPublish,
//     keyword,
//   ]);
//   const deleteHandler = (id) => {
//     if (window.confirm("Are you sure")) {
//       dispatch(deleteProduct(id));
//     }
//   };

//   const createproducthandler = (e) => {
//     if (userInfo?.isAdmin) {
//       history.push("/admin/product/create");
//     }
//   };

//   const publishProduct = (id) => {
//     dispatch(publishTheProduct(id));
//   };
//   return (
//     <>
//       <SearchBox
//         url="admin/productlist"
//         history={history}
//         placing="productlist"
//       />
//       <div className="myProductsContainer">
//         <div className="myProductsCreateButton">
//           <Button className="createButton" onClick={createproducthandler}>
//             <i className="fas fa-plus"></i>Create Product
//           </Button>
//         </div>

//         {loadingDelete && <Loader />}
//         {errorDelete && <Message variant="danger">{errorDelete}</Message>}
//         {loading ? (
//           <Loader />
//         ) : error ? (
//           <Message variant="danger">{error}</Message>
//         ) : (
//           <>
//             <div className="productTableHeadingRow">
//               <div className="productName">Product</div>
//               <div className="productPrice">Price</div>
//               <div className="productCategory">Category</div>
//               <div className="productOnStock">Stock</div>
//               <div className="productBrand">Uploader</div>
//               <div className="productChangingButtons">Publish/Edit/Delete</div>
//             </div>

//             {products?.map((product) => {
//               return (
//                 <>
//                   <div className="productTableRow" key={product._id}>
//                     <div className="productName">
//                       <div className="productImageContainer">
//                         <img
//                           src={product.image}
//                           alt={product.name}
//                           className="productImage"
//                         />
//                       </div>
//                       <div className="productInfo">
//                         <Link
//                           className="productInfoLink"
//                           to={`/product/${product.name}/${product._id}`}
//                         >
//                           {product.name}
//                         </Link>
//                       </div>
//                     </div>
//                     <div className="productPrice">
//                       <span>${product.price}</span>
//                     </div>
//                     <div className="productCategory">
//                       <span>{product.category}</span>
//                     </div>
//                     <div className="productOnStock">
//                       <span>{product.countInStock}</span>
//                     </div>
//                     <div className="productBrand">
//                       <span>
//                         {product?.vendorShop?.shopName ||
//                           product?.wholesalerShop?.shopName}
//                       </span>
//                     </div>
//                     <div className="productChangingButtons">
//                       <Button
//                         className="btn-sm publishButton"
//                         onClick={() => publishProduct(product._id)}
//                       >
//                         {product.publish === true ? (
//                           <i className="fa fa-download" aria-hidden="true"></i>
//                         ) : (
//                           <i className="fa fa-upload" aria-hidden="true"></i>
//                         )}

//                         <span className="publishTooltip">
//                           {product.publish === true ? "Unpublish" : "Publish"}
//                         </span>
//                       </Button>{" "}
//                       <LinkContainer
//                         to={`${
//                           userInfo?.isAdmin
//                             ? `/admin/product/${product._id}/${userId}/edit`
//                             : `/vendor/product/${product._id}/${userId}/edit`
//                         }`}
//                       >
//                         <Button variant="light" className="btn-sm editButton">
//                           <i className="fas fa-edit"></i>
//                         </Button>
//                       </LinkContainer>
//                       <Button
//                         variant="danger"
//                         className="btn-sm deleteButton"
//                         onClick={() => deleteHandler(product._id)}
//                       >
//                         <i className="fas fa-trash"></i>
//                       </Button>
//                     </div>
//                   </div>
//                   <div className="horizontalLine"></div>
//                 </>
//               );
//             })}
//           </>
//         )}
//       </div>

//       <Paginate url={`/admin/productlist`} pages={pages} page={page} />
//     </>
//   );
// };

// export default AdminProductListScreen;
