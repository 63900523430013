import React from "react";
import { Route } from "react-router-dom";
import RegisterScreen from "../screens/Register/RegisterScreen";
import VendorRegistrationScreen from "../screens/Register/VendorRegistrationScreen";

const RegisterRoutes = () => {
  return (
    <div>
      <Route path="/vendor/registration" component={VendorRegistrationScreen} />
      <Route path="/register" component={RegisterScreen} />
    </div>
  );
};

export default RegisterRoutes;
