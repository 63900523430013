import React from "react";
import { Container } from "react-bootstrap";

const HowToBuy = () => {
  return (
    <Container>
      <img
        src="/images/commingsoon.png"
        alt="About Us"
        style={{ height: "50vh", width: "100%" }}
      />
    </Container>
  );
};

export default HowToBuy;
