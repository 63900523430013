import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const AllCategoryScreen = () => {
  const categoryHeadingsList = useSelector(
    (state) => state.categoryHeadingsList
  );
  const { categories } = categoryHeadingsList;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        flexWrap: "wrap",
      }}
    >
      {categories?.map((category) => {
        return (
          <Link
            key={category._id}
            to={`/category/${category.name}`}
            style={{ textDecoration: "none" }}
          >
            <div
              style={{
                height: "130px",
                width: "130px",
                display: "flex",
                margin: "20px 22.5px",
                flexDirection: "column",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
                background: "#F9F9F9",
              }}
            >
              <div
                className="imageContainer"
                style={{
                  height: "60%",
                  width: "60%",
                  padding: "5px",
                }}
              >
                <img
                  src={category.image}
                  alt="Category"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              {category.name}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default AllCategoryScreen;
