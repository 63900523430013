import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";
import ManageStaffsComponent from "../../components/Dashboard/ManageStaffsComponent";

const ManageStaffs = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user } = vendorDetails;
  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;
  useEffect(() => {
    if (userInfo.type === "vendor") {
      dispatch(getVendorDetails(userInfo._id));
    } else {
      dispatch(getWholesalerDetails(userInfo._id));
    }
  }, [userInfo._id, dispatch, userInfo.type]);
  return (
    <div className="mt-20">
      <ManageStaffsComponent
        user={userInfo?.type === "vendor" ? user : userDetails}
      />
    </div>
  );
};

export default ManageStaffs;
