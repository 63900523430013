import React from "react";
import { Route } from "react-router-dom";
import ProfileScreen from "../screens/User/ProfileScreens";
import ShopDetailsScreen from "../screens/User/ShopDetailsScreen";
import ShopProfileScreen from "../screens/User/ShopProfileScreen";
import UserEditScreen from "../screens/User/UserEditScreen";
import UserListScreen from "../screens/User/UserListScreen";

const UserRoutes = () => {
  return (
    <>
      <Route path="/admin/userlist" component={UserListScreen} exact />
      <Route
        path="/admin/userlist/page/:pageNumber"
        component={UserListScreen}
      />

      <Route path="/admin/user/edit/:id" component={UserEditScreen} />
      <Route path="/profile" component={ProfileScreen} />
      <Route path="/shopprofile" component={ShopProfileScreen} />
      <Route path="/shopDetails/:id" component={ShopDetailsScreen} />
    </>
  );
};

export default UserRoutes;
