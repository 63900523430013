import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listBestOffersProducts,
  listWishlist,
} from "../../actions/productActions";
import { Row, Col, Toast, Alert } from "react-bootstrap";
import Product from "../../components/Product.js";
import Paginate from "../../components/Paginate";

const BestOfferScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const bestOffersProductList = useSelector(
    (state) => state.bestOffersProductList
  );
  const { products: bestOffersProducts, pages, page } = bestOffersProductList;
  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };

  useEffect(() => {
    dispatch(listBestOffersProducts(pageNumber));
    dispatch(listWishlist());
  }, [dispatch, pageNumber]);

  return (
    <div className="container">
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}
      <Row className="mt-4">
        <Col md={12} xl={12} sm={6}>
          <h3
            style={{
              fontFamily: "Montserrat",
              fontSize: 36,
              color: "#DC1E3E",
            }}
          >
            Best Offer{" "}
            <span
              style={{
                fontFamily: "Montserrat",
                fontSize: 36,
                color: "#003893",
              }}
            >
              Product{" "}
            </span>
          </h3>
        </Col>
      </Row>
      <Row>
        {bestOffersProducts?.map((product) => {
          return (
            <Col md={2} xl={2} sm={6}>
              <Product
                key={product.id}
                history={history}
                product={product}
                toastToggler={toastToggler}
              />
            </Col>
          );
        })}
      </Row>
      <Paginate url="/best-offer" pages={pages} page={page} />
    </div>
  );
};

export default BestOfferScreen;
