import {
  CART_ADD_ITEM,
  CART_ADD_TO_SERVER_FAIL,
  CART_ADD_TO_SERVER_REQUEST,
  CART_ADD_TO_SERVER_RESET,
  CART_ADD_TO_SERVER_SUCCESS,
  CART_EDIT_CHECKED_SERVER_FAIL,
  CART_EDIT_CHECKED_SERVER_REQUEST,
  CART_EDIT_CHECKED_SERVER_RESET,
  CART_EDIT_CHECKED_SERVER_SUCCESS,
  CART_GET_CHECKED_FROM_SERVER_FAIL,
  CART_GET_CHECKED_FROM_SERVER_REQUEST,
  CART_GET_CHECKED_FROM_SERVER_SUCCESS,
  CART_GET_FROM_SERVER_FAIL,
  CART_GET_FROM_SERVER_REQUEST,
  CART_GET_FROM_SERVER_SUCCESS,
  CART_REMOVE_CHECKED_FROM_SERVER_FAIL,
  CART_REMOVE_CHECKED_FROM_SERVER_REQUEST,
  CART_REMOVE_CHECKED_FROM_SERVER_RESET,
  CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS,
  CART_REMOVE_FROM_SERVER_FAIL,
  CART_REMOVE_FROM_SERVER_REQUEST,
  CART_REMOVE_FROM_SERVER_RESET,
  CART_REMOVE_FROM_SERVER_SUCCESS,
  CART_REMOVE_ITEM,
  CART_RESET,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../constants/cartConstants";

export const cartItemAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_ADD_TO_SERVER_REQUEST:
      return {
        loading: true,
      };

    case CART_ADD_TO_SERVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case CART_ADD_TO_SERVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CART_ADD_TO_SERVER_RESET:
      return {};

    default:
      return state;
  }
};

// export const getCartItemsReducer = (state = { cart: [] }, action) => {
//   switch (action.type) {
//     case CART_GET_FROM_SERVER_REQUEST:
//       return { loading: true, ...state };
//     case CART_GET_FROM_SERVER_SUCCESS:
//       return { loading: false, cart: action.payload };
//     case CART_GET_FROM_SERVER_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// export const getCheckedCartItemsReducer = (state = { cart: [] }, action) => {
//   switch (action.type) {
//     case CART_GET_CHECKED_FROM_SERVER_REQUEST:
//       return { loading: true, ...state };
//     case CART_GET_CHECKED_FROM_SERVER_SUCCESS:
//       return { loading: false, cart: action.payload };
//     case CART_GET_CHECKED_FROM_SERVER_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

export const getCartItemsReducer = (
  state = { carts: {}, shops: {} },
  action
) => {
  switch (action.type) {
    case CART_GET_FROM_SERVER_REQUEST:
      return { loading: true, ...state };
    case CART_GET_FROM_SERVER_SUCCESS:
      return {
        loading: false,
        cart: action.payload.cart,
        shop: action.payload.shopdetails,
      };
    case CART_GET_FROM_SERVER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getCheckedCartItemsReducer = (state = { cart: [] }, action) => {
  switch (action.type) {
    case CART_GET_CHECKED_FROM_SERVER_REQUEST:
      return { loading: true, ...state };
    case CART_GET_CHECKED_FROM_SERVER_SUCCESS:
      return { loading: false, cart: action.payload };
    case CART_GET_CHECKED_FROM_SERVER_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cartItemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_REMOVE_FROM_SERVER_REQUEST:
      return { loading: true };
    case CART_REMOVE_FROM_SERVER_SUCCESS:
      return { loading: false, success: true };
    case CART_REMOVE_FROM_SERVER_FAIL:
      return { loading: false, error: action.payload };
    case CART_REMOVE_FROM_SERVER_RESET:
      return {};
    default:
      return state;
  }
};

export const cartItemEditCheckedReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_EDIT_CHECKED_SERVER_REQUEST:
      return { loading: true };
    case CART_EDIT_CHECKED_SERVER_SUCCESS:
      return { loading: false, success: true };
    case CART_EDIT_CHECKED_SERVER_FAIL:
      return { loading: false, error: action.payload };
    case CART_EDIT_CHECKED_SERVER_RESET:
      return {};

    default:
      return state;
  }
};

export const cartItemsDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_REMOVE_CHECKED_FROM_SERVER_REQUEST:
      return { loading: true };
    case CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS:
      return { loading: false, success: true };
    case CART_REMOVE_CHECKED_FROM_SERVER_FAIL:
      return { loading: false, error: action.payload };
    case CART_REMOVE_CHECKED_FROM_SERVER_RESET:
      return {};
    default:
      return state;
  }
};

export const cartReducer = (state = { cartItems: [], address: {} }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem = state.cartItems.find((x) => x.product === item.product);
      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product ? item : x
          ),
        };
      } else {
        return { ...state, cartItems: [...state.cartItems, item] };
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.product !== action.payload),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CART_RESET:
      return { cartItems: [] };

    default:
      return state;
  }
};
