import React, { useEffect } from "react";

// components
import { useDispatch, useSelector } from "react-redux";
import CardStats from "./Cards/CardStats.js";

import { dashboardCardStats } from "../../actions/dashboardAction.js";

const DashboardCardStats = () => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: detailsUser } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;

  const dashboardCard = useSelector((state) => state.dashboardCard);
  const {
    products,
    active_products,
    order,
    paidorder,
    worder,
    wpaidorder,
    paid_sales,
    unpaid_sales,
    lessstock,
    nostock,
  } = dashboardCard;

  const inactive_products = products - active_products;
  const notpaid_order = order - paidorder;
  const wnotpaid_order = worder - wpaidorder;
  const total_sales = paid_sales + unpaid_sales;
  const total_stock_out = lessstock + nostock;

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(dashboardCardStats(detailsUser?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(dashboardCardStats(userDetails?._id));
    }
  }, [
    dispatch,
    userInfo?.type,
    userInfo?._id,
    userDetails?._id,
    detailsUser?._id,
  ]);

  return (
    <>
      {/* Header */}
      <div className="relative md:pt-32 pb-24 mt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Product"
                    statTotalProduct={products}
                    statArrow="up"
                    statActiveProduct={active_products}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Active Product"
                    statInActiveProduct={inactive_products}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Inactive Product"
                    statIconName="fab fa-product-hunt fa-2x"
                    statIconColor="bg-red-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Order"
                    statTotalProduct={order}
                    statArrow="up"
                    statActiveProduct={paidorder}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Paid Order"
                    statInActiveProduct={notpaid_order}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="UnPaid Order"
                    statIconName="fas fa-clipboard-check fa-2x"
                    statIconColor="bg-yellow-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Sales"
                    statTotalProduct={parseFloat(total_sales).toFixed(2)}
                    statArrow="up"
                    statActiveProduct={parseFloat(paid_sales).toFixed(2)}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Total Sales Amount Paid"
                    statInActiveProduct={parseFloat(unpaid_sales).toFixed(2)}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Sales Amount to be Paid"
                    statIconName="fas fa-hand-holding-usd fa-2x"
                    statIconColor="bg-green-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Less/Out of Stock"
                    statTotalProduct={total_stock_out}
                    statArrow="up"
                    statActiveProduct={lessstock}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Less Stock Product"
                    statInActiveProduct={nostock}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Out of stock Product"
                    statIconName="fab fa-product-hunt fa-2x"
                    statIconColor="bg-pink-500"
                  />
                </div>
              </div>
            ) : null}
            {userInfo && !userInfo.isAdmin && userInfo.type === "wholesaler" ? (
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Product"
                    statTotalProduct={products}
                    statArrow="up"
                    statActiveProduct={active_products}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Active Product"
                    statInActiveProduct={inactive_products}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Inactive Product"
                    statIconName="fab fa-product-hunt fa-2x"
                    statIconColor="bg-red-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Order"
                    statTotalProduct={worder}
                    statArrow="up"
                    statActiveProduct={wpaidorder}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Paid Order"
                    statInActiveProduct={wnotpaid_order}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="UnPaid Order"
                    statIconName="fas fa-clipboard-check fa-2x"
                    statIconColor="bg-yellow-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Total Sales"
                    statTotalProduct={parseFloat(total_sales).toFixed(2)}
                    statArrow="up"
                    statActiveProduct={parseFloat(paid_sales).toFixed(2)}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Total Sales Amount Paid"
                    statInActiveProduct={parseFloat(unpaid_sales).toFixed(2)}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Sales Amount to be Paid"
                    statIconName="fas fa-hand-holding-usd fa-2x"
                    statIconColor="bg-green-500"
                  />
                </div>
                <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                  <CardStats
                    statSubtitle="Less/Out of Stock"
                    statTotalProduct={total_stock_out}
                    statArrow="up"
                    statActiveProduct={lessstock}
                    statActiveColor="text-green-500"
                    statActiveDescripiron="Less Stock Product"
                    statInActiveProduct={nostock}
                    statInActiveColor="text-red-500"
                    statInActiveDescripiron="Out of stock Product"
                    statIconName="fab fa-product-hunt fa-2x"
                    statIconColor="bg-pink-500"
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardCardStats;
