import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import ReactImageZoom from "react-image-zoom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Button,
  Form,
  Nav,
  Toast,
  Alert,
} from "react-bootstrap";
import RatingWithoutSold from "../../components/RatingWithoutSold";
import {
  listProductDetails,
  listProducts,
  listMyPublishedProducts,
  listMyPublishedProductsWholesaler,
  publishTheProduct,
} from "../../actions/productActions";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Meta from "../../components/Meta";
import { addCartItemToServer } from "../../actions/cartActions";
import AddToWishListButton from "../../components/AddToWishListButton";
import "../../index.css";
import ShareButton from "../../components/ShareButton";
import parse from "html-react-parser";
import "./ProductScreen.css";
import Loader from "../../components/Loader";
import { PRODUCT_PUBLISH_RESET } from "../../constants/productConstants";

//google tag manager
import TagManager from "react-gtm-module";

const ProductScreen = ({ history, match }) => {
  const keyword = match.params.keyword;
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { product } = productDetails;
  const [imag, setImag] = useState(product?.image);
  const [qty, setQty] = useState(1);
  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const prdRef = useRef(null);

  const revRef = useRef(null);

  const faqRef = useRef(null);

  const scrollToProduct = () => {
    prdRef.current.scrollIntoView();
  };

  const scrollToReview = () => {
    revRef.current.scrollIntoView();
  };

  const scrollToFaq = () => {
    faqRef.current.scrollIntoView();
  };

  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productListMyPublished = useSelector(
    (state) => state.productListMyPublished
  );
  const { loading, error, products } = productListMyPublished;

  const productPublish = useSelector((state) => state.productPublish);
  const { success: successPublish } = productPublish;

  const addToCartHandler = () => {
    const tagManagerArgs = {
      gtmId: "GTM-P27XCP3",
      dataLayer: {
        event: "Add to cart", // event to be picked up by GTM
        productId: product?._id || "", // anything you want here
        name: product?.name || "",
        category: product?.category || "",
        brand: product?.brand || "",
        price: product?.price || "",
        userId: userInfo?._id || "",
        time: Date.now(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);

    if (!userInfo) {
      history.push("/login");
    }
    dispatch(addCartItemToServer(match.params.id, qty));
    setShowToast(true);
  };

  //If ordered quantity is beyond the range of products count in stock and min Order Quantity the add to cart button is disabled
  const buttonDisabler =
    product?.countInStock === 0 ||
    product?.minOrderQuantity > qty ||
    qty > product?.countInStock;
  //finds the category product lies in as category in product can be entity of any level so we compare it with all and whole category chain is
  //displayed on the top

  const props = {
    width: 500,
    height: 500,
    zoomWidth: 500,
    zoomPosition: "original",
    img: imag,
  };

  const sold = product?.initialStock - product?.countInStock;

  const imageChanger = (im) => {
    setImag(im);
  };
  // const quickBuy = () => {
  //   dispatch(addCartItemToServer(match.params.id.trim(), qty));

  //   if (userInfo.type === "customer") {
  //     history.push("/customer/placeorder");
  //   } else if ((userInfo.type = "vendor")) {
  //     history.push("/retailer/placeorder");
  //   } else if ((userInfo.type = "wholesaler")) {
  //     history.push("/wholesale/placeorder");
  //   }
  // };

  useEffect(() => {
    if (product?.uploaderType === "vendor") {
      dispatch(
        listMyPublishedProducts(product?.vendorShop?.shopName, "", 1, "")
      );
    } else if (product?.uploaderType === "wholesaler") {
      dispatch(
        listMyPublishedProductsWholesaler(
          product?.wholesalerShop?.shopName,
          "",
          1,
          ""
        )
      );
    }
  }, [
    dispatch,
    product?.vendorShop?.shopName,
    product?.wholesalerShop?.shopName,
    product?.uploaderType,
  ]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // if (successProductReview) {
    //   alert("Review Submitted!");
    // setRating(0);
    // setComment("");
    //   dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    // }
    dispatch(listProductDetails(match.params.id));
    setImag(product?.image);
    setQty(product?.minOrderQuantity);
  }, [
    dispatch,
    match,
    // successProductReview,
    product?.minOrderQuantity,
    product?.updatedById,
    product?.image,
    product?.uploaderType,
  ]);
  useEffect(() => {
    if (successPublish) {
      alert("Product publish status changed");
    }
    dispatch(listProductDetails(match.params.id));
    dispatch({ type: PRODUCT_PUBLISH_RESET });
  }, [dispatch, successPublish, match.params.id]);
  useEffect(() => {
    dispatch(listProducts(6, keyword));
  }, [dispatch, keyword]);

  return (
    <>
      <Meta
        title={product?.name}
        name={product?.name}
        url={window.location.href}
        image={product?.image}
        desc={product?.description?.substring(0, 50)}
      />
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}
      <Row className="breadcrumb pt-4">
        <Col>
          <h5 style={{ letterSpacing: "0px" }}>
            {product?.categorySet &&
              product.categorySet.map((cat) => {
                if (cat !== null) {
                  return (
                    <Link to={`/category/${cat}`}>
                      <span className="category">
                        {cat}
                        <i className="fas fa-chevron-right mx-2"></i>
                      </span>
                    </Link>
                  );
                }
                return;
              })}

            <span className="category">{product?.name}</span>
          </h5>
        </Col>
        {userInfo?.isAdmin && (
          <Col>
            {product.publish ? (
              <Button onClick={() => dispatch(publishTheProduct(product._id))}>
                Unpublish
              </Button>
            ) : (
              <Button onClick={() => dispatch(publishTheProduct(product._id))}>
                Publish
              </Button>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col md={6}>
          <Row style={{ marginBottom: "3px" }}>
            {imag && <ReactImageZoom {...props} className="img-fluid" />}
          </Row>
          <Row>
            <Col
              className="col-1 col-md-1 col-xl-1 mr-2 my-2"
              onClick={() => imageChanger(product?.image)}
            >
              {product?.images && (
                <Image
                  src={product?.image}
                  className="img-fluid product-image"
                />
              )}
            </Col>

            <Col
              className="col-1 col-md-1 col-xl-1 m-2"
              onClick={() => imageChanger(product?.images[0])}
            >
              {product?.images && product?.images[0] && (
                <Image
                  src={product?.images[0]}
                  className="img-fluid product-image"
                />
              )}
            </Col>

            <Col
              className="col-1 col-md-1 col-xl-1 m-2"
              onClick={() => imageChanger(product?.images[1])}
            >
              {product?.images && product?.images[1] && (
                <Image
                  src={product?.images[1]}
                  className="img-fluid product-image"
                />
              )}
            </Col>
            <Col
              className="col-1 col-md-1 col-xl-1 m-2"
              onClick={() => imageChanger(product?.images[2])}
            >
              {product?.images && product?.images[2] && (
                <Image
                  src={product?.images[2]}
                  className="img-fluid product-image"
                />
              )}
            </Col>
            <Col
              className="col-1 col-md-1 col-xl-1 m-2"
              onClick={() => imageChanger(product?.images[3])}
            >
              {product?.images && product?.images[3] && (
                <Image
                  src={product?.images[3]}
                  className="img-fluid product-image"
                />
              )}
            </Col>

            <Col
              className="col-1 col-md-1 col-xl-1 m-2"
              onClick={() => imageChanger(product?.images[4])}
            >
              {product?.images && product?.images[4] && (
                <Image
                  src={product?.images[4]}
                  className="img-fluid product-image"
                />
              )}
            </Col>

            <Col
              className="col-1 col-md-1 col-xl-1 ml-2 my-2"
              onClick={() => imageChanger(product?.images[5])}
            >
              {product?.images && product?.images[5] && (
                <Image
                  src={product?.images[5]}
                  className="img-fluid product-image"
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col md={6}>
          <Row>
            <h3 className="productscreen-header">{product?.name}</h3>
          </Row>
          <Row className="mt-4">
            <Col md={9} style={{ paddingTop: 10 }}>
              <RatingWithoutSold value={product?.rating} />
            </Col>
            <Col md={3}>
              <Row>
                <Col md={6}>
                  <ShareButton />
                </Col>
                <Col md={6}>
                  <AddToWishListButton product={product} />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <Row className="mt-4">
            <Col md={8}>
              {product?.countInStock > 0
                ? `Product Available(${product.countInStock})`
                : "Out Of Stock"}
            </Col>
            <Col md={4} style={{ textAlign: "end" }}>
              {product?.countInStock > 0 ? `Sold(${sold})` : "Out Of Stock"}
            </Col>
          </Row> */}
          <Row className="mt-4">
            <Col
              md={8}
              className={product?.countInStock > 0 ? "text-black" : "text-red"}
            >
              {product?.countInStock > 0
                ? `Product Available (${product.countInStock})`
                : "Out Of Stock"}
            </Col>
            <Col md={4} style={{ textAlign: "end" }} className="text-black">
              {`Sold (${sold})`}
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={7}>
              {product?.countInStock > 0 && (
                <Row>
                  <Col md={3}>
                    <h3 className="productscreen-quantity">Quantity</h3>
                  </Col>
                  <Col md={3}>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                    <Form.Control
                      type="number"
                      value={qty}
                      min={product?.minOrderQuantity}
                      max={product?.countInStock}
                      onChange={(e) => {
                        setQty(e.target.value);

                        if (
                          e.target.value > product?.countInStock ||
                          e.target.value < product?.minOrderQuantity
                        ) {
                          setErrorMessage("Ordered quantity is out of Range");
                        } else {
                          setErrorMessage("");
                        }
                      }}
                    ></Form.Control>
                  </Col>
                  <Col md={6} className="mt-2">
                    <span style={{ fontSize: "9px" }}>
                      `(Minimum order quantity {product?.minOrderQuantity})`
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="ms-1">
                {product?.discount ? (
                  <>
                    <Row
                      style={{
                        color: "gray",
                        textDecoration: "line-through",
                      }}
                    >
                      ₱ {product?.price}
                    </Row>
                    <Row style={{ color: "#dc143c", fontSize: "25px" }}>
                      ₱ {product.price - product.discount}
                    </Row>
                  </>
                ) : (
                  <Row style={{ color: "#dc143c", fontSize: "20px" }}>
                    ₱ {product?.price}
                  </Row>
                )}
              </Row>
            </Col>
            {/* <Col md={4} className="productscreen-return">
              {product?.returnScheme && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-undo-alt mt-1">
                    &nbsp;&nbsp; {product?.returnScheme}
                  </i>
                </Row>
              )}
              {product?.warranty && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-shield-alt mt-1">
                    &nbsp;&nbsp; {product?.warranty}
                  </i>
                </Row>
              )}
              {product?.guarantee && (
                <Row className="my-3 ms-3">
                  <i className="fas fa-certificate mt-1">
                    &nbsp;&nbsp; {product?.guarantee}
                  </i>
                </Row>
              )}
            </Col> */}
            <Col md={4} className="productscreen-return">
              {product?.returnScheme && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-undo-alt mt-1">
                    &nbsp;&nbsp; {product?.returnScheme}
                  </i>
                </Row>
              )}
              {!product?.returnScheme && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-undo-alt mt-1">&nbsp;&nbsp; No Return</i>
                </Row>
              )}
              {product?.warranty && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-shield-alt mt-1">
                    &nbsp;&nbsp; {product?.warranty}
                  </i>
                </Row>
              )}
              {!product?.warranty && (
                <Row className="mt-3 ms-3">
                  <i className="fas fa-shield-alt mt-1">
                    &nbsp;&nbsp; No Guarantee
                  </i>
                </Row>
              )}
              {product?.guarantee && (
                <Row className="my-3 ms-3">
                  <i className="fas fa-certificate mt-1">
                    &nbsp;&nbsp; {product?.guarantee}
                  </i>
                </Row>
              )}
              {!product?.guarantee && (
                <Row className="my-3 ms-3">
                  <i className="fas fa-certificate mt-1">
                    &nbsp;&nbsp; No Warranty
                  </i>
                </Row>
              )}
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <Button
                className="btn-block productscreen-button"
                type="button"
                variant="info"
                disabled={buttonDisabler}
                onClick={addToCartHandler}
              >
                Add to Cart
              </Button>
            </Col>
            <Col md={6}>
              {product?.externalCheckoutLink && (
                <a href={product?.externalCheckoutLink}>
                  <Button
                    className="btn-block productscreen-button"
                    type="button"
                    variant="info"
                    style={{ float: "right" }}
                  >
                    Checkout via Shopee
                  </Button>
                </a>
              )}

              {/* <Button
                className="btn-block productscreen-button"
                type="button"
                variant="info"
                style={{ float: "right" }}
                disabled={buttonDisabler}
                onClick={quickBuy}
              >
                Quick Buy
              </Button> */}
            </Col>
          </Row>
          <hr style={{ paddingRight: 0, marginRight: 0 }} />
          <Row className="productscreen-sellerData mt-4">
            <Col md={6} className="product-sold ps-4">
              {" "}
              <Row>
                <span className="sold-by">Sold By</span>
              </Row>
              <Row>
                <h6 className="mt-1">
                  {product?.uploaderType === "vendor"
                    ? product?.vendorShop?.shopName.substring(0, 25)
                    : product?.vendorShop?.shopName.substring(0, 25)}
                  {product?.uploaderType === "wholesaler"
                    ? product?.wholesalerShop?.shopName.substring(0, 25)
                    : product?.wholesalerShop?.shopName.substring(0, 25)}
                </h6>
              </Row>
            </Col>
            <Col md={6} className="ps-4">
              {" "}
              <Row>
                <span className="sold-by">Seller Rating</span>
              </Row>
              <Row>
                <h6 className="mt-1">
                  <RatingWithoutSold
                    value={
                      shop
                        ? shop.reviews?.reduce(
                            (acc, item) => item.rating + acc,
                            0
                          ) / shop.reviews?.length
                        : shopInfo?.reviews?.reduce(
                            (acc, item) => item.rating + acc,
                            0
                          ) / shopInfo?.reviews?.length
                    }
                  />
                </h6>
              </Row>
            </Col>
            <Col md={12} className="text-center store-link">
              {product?.uploaderType === "vendor" && (
                <Link to={`/shop/${product?.vendorShop?.shopName}`}>
                  Go To Store
                </Link>
              )}
              {product?.uploaderType === "wholesaler" && (
                <Link
                  to={`/wholesaleshop/${product?.wholesalerShop?.shopName}`}
                >
                  Go To Store
                </Link>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-4">
        <Nav className="nav nav-justified product-nav">
          <Nav.Link className="nav-link my-nav" onClick={scrollToProduct}>
            Product Details
          </Nav.Link>
          <Nav.Link className="nav-link my-nav" onClick={scrollToReview}>
            Reviews
          </Nav.Link>
          <Nav.Link className="nav-link my-nav" onClick={scrollToFaq}>
            FAQs
          </Nav.Link>
        </Nav>
      </Row>
      <Row className="my-3">
        <div id="nav-tabContent">
          <div
            id="nav-product-details"
            ref={prdRef}
            className="product-tabs my-5 ps-4"
          >
            <h4 className="mb-3">Product Details</h4>
            {product?.description && parse(product.description)}
          </div>
          <hr style={{ paddingRight: 0, marginRight: 0 }} />
          <div
            id="nav-product-reviews"
            ref={revRef}
            className="product-tabs my-5 ps-4"
          >
            <h4 className="mb-3">Reviews</h4>
            {product?.reviews?.length === 0 && <Message>No Reviews</Message>}
            <ListGroup>
              {product?.reviews?.map((review) => (
                <Row className="mx-2">
                  <Col md={2}>
                    <img
                      className="rounded-circle z-depth-2"
                      alt="man"
                      src="/images/default-man.jpg"
                      data-holder-rendered="true"
                    ></img>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <h5 className="review-name ">{review.name}</h5>
                    </Row>
                    <Row>
                      <RatingWithoutSold
                        value={review.rating}
                        className="mt-2"
                      />
                    </Row>
                    <Row>
                      <p className="review-comment mt-2">{review.comment}</p>
                    </Row>
                    <Row>
                      {review.images &&
                        review.images.map((image, index) => {
                          return (
                            <Col md={2}>
                              {image && (
                                <a
                                  href={image}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    key={index}
                                    className="img-thumbnail"
                                    src={image}
                                    alt="imageAlt"
                                  />
                                </a>
                              )}
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                  <Col md={2}>{review.createdAt?.substring(0, 10)}</Col>
                </Row>
              ))}
            </ListGroup>
          </div>
          <hr style={{ paddingRight: 0, marginRight: 0 }} />
          <div
            id="nav-product-faqs"
            ref={faqRef}
            className="product-tabs my-5 px-4"
          >
            <h4 className="mb-3">FAQS</h4>
            {product?.FAQs?.map((faq) => (
              <>
                <p className="faq-box">
                  <b>Question:</b> {faq.question}
                </p>
                <p className="faq-box ">
                  <b>Answer:</b>
                  {faq.answer}
                </p>
              </>
            ))}
          </div>
        </div>
      </Row>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row className="my-5">
          <h4 className="mb-4">Products From Same Store</h4>
          {products?.slice(0, 4).map((p) => {
            if (p._id !== product?._id) {
              return (
                <Col className="mx-2" md={2} xl={2} sm={6} key={p._id}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={p}
                  />
                </Col>
              );
            }
            return;
          })}
        </Row>
      )}
    </>
  );
};

export default ProductScreen;
