import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Tab, Row, Col, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listUsers,
  deleteUser,
  getVendorRegistrationList,
  approveForSelling,
  disapproveForSelling,
  getWholesalerRegistrationList,
} from "../../actions/userActions";
import Paginate from "../../components/Paginate";
import "./UserListScreen.css";

const UserListScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const userList = useSelector((state) => state.userList);
  const { loading, error, users, pages, page } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorList = useSelector((state) => state.vendorList);
  const { vendors } = vendorList;

  const wholesalerList = useSelector((state) => state.wholesalerList);
  const { wholesalers } = wholesalerList;

  const sellerStatusUpdate = useSelector((state) => state.sellerStatusUpdate);
  const { success: statusUpdateSuccessful } = sellerStatusUpdate;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  const deletehandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteUser(id));
    }
  };
  const approveAsSeller = (type, status, id) => {
    if (status === "approved") {
      dispatch(approveForSelling({ type, status, id }));
    }
    if (status === "disapproved") {
      let message = prompt(
        "Please enter the reason why sell request has been rejected",
        "Please dont spam"
      );
      if (message !== null || message !== "") {
        dispatch(disapproveForSelling({ type, status, id, message }));
      }
    }
  };
  const approvedVendors = vendors?.filter(
    (vendor) => vendor.status === "approved"
  );
  const unapprovedVendors = vendors?.filter(
    (vendor) => vendor.status !== "approved"
  );
  const approvedWholesalers = wholesalers?.filter(
    (wholesalers) => wholesalers.status === "approved"
  );
  const unapprovedWholesalers = wholesalers?.filter(
    (wholesalers) => wholesalers.status !== "approved"
  );
  useEffect(() => {
    dispatch(getVendorRegistrationList());
    dispatch(getWholesalerRegistrationList());

    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers(pageNumber));
    } else {
      history.push("/login");
    }
  }, [
    history,
    dispatch,
    successDelete,
    userInfo,
    pageNumber,
    statusUpdateSuccessful,
  ]);
  return (
    <div className="mt-3">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div>
          <Tabs
            defaultActiveKey="users"
            id="uncontrolled-tab-example"
            className="mb-3 mt-3"
          >
            <Tab eventKey="users" title="Users" className="userTabContainer">
              <Row>
                <Table responsive>
                  <thead>
                    <tr className="usersListRowHeading">
                      <th>AVATAR</th>
                      <th>NAME</th>
                      <th>EMAIL</th>
                      <th>ADMIN</th>
                      <th>TYPE</th>
                      <th>EDIT/DELETE</th>
                    </tr>
                  </thead>

                  <tbody>
                    {users?.map((user) => (
                      <tr key={user._id} className="usersListData">
                        <td className="userAvatar">
                          <div className="imageCircle">
                            {user.avatar ? (
                              <img src={user.avatar} alt="userAvatar" />
                            ) : (
                              <div className="nameFirstLatter">
                                {user.name.split("")[0]}
                              </div>
                            )}
                          </div>
                        </td>
                        <td> {user.name} </td>
                        <td>
                          <a href={`mailto:${user._id}`}>{user.email}</a>
                        </td>
                        <td>
                          {user.isAdmin ? (
                            <i
                              className="fas fa-check"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <i
                              className="fas fa-times"
                              style={{ color: "red" }}
                            ></i>
                          )}
                        </td>
                        <td> {user.type} </td>
                        <td>
                          <LinkContainer to={`/admin/user/${user._id}/edit`}>
                            <Button className="userListBtn btn-sm">
                              <i className="fas fa-edit text-dark"></i>
                            </Button>
                          </LinkContainer>
                          <Button
                            className="userListBtn btn-sm"
                            onClick={() => deletehandler(user._id)}
                          >
                            <i className="fas fa-trash text-danger"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <Paginate
                  className="userPagination"
                  url="/admin/userlist"
                  pages={pages}
                  page={page}
                />
              </Row>
            </Tab>
            <Tab
              eventKey="unapproved-vendor"
              title="Unapproved Vendor"
              className="userTabContainer"
            >
              <div className="userContainer">
                <Row>
                  <Table responsive>
                    <thead>
                      <tr className="usersListRowHeading">
                        <th>SHOP</th>
                        <th>ACTUAL ADDRESS</th>
                        <th className="onCenterAlign">SHOP DETAILS</th>
                        <th className="onCenterAlign">DISAPPROVE</th>
                      </tr>
                    </thead>

                    <tbody>
                      {unapprovedVendors?.map((details) => (
                        <tr key={details._id} className="usersListData">
                          <td>{details.shopName} </td>
                          <td>
                            {details.headOffice?.addressLine1}{" "}
                            {details.headOffice?.district}{" "}
                            {details.headOffice?.country},{" "}
                            {details.headOffice?.phone}
                          </td>
                          <td className="onCenterAlign">
                            <a href={`/shopDetails/${details?.shopName}`}>
                              <i className="fas fa-align-left text-info"></i>
                            </a>
                          </td>
                          <td className="onCenterAlign">
                            <Row>
                              <Col md={6}>
                                {details.status !== "approved" && (
                                  <div
                                    onClick={() => {
                                      approveAsSeller(
                                        "vendor",
                                        "approved",
                                        details._id
                                      );
                                    }}
                                  >
                                    <i className="fas fa-check text-success"></i>
                                  </div>
                                )}
                              </Col>
                              <Col md={6}>
                                <div
                                  onClick={() => {
                                    approveAsSeller(
                                      "vendor",
                                      "disapproved",
                                      details._id
                                    );
                                  }}
                                >
                                  <i className="fas fa-times text-danger"></i>
                                </div>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="approved-vendor"
              title="Approved Vendor"
              className="userTabContainer"
            >
              <div className="userContainer">
                <Row>
                  <Table responsive>
                    <thead>
                      <tr className="usersListRowHeading">
                        <th>SHOP</th>
                        <th>ACTUAL ADDRESS</th>
                        <th className="onCenterAlign">SHOP DETAILS</th>
                        <th className="onCenterAlign">DISAPPROVE</th>
                      </tr>
                    </thead>

                    <tbody>
                      {approvedVendors?.map((details) => (
                        <tr key={details._id} className="usersListData">
                          <td>{details.shopName} </td>
                          <td>
                            {details.headOffice?.addressLine1}{" "}
                            {details.headOffice?.district}{" "}
                            {details.headOffice?.country},{" "}
                            {details.headOffice?.phone}
                          </td>
                          <td className="onCenterAlign">
                            <a href={`/shopDetails/${details?.shopName}`}>
                              <i className="fas fa-align-left text-info"></i>
                            </a>
                          </td>
                          <td className="onCenterAlign">
                            {details.status === "approved" && (
                              <Button
                                className="userListDisapproveBtn"
                                onClick={() => {
                                  approveAsSeller(
                                    "vendor",
                                    "disapproved",
                                    details._id
                                  );
                                }}
                              >
                                DISAPPROVE
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="unapproved-wholesaler"
              title="Unapproved Wholesaler"
              className="userTabContainer"
            >
              <div className="userContainer">
                <Row>
                  <Table responsive>
                    <thead>
                      <tr className="usersListRowHeading">
                        <th>SHOP</th>
                        <th>ACTUAL ADDRESS</th>
                        <th className="onCenterAlign">SHOP DETAILS</th>
                        <th className="onCenterAlign">DISAPPROVE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unapprovedWholesalers?.map((details) => (
                        <tr key={details._id} className="usersListData">
                          <td>{details.shopName} </td>
                          <td>
                            {details.headOffice?.addressLine1}{" "}
                            {details.headOffice?.district}{" "}
                            {details.headOffice?.country},{" "}
                            {details.headOffice?.phone}
                          </td>
                          <td className="onCenterAlign">
                            <a href={`/shopDetails/${details?.shopName}`}>
                              <i className="fas fa-align-left text-info"></i>
                            </a>
                          </td>
                          <td className="onCenterAlign">
                            <Row>
                              <Col md={6}>
                                {details.status !== "approved" && (
                                  <div
                                    onClick={() => {
                                      approveAsSeller(
                                        "wholesaler",
                                        "approved",
                                        details._id
                                      );
                                    }}
                                  >
                                    <i className="fas fa-check text-success"></i>
                                  </div>
                                )}
                              </Col>
                              <Col md={6}>
                                <div
                                  onClick={() => {
                                    approveAsSeller(
                                      "wholesaler",
                                      "disapproved",
                                      details._id
                                    );
                                  }}
                                >
                                  <i className="fas fa-times text-danger"></i>
                                </div>
                              </Col>
                            </Row>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Tab>
            <Tab
              eventKey="approved-wholesaler"
              title="Approved Wholesaler"
              className="userTabContainer"
            >
              <div className="userContainer">
                <Row>
                  <Table responsive>
                    <thead>
                      <tr className="usersListRowHeading">
                        <th>SHOP</th>
                        <th>ACTUAL ADDRESS</th>
                        <th className="onCenterAlign">SHOP DETAILS</th>
                        <th className="onCenterAlign">DISAPPROVE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {approvedWholesalers?.map((details) => (
                        <tr key={details._id} className="usersListData">
                          <td>{details.shopName} </td>
                          <td>
                            {details.headOffice?.addressLine1}{" "}
                            {details.headOffice?.district}{" "}
                            {details.headOffice?.country},{" "}
                            {details.headOffice?.phone}
                          </td>
                          <td className="onCenterAlign">
                            <a href={`/shopDetails/${details?.shopName}`}>
                              <i className="fas fa-align-left text-info"></i>
                            </a>
                          </td>
                          <td className="onCenterAlign">
                            <Button
                              className="userListDisapproveBtn"
                              onClick={() => {
                                approveAsSeller(
                                  "wholesaler",
                                  "disapproved",
                                  details._id
                                );
                              }}
                            >
                              DISAPPROVE
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Row>
              </div>
            </Tab>
          </Tabs>
        </div>
      )}
    </div>
  );
};

export default UserListScreen;

// import React, { useEffect } from "react";
// import { LinkContainer } from "react-router-bootstrap";
// import { Table, Button, Tab, Row, Col, Nav } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";
// import {
//   listUsers,
//   deleteUser,
//   getVendorRegistrationList,
//   approveForSelling,
//   disapproveForSelling,
//   getWholesalerRegistrationList,
// } from "../../actions/userActions";
// import Paginate from "../../components/Paginate";
// import Meta from "../../components/Meta";

// const UserListScreen = ({ history, match }) => {
//   const dispatch = useDispatch();
//   const pageNumber = match.params.pageNumber || 1;
//   const userList = useSelector((state) => state.userList);
//   const { loading, error, users, pages, page } = userList;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const vendorList = useSelector((state) => state.vendorList);
//   const { vendors } = vendorList;

//   const wholesalerList = useSelector((state) => state.wholesalerList);
//   const { wholesalers } = wholesalerList;

//   const sellerStatusUpdate = useSelector((state) => state.sellerStatusUpdate);
//   const { success: statusUpdateSuccessful } = sellerStatusUpdate;

//   const userDelete = useSelector((state) => state.userDelete);
//   const { success: successDelete } = userDelete;

//   const deletehandler = (id) => {
//     if (window.confirm("Are you sure")) {
//       dispatch(deleteUser(id));
//     }
//   };
//   const approveAsSeller = (type, status, id) => {
//     if (status === "approved") {
//       dispatch(approveForSelling({ type, status, id }));
//     }
//     if (status === "disapproved") {
//       let message = prompt("Please enter your name", "Please dont spam");
//       if (message !== null || message !== "") {
//         dispatch(disapproveForSelling({ type, status, id, message }));
//       }
//     }
//   };
//   const approvedVendors = vendors?.filter(
//     (vendor) => vendor.status === "approved"
//   );
//   const unapprovedVendors = vendors?.filter(
//     (vendor) => vendor.status !== "approved"
//   );
//   const approvedWholesalers = wholesalers?.filter(
//     (wholesalers) => wholesalers.status === "approved"
//   );
//   const unapprovedWholesalers = wholesalers?.filter(
//     (wholesalers) => wholesalers.status !== "approved"
//   );
//   useEffect(() => {
//     dispatch(getVendorRegistrationList());
//     dispatch(getWholesalerRegistrationList());

//     if (userInfo && userInfo.isAdmin) {
//       dispatch(listUsers(pageNumber));
//     } else {
//       history.push("/login");
//     }
//   }, [
//     history,
//     dispatch,
//     successDelete,
//     userInfo,
//     pageNumber,
//     statusUpdateSuccessful,
//   ]);
//   return (
//     <>
//       <Meta title="Users" />

//       <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//         <Row>
//           <Col sm={3}>
//             <Nav variant="pills" className="flex-column">
//               <Nav.Item>
//                 <Nav.Link eventKey="first">Users</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="second">Unapproved Vendors</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="third">Approved Vendors</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="fourth">Unapproved Wholesaler</Nav.Link>
//               </Nav.Item>
//               <Nav.Item>
//                 <Nav.Link eventKey="fifth">Approved Wholesalers</Nav.Link>
//               </Nav.Item>
//             </Nav>
//           </Col>
//           <Col sm={9}>
//             <Tab.Content>
//               <Tab.Pane eventKey="first">
//                 <h1>Users</h1>
//                 {loading ? (
//                   <Loader />
//                 ) : error ? (
//                   <Message variant="danger">{error}</Message>
//                 ) : (
//                   <Table striped bordered hover responsive className="table-sm">
//                     <thead>
//                       <tr>
//                         <th>AVATAR</th>
//                         <th>NAME</th>
//                         <th>EMAIL</th>
//                         <th>ADMIN</th>
//                         <th>TYPE</th>
//                         <th></th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {users?.map((user) => (
//                         <tr key={user._id}>
//                           <td>
//                             <div
//                               style={{
//                                 borderRadius: "50%",
//                                 height: "40px",
//                                 width: "40px",
//                                 border: "1px solid black",
//                                 display: "flex",
//                                 justifyContent: "center",
//                                 alignItems: "center",
//                               }}
//                             >
//                               {user.avatar ? (
//                                 <img src={user.avatar} alt="" />
//                               ) : (
//                                 `${user.name.split("")[0]}`
//                               )}
//                             </div>
//                           </td>
//                           <td>{user.name}</td>
//                           <td>
//                             <a href={`mailto:${user._id}`}>{user.email}</a>
//                           </td>
//                           <td>
//                             {user.isAdmin ? (
//                               <i
//                                 className="fas fa-check"
//                                 style={{ color: "green" }}
//                               ></i>
//                             ) : (
//                               <i
//                                 className="fas fa-times"
//                                 style={{ color: "red" }}
//                               ></i>
//                             )}
//                           </td>
//                           <td>{user.type}</td>
//                           <td>
//                             <LinkContainer to={`/admin/user/${user._id}/edit`}>
//                               <Button variant="light" className="btn-sm">
//                                 <i className="fas fa-edit"></i>
//                               </Button>
//                             </LinkContainer>
//                             <Button
//                               variant="danger"
//                               className="btn-sm"
//                               onClick={() => deletehandler(user._id)}
//                             >
//                               <i className="fas fa-trash"></i>
//                             </Button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 )}
//                 <Paginate url="/admin/userlist" pages={pages} page={page} />
//               </Tab.Pane>
//               <Tab.Pane eventKey="second">
//                 <h1>Unapproved Vendors</h1>
//                 {loading ? (
//                   <Loader />
//                 ) : error ? (
//                   <Message variant="danger">{error}</Message>
//                 ) : (
//                   <Table striped bordered hover responsive className="table-sm">
//                     <thead>
//                       <tr>
//                         <th>SHOP NAME</th>
//                         <th>ADDRESS INFORMATION</th>
//                         <th>BANK INFORMATION</th>
//                         <th>APPROVE/DISAPPROVE</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {unapprovedVendors?.map((details) => (
//                         <tr key={details._id}>
//                           <td>{details.shopName}</td>

//                           <td>
//                             Warehouse Address
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.warehouseAddress?.country}</span>
//                               <span>{details.warehouseAddress?.city}</span>
//                               <span>{details.warehouseAddress?.address}</span>
//                               <span>
//                                 {details.warehouseAddress?.postalCode}
//                               </span>
//                             </div>
//                             ReturnAddress
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.returnAddress?.country}</span>
//                               <span>{details.returnAddress?.city}</span>
//                               <span>{details.returnAddress?.address}</span>
//                               <span>{details.returnAddress?.postalCode}</span>
//                             </div>
//                           </td>
//                           <td
//                             style={{ display: "flex", flexDirection: "column" }}
//                           >
//                             <span>
//                               Account Name:
//                               {details?.bankInformation?.accountName}
//                             </span>
//                             <span>
//                               Account Number:?
//                               {details?.bankInformation?.accountNumber}
//                             </span>
//                             <span>
//                               Bank Name:{details?.bankInformation?.bankName}
//                             </span>
//                             <span>
//                               Branch Name:{details?.bankInformation?.branchName}
//                             </span>
//                           </td>
//                           <td>
//                             {details?.status !== "approved" && (
//                               <Button
//                                 onClick={() => {
//                                   approveAsSeller(
//                                     "vendor",
//                                     "approved",
//                                     details._id
//                                   );
//                                 }}
//                               >
//                                 Approve
//                               </Button>
//                             )}
//                             <Button
//                               onClick={() => {
//                                 approveAsSeller(
//                                   "vendor",
//                                   "disapproved",
//                                   details._id
//                                 );
//                               }}
//                             >
//                               Disapprove
//                             </Button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 )}
//               </Tab.Pane>
//               <Tab.Pane eventKey="third">
//                 <h1>Approved Vendors</h1>
//                 {loading ? (
//                   <Loader />
//                 ) : error ? (
//                   <Message variant="danger">{error}</Message>
//                 ) : (
//                   <Table striped bordered hover responsive className="table-sm">
//                     <thead>
//                       <tr>
//                         <th>SHOP NAME</th>
//                         <th>ADDRESS INFORMATION</th>
//                         <th>BANK INFORMATION</th>
//                         <th>APPROVE/DISAPPROVE</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {approvedVendors?.map((details) => (
//                         <tr key={details._id}>
//                           <td>{details.shopName}</td>

//                           <td>
//                             Warehouse Address
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.warehouseAddress?.country}</span>
//                               <span>{details.warehouseAddress?.city}</span>
//                               <span>{details.warehouseAddress?.address}</span>
//                               <span>
//                                 {details.warehouseAddress?.postalCode}
//                               </span>
//                             </div>
//                             ReturnAddress
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.returnAddress?.country}</span>
//                               <span>{details.returnAddress?.city}</span>
//                               <span>{details.returnAddress?.address}</span>
//                               <span>{details.returnAddress?.postalCode}</span>
//                             </div>
//                           </td>
//                           <td
//                             style={{ display: "flex", flexDirection: "column" }}
//                           >
//                             <span>
//                               Account Name:
//                               {details?.bankInformation?.accountName}
//                             </span>
//                             <span>
//                               Account Number:
//                               {details?.bankInformation?.accountNumber}
//                             </span>
//                             <span>
//                               Bank Name:{details?.bankInformation?.bankName}
//                             </span>
//                             <span>
//                               Branch Name:{details?.bankInformation?.branchName}
//                             </span>
//                           </td>
//                           <td>
//                             {details?.status !== "approved" && (
//                               <Button
//                                 onClick={() => {
//                                   approveAsSeller(
//                                     "vendor",
//                                     "approved",
//                                     details._id
//                                   );
//                                 }}
//                               >
//                                 Approve
//                               </Button>
//                             )}
//                             <Button
//                               onClick={() => {
//                                 approveAsSeller(
//                                   "vendor",
//                                   "disapproved",
//                                   details._id
//                                 );
//                               }}
//                             >
//                               Disapprove
//                             </Button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 )}
//               </Tab.Pane>
//               <Tab.Pane eventKey="fourth">
//                 <h1>Unapproved Wholesalers</h1>
//                 {loading ? (
//                   <Loader />
//                 ) : error ? (
//                   <Message variant="danger">{error}</Message>
//                 ) : (
//                   <Table striped bordered hover responsive className="table-sm">
//                     <thead>
//                       <tr>
//                         <th>SHOP NAME</th>
//                         <th>ADDRESS INFORMATION</th>
//                         <th>BANK INFORMATION</th>
//                         <th>APPROVE/DISAPPROVE</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {unapprovedWholesalers?.map((details) => (
//                         <tr key={details._id}>
//                           <td>{details.shopName}</td>

//                           <td>
//                             Warehouse Address
//                             <div>
//                               <span>{details.warehouseAddress?.country}</span>
//                               <span>{details.warehouseAddress?.city}</span>
//                               <span>{details.warehouseAddress?.address}</span>
//                               <span>
//                                 {details.warehouseAddress?.postalCode}
//                               </span>
//                             </div>
//                             ReturnAddress
//                             <div>
//                               <span>{details.returnAddress?.country}</span>
//                               <span>{details.returnAddress?.city}</span>
//                               <span>{details.returnAddress?.address}</span>
//                               <span>{details.returnAddress?.postalCode}</span>
//                             </div>
//                           </td>
//                           <td>
//                             <span>
//                               Account Name:
//                               {details.bankInformation?.accountName}
//                             </span>
//                             <span>
//                               Account Number:
//                               {details.bankInformation?.accountNumber}
//                             </span>
//                             <span>
//                               Bank Name:{details.bankInformation?.bankName}
//                             </span>
//                             <span>
//                               Branch Name:{details.bankInformation?.branchName}
//                             </span>
//                           </td>
//                           <td>
//                             {details.status !== "approved" && (
//                               <Button
//                                 onClick={() => {
//                                   approveAsSeller(
//                                     "wholesaler",
//                                     "approved",
//                                     details._id
//                                   );
//                                 }}
//                               >
//                                 Approve
//                               </Button>
//                             )}

//                             <Button
//                               onClick={() => {
//                                 approveAsSeller(
//                                   "wholesaler",
//                                   "disapproved",
//                                   details._id
//                                 );
//                               }}
//                             >
//                               Disapprove
//                             </Button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 )}
//               </Tab.Pane>
//               <Tab.Pane eventKey="fifth">
//                 "<h1>Approved Wholesalers</h1>
//                 {loading ? (
//                   <Loader />
//                 ) : error ? (
//                   <Message variant="danger">{error}</Message>
//                 ) : (
//                   <Table striped bordered hover responsive className="table-sm">
//                     <thead>
//                       <tr>
//                         <th>SHOP NAME</th>
//                         <th>ADDRESS INFORMATION</th>
//                         <th>BANK INFORMATION</th>
//                         <th>APPROVE/DISAPPROVE</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {approvedWholesalers?.map((details) => (
//                         <tr key={details._id}>
//                           <td>{details.shopName}</td>

//                           <td
//                             style={{ display: "flex", flexDirection: "column" }}
//                           >
//                             Warehouse Address
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.warehouseAddress?.country}</span>
//                               <span>{details.warehouseAddress?.city}</span>
//                               <span>{details.warehouseAddress?.address}</span>
//                               <span>
//                                 {details.warehouseAddress?.postalCode}
//                               </span>
//                             </div>
//                             ReturnAddress
//                             <div
//                               style={{
//                                 display: "flex",
//                                 flexDirection: "column",
//                               }}
//                             >
//                               <span>{details.returnAddress?.country}</span>
//                               <span>{details.returnAddress?.city}</span>
//                               <span>{details.returnAddress?.address}</span>
//                               <span>{details.returnAddress?.postalCode}</span>
//                             </div>
//                           </td>
//                           <td
//                             style={{ display: "flex", flexDirection: "column" }}
//                           >
//                             <span>
//                               Account Name:
//                               {details?.bankInformation?.accountName}
//                             </span>
//                             <span>
//                               Account Number:
//                               {details?.bankInformation?.accountNumber}
//                             </span>
//                             <span>
//                               Bank Name:{details?.bankInformation?.bankName}
//                             </span>
//                             <span>
//                               Branch Name:{details?.bankInformation?.branchName}
//                             </span>
//                           </td>
//                           <td>
//                             {details?.status !== "approved" && (
//                               <Button
//                                 onClick={() => {
//                                   approveAsSeller(
//                                     "wholesaler",
//                                     "approved",
//                                     details?._id
//                                   );
//                                 }}
//                               >
//                                 Approve
//                               </Button>
//                             )}

//                             <Button
//                               onClick={() => {
//                                 approveAsSeller(
//                                   "wholesaler",
//                                   "disapproved",
//                                   details?._id
//                                 );
//                               }}
//                             >
//                               Disapprove
//                             </Button>
//                           </td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </Table>
//                 )}
//               </Tab.Pane>
//             </Tab.Content>
//           </Col>
//         </Row>
//       </Tab.Container>
//     </>
//   );
// };

// export default UserListScreen;
