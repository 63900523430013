import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Col, Container } from "react-bootstrap";
import { detailsStaffByStaffId, updateStaff } from "../../actions/staffActions";
import { STAFF_UPDATE_RESET } from "../../constants/staffConstants";
import "./EditStaff.css";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";

const EditStaff = ({ match }) => {
  const dispatch = useDispatch();
  const staffId = match.params.id;
  const [id, setId] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityName, setEntityName] = useState("");
  const [position, setPosition] = useState("");
  const [validated, setValidated] = useState(false);

  const staffDetailsByStaffId = useSelector(
    (state) => state.staffDetailsByStaffId
  );
  const { staff } = staffDetailsByStaffId;

  const staffUpdate = useSelector((state) => state.staffUpdate);
  const { success } = staffUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;

  useEffect(() => {
    if (success) {
      dispatch(detailsStaffByStaffId(staffId));
      dispatch({ type: STAFF_UPDATE_RESET });
    }
    if (staff) {
      setId(staff?.staffId);
      setPhone(staff?.phone);
      setRole(staff?.role);
      setEntityType(staff?.entityType);
      setEntityName(staff?.entityName);
      setPosition(staff?.position);
    } else {
      dispatch(detailsStaffByStaffId(staffId));
    }
  }, [
    dispatch,
    success,
    staff,
    staffId,
    staff?.staffId,
    staff?.phone,
    staff?.role,
    staff?.entityType,
    staff?.entityName,
    staff?.position,
  ]);
  useEffect(() => {
    dispatch(detailsStaffByStaffId(staffId));
  }, [dispatch, staffId]);

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
  }, [userInfo?._id, dispatch, userInfo?.type]);

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(
        updateStaff(match.params.id, {
          id,
          phone,
          entityType,
          entityName,
          position,
          role,
        })
      );
    }
  };
  return (
    <Container>
      <Form onSubmit={(e) => submitHandler(e)} noValidate validated={validated}>
        <Form.Group controlId="id">
          <Form.Label style={{ fontSize: "13px" }}>Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.Label style={{ fontSize: "13px" }}>Phone</Form.Label>
          <Form.Control
            type="number"
            className="manageStaffsPhoneNumber"
            placeholder="Phone Number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="entityType">
          <Form.Label style={{ fontSize: "13px" }}>Entity Type</Form.Label>

          <Form.Control
            className="inputField"
            as="select"
            value={entityType}
            onChange={(e) => setEntityType(e.target.value)}
          >
            <option value="">Choose Branch that handles the product</option>
            <option value="warehouse">Warehouse</option>
            <option value="branch">Branch</option>
            <option value="headOffice">Head Offie</option>
          </Form.Control>
        </Form.Group>

        {user && (
          <Form.Group controlId="entityName">
            <Form.Label style={{ fontSize: "13px" }}>Entity Name</Form.Label>

            <Form.Control
              className="inputField"
              as="select"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
            >
              {entityType === "warehouse" &&
                user?.warehouseAddress?.map((w) => (
                  <option value={w.name}>{w.name}</option>
                ))}
              {entityType === "branch" &&
                user?.branchAddress?.map((w) => (
                  <option value={w.name}>{w.name}</option>
                ))}

              {entityType === "headOffice" && (
                <option value="headOffice">HeadOffice</option>
              )}
            </Form.Control>
          </Form.Group>
        )}

        {userDetails && (
          <Form.Group controlId="entityName">
            <Form.Label style={{ fontSize: "13px" }}>Entity Name</Form.Label>

            <Form.Control
              className="inputField"
              as="select"
              value={entityName}
              onChange={(e) => setEntityName(e.target.value)}
            >
              {entityType === "warehouse" &&
                userDetails?.warehouseAddress?.map((w) => (
                  <option value={w.name}>{w.name}</option>
                ))}
              {entityType === "branch" &&
                userDetails?.branchAddress?.map((w) => (
                  <option value={w.name}>{w.name}</option>
                ))}

              {entityType === "headOffice" && (
                <option value="headOffice">HeadOffice</option>
              )}
            </Form.Control>
          </Form.Group>
        )}

        <Form.Group controlId="position">
          <Form.Label style={{ fontSize: "13px" }}>Position</Form.Label>
          <Form.Control
            type="text"
            placeholder="Position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="type">
          <Form.Label>Account Type</Form.Label>

          <Col>
            <Form.Check
              type="radio"
              label="User"
              name="type"
              value="user"
              onChange={(e) => setRole(e.target.value)}
            ></Form.Check>
            <Form.Check
              type="radio"
              label="Moderator"
              name="type"
              value="moderator"
              onChange={(e) => setRole(e.target.value)}
            ></Form.Check>
            <Form.Check
              type="radio"
              label="Admin"
              name="type"
              value="admin"
              onChange={(e) => setRole(e.target.value)}
            ></Form.Check>
          </Col>
        </Form.Group>
        <Button type="submit" variant="primary">
          Update
        </Button>
      </Form>
    </Container>
  );
};

export default EditStaff;
