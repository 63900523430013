import axios from "axios";
import {
  CATEGORY_CREATE_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_GET_FAIL,
  CATEGORY_GET_REQUEST,
  CATEGORY_GET_SUCCESS,
  CATEGORY_HEADINGS_GET_FAIL,
  CATEGORY_HEADINGS_GET_REQUEST,
  CATEGORY_HEADINGS_GET_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  SUB_CATEGORY_GET_FAIL,
  SUB_CATEGORY_GET_REQUEST,
  SUB_CATEGORY_GET_SUCCESS,
  VENDORSPECIFIC_CATEGORY_GET_FAIL,
  VENDORSPECIFIC_CATEGORY_GET_REQUEST,
  VENDORSPECIFIC_CATEGORY_GET_SUCCESS,
} from "../constants/categoryConstants";

export const createCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/category`, category, config);
    dispatch({
      type: CATEGORY_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCategory =
  (pageNumber = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_GET_REQUEST });
      const { data } = await axios.get(
        `/api/category?pageNumber=${pageNumber}`
      );
      dispatch({
        type: CATEGORY_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CATEGORY_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getVendorCategories = (id) => async (dispatch) => {
  try {
    dispatch({ type: VENDORSPECIFIC_CATEGORY_GET_REQUEST });
    const { data } = await axios.get(`/api/category/vendorspecific/${id}`);
    dispatch({
      type: VENDORSPECIFIC_CATEGORY_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VENDORSPECIFIC_CATEGORY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSubCategories = (category) => async (dispatch) => {
  try {
    dispatch({ type: SUB_CATEGORY_GET_REQUEST });
    const { data } = await axios.get(`/api/category/subcategories/${category}`);
    dispatch({
      type: SUB_CATEGORY_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUB_CATEGORY_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getCategoryHeadings = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_HEADINGS_GET_REQUEST });
    const { data } = await axios.get("/api/category/headings");
    dispatch({
      type: CATEGORY_HEADINGS_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_HEADINGS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/category/${id}`);

    dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/category/${category.id}`, category, config);
    dispatch({
      type: CATEGORY_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const categoryDelete = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CATEGORY_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/category/${id}`, config);
    dispatch({
      type: CATEGORY_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
