import {
  SELLER_CREATE_REVIEW_FAIL,
  SELLER_CREATE_REVIEW_REQUEST,
  SELLER_CREATE_REVIEW_RESET,
  SELLER_CREATE_REVIEW_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_RESET,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_RESET,
  USER_LIST_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESET,
  USER_UPDATE_SUCCESS,
  USER_VENDOR_DETAILS_FAIL,
  USER_VENDOR_DETAILS_REQUEST,
  USER_VENDOR_DETAILS_SUCCESS,
  USER_WHOLESALER_DETAILS_FAIL,
  USER_WHOLESALER_DETAILS_REQUEST,
  USER_WHOLESALER_DETAILS_SUCCESS,
  VENDOR_DETAILS_REGISTRATION_RESET,
  VENDOR_DETAILS_REGISTRATION_FAIL,
  VENDOR_DETAILS_REGISTRATION_REQUEST,
  VENDOR_DETAILS_REGISTRATION_SUCCESS,
  VENDOR_NOUSER_REGISTRATION_FAIL,
  VENDOR_NOUSER_REGISTRATION_REQUEST,
  VENDOR_NOUSER_REGISTRATION_SUCCESS,
  VENDOR_REGISTRATION_FAIL,
  VENDOR_REGISTRATION_REQUEST,
  VENDOR_REGISTRATION_SUCCESS,
  WHOLESALER_DETAILS_REGISTRATION_FAIL,
  WHOLESALER_DETAILS_REGISTRATION_REQUEST,
  WHOLESALER_DETAILS_REGISTRATION_SUCCESS,
  WHOLESALER_NOUSER_REGISTRATION_FAIL,
  WHOLESALER_NOUSER_REGISTRATION_REQUEST,
  WHOLESALER_NOUSER_REGISTRATION_SUCCESS,
  WHOLESALER_REGISTRATION_FAIL,
  WHOLESALER_REGISTRATION_REQUEST,
  WHOLESALER_REGISTRATION_SUCCESS,
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAIL,
  VENDOR_LIST_RESET,
  WHOLESALER_LIST_REQUEST,
  WHOLESALER_LIST_SUCCESS,
  WHOLESALER_LIST_FAIL,
  WHOLESALER_LIST_RESET,
  APPROVE_FOR_SELLING_REQUEST,
  APPROVE_FOR_SELLING_SUCCESS,
  APPROVE_FOR_SELLING_FAIL,
  DISAPPROVE_FOR_SELLING_REQUEST,
  DISAPPROVE_FOR_SELLING_SUCCESS,
  DISAPPROVE_FOR_SELLING_FAIL,
  USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST,
  USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS,
  USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL,
  USER_VENDOR_DETAILS_RESET,
  USER_WHOLESALER_DETAILS_RESET,
  WHOLESALER_DETAILS_REGISTRATION_RESET,
  USER_UPDATE_PROFILE_RESET,
  USER_SPECIFIC_DETAILS_REQUEST,
  USER_SPECIFIC_DETAILS_SUCCESS,
  USER_SPECIFIC_DETAILS_FAIL,
  USER_SPECIFIC_DETAILS_RESET,
  FOLLOW_A_SHOP_REQUEST,
  FOLLOW_A_SHOP_SUCCESS,
  FOLLOW_A_SHOP_FAIL,
  UNFOLLOW_A_SHOP_REQUEST,
  UNFOLLOW_A_SHOP_SUCCESS,
  UNFOLLOW_A_SHOP_FAIL,
  WHOLESALER_SHOP_LIST_REQUEST,
  WHOLESALER_SHOP_LIST_SUCCESS,
  WHOLESALER_SHOP_LIST_FAIL,
  WHOLESALER_SHOP_LIST_RESET,
  VENDOR_SHOP_LIST_REQUEST,
  VENDOR_SHOP_LIST_SUCCESS,
  VENDOR_SHOP_LIST_FAIL,
  VENDOR_SHOP_LIST_RESET,
  APPROVE_FOR_SELLING_RESET,
  DISAPPROVE_FOR_SELLING_RESET,
  VENDOR_ADDRESS_DEACTIVATE_REQUEST,
  VENDOR_ADDRESS_DEACTIVATE_SUCCESS,
  VENDOR_ADDRESS_DEACTIVATE_FAIL,
  VENDOR_ADDRESS_DEACTIVATE_RESET,
  WHOLESALER_ADDRESS_DEACTIVATE_RESET,
  WHOLESALER_ADDRESS_DEACTIVATE_REQUEST,
  WHOLESALER_ADDRESS_DEACTIVATE_SUCCESS,
  WHOLESALER_ADDRESS_DEACTIVATE_FAIL,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAIL,
  CUSTOMER_REGISTER_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };

    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };

    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { ...state, loading: true };

    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };

    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const customerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_REGISTER_REQUEST:
      return { ...state, loading: true };

    case CUSTOMER_REGISTER_SUCCESS:
      return { loading: false, success: action.payload };

    case CUSTOMER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const vendorRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_REGISTRATION_REQUEST:
      return { ...state, loading: true };

    case VENDOR_REGISTRATION_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const wholesalerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case WHOLESALER_REGISTRATION_REQUEST:
      return { ...state, loading: true };

    case WHOLESALER_REGISTRATION_SUCCESS:
      return { loading: false, success: true };

    case WHOLESALER_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const vendorNoUserRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_NOUSER_REGISTRATION_REQUEST:
      return { ...state, loading: true };

    case VENDOR_NOUSER_REGISTRATION_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_NOUSER_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const wholesalerNoUserRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case WHOLESALER_NOUSER_REGISTRATION_REQUEST:
      return { ...state, loading: true };

    case WHOLESALER_NOUSER_REGISTRATION_SUCCESS:
      return { loading: false, success: true };

    case WHOLESALER_NOUSER_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };

    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };

    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const userSpecificDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_SPECIFIC_DETAILS_REQUEST:
      return { loading: true };

    case USER_SPECIFIC_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };

    case USER_SPECIFIC_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_SPECIFIC_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const vendorDetailsReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case USER_VENDOR_DETAILS_REQUEST:
      return { loading: true };

    case USER_VENDOR_DETAILS_SUCCESS:
      return { loading: false, user: action.payload, success: true };

    case USER_VENDOR_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const wholesalerDetailsReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case USER_WHOLESALER_DETAILS_REQUEST:
      return { loading: true };

    case USER_WHOLESALER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload, success: true };

    case USER_WHOLESALER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const vendorDetailsByShopNameReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST:
      return { loading: true };

    case USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS:
      return { loading: false, user: action.payload, success: true };

    case USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL:
      return { loading: false, error: action.payload };
    case USER_VENDOR_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const wholesalerDetailsByShopNameReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST:
      return { loading: true };

    case USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS:
      return { loading: false, user: action.payload, success: true };

    case USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL:
      return { loading: false, error: action.payload };
    case USER_WHOLESALER_DETAILS_RESET:
      return { user: {} };

    default:
      return state;
  }
};

export const userUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_PROFILE_REQUEST:
      return { loading: true };

    case USER_UPDATE_PROFILE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case USER_UPDATE_PROFILE_FAIL:
      return { loading: false, error: action.payload };

    case USER_UPDATE_PROFILE_RESET:
      return {};
    default:
      return state;
  }
};

export const userListReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case USER_LIST_REQUEST:
      return { loading: true };

    case USER_LIST_SUCCESS:
      return {
        loading: false,
        users: action.payload.users,
        pages: action.payload.pages,
        page: action.payload.page,
      };

    case USER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case USER_LIST_RESET:
      return { user: [] };
    default:
      return state;
  }
};
export const vendorListReducer = (state = { vendors: [] }, action) => {
  switch (action.type) {
    case VENDOR_LIST_REQUEST:
      return { loading: true };

    case VENDOR_LIST_SUCCESS:
      return { loading: false, vendors: action.payload };

    case VENDOR_LIST_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_LIST_RESET:
      return { vendor: [] };
    default:
      return state;
  }
};
export const wholesalerListReducer = (state = { wholesalers: [] }, action) => {
  switch (action.type) {
    case WHOLESALER_LIST_REQUEST:
      return { loading: true };

    case WHOLESALER_LIST_SUCCESS:
      return { loading: false, wholesalers: action.payload };

    case WHOLESALER_LIST_FAIL:
      return { loading: false, error: action.payload };
    case WHOLESALER_LIST_RESET:
      return { wholesaler: [] };
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };

    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };

    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_REQUEST:
      return { loading: true };

    case USER_UPDATE_SUCCESS:
      return { loading: false, success: true };

    case USER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_RESET:
      return { user: {} };
    default:
      return state;
  }
};

export const sellerStatusUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case APPROVE_FOR_SELLING_REQUEST:
      return { loading: true };

    case APPROVE_FOR_SELLING_SUCCESS:
      return { loading: false, success: true };

    case APPROVE_FOR_SELLING_FAIL:
      return { loading: false, error: action.payload };

    case APPROVE_FOR_SELLING_RESET:
      return {};

    default:
      return state;
  }
};
export const sellerStatusDisapproveReducer = (state = {}, action) => {
  switch (action.type) {
    case DISAPPROVE_FOR_SELLING_REQUEST:
      return { loading: true };

    case DISAPPROVE_FOR_SELLING_SUCCESS:
      return { loading: false, success: true };

    case DISAPPROVE_FOR_SELLING_FAIL:
      return { loading: false, error: action.payload };

    case DISAPPROVE_FOR_SELLING_RESET:
      return {};

    default:
      return state;
  }
};
export const sellerReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case SELLER_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case SELLER_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case SELLER_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case SELLER_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};
export const vendorUpdateDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_DETAILS_REGISTRATION_REQUEST:
      return { loading: true };

    case VENDOR_DETAILS_REGISTRATION_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case VENDOR_DETAILS_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    case VENDOR_DETAILS_REGISTRATION_RESET:
      return { success: false };

    default:
      return state;
  }
};

export const wholesalerUpdateDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case WHOLESALER_DETAILS_REGISTRATION_REQUEST:
      return { loading: true };

    case WHOLESALER_DETAILS_REGISTRATION_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };

    case WHOLESALER_DETAILS_REGISTRATION_FAIL:
      return { loading: false, error: action.payload };

    case WHOLESALER_DETAILS_REGISTRATION_RESET:
      return { success: false };

    default:
      return state;
  }
};
export const followShopReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_A_SHOP_REQUEST:
      return { loading: true };

    case FOLLOW_A_SHOP_SUCCESS:
      return { loading: false, success: true };

    case FOLLOW_A_SHOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const unfollowShopReducer = (state = {}, action) => {
  switch (action.type) {
    case UNFOLLOW_A_SHOP_REQUEST:
      return { loading: true };

    case UNFOLLOW_A_SHOP_SUCCESS:
      return { loading: false, success: true };

    case UNFOLLOW_A_SHOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vendorShopListReducer = (state = { shops: [] }, action) => {
  switch (action.type) {
    case VENDOR_SHOP_LIST_REQUEST:
      return { loading: true };

    case VENDOR_SHOP_LIST_SUCCESS:
      return {
        loading: false,
        shops: action.payload.shops,
        pages: action.payload.pages,
        page: action.payload.page,
      };

    case VENDOR_SHOP_LIST_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_SHOP_LIST_RESET:
      return { shops: [] };
    default:
      return state;
  }
};

export const wholesalerShopListReducer = (state = { shops: [] }, action) => {
  switch (action.type) {
    case WHOLESALER_SHOP_LIST_REQUEST:
      return { loading: true };

    case WHOLESALER_SHOP_LIST_SUCCESS:
      return {
        loading: false,
        shops: action.payload.shops,
        pages: action.payload.pages,
        page: action.payload.page,
      };

    case WHOLESALER_SHOP_LIST_FAIL:
      return { loading: false, error: action.payload };
    case WHOLESALER_SHOP_LIST_RESET:
      return { shops: [] };
    default:
      return state;
  }
};

export const vendorAddressDeactivationReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDOR_ADDRESS_DEACTIVATE_REQUEST:
      return { loading: true };

    case VENDOR_ADDRESS_DEACTIVATE_SUCCESS:
      return { loading: false, success: true };

    case VENDOR_ADDRESS_DEACTIVATE_FAIL:
      return { loading: false, error: action.payload };

    case VENDOR_ADDRESS_DEACTIVATE_RESET:
      return {};

    default:
      return state;
  }
};
export const wholesalerAddressDeactivationReducer = (state = {}, action) => {
  switch (action.type) {
    case WHOLESALER_ADDRESS_DEACTIVATE_REQUEST:
      return { loading: true };

    case WHOLESALER_ADDRESS_DEACTIVATE_SUCCESS:
      return { loading: false, success: true };

    case WHOLESALER_ADDRESS_DEACTIVATE_FAIL:
      return { loading: false, error: action.payload };

    case WHOLESALER_ADDRESS_DEACTIVATE_RESET:
      return {};

    default:
      return state;
  }
};
