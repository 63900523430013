import axios from "axios";
import React, { useState } from "react";
import { Form, Row, Button, Col, Container } from "react-bootstrap";
import FormContainer from "../../components/FormContainer";
import Message from "../../components/Message";

const EnterEmailScreen = ({ history }) => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  const submitEmail = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      axios.post("/api/users/forgotpassword", { email }).then((res) => {
        if (res.status === 200) {
          setMessage("Please check your email for a link.");
        } else {
          setMessage(res?.data?.message);
        }
      });
    }
  };

  return (
    <FormContainer>
      <Container className="mt-5 mb-5">
        <Row className="h-100">
          <Col className="align-items-center registerForm text-center">
            <Col>
              <h1 className="registerHeader mt-5 mb-5">Reset Password</h1>
            </Col>
            {message && <Message variant="info">{message}</Message>}
            <Form noValidate validated={validated} onSubmit={submitEmail}>
              <Form.Group>
                <Row className="mt-5">
                  <Col sm={8} className="ResetPasswordEmailField mx-auto">
                    <Form.Control
                      className="registerFields mb-3"
                      type="email"
                      placeholder="Enter yout email "
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Button type="submit" className="registerBtn">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default EnterEmailScreen;
