import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import {
  getVendorDetailsByShopName,
  getWholesalerDetailsByShopName,
} from "../actions/userActions";

import {
  followAShop,
  listFollowers,
  listFollowings,
  unfollowAShop,
} from "../actions/followActions";

import {
  FOLLOW_A_SHOP_RESET,
  UNFOLLOW_A_SHOP_RESET,
} from "../constants/followConstants.js";

const FollowButton = ({ id, type }) => {
  const dispatch = useDispatch();

  const vendorDetailsByShopName = useSelector(
    (state) => state.vendorDetailsByShopName
  );
  const { user } = vendorDetailsByShopName;

  const wholesalerDetailsByShopName = useSelector(
    (state) => state.wholesalerDetailsByShopName
  );
  const { user: userDet } = wholesalerDetailsByShopName;

  const followShop = useSelector((state) => state.followShop);
  const { success: followSuccess } = followShop;
  const unfollowShop = useSelector((state) => state.unfollowShop);
  const { success: unfollowSuccess } = unfollowShop;

  const getFollowings = useSelector((state) => state.getFollowings);
  const { followings } = getFollowings;

  const vendorCategories = useSelector((state) => state.vendorCategories);
  const { categories } = vendorCategories;
  let option = [];

  categories?.map((c) => option.push({ value: c, label: c }));

  const handleFollowButton = () => {
    if (followed.length === 0) {
      dispatch(followAShop(id));
    } else {
      dispatch(unfollowAShop(id));
    }
  };

  const followed =
    type === "vendor"
      ? followings?.filter((f) => f.shop === user?._id)
      : followings?.filter((f) => f.shop === userDet?._id);

  useEffect(() => {
    if (type === "vendor") {
      dispatch(getVendorDetailsByShopName(id));
    } else {
      dispatch(getWholesalerDetailsByShopName(id));
    }
  }, [dispatch, id, type]);

  useEffect(() => {
    if (followSuccess || unfollowSuccess) {
      dispatch({ type: FOLLOW_A_SHOP_RESET });
      dispatch({ type: UNFOLLOW_A_SHOP_RESET });
    }
    dispatch(listFollowings());
    dispatch(listFollowers(id));
  }, [dispatch, id, followSuccess, unfollowSuccess]);
  return (
    <div>
      <Button className="followBtn" variant="info" onClick={handleFollowButton}>
        {followed?.length === 0 ? (
          <>
            <i className="fas fa-plus"></i> Follow
          </>
        ) : (
          "Unfollow"
        )}
      </Button>
    </div>
  );
};

export default FollowButton;
