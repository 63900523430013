import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_VENDOR_DELIVER_FAIL,
  ORDER_VENDOR_DELIVER_REQUEST,
  ORDER_VENDOR_DELIVER_SUCCESS,
  ORDER_VENDOR_DETAILS_FAIL,
  ORDER_VENDOR_DETAILS_REQUEST,
  ORDER_VENDOR_DETAILS_SUCCESS,
  ORDER_VENDOR_FAIL,
  ORDER_VENDOR_PAY_FAIL,
  ORDER_VENDOR_PAY_REQUEST,
  ORDER_VENDOR_PAY_SUCCESS,
  ORDER_VENDOR_REQUEST,
  ORDER_VENDOR_SUCCESS,
  ORDER_WHOLESALE_LIST_FAIL,
  ORDER_WHOLESALE_LIST_REQUEST,
  ORDER_WHOLESALE_LIST_SUCCESS,
  ORDER_wholesaler_DELIVER_FAIL,
  ORDER_wholesaler_DELIVER_REQUEST,
  ORDER_wholesaler_DELIVER_SUCCESS,
  ORDER_wholesaler_DETAILS_FAIL,
  ORDER_wholesaler_DETAILS_REQUEST,
  ORDER_wholesaler_DETAILS_SUCCESS,
  ORDER_wholesaler_FAIL,
  ORDER_wholesaler_PAY_FAIL,
  ORDER_wholesaler_PAY_REQUEST,
  ORDER_wholesaler_PAY_SUCCESS,
  ORDER_wholesaler_REQUEST,
  ORDER_wholesaler_SUCCESS,
  INVOICE_INFORMATION_REQUEST,
  INVOICE_INFORMATION_SUCCESS,
  INVOICE_INFORMATION_FAIL,
  DELIVERY_LABEL_DETAILS_REQUEST,
  DELIVERY_LABEL_DETAILS_SUCCESS,
  DELIVERY_LABEL_DETAILS_FAIL,
  VENDORORDER_UPDATE_REQUEST,
  VENDORORDER_UPDATE_SUCCESS,
  VENDORORDER_UPDATE_FAIL,
  WHOLESALERORDER_UPDATE_REQUEST,
  WHOLESALERORDER_UPDATE_SUCCESS,
  WHOLESALERORDER_UPDATE_FAIL,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  ADD_ORDERITEM_REQUEST,
  ADD_ORDERITEM_SUCCESS,
  ADD_ORDERITEM_FAIL,
  DELETE_ORDERITEM_REQUEST,
  DELETE_ORDERITEM_SUCCESS,
  DELETE_ORDERITEM_FAIL,
} from "../constants/orderConstants";
import axios from "axios";
export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/orders`, order, config);
    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//Save vendorwise sepereated orders in VendorOrders collection in database
export const createVendorOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_VENDOR_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/orders/vendor`, order, config);

    dispatch({
      type: ORDER_VENDOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_VENDOR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Save wholesaler sepereated orders in WholesalerOrders collection in database
export const createWholesalerOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_wholesaler_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/orders/wholesaler`, order, config);
    dispatch({
      type: ORDER_wholesaler_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_wholesaler_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//update a vendor order
export const updateVendorOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VENDORORDER_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/orders/edit/vendor/${order.id}`, order, config);
    dispatch({
      type: VENDORORDER_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: VENDORORDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//update a wholesaler order
export const updateWholesalerOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WHOLESALERORDER_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.put(`/api/orders/edit/wholesaler/${order.id}`, order, config);
    dispatch({
      type: WHOLESALERORDER_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: WHOLESALERORDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//delete an order
export const deleteAnOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ORDER_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/orders/delete/${id}`, config);
    dispatch({
      type: DELETE_ORDER_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//delete an order
export const addProductOnOrderItems = (item) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_ORDERITEM_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(
      `/api/orders/add/product/${item.orderId}`,
      { item },
      config
    );
    dispatch({
      type: ADD_ORDERITEM_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_ORDERITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//delete an order
export const deleteOrderItem = (orderId, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_ORDERITEM_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/orders/orderitem/delete/${orderId}/${id}`, config);
    dispatch({
      type: DELETE_ORDERITEM_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ORDERITEM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/orders/${id}`, config);
    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
//Get vendorwise order's details
export const getVendorOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_VENDOR_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/orders/vendorshop/${id}`, config);

    dispatch({
      type: ORDER_VENDOR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_VENDOR_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Get wholesaler order's details
export const getWholesalerOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_wholesaler_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/orders/wholesalershop/${id}`,
      config
    );

    dispatch({
      type: ORDER_wholesaler_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_wholesaler_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Get DeliveryLabel details
export const getDeliveryLabelDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELIVERY_LABEL_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/orders/deliverylabel/${id}`, config);

    dispatch({
      type: DELIVERY_LABEL_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELIVERY_LABEL_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const payOrder =
  (orderId, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/${orderId}/pay`,
        paymentResult,
        config
      );
      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/orders/${order._id}/deliver`,
      {},
      config
    );
    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const paymentVendorOrder =
  (status, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_VENDOR_PAY_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/vendor/${id}/pay`,
        { status },
        config
      );
      dispatch({
        type: ORDER_VENDOR_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_VENDOR_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const paymentWholesalerOrder =
  (status, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_wholesaler_PAY_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/wholesaler/${id}/pay`,
        { status },
        config
      );
      dispatch({
        type: ORDER_wholesaler_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_wholesaler_PAY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const deliverVendorOrder =
  (status, cancelMessage, orderId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_VENDOR_DELIVER_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/vendor/${orderId}/deliver`,
        { status, cancelMessage },
        config
      );
      dispatch({
        type: ORDER_VENDOR_DELIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_VENDOR_DELIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deliverWholesalerOrder =
  (orders, status, cancelMessage, orderId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_wholesaler_DELIVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/orders/wholesaler/${orderId}/deliver`,
        { orders, status, cancelMessage },
        config
      );
      dispatch({
        type: ORDER_wholesaler_DELIVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_wholesaler_DELIVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//List orders made by user
export const listMyOrders = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/orders/myorders?pageNumber=${pageNumber}`,
      config
    );
    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//list all the orders
export const listOrders = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/orders?pageNumber=${pageNumber}`,
      config
    );
    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//List orders to him vendorwise to the logged in vendor
export const listVendorOrders =
  (pageNumber, shop) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_LIST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/orders/vendor/${shop}?pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

//List orders to him wholesaler to the logged in wholesale

export const listWholesalerOrders =
  (pageNumber, shop) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_WHOLESALE_LIST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/orders/wholesaler/${shop}?pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: ORDER_WHOLESALE_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ORDER_WHOLESALE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getInvoiceInformation = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INVOICE_INFORMATION_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/orders/invoice/${id}`, config);

    dispatch({
      type: INVOICE_INFORMATION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: INVOICE_INFORMATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
