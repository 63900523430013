import React from "react";
import { useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const DashboardSidebar = () => {
  const staffDetails = useSelector((state) => state.staffDetails);
  const { staff } = staffDetails;
  return (
    <div className="dashboardSidebar" style={{ flex: "0.3" }}>
      <ListGroup>
        {staff.role === "admin" && (
          <ListGroup.Item>
            <Link to="/vendor/dashboard/managestaffs">Manage Staffs</Link>
          </ListGroup.Item>
        )}

        <ListGroup.Item>
          <Link to="/vendor/dashboard/chat">Chat</Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <Link to="/vendor/productlist">Manage Products</Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <Link to="/vendor/inventory">Inventory Management</Link>
        </ListGroup.Item>
        <ListGroup.Item>
          <Link to="/vendor/promotion">Promotions Management</Link>
        </ListGroup.Item>
      </ListGroup>
    </div>
  );
};

export default DashboardSidebar;
