import axios from "axios";
import {
  STAFF_CREATE_FAIL,
  STAFF_CREATE_REQUEST,
  STAFF_CREATE_SUCCESS,
  STAFF_DELETE_FAIL,
  STAFF_DELETE_REQUEST,
  STAFF_DELETE_SUCCESS,
  STAFF_DETAILS_BY_STAFF_ID_FAIL,
  STAFF_DETAILS_BY_STAFF_ID_REQUEST,
  STAFF_DETAILS_BY_STAFF_ID_SUCCESS,
  STAFF_DETAILS_FAIL,
  STAFF_DETAILS_REQUEST,
  STAFF_DETAILS_SUCCESS,
  STAFF_GET_FAIL,
  STAFF_GET_REQUEST,
  STAFF_GET_SUCCESS,
  STAFF_UPDATE_FAIL,
  STAFF_UPDATE_REQUEST,
  STAFF_UPDATE_SUCCESS,
} from "../constants/staffConstants";

export const createStaff = (staff) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STAFF_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/staff`, staff, config);
    dispatch({
      type: STAFF_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getStaff = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: STAFF_GET_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/staff/all/${id}`, config);
    dispatch({
      type: STAFF_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsStaff = (id) => async (dispatch) => {
  try {
    dispatch({ type: STAFF_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/staff/${id}`);

    dispatch({ type: STAFF_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STAFF_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const detailsStaffByStaffId = (id) => async (dispatch) => {
  try {
    dispatch({ type: STAFF_DETAILS_BY_STAFF_ID_REQUEST });
    const { data } = await axios.get(`/api/staff/bystaffid/${id}`);

    dispatch({ type: STAFF_DETAILS_BY_STAFF_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: STAFF_DETAILS_BY_STAFF_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updateStaff = (id, staff) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STAFF_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(`/api/staff/${id}`, { staff }, config);
    dispatch({
      type: STAFF_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STAFF_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const staffDelete = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STAFF_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/staff/${id}`, config);
    dispatch({
      type: STAFF_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: STAFF_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
