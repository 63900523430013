import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { listFeaturedBlog, listNewBlog } from "../../actions/blogAction";
import "./BlogScreen.css";

const BlogScreen = () => {
  const dispatch = useDispatch();

  const blogListFeatured = useSelector((state) => state.blogListFeatured);
  const { blog: featuredBlog } = blogListFeatured;

  const blogListNew = useSelector((state) => state.blogListNew);
  const { blog: newBlog } = blogListNew;

  useEffect(() => {
    dispatch(listNewBlog());
    dispatch(listFeaturedBlog());
  }, [dispatch]);
  return (
    <>
      <Container>
        <img
          src="/images/blog.png"
          alt="About Us"
          style={{ height: "100vh", width: "100%" }}
        />
        <Row>
          <Col>
            <h2 className="blogScreenH1 mt-5"> Recently added</h2>
          </Col>
        </Row>

        <Row className="mt-3">
          {newBlog?.map((blog, index) => (
            <Col key={index} xs={12} md={4} className="blogScreenArticleBox">
              <p className="blogScreenDate">{blog.createdAt}</p>
              <h2 className="blogScreenBlogTitle">{blog.title}</h2>
              <img
                src={blog.image}
                alt="About Us"
                style={{ height: "360px", width: "450px" }}
              />
              <p className="blogScreenBlogdescription">
                {blog.content?.substring(0, 140)}
                ...
              </p>

              <h4 className="BlogScreenReadMore">
                READ MORE <i className="fas fa-long-arrow-alt-right"></i>
              </h4>
            </Col>
          ))}
        </Row>
      </Container>

      <Container>
        <Row>
          <Col>
            <h2 className="blogScreenH1 mt-5"> Featured </h2>
          </Col>
        </Row>
        <Row className="my-3">
          {featuredBlog?.map((blog, index) => (
            <Col
              key={index}
              xs={12}
              md={4}
              className="blogScreenArticleBox  mx-auto mx-md-0"
            >
              <p className="blogScreenDate">{blog.createdAt}</p>
              <h2 className="blogScreenBlogTitle">{blog.title}</h2>
              <img
                src={blog.image}
                alt="About Us"
                style={{ height: "360px", width: "450px" }}
              />
              <p className="blogScreenBlogdescription">
                {blog.content?.substring(0, 140)}
                ...
              </p>

              <h4 className="BlogScreenReadMore">
                READ MORE <i className="fas fa-long-arrow-alt-right"></i>
              </h4>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default BlogScreen;
