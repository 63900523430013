import React from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useState } from "react";
import "./Sidebar.css";

const Sidebar = ({ shopName, setShowFull }) => {
  const [collapseShow, setCollapseShow] = React.useState("hidden");

  const [showDashboardLi, setDashboardLi] = useState(false);
  const [showProductLi, setProductLi] = useState(false);
  const [showOrderLi, setOrderLi] = useState(false);
  const [showWholesalerProLi, setWholesalerProLi] = useState(false);
  const [showMyDataLi, setMyDataLi] = useState(false);
  const [showManagementLi, setManagementLi] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const setDashboard = () => {
    setDashboardLi(true);
    setProductLi(false);
    setOrderLi(false);
    setWholesalerProLi(false);
    setMyDataLi(false);
    setManagementLi(false);
  };
  const setProduct = () => {
    setDashboardLi(false);
    setProductLi(true);
    setOrderLi(false);
    setWholesalerProLi(false);
    setMyDataLi(false);
    setManagementLi(false);
  };
  const setWholesalerProduct = () => {
    setDashboardLi(false);
    setProductLi(false);
    setOrderLi(false);
    setWholesalerProLi(true);
    setMyDataLi(false);
    setManagementLi(false);
  };
  const setMyData = () => {
    setDashboardLi(false);
    setProductLi(false);
    setOrderLi(false);
    setWholesalerProLi(false);
    setMyDataLi(true);
    setManagementLi(false);
  };
  const setManagement = () => {
    setDashboardLi(false);
    setProductLi(false);
    setOrderLi(false);
    setWholesalerProLi(false);
    setMyDataLi(false);
    setManagementLi(true);
  };
  const setOrder = () => {
    setDashboardLi(false);
    setProductLi(false);
    setOrderLi(true);
    setWholesalerProLi(false);
    setMyDataLi(false);
    setManagementLi(false);
  };
  const setAllSubmenu = () => {
    setDashboardLi(false);
    setProductLi(false);
    setOrderLi(false);
    setWholesalerProLi(false);
    setMyDataLi(false);
    setManagementLi(false);
  };

  return (
    <>
      {setShowFull === false && (
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 py-4 px-6  z-50">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link className="hidden md:block mb-4" to={`/`}>
              <div className="flex flex-wrap">
                <div className="md:w-3/12">
                  <img
                    alt="..."
                    className="w-10  rounded-full align-middle border-none shadow-lg"
                    src="/images/logo.jpg"
                  />
                </div>
                <div className="md:w-8/12 pt-2 text-blueGray-500 text-xl uppercase font-bold block pt-1 pb-1 no-underline">
                  Pinoy Can Buy
                </div>
              </div>
            </Link>
            <div
              className={
                "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                collapseShow
              }
            >
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link to={`/`}>
                      <div className="flex flex-wrap">
                        <div className="md:w-3/12">
                          <img
                            alt="..."
                            className="w-10  rounded-full align-middle border-none shadow-lg"
                            src="/images/logo.jpg"
                          />
                        </div>
                        <div className="md:w-8/12 pt-2 text-blueGray-500 text-xl uppercase font-bold block pt-1 pb-1 no-underline">
                          Pinoy Can Buy
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Form */}
              <form className="mt-6 mb-4 md:hidden">
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Search"
                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                  />
                </div>
              </form>
              {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <h6 className="mt-3 md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Dashboard
                  </h6>
                  {/* Navigation */}
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          `vendor/${shopName}/dashboard`
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/vendor/${shopName}/dashboard`}
                    >
                      <i
                        className={
                          "fas fa-tachometer-alt mr-2 text-sm " +
                          (window.location.href.indexOf(
                            `vendor/${shopName}/dashboard`
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Dashboard
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  {/* Heading */}
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Product
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "{`/vendor/product/create`}"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/vendor/product/create`}
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf(
                            `/vendor/product/create`
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Create Product
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/vendor/productlist") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/vendor/productlist"
                    >
                      <i
                        className={
                          "fas fa-boxes mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/vendor/productlist"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      All Product
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Order
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/vendor/orderlist") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/vendor/orderlist"
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down mr-2 text-sm " +
                          (window.location.href.indexOf("/vendor/orderlist") !==
                          -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      All Orders
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Wholesaler
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/wholesaleProducts") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/wholesaleProducts"
                    >
                      <i
                        className={
                          "fas fa-archive mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/wholesaleProducts"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Products
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    My Data
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/myorders") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/myorders"
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down mr-2 text-sm " +
                          (window.location.href.indexOf("/myorders") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Orders
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(`/shop/${shopName}`) !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/shop/${shopName}`}
                    >
                      <i
                        className={
                          "fas fa-store mr-2 text-sm " +
                          (window.location.href.indexOf(`/shop/${shopName}`) !==
                          -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Store
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/followed") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/followed"
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf("/followed") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Followed Products
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/mywishlist") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/mywishlist"
                    >
                      <i
                        className={
                          "far fa-heart mr-2 text-sm " +
                          (window.location.href.indexOf("/mywishlist") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Wishlist
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Management
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/vendor/inventory") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/vendor/inventory"
                    >
                      <i
                        className={
                          "fas fa-boxes mr-2 text-sm " +
                          (window.location.href.indexOf("/vendor/inventory") !==
                          -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Inventory
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "{`/vendor/promotion`}"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/vendor/promotion`}
                    >
                      <i
                        className={
                          "fas fa-bullhorn mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "{`/vendor/promotion`}"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Promotion
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("{`/vendor/banner`}") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/vendor/banner`}
                    >
                      <i
                        className={
                          "fas fa-images mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "{`/vendor/banner`}"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Banner & Image
                    </Link>
                  </li>
                </ul>
              ) : null}
              {userInfo &&
              !userInfo.isAdmin &&
              userInfo.type === "wholesaler" ? (
                <ul className="md:flex-col md:min-w-full mt-4 flex flex-col list-none">
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Dashboard
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          `wholesaler/${shopName}/dashboard`
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/wholesaler/${shopName}/dashboard`}
                    >
                      <i
                        className={
                          "fas fa-tachometer-alt mr-2 text-sm " +
                          (window.location.href.indexOf(
                            `wholesaler/${shopName}/dashboard`
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Dashboard
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Product
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          `/wholesaler/product/create`
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/wholesaler/product/create`}
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "{`/wholesaler/product/create`}"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Create Product
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "/wholesaler/productlist"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/wholesaler/productlist"
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/wholesaler/productlist"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      All Product
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Order
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "/wholesaler/orderlist"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/wholesaler/orderlist"
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/wholesaler/orderlist"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      All Orders
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Wholesaler
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/wholesaleProducts") !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/wholesaleProducts"
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/wholesaleProducts"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Products
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    My Data
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/myorders") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/myorders"
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down mr-2 text-sm " +
                          (window.location.href.indexOf("/myorders") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Orders
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(`/shop/${shopName}`) !==
                        -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/wholesaleshop/${shopName}`}
                    >
                      <i
                        className={
                          "fas fa-store mr-2 text-sm " +
                          (window.location.href.indexOf(
                            `/wholesaleshop/${shopName}`
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Store
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/followed") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/followed"
                    >
                      <i
                        className={
                          "fas fa-box mr-2 text-sm " +
                          (window.location.href.indexOf("/followed") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Followed Products
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf("/mywishlist") !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/mywishlist"
                    >
                      <i
                        className={
                          "far fa-heart mr-2 text-sm " +
                          (window.location.href.indexOf("/mywishlist") !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Wishlist
                    </Link>
                  </li>
                  <hr className="my-3 md:min-w-full" />
                  <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-1 no-underline">
                    Management
                  </h6>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "/wholesaler/inventory"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to="/wholesaler/inventory"
                    >
                      <i
                        className={
                          "fas fa-boxes mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "/wholesaler/inventory"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Inventory
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        "text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "{`/wholesaler/promotion`}"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/wholesaler/promotion`}
                    >
                      <i
                        className={
                          "fas fa-bullhorn mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "{`/wholesaler/promotion`}"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Promotion
                    </Link>
                  </li>
                  <li className="items-center px-2 mt-1">
                    <Link
                      className={
                        " text-xs  font-bold block " +
                        (window.location.href.indexOf(
                          "{`/wholesaler/banner`}"
                        ) !== -1
                          ? "text-red-500 hover:text-green-600"
                          : "text-blue-700 hover:text-green-500")
                      }
                      to={`/wholesaler/banner`}
                    >
                      <i
                        className={
                          "fas fa-images mr-2 text-sm " +
                          (window.location.href.indexOf(
                            "{`/wholesaler/banner`}"
                          ) !== -1
                            ? "opacity-75"
                            : "text-blue-300")
                        }
                      ></i>{" "}
                      Banner & Image
                    </Link>
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        </nav>
      )}
      {setShowFull === true && (
        <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:flex-row md:flex-nowrap shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-20  z-50">
          <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
            {/* Toggler */}
            <button
              className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              type="button"
              onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
            >
              <i className="fas fa-bars"></i>
            </button>
            {/* Brand */}
            <Link className="hidden md:block mb-4" to={`/`}>
              <div className="flex flex-wrap">
                <div className="md:w-12/12 pt-3 pb-3 px-4">
                  <img
                    alt="..."
                    className="w-100  rounded-full align-middle border-none shadow-lg"
                    src="/images/logo.jpg"
                  />
                </div>
                {/* <div className="md:w-8/12 pt-2 text-blueGray-500 text-xl uppercase font-bold block pt-1 pb-1 no-underline">
                Reseller
              </div> */}
              </div>
            </Link>
            <hr className="md:min-w-full" />
            <div
              className={
                "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:shadow-none absolute top-0 left-0 right-0 z-40 h-auto items-center flex-1 rounded " +
                collapseShow
              }
            >
              <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                <div className="flex flex-wrap">
                  <div className="w-6/12">
                    <Link to={`/`}>
                      <div className="flex flex-wrap">
                        <div className="md:w-3/12">
                          <img
                            alt="..."
                            className="w-10  rounded-full align-middle border-none shadow-lg"
                            src="/images/logo.jpg"
                          />
                        </div>
                        <div className="md:w-8/12 pt-2 text-blueGray-500 text-xl uppercase font-bold block pt-1 pb-1 no-underline">
                          Pinoy Can Buy
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="w-6/12 flex justify-end">
                    <button
                      type="button"
                      className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                      onClick={() => setCollapseShow("hidden")}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* Form */}
              <form className="mt-6 mb-4 md:hidden">
                <div className="mb-3 pt-0">
                  <input
                    type="text"
                    placeholder="Search"
                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                  />
                </div>
              </form>
              {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
                <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                  <div className="test">
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setDashboard()}
                    >
                      <i
                        className={
                          "fas fa-tachometer-alt text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showDashboardLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              `vendor/${shopName}/dashboard`
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/vendor/${shopName}/dashboard`}
                        >
                          <i
                            className={
                              "fas fa-tachometer-alt mr-2 text-sm " +
                              (window.location.href.indexOf(
                                `vendor/${shopName}/dashboard`
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Dashboard
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  {/* Heading */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setProduct()}
                    >
                      <i
                        className={"fas fa-box text-2xl opacity-75 text-black"}
                      ></i>
                    </h6>
                    <ul
                      className={
                        showProductLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "{`/vendor/product/create`}"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/vendor/product/create`}
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf(
                                `/vendor/product/create`
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Create Product
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/vendor/productlist"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/vendor/productlist"
                        >
                          <i
                            className={
                              "fas fa-boxes mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/vendor/productlist"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          All Product
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setOrder()}
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showOrderLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/vendor/orderlist"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/vendor/orderlist"
                        >
                          <i
                            className={
                              "fas fa-cart-arrow-down mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/vendor/orderlist"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          All Orders
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="test" style={{ position: "relative" }}>
                    {/* <hr className="my-3 md:min-w-full" /> */}
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setWholesalerProduct()}
                    >
                      <i
                        className={
                          "fas fa-industry text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showWholesalerProLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/wholesaleProducts"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/wholesaleProducts"
                        >
                          <i
                            className={
                              "fas fa-archive mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/wholesaleProducts"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Products
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setMyData()}
                    >
                      <i
                        className={
                          "fas fa-info-circle text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showMyDataLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/myorders") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/myorders"
                        >
                          <i
                            className={
                              "fas fa-cart-arrow-down mr-2 text-sm " +
                              (window.location.href.indexOf("/myorders") !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Orders
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              `/shop/${shopName}`
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/shop/${shopName}`}
                        >
                          <i
                            className={
                              "fas fa-store mr-2 text-sm " +
                              (window.location.href.indexOf(
                                `/shop/${shopName}`
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Store
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/followed") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/followed"
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf("/followed") !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Followed Products
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/mywishlist") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/mywishlist"
                        >
                          <i
                            className={
                              "far fa-heart mr-2 text-sm " +
                              (window.location.href.indexOf("/mywishlist") !==
                              -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Wishlist
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setManagement()}
                    >
                      <i
                        className={
                          "fas fa-tasks text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showManagementLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/vendor/inventory"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/vendor/inventory"
                        >
                          <i
                            className={
                              "fas fa-boxes mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/vendor/inventory"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Inventory
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "{`/vendor/promotion`}"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/vendor/promotion`}
                        >
                          <i
                            className={
                              "fas fa-bullhorn mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "{`/vendor/promotion`}"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Promotion
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "{`/vendor/banner`}"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/vendor/banner`}
                        >
                          <i
                            className={
                              "fas fa-images mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "{`/vendor/banner`}"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Banner & Image
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              ) : null}
              {userInfo &&
              !userInfo.isAdmin &&
              userInfo.type === "wholesaler" ? (
                <ul className="md:flex-col md:min-w-full mt-4 flex flex-col list-none">
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setDashboard()}
                    >
                      <i
                        className={
                          "fas fa-tachometer-alt text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showDashboardLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              `wholesaler/${shopName}/dashboard`
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/wholesaler/${shopName}/dashboard`}
                        >
                          <i
                            className={
                              "fas fa-tachometer-alt mr-2 text-sm " +
                              (window.location.href.indexOf(
                                `wholesaler/${shopName}/dashboard`
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Dashboard
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setProduct()}
                    >
                      <i
                        className={"fas fa-box text-2xl opacity-75 text-black"}
                      ></i>
                    </h6>
                    <ul
                      className={
                        showProductLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              `/wholesaler/product/create`
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/wholesaler/product/create`}
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "{`/wholesaler/product/create`}"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Create Product
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/wholesaler/productlist"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/wholesaler/productlist"
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/wholesaler/productlist"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          All Product
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setOrder()}
                    >
                      <i
                        className={
                          "fas fa-cart-arrow-down text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showOrderLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/wholesaler/orderlist"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/wholesaler/orderlist"
                        >
                          <i
                            className={
                              "fas fa-cart-arrow-down mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/wholesaler/orderlist"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          All Orders
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setWholesalerProduct()}
                    >
                      <i
                        className={
                          "fas fa-industry text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showWholesalerProLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/wholesaleProducts"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/wholesaleProducts"
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/wholesaleProducts"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Products
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setMyData()}
                    >
                      <i
                        className={
                          "fas fa-info-circle text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showMyDataLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/myorders") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/myorders"
                        >
                          <i
                            className={
                              "fas fa-cart-arrow-down mr-2 text-sm " +
                              (window.location.href.indexOf("/myorders") !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Orders
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              `/shop/${shopName}`
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/wholesaleshop/${shopName}`}
                        >
                          <i
                            className={
                              "fas fa-store mr-2 text-sm " +
                              (window.location.href.indexOf(
                                `/wholesaleshop/${shopName}`
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Store
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/followed") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/followed"
                        >
                          <i
                            className={
                              "fas fa-box mr-2 text-sm " +
                              (window.location.href.indexOf("/followed") !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Followed Products
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf("/mywishlist") !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/mywishlist"
                        >
                          <i
                            className={
                              "far fa-heart mr-2 text-sm " +
                              (window.location.href.indexOf("/mywishlist") !==
                              -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Wishlist
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <hr className="my-3 md:min-w-full" /> */}
                  <div className="test" style={{ position: "relative" }}>
                    <h6
                      className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-3 pb-3 px-4 no-underline my-icon"
                      onClick={() => setManagement()}
                    >
                      <i
                        className={
                          "fas fa-tasks text-2xl opacity-75 text-black"
                        }
                      ></i>
                    </h6>
                    <ul
                      className={
                        showManagementLi
                          ? "small-sidebar-ul active"
                          : "small-sidebar-ul"
                      }
                    >
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "/wholesaler/inventory"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to="/wholesaler/inventory"
                        >
                          <i
                            className={
                              "fas fa-boxes mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "/wholesaler/inventory"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Inventory
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            "text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "{`/wholesaler/promotion`}"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/wholesaler/promotion`}
                        >
                          <i
                            className={
                              "fas fa-bullhorn mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "{`/wholesaler/promotion`}"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Promotion
                        </Link>
                      </li>
                      <li
                        className="items-center px-2 mt-1"
                        onClick={() => setAllSubmenu()}
                      >
                        <Link
                          className={
                            " text-xs  font-bold block " +
                            (window.location.href.indexOf(
                              "{`/wholesaler/banner`}"
                            ) !== -1
                              ? "text-red-500 hover:text-green-600"
                              : "text-blue-700 hover:text-green-500")
                          }
                          to={`/wholesaler/banner`}
                        >
                          <i
                            className={
                              "fas fa-images mr-2 text-sm " +
                              (window.location.href.indexOf(
                                "{`/wholesaler/banner`}"
                              ) !== -1
                                ? "opacity-75"
                                : "text-blue-300")
                            }
                          ></i>{" "}
                          Banner & Image
                        </Link>
                      </li>
                    </ul>
                  </div>
                </ul>
              ) : null}
            </div>
          </div>
        </nav>
      )}
    </>
  );
};
export default Sidebar;
