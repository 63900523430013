import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { NavDropdown } from "react-bootstrap";

const WholesalerHeader = ({ name, shopName, logoutHandler }) => {
  return (
    <NavDropdown title={`Hello ${name}`} id="vendormenu">
      <LinkContainer to={`/wholesaler/${shopName}/dashboard`}>
        <NavDropdown.Item>Dashboard</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/wholesaleProducts">
        <NavDropdown.Item>Wholesale Products</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/wholesaler/productlist">
        <NavDropdown.Item>My Products</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to={`/wholesaleshop/${shopName}`}>
        <NavDropdown.Item>My Shop</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/profile">
        <NavDropdown.Item>User Profile</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/shopprofile">
        <NavDropdown.Item>Shop Profile</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/myorders">
        <NavDropdown.Item>My Orders</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/followed">
        <NavDropdown.Item>Followed Shops</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/mywishlist">
        <NavDropdown.Item>My Wishlist</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/wholesaler/orderlist">
        <NavDropdown.Item>Orders</NavDropdown.Item>
      </LinkContainer>
      {/* <LinkContainer to="/wholesaler/promotions">
        <NavDropdown.Item>Promotions</NavDropdown.Item>
      </LinkContainer> */}
      {/* <LinkContainer to="/wholesaler/settings">
        <NavDropdown.Item>Settings</NavDropdown.Item>
      </LinkContainer> */}
      {/* <LinkContainer to="/shopprofile">
        <NavDropdown.Item>Shop Profile</NavDropdown.Item>
      </LinkContainer> */}
      <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
    </NavDropdown>
  );
};

export default WholesalerHeader;
