import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUsScreen.css";
const AboutUsScreen = () => {
  return (
    <>
      <Container fluid style={{ padding: 0 }}>
        <img
          src="/images/aboutus.jpg"
          alt="About Us"
          style={{ height: "50vh", width: "100%" }}
        />
        <Container>
          <Row className="companyDescription">
            <p className="descriptionTitle">
              Pinoy Can Buy is the e-commerce site that serve the people’s need.
            </p>
            <Col md={{ span: 6, offset: 3 }} className="mt-2">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                sit et viverra viverra mattis. Velit in montes, arcu, ornare
                dolor amet.
              </p>
            </Col>
          </Row>
        </Container>
        <Row className="aboutUsImageRow">
          <Container>
            <Row>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/aboutus1.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsImageTitle"> our Story </h4>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/aboutus2.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsImageTitle"> Our Services </h4>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/aboutus3.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsImageTitle"> Our Mission </h4>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/aboutus4.png"
                  alt="About Us"
                  style={{
                    width: "100%",
                    backgroundColor: "#F9F9F9",
                    // border: '0.25px solid gray',
                  }}
                />
                <h4 className="aboutUsImageTitle"> Our Vision </h4>
              </Col>
            </Row>
          </Container>
        </Row>

        <Row className="companyDescription">
          <Col md={{ span: 8, offset: 2 }} className="ceoMessage mt-2">
            <p>
              <i>
                “ Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna,
                et platea mauris in. Pretium scelerisque molestie ullamcorper
                imperdiet sagittis, at. Turpis elementum nisl donec ut ultrices.
                Turpis ornare sollicitudin porta est. Cursus orci morbi
                adipiscing fermentum, sagittis. Posuere aliquet dignissim non,
                faucibus quam pretium arcu posuere. ”
              </i>
            </p>
            <p className="ceoNepalCanGroup"> - Chairman, Pinoy Can</p>
          </Col>
        </Row>

        <Row className="aboutUsImageRow">
          <Container>
            <Row>
              <p className="descriptionTitle">Our Team</p>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/team1.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsTeamName"> Brooklyn Simmons </h4>
                <p className="aboutUsTeamDepartment"> Managing Partner</p>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/team2.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsTeamName"> Savannah Nguyen </h4>
                <p className="aboutUsTeamDepartment"> Assistant Manager</p>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/team3.png"
                  alt="About Us"
                  style={{ width: "100%" }}
                />
                <h4 className="aboutUsTeamName"> Jenny Wilson </h4>
                <p className="aboutUsTeamDepartment"> IT Manager</p>
              </Col>
              <Col md={3} className="aboutUsImageBox">
                <img
                  src="/images/team4.png"
                  alt="About Us"
                  style={{
                    width: "100%",
                    backgroundColor: "#F9F9F9",
                    // border: '0.25px solid gray',
                  }}
                />
                <h4 className="aboutUsTeamName"> Esther Howard </h4>
                <p className="aboutUsTeamDepartment"> Managing Director</p>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default AboutUsScreen;
