import React, { useState, useEffect } from "react";
import { Form, Button, Row, Modal, Toast, Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import {
  getUserDetails,
  getVendorDetails,
  getWholesalerDetails,
  updateUserProfile,
} from "../../actions/userActions";
import Meta from "../../components/Meta";
import { USER_UPDATE_PROFILE_RESET } from "../../constants/userConstants";
import "./ProfileScreen.css";
import axios from "axios";

const ProfileScreen = ({ history }) => {
  const [show, setShow] = useState(false);
  const [OTP, setOTP] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    axios.get("/api/users/send/VerificationEmail", config);
    setShow(true);
  };

  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [phone, setPhone] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [changedVerificationStatus, setChangedVerificationStatus] =
    useState("");

  const [showNameField, setShowNameField] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [verified, setVerified] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success, error: updateError } = userUpdateProfile;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  useEffect(() => {
    if (success) {
      setMessage("Your account has been updated");
      setPassword("");
      setConfirmPassword("");
      setOldPassword("");
      setShowNameField(false);
      setShowPhoneField(false);
      setShowEmailField(false);
      setShowPasswordField(false);
      setVerified(user?.verified);
    }
    if (!userInfo) {
      history.push("/");
    } else {
      if ((user && !user.name) || success || changedVerificationStatus) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
      } else {
        setName(user?.name);
        setEmail(user?.email);
        setPhone(user?.phone);
        setVerified(user?.verified);
      }
    }
  }, [history, userInfo, dispatch, user, success]);
  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);
  const verifyButtonHandler = async () => {
    axios
      .post("/api/users/verify", {
        id: userInfo?._id,
        OTP,
      })
      .then((res) => {
        setChangedVerificationStatus(res.data);
        setShowToast(true);
      });
    setShow(false);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (password && !oldPassword) {
      setMessage("Enter the current password");
    } else {
      if (password !== confirmPassword) {
        setMessage("Passwords do not Match");
      } else {
        dispatch(
          updateUserProfile({
            id: user?._id,
            name,
            email,
            phone,
            password,
            oldPassword,
          })
        );
      }
    }
  };
  return (
    <>
      <Toast
        show={showToast}
        position="top-end"
        onClose={() => setShowToast(false)}
        delay={3000}
        autohide
        style={{
          position: "fixed",
          float: "right",
        }}
      >
        <Toast.Body
          style={{
            padding: "0px",
            margin: "0px",
          }}
        >
          <div>
            <Alert
              variant="info"
              style={{
                margin: "0px",
              }}
            >
              {changedVerificationStatus}
            </Alert>
          </div>
        </Toast.Body>
      </Toast>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Pleasse check your email for verification code
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your Code
          <input
            type="text"
            value={OTP}
            onChange={(e) => setOTP(e.target.value)}
            placeHolder="Enter your code here"
            className="ml-3 p-1 rounded border border-secondary"
          ></input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={verifyButtonHandler}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Meta title={user?.name} />
      {message && <Message variant="info">{message}</Message>}
      {updateError && <Message variant="danger">{updateError}</Message>}
      <div className="accountProfileContainer mb-5">
        <div className="accountProfile">
          <div className="accountProfileHeader">
            <h3>Account Profile</h3>
            {
              // user?.verified
              verified ? (
                <span style={{ color: "green", fontSize: "12px" }}>
                  <i className="fas fa-check-circle"></i>Verified
                </span>
              ) : (
                <>
                  <span style={{ color: "red", fontSize: "12px" }}>
                    <i className="fas fa-check-circle"></i>Unverified
                  </span>
                  <Button
                    onClick={handleShow}
                    className="p-1 bg-success ml-3 verifyButton"
                  >
                    Verify
                  </Button>
                </>
              )
            }
            <br />
            <span>Change your basic account settings</span>
          </div>

          <div className="accountProfileDetails">
            <Form onSubmit={submitHandler}>
              <div className="accountProfileInfoContainer row">
                <div className="accountProfileInfoLabelContainer col-md-3">
                  <i className="fas fa-user"></i>Profile Name
                </div>
                <div className="accountProfileInfo col-md-8">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingTop: 15 }}>
                      {name && name}
                    </div>
                    <div className="col-md-8">
                      <Form.Control
                        className={`${
                          showNameField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="name"
                        placeholder="Enter Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      ></Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowNameField(!showNameField);
                    }}
                    className="btn btn-primary EditBtn"
                  >
                    {showNameField ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>
              <div className="accountProfileInfoContainer row">
                <div className="accountProfileInfoLabelContainer col-md-3">
                  <i className="fas fa-phone"></i>Phone
                </div>
                <div className="accountProfileInfo col-md-8">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingTop: 15 }}>
                      {phone && phone}{" "}
                    </div>
                    <div className="col-md-8">
                      <Form.Control
                        className={`${
                          showPhoneField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="text"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      ></Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPhoneField(!showPhoneField);
                    }}
                    className="btn btn-primary EditBtn"
                  >
                    {showPhoneField ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>
              <div className="accountProfileInfoContainer row">
                <div className="accountProfileInfoLabelContainer col-md-3">
                  <i className="fas fa-envelope"></i>Email
                </div>
                <div className="accountProfileInfo col-md-8">
                  <div className="row">
                    <div className="col-md-4" style={{ paddingTop: 15 }}>
                      {email && email}{" "}
                    </div>
                    <div className="col-md-8">
                      <Form.Control
                        className={`${
                          showEmailField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="email"
                        placeholder="Enter Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowEmailField(!showEmailField);
                    }}
                    className="btn btn-primary EditBtn"
                  >
                    {showEmailField ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>
              <div className="accountProfileInfoContainer row">
                <div className="accountProfileInfoLabelContainer col-md-3">
                  <i className="fas fa-lock"></i>
                  Login Password
                  <p className="smallText mt-2">
                    <div className="row">
                      <div className="col-md-1">
                        <i className="fas fa-exclamation-circle"></i>
                      </div>
                      <div className="col-md-11">
                        <p>
                          Change your password regularly to improve security
                        </p>
                      </div>
                    </div>
                  </p>
                </div>
                <div className="accountProfileInfo col-md-8">
                  <div className="row">
                    <div className="col-md-4"></div>
                    <div className="col-md-8">
                      <Form.Control
                        className={`${
                          showPasswordField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="password"
                        placeholder="Enter Current Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        style={{
                          display: "initial",
                          border:
                            updateError &&
                            updateError === "Incorrect Password" &&
                            "1px solid red",
                        }}
                      ></Form.Control>
                      <Form.Control
                        className={`${
                          showPasswordField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="password"
                        placeholder="Enter New Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ display: "initial" }}
                      ></Form.Control>
                      <Form.Control
                        className={`${
                          showPasswordField
                            ? "accountProfileInfoFormControl"
                            : "accountProfileInfoFormControlHidden"
                        }`}
                        type="Password"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={{ display: "initial", marginTop: 5 }}
                      ></Form.Control>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPasswordField(!showPasswordField);
                    }}
                    className="btn btn-primary EditBtn"
                  >
                    {showPasswordField ? "Cancel" : "Edit"}
                  </button>
                </div>
              </div>

              <Row className="accountProfileDetails">
                <div className="col flotRight">
                  <Button
                    type="submit"
                    className="btn btn-primary my-3 accountProfileBtn"
                  >
                    Submit
                  </Button>
                  {userInfo?.type === "vendor" && (
                    <Button
                      className="accountProfileBtn"
                      onClick={() => {
                        history.push("/vendor/registration");
                      }}
                    >
                      {shop?.status === "approved"
                        ? "Shop Details Edit"
                        : "Shop Registration"}
                    </Button>
                  )}
                  {userInfo?.type === "wholesaler" && (
                    <Button
                      className="accountProfileBtn"
                      onClick={() => {
                        history.push("/wholesaler/registration");
                      }}
                    >
                      {shopInfo?.status === "approved"
                        ? "Shop Details Edit"
                        : "Shop Registration"}
                    </Button>
                  )}
                </div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileScreen;
