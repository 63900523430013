import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./OrderCreateScreen.css";
import {
  addProductOnOrderItems,
  getVendorOrderDetails,
  getWholesalerOrderDetails,
  updateVendorOrder,
  updateWholesalerOrder,
  deleteOrderItem,
} from "../../actions/orderActions";

import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../actions/philipinesAddressAction";
import Axios from "axios";

const OrderEditScreen = ({ history, match }) => {
  const dispatch = useDispatch();
  const orderId = match.params.id;
  const [sourceRef, setSourceRef] = useState("");

  //new product in orderlist
  const [newProduct, setNewProduct] = useState({});
  const [newDiscount, setNewDiscount] = useState(0);
  const [newDiscountedPrice, setNewDiscountedPrice] = useState(0);
  const [newQuantity, setNewQuantity] = useState(0);
  const [newPrice, setNewPrice] = useState(0);

  const [option, setOption] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [itemsPrice, setItemsPrice] = useState(0);
  const [shippingPrice, setShippingPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");
  const [source, setSource] = useState("");
  const [orderItems, setOrderItems] = useState([]);

  const [addressLine1, setAddressLine1] = useState("");
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philipines");
  const [postalCode, setPostalCode] = useState("");
  const [place, setPlace] = useState("");
  const [ward, setWard] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalAddressInfo, setAdditionalAddressInfo] = useState("");

  const [billing_addressLine1, setBillingAddressLine1] = useState("");
  const [billing_muncipality, setBillingMuncipality] = useState("");
  const [billing_province, setBillingProvince] = useState("");
  const [billing_district, setBillingDistrict] = useState("");
  const [billing_country, setBillingCountry] = useState("Philipines");
  const [billing_postalCode, setBillingPostalCode] = useState("");
  const [billing_place, setBillingPlace] = useState("");
  const [billing_ward, setBillingWard] = useState("");
  const [billing_phone, setBillingPhone] = useState("");
  const [additionalBillingAddressInfo, setAdditionalBillingAddressInfo] =
    useState("");
  const [additionalShipping, setAdditionalShipping] = useState("");
  const [feesAndCharges, setFeesAndCharges] = useState("");
  const [receiver, setReceiver] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [showAddressField, setShowAddressField] = useState(false);
  const [showBillingAddressField, setShowBillingAddressField] = useState(false);

  const [sellerVoucher, setSellerVoucher] = useState(0);
  const [platformVoucher, setPlatformVoucher] = useState(0);
  const [platformCoins, setplatformCoins] = useState(0);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderVendorDetails = useSelector((state) => state.orderVendorDetails);
  const { orders: vendorOrder } = orderVendorDetails;

  const orderEditWholesaler = useSelector((state) => state.orderEditWholesaler);
  const { success: editSuccessWholesaler } = orderEditWholesaler;

  const orderWholesalerDetails = useSelector(
    (state) => state.orderWholesalerDetails
  );
  const { orders: wholesalerOrder } = orderWholesalerDetails;

  const orderEditVendor = useSelector((state) => state.orderEditVendor);
  const { success: editSuccessVendor } = orderEditVendor;

  const orderItemDelete = useSelector((state) => state.orderItemDelete);
  const { success: deleteOrderItemSuccess } = orderItemDelete;

  const addOrderItem = useSelector((state) => state.addOrderItem);
  const { success: addOrderItemSuccess } = addOrderItem;

  async function loadCustomer(value) {
    if (value.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const cust = await Axios.get(`/api/users/existingUsers/${value}`, config);
      return cust.data.users;
    }
  }

  async function loadProducts(value) {
    if (value.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const prod = await Axios.get(`/api/products/list/${value}`, config);
      return prod.data.products;
    }
  }

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }

  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  const submitHandler = () => {
    if (vendorOrder)
      dispatch(
        updateVendorOrder({
          id: vendorOrder?._id,
          source,
          sourceRef,
          userId: customer,
          userName: customerName,
          // address: shipp,
          paymentMethod,
          // itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          additionalShipping,
          feesAndCharges,
          sellerVoucher,
          platformVoucher,
          platformCoins,
        })
      );
    if (wholesalerOrder)
      dispatch(
        updateWholesalerOrder({
          id: wholesalerOrder?._id,
          source,
          sourceRef,
          userId: customer,
          userName: customerName,
          // address: shipp,
          paymentMethod,
          // itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          additionalShipping,
          feesAndCharges,
          sellerVoucher,
          platformVoucher,
          platformCoins,
        })
      );
  };

  const deleteItem = (id) => {
    dispatch(deleteOrderItem(orderId, id));
  };

  const addProducts = (e) => {
    e.preventDefault();
    dispatch(
      addProductOnOrderItems({
        orderId,
        newProduct,
        newPrice,
        newDiscount,
        newQuantity,
        newDiscountedPrice,
      })
    );

    setNewProduct({});
    setNewPrice("");
    setNewDiscount("");
    setNewQuantity("");
    setNewDiscountedPrice("");
  };

  useEffect(() => {
    if (editSuccessVendor) {
      history.push("/vendor/orderlist");
    }

    if (editSuccessWholesaler) {
      history.push("/wholesaler/orderlist");
    }
    loadRegion();
    if (userInfo?.type === "vendor") {
      dispatch(getVendorOrderDetails(orderId));
    }
    if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerOrderDetails(orderId));
    }
    if (deleteOrderItemSuccess || addOrderItemSuccess) {
      if (userInfo?.type === "vendor") {
        dispatch(getVendorOrderDetails(orderId));
      }
      if (userInfo?.type === "wholesaler") {
        dispatch(getWholesalerOrderDetails(orderId));
      }
    }
  }, [
    dispatch,
    orderId,
    userInfo?.type,
    editSuccessVendor,
    editSuccessWholesaler,
    deleteOrderItemSuccess,
    addOrderItemSuccess,
  ]);
  useEffect(() => {
    if (vendorOrder) {
      let tempP = vendorOrder.orderItems?.reduce(
        (acc, item) => Number(item.discountedPrice) + acc,
        0
      );

      setItemsPrice(tempP);
      setAdditionalShipping(vendorOrder.additionalShipping);
      setFeesAndCharges(vendorOrder.feesAndCharges);
      setPaymentMethod(vendorOrder.paymentMethod);
      setPlatformVoucher(vendorOrder.platformVoucher);
      setSellerVoucher(vendorOrder.sellerVoucher);
      setShippingPrice(vendorOrder.shippingPrice);
      setplatformCoins(vendorOrder.platformCoins);
      setSource(vendorOrder.source);
      setSourceRef(vendorOrder.sourceRef);
      setPaymentMethod(vendorOrder.paymentMethod);
      setTaxPrice(vendorOrder.taxPrice);
      setTotalPrice(vendorOrder.totalPrice);

      setAdditionalAddressInfo(vendorOrder.address?.shipping_additional);
      setCountry(vendorOrder.address?.shipping_country);
      setDistrict(vendorOrder.address?.shipping_district);
      setMuncipality(vendorOrder.address?.shipping_municipality);
      setPlace(vendorOrder.address?.shipping_place);
      setPhone(vendorOrder.address?.shipping_phone);
      setPostalCode(vendorOrder.address?.shipping_postalCode);
      setProvince(vendorOrder.address?.shipping_province);

      setAdditionalBillingAddressInfo(vendorOrder.address?.billing_additional);
      setBillingCountry(vendorOrder.address?.billing_country);
      setBillingDistrict(vendorOrder.address?.billing_district);
      setBillingMuncipality(vendorOrder.address?.billing_municipality);
      setBillingPlace(vendorOrder.address?.billing_place);
      setBillingPhone(vendorOrder.address?.billing_phone);
      setBillingPostalCode(vendorOrder.address?.billing_postalCode);
      setBillingProvince(vendorOrder.address?.billing_province);
      setReceiver(vendorOrder.address?.receiver);
      setOrderItems(vendorOrder.orderItems);
    }
    if (wholesalerOrder) {
      setItemsPrice(
        wholesalerOrder.orderItems?.reduce(
          (acc, item) => Number(item.discountedPrice) + acc,
          0
        )
      );
      setAdditionalShipping(wholesalerOrder.additionalShipping);
      setFeesAndCharges(wholesalerOrder.feesAndCharges);
      setPaymentMethod(wholesalerOrder.paymentMethod);
      setPlatformVoucher(wholesalerOrder.platformVoucher);
      setSellerVoucher(wholesalerOrder.sellerVoucher);
      setShippingPrice(wholesalerOrder.shippingPrice);
      setplatformCoins(wholesalerOrder.platformCoins);
      setSource(wholesalerOrder.source);
      setSourceRef(wholesalerOrder.sourceRef);
      setPaymentMethod(wholesalerOrder.paymentMethod);
      setTaxPrice(wholesalerOrder.taxPrice);
      setTotalPrice(wholesalerOrder.totalPrice);
      setOrderItems(wholesalerOrder.orderItems);

      setAdditionalAddressInfo(wholesalerOrder.address?.shipping_additional);
      setCountry(wholesalerOrder.address?.shipping_country);
      setDistrict(wholesalerOrder.address?.shipping_district);
      setMuncipality(wholesalerOrder.address?.shipping_municipality);
      setPlace(wholesalerOrder.address?.shipping_place);
      setPhone(wholesalerOrder.address?.shipping_phone);
      setPostalCode(wholesalerOrder.address?.shipping_postalCode);
      setProvince(wholesalerOrder.address?.shipping_province);

      setAdditionalBillingAddressInfo(
        wholesalerOrder.address?.billing_additional
      );
      setBillingCountry(wholesalerOrder.address?.billing_country);
      setBillingDistrict(wholesalerOrder.address?.billing_district);
      setBillingMuncipality(wholesalerOrder.address?.billing_municipality);
      setBillingPlace(wholesalerOrder.address?.billing_place);
      setBillingPhone(wholesalerOrder.address?.billing_phone);
      setBillingPostalCode(wholesalerOrder.address?.billing_postalCode);
      setBillingProvince(wholesalerOrder.address?.billing_province);
      setReceiver(wholesalerOrder.address?.receiver);
    }
  }, [vendorOrder, wholesalerOrder]);

  useEffect(() => {
    setNewDiscountedPrice(Number(newPrice) - Number(newDiscount));
    // setItemsPrice(
    //   Number(itemsPrice) + Number(newDiscountedPrice) * Number(newQuantity)
    // );
    // setTotalPrice(
    //   Number(totalPrice) + Number(newDiscountedPrice) * Number(newQuantity)
    // );
  }, [newProduct, newPrice, newDiscount]);

  useEffect(() => {
    setTotalPrice(
      Number(itemsPrice) +
        Number(shippingPrice) +
        Number(taxPrice) -
        Number(sellerVoucher) -
        Number(platformVoucher) -
        Number(platformCoins)
    );
  }, [
    itemsPrice,
    shippingPrice,
    taxPrice,
    sellerVoucher,
    platformVoucher,
    platformCoins,
  ]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={9}>
          <Button
            className="bg-white text-secondary p-0 mt-2"
            onClick={() => {
              if (userInfo?.type === "wholesaler") {
                history.push("/wholesaler/orderlist");
              } else if (userInfo?.type === "vendor") {
                history.push("/vendor/orderlist");
              }
            }}
          >
            <i
              className="fas fa-angle-left p-2 mr-1"
              style={{ border: "1px solid whitesmoke" }}
            ></i>
            Go Back
          </Button>
          <Container className="mt-3 mb-3">
            <Row className="h-100">
              <Col className="orderScreenregisterForm text-center">
                <Col>
                  <h1 className="registerHeader text-center">Edit Order</h1>
                </Col>
                <Form onSubmit={submitHandler}>
                  <div className="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={12}>
                        <Form.Group
                          className="productRatingFormGroup"
                          controlId="rating"
                        >
                          <Form.Label className="orderCreateLabel">
                            Source
                          </Form.Label>
                          <Form.Control
                            className="orderCreateInputField"
                            as="select"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            required
                          >
                            <option value="">Select...</option>
                            <option value="Shopee">Shopee</option>
                            <option value="Lazada">Lazada</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Tiktok">Tiktok</option>
                            <option value="Other">Other</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Group controlId="sourceref">
                          <Form.Label className="orderCreateLabel">
                            SourceRef
                          </Form.Label>

                          <Form.Control
                            className="orderCreateInputField"
                            type="text"
                            value={sourceRef}
                            onChange={(e) => setSourceRef(e.target.value)}
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <div cLassName="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={8}>
                        <Form.Group controlId="customer">
                          <Form.Label className="orderCreateLabel">
                            Customer
                          </Form.Label>
                          <p>
                            {vendorOrder?.user?.email ||
                              wholesalerOrder?.user?.email}
                          </p>
                          <AsyncSelect
                            className="orderCreateInputField"
                            cacheOptions
                            defaultOptions
                            getOptionLabel={(e) => `${e?.name} ${e?.email}`}
                            getOptionValue={(e) => e?._id}
                            loadOptions={loadCustomer}
                            onChange={(e) => {
                              setCustomer(e?._id);
                              setCustomerName(e?.name);
                            }}
                            placeholder="Type characters for dropdown to appear"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={3}>
                        <Form.Label className="orderCreateLabel">
                          Products
                        </Form.Label>
                        <AsyncSelect
                          className="orderCreateInputField"
                          cacheOptions
                          defaultOptions
                          getOptionLabel={(e) => e?.name}
                          getOptionValue={(e) => e}
                          loadOptions={loadProducts}
                          onChange={(e) => {
                            setNewProduct(e);
                            setNewQuantity(1);
                            setNewPrice(e?.price);
                            setNewDiscount(e?.discount || 0);
                            setNewDiscountedPrice(e?.price - e?.discount);
                          }}
                          placeholder="Search products"
                        />
                      </Col>
                      <Col sm={2} className="px-2">
                        <Form.Label className="orderCreateLabel">
                          Qty
                        </Form.Label>
                        <Form.Control
                          className="orderCreateInputField"
                          type="number"
                          placeholder="Enter Quantity"
                          value={newQuantity}
                          onChange={(e) => {
                            setNewQuantity(e.target.value);
                          }}
                        ></Form.Control>
                      </Col>
                      <Col sm={2} className="px-2">
                        <Form.Label className="orderCreateLabel">
                          Price
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Price"
                          value={newPrice}
                          onChange={(e) => {
                            setNewPrice(e.target.value);
                          }}
                        ></Form.Control>
                      </Col>
                      <Col sm={2} className="px-2">
                        <Form.Label className="orderCreateLabel">
                          Discount
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Discount"
                          value={newDiscount}
                          onChange={(e) => {
                            setNewDiscount(e.target.value);
                          }}
                        ></Form.Control>
                      </Col>
                      <Col sm={2} className="px-2">
                        <Form.Label className="orderCreateLabel">
                          Price A/D
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Price A/D"
                          value={newDiscountedPrice}
                          onChange={(e) => {
                            setNewDiscountedPrice(e.target.value);
                          }}
                        ></Form.Control>
                      </Col>
                      <Col>
                        <Button
                          onClick={(e) => addProducts(e)}
                          className="orderCreateRoundBtn"
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                    {orderItems?.map((p, index) => (
                      <Row key={index} className="ps-5 mb-4 mt-1">
                        <Col>
                          <img
                            src={p.image}
                            alt={p.name}
                            style={{ height: "100px", width: "100px" }}
                          />{" "}
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                          {p.qty}{" "}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          {p.price}{" "}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          {p.discount}{" "}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          {p.discountedPrice}{" "}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center">
                          <Button
                            className="editAddressRoundBtn "
                            onClick={() => deleteItem(p.product)}
                          >
                            <i className="fas fa-trash fa-lg"></i>
                          </Button>
                        </Col>
                      </Row>
                    ))}
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={10}>
                        <Form.Label className="orderCreateLabel">
                          Address
                        </Form.Label>
                        <p className="float-left">Shipping Address</p>
                      </Col>
                      <Col sm={2}>
                        <Button
                          onClick={() => setShowAddressField(true)}
                          className="orderCreateRoundBtn pt-2"
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                    <div
                      className="addressInformation"
                      style={{ display: showAddressField ? "block" : "none" }}
                    >
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="country" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Country
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={country}
                              disabled
                              onChange={(e) => setCountry(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="province" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Region
                            </Form.Label>
                            <Select
                              className="orderCreateInputField"
                              options={option}
                              onChange={(e) => {
                                setProvince(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="district" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Province
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              loadOptions={loadProvince}
                              onChange={(e) => {
                                setDistrict(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group
                            controlId="muncipality"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Municipality
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadMunicipality}
                              onChange={(e) => {
                                setMuncipality(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="ward" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Barangay
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadBarangay}
                              onChange={(e) => {
                                setWard(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="street" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Street Address
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={addressLine1}
                              onChange={(e) => setAddressLine1(e.target.value)}
                              required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <Form.Group
                            controlId="additional"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Additional Address Information
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={additionalAddressInfo}
                              onChange={(e) =>
                                setAdditionalAddressInfo(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6}>
                          <Form.Group
                            controlId="postalcode"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              ZIP Code
                            </Form.Label>
                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={postalCode}
                              onChange={(e) => setPostalCode(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="phone" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Phone
                            </Form.Label>
                            <Form.Control
                              type="number"
                              className="wardNumberAddressField orderCreateInputField"
                              value={phone}
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="place" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Address Type
                            </Form.Label>
                            <Form.Control
                              className="orderCreateInputField"
                              as="select"
                              aria-label="Default select example"
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                              required
                            >
                              <option value="">
                                Please Select the Address Type
                              </option>
                              <option value="Home">Home</option>
                              <option value="Office">Office</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="phone" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Receiver
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="orderCreateInputField"
                              value={receiver}
                              onChange={(e) => setReceiver(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="mb-3 mt-3">
                        <Row>
                          <Col xs={1}>
                            <Form.Check
                              defaultChecked
                              value={isBilling}
                              onChange={(e) => {
                                setIsBilling(e.target.checked);
                                setShowBillingAddressField(
                                  !showBillingAddressField
                                );
                              }}
                            />
                          </Col>
                          <Col xs={11}>
                            <Form.Label className="orderCreateLabel">
                              Same as Shipping Address
                            </Form.Label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: showBillingAddressField ? "block" : "none",
                    }}
                  >
                    <div className="orderCreateFieldBox p-3">
                      <Row>
                        <Col sm={10}>
                          <Form.Label className="orderCreateLabel">
                            Address
                          </Form.Label>
                          <p className="float-left">Billing Address</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="country" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Country
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_country}
                              diasbled
                              onChange={(e) =>
                                setBillingCountry(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="province" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Region
                            </Form.Label>
                            <Select
                              className="orderCreateInputField"
                              options={option}
                              onChange={(e) => {
                                setBillingProvince(e.value);
                              }}
                              placeholder="Choose your region"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="district" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Province
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              loadOptions={loadProvince}
                              onChange={(e) => {
                                setBillingDistrict(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group
                            controlId="muncipality"
                            className="mt-3 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Municipality
                            </Form.Label>
                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadMunicipality}
                              onChange={(e) => {
                                setBillingMuncipality(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="ward" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Barangay
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadBarangay}
                              onChange={(e) => {
                                setBillingWard(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <Form.Group controlId="street" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Street Address
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_addressLine1}
                              onChange={(e) =>
                                setBillingAddressLine1(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <Form.Group
                            controlId="additional"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Additional Address Information
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={additionalBillingAddressInfo}
                              onChange={(e) =>
                                setAdditionalBillingAddressInfo(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="place" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Address Type
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              as="select"
                              aria-label="Default select example"
                              value={billing_place}
                              onChange={(e) => setBillingPlace(e.target.value)}
                            >
                              <option value="">
                                Please Select the Address Type
                              </option>
                              <option value="Home">Home</option>
                              <option value="Office">Office</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group
                            controlId="postalcode"
                            className="mt-3 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              ZIP Code
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_postalCode}
                              onChange={(e) =>
                                setBillingPostalCode(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="phone" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Phone
                            </Form.Label>

                            <Form.Control
                              type="number"
                              className="wardNumberAddressField orderCreateInputField"
                              value={billing_phone}
                              onChange={(e) => setBillingPhone(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="paymentMethod">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Payment Method
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        as="select"
                        required
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="">Please Select Payment Method</option>
                        <option value="COD">COD</option>
                        <option value="GCash">GCash</option>
                        <option value="Bank transfer">Bank transfer</option>
                        <option value="Paid via platform">
                          Paid via platform
                        </option>

                        <option value="Credit/Debit Card" disabled>
                          Credit/Debit Card{" "}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="itemsPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Items Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Items Price"
                        value={itemsPrice}
                        disabled
                        onChange={(e) => {
                          setItemsPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="taxPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Tax Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Tax Price"
                        value={taxPrice}
                        onChange={(e) => {
                          setTaxPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="shippingPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Shipping Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Shipping Price"
                        value={shippingPrice}
                        onChange={(e) => {
                          setShippingPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="sellerVoucher">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Seller Voucher
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={sellerVoucher}
                        onChange={(e) => {
                          setSellerVoucher(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="platformVoucher">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Platform Voucher
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={platformVoucher}
                        onChange={(e) => {
                          setPlatformVoucher(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="platformCoins">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Shopee Coins
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={platformCoins}
                        onChange={(e) => {
                          setplatformCoins(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Total
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Total Price"
                        value={totalPrice}
                        disabled
                        onChange={(e) => {
                          setTotalPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Additional Shipping Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Total Price"
                        value={additionalShipping}
                        onChange={(e) => {
                          setAdditionalShipping(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Additional Fees and Charges
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Total Price"
                        value={feesAndCharges}
                        onChange={(e) => {
                          setFeesAndCharges(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="mt-3 p-4">
                    <div className="d-grid gap-2">
                      <Button
                        type="submit"
                        onClick={submitHandler}
                        className="registerBtn mt-2 mb-2"
                      >
                        Update
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderEditScreen;
