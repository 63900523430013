import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInvoiceInformation } from "../../actions/orderActions";
import "./invoice.css";

const ReceiptScreen = ({ match }) => {
  const orderId = match.params.id;
  const dispatch = useDispatch();

  const invoiceInformation = useSelector((state) => state.invoiceInformation);
  const { orderInfo, shopInfo } = invoiceInformation;

  useEffect(() => {
    dispatch(getInvoiceInformation(orderId));
  }, [dispatch, orderId]);
  return (
    <div className="invoiceContainer">
      <div className="invoice">
        <div className="upperContainer">
          <div className="upperLeftContainer">
            <div className="invoiceFrom">
              <div className="invoiceLogoContainer">
                <img src="/images/logo.jpg" alt="logo" />
              </div>
              <div className="invoiceFromDetails">
                <span className="fromTitle">{shopInfo?.shopName}</span>
                <br />
                {shopInfo?.headOffice?.district} <br />
                {shopInfo?.headOffice?.province}
                <br />
                {shopInfo?.headOffice?.country}
              </div>
            </div>
            <div className="invoiceTo">
              <span className="fromTitle">Bill To</span>
              <br />
              {orderInfo?.user?.name}
            </div>
          </div>
          <div className="upperRightContainer">
            <div className="invoiceNumberContainer">
              <span style={{ fontSize: "2rem", fontWeight: "700" }}>
                Receipt
              </span>
              <p>{orderInfo?.identifier}</p>
            </div>
            <div className="balanceDue">
              <span className="fromTitle">Balance Due</span>
              <br />₱ {orderInfo?.totalPrice}
            </div>
            <div className="invoiceDetails">
              <div className="invoiceDetailsTitle">
                Receipt Date:
                <br />
              </div>
              <div className="invoiceDetailsInformation">
                {new Date(orderInfo?.createdAt).toLocaleDateString()}
                <br />
              </div>
            </div>
          </div>
        </div>
        <div className="tableContainer">
          <div className="tableHeader">
            <div className="SN">#</div>
            <div className="itemDescription">Items and Description</div>
            <div className="invoiceQty">Qty</div>
            <div className="invoiceRate">Rate</div>

            <div className="invoiceAmount">Amount</div>
          </div>

          {orderInfo?.orderItems?.map((o) => {
            return (
              <div key={o._id} className="tableRow">
                <div className="SN">1</div>
                <div className="itemDescription">{o.name}</div>
                <div className="invoiceQty">{o.qty}</div>
                <div className="invoiceRate">{o.price}</div>

                <div className="invoiceAmount">{o.qty * o.price}</div>
              </div>
            );
          })}
        </div>

        <div className="invoiceSubtotal">
          <div className="invoiceDetailsTitle">
            Sub Total:
            <br /> Total:
            <br /> Balance Due:
          </div>
          <div className="invoiceDetailsInformation">
            ₱ {orderInfo?.totalPrice}
            <br /> ₱ {orderInfo?.totalPrice} <br />₱ {orderInfo?.totalPrice}
          </div>

          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default ReceiptScreen;
