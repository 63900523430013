import {
  DASHBOARD_DATA_FAIL,
  DASHBOARD_DATA_REQUEST,
  DASHBOARD_DATA_SUCCESS,
} from "../constants/dashboardConstants";

export const dashboardCardReducer = (
  state = {
    products: [],
    active_products: [],
    order: [],
    paidorder: [],
    worder: [],
    wpaidorder: [],
    paid_sales: [],
    unpaid_sales: [],
    vdata: [],
    daysAgo: [],
    sdata: [],
    lessstock: [],
    nostock: [],
    last5order: [],
    wlast5order: [],
    last5products: [],
  },
  action
) => {
  switch (action.type) {
    case DASHBOARD_DATA_REQUEST:
      return { loading: true };
    case DASHBOARD_DATA_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        active_products: action.payload.active_products,
        order: action.payload.order,
        paidorder: action.payload.paidorder,
        worder: action.payload.worder,
        wpaidorder: action.payload.wpaidorder,
        paid_sales: action.payload.paid_sales,
        unpaid_sales: action.payload.unpaid_sales,
        vdata: action.payload.vdata,
        daysAgo: action.payload.daysAgo,
        sdata: action.payload.sdata,
        lessstock: action.payload.lessstock,
        nostock: action.payload.nostock,
        last5order: action.payload.last5order,
        wlast5order: action.payload.wlast5order,
        last5products: action.payload.last5products,
      };
    case DASHBOARD_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
