export const FOLLOW_A_SHOP_REQUEST = "FOLLOW_A_SHOP_REQUEST";
export const FOLLOW_A_SHOP_SUCCESS = "FOLLOW_A_SHOP_SUCCESS";
export const FOLLOW_A_SHOP_FAIL = "FOLLOW_A_SHOP_FAIL";
export const FOLLOW_A_SHOP_RESET = "FOLLOW_A_SHOP_RESET";

export const UNFOLLOW_A_SHOP_REQUEST = "UNFOLLOW_A_SHOP_REQUEST";
export const UNFOLLOW_A_SHOP_SUCCESS = "UNFOLLOW_A_SHOP_SUCCESS";
export const UNFOLLOW_A_SHOP_FAIL = "UNFOLLOW_A_SHOP_FAIL";
export const UNFOLLOW_A_SHOP_RESET = "UNFOLLOW_A_SHOP_RESET";

export const LIST_THE_FOLLOWERS_REQUEST = "LIST_THE_FOLLOWERS_REQUEST";
export const LIST_THE_FOLLOWERS_SUCCESS = "LIST_THE_FOLLOWERS_SUCCESS";
export const LIST_THE_FOLLOWERS_FAIL = "LIST_THE_FOLLOWERS_FAIL";

export const LIST_THE_FOLLOWINGS_REQUEST = "LIST_THE_FOLLOWINGS_REQUEST";
export const LIST_THE_FOLLOWINGS_SUCCESS = "LIST_THE_FOLLOWINGS_SUCCESS";
export const LIST_THE_FOLLOWINGS_FAIL = "LIST_THE_FOLLOWINGS_FAIL";

export const LIST_THE_FOLLOWING_PRODUCTS_REQUEST =
  "LIST_THE_FOLLOWING_PRODUCTS_REQUEST";
export const LIST_THE_FOLLOWING_PRODUCTS_SUCCESS =
  "LIST_THE_FOLLOWING_PRODUCTS_SUCCESS";
export const LIST_THE_FOLLOWING_PRODUCTS_FAIL =
  "LIST_THE_FOLLOWING_PRODUCTS_FAIL";
