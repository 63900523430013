import React, { useEffect, useState } from "react";

import { Form, Button, Tabs, Col, Row, Tab, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getVendorDetails,
  getWholesalerDetails,
  registerVendorDetails,
  registerWholesalerDetails,
} from "../../actions/userActions";
import Axios from "axios";

import Message from "../../components/Message";
import Meta from "../../components/Meta";
import {
  VENDOR_ADDRESS_DEACTIVATE_RESET,
  VENDOR_DETAILS_REGISTRATION_RESET,
  WHOLESALER_ADDRESS_DEACTIVATE_RESET,
  WHOLESALER_DETAILS_REGISTRATION_RESET,
} from "../../constants/userConstants";
import "../../index.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import VendorAddress from "../../components/VendorAddress";
import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../actions/philipinesAddressAction";

const AdminShopEditScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const name = match.params.name;
  const type = match.params.type;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const vendorDetailsByShopName = useSelector(
    (state) => state.vendorDetailsByShopName
  );
  const { user: detailsUser } = vendorDetailsByShopName;

  const wholesalerDetailsByShopName = useSelector(
    (state) => state.wholesalerDetailsByShopName
  );
  const { user: detailswholesaler } = wholesalerDetailsByShopName;
  const vendorUpdateDetails = useSelector((state) => state.vendorUpdateDetails);
  const { success } = vendorUpdateDetails;

  const vendorAddressDeactivation = useSelector(
    (state) => state.vendorAddressDeactivation
  );
  const { success: vendorAddressDeactivationSuccess } =
    vendorAddressDeactivation;

  const wholesalerAddressDeactivation = useSelector(
    (state) => state.wholesalerAddressDeactivation
  );
  const { success: wholesalerAddressDeactivationSuccess } =
    wholesalerAddressDeactivation;

  const [option, setOption] = useState([]);
  const [key, setKey] = useState("shopNameRegistration");
  const [shopName, setShopName] = useState(detailsUser?.shopName);
  const [accountName, setAccountName] = useState(
    detailsUser?.bankInformation?.accountName
  );
  const [accountNumber, setAccountNumber] = useState(
    detailsUser?.bankInformation?.accountNumber
  );
  const [bankName, setBankName] = useState(
    detailsUser?.bankInformation?.branchName
  );
  const [branchName, setBranchName] = useState(
    detailsUser?.bankInformation?.uploadCheck
  );
  const [uploadCheck, setUploadCheck] = useState(
    detailsUser?.bankInformation?.uploadCheck
  );
  const [citizenship, setCitizenship] = useState("");
  const [panVat, setPanVat] = useState("");

  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [tagline, setTagline] = useState("");
  const [shopNameTestResult, setShopNameTestResult] = useState("");

  /// new address values
  const [newAddressType, setNewAddressType] = useState("");
  const [newName, setNewName] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [newProvince, setNewProvince] = useState("");
  const [newDistrict, setNewDistrict] = useState("");
  const [newMuncipality, setNewMuncipality] = useState("");
  const [newWardNo, setNewWardNo] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newStreetAddress, setNewStreetAddress] = useState("");
  const [newPostalCode, setNewPostalCode] = useState("");
  const [newPhoneNo, setNewPhoneNo] = useState("");
  const [validated, setValidated] = useState(false);

  const uploadFileHandler = (e, type) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      if (type === "uploadCheck") setUploadCheck(res.data.url);
      else if (type === "panVat") setPanVat(res.data.url);
      else if (type === "citizenship") setCitizenship(res.data.url);
    });
  };

  const submitDocumentsHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (type === "vendor") {
        dispatch(
          registerVendorDetails(detailsUser?._id, {
            documents: { citizenship, panVat },
          })
        );
      } else if (type === "wholesaler") {
        dispatch(
          registerWholesalerDetails(detailswholesaler?._id, {
            documents: { citizenship, panVat },
          })
        );
      }
      setKey("shopNameRegistration");
    }
  };

  const shopNameTest = async (testName) => {
    setShopName(testName);
    if (type === "vendor") {
      axios
        .get(`/api/users/shopnametest/${testName}`)
        .then((res) => setShopNameTestResult(res.data));
    } else if (type === "wholesaler") {
      axios
        .get(`/api/users/wholesaler/shopnametest/${testName}`)
        .then((res) => setShopNameTestResult(res.data));
    }
  };
  const submitNameHandler = (e) => {
    e.preventDefault();
    if (type === "vendor") {
      dispatch(
        registerVendorDetails(detailsUser?._id, {
          shopName,
          tagline,
        })
      );
    } else if (type === "wholesaler") {
      dispatch(
        registerWholesalerDetails(detailswholesaler?._id, {
          shopName,
          tagline,
        })
      );
    }
    setKey("addressInformation");
  };
  const submitPaymentDetailsHandler = (e) => {
    e.preventDefault();
    if (type === "vendor") {
      dispatch(
        registerVendorDetails(detailsUser?._id, {
          accountName,
          accountNumber,
          bankName,
          branchName,
          uploadCheck,
        })
      );
    } else if (type === "wholesaler") {
      dispatch(
        registerWholesalerDetails(detailswholesaler?._id, {
          accountName,
          accountNumber,
          bankName,
          branchName,
          uploadCheck,
        })
      );
    }

    handleCloseBank();
    setKey("documents");
  };
  const submitHandler = (e, submissionType) => {
    e.preventDefault();
    if (type === "vendor") {
      dispatch(
        registerVendorDetails(detailsUser?._id, {
          submissionType,
        })
      );
    } else if (type === "wholesaler") {
      dispatch(
        registerWholesalerDetails(detailswholesaler?._id, {
          submissionType,
        })
      );
    }
  };

  // submit new Address
  const submitNewAddress = () => {
    if (newAddressType === "HeadOffice") {
      if (type === "vendor") {
        dispatch(
          registerVendorDetails(detailsUser._id, {
            headOfficeMuncipality: newMuncipality,
            headOfficeAddress: newStreetAddress,
            headOfficeDistrict: newDistrict,
            headOfficeProvince: newProvince,
            headOfficePostalCode: newPostalCode,
            headOfficeCountry: newCountry,
            headOfficeWard: newWardNo,
            headOfficePhone: newPhoneNo,
            headOfficeAdditional: newAddress,
          })
        );
      } else if (type === "wholesaler") {
        dispatch(
          registerWholesalerDetails(detailswholesaler._id, {
            headOfficeMuncipality: newMuncipality,
            headOfficeAddress: newStreetAddress,
            headOfficeDistrict: newDistrict,
            headOfficeProvince: newProvince,
            headOfficePostalCode: newPostalCode,
            headOfficeCountry: newCountry,
            headOfficeWard: newWardNo,
            headOfficePhone: newPhoneNo,
            headOfficeAdditional: newAddress,
          })
        );
      }
    } else {
      let _tempWareAddress = [...detailsUser.warehouseAddress];
      let _tempBrnAddress = [...detailsUser.branchAddress];
      let _tempRetAddress = [...detailsUser.returnAddress];
      let newAddressInfo = {
        name: newName,
        country: newCountry,
        province: newProvince,
        district: newDistrict,
        muncipality: newMuncipality,
        ward: newWardNo,
        newAddress,
        addressLine1: newStreetAddress,
        postalCode: newPostalCode,
        phone: newPhoneNo,
      };
      if (newAddressType === "Warehouse") {
        _tempWareAddress = [..._tempWareAddress, newAddressInfo];
      } else if (newAddressType === "Branch") {
        _tempBrnAddress = [..._tempBrnAddress, newAddressInfo];
      } else {
        _tempRetAddress = [..._tempRetAddress, newAddressInfo];
      }
      if (type === "vendor") {
        dispatch(
          registerVendorDetails(detailsUser._id, {
            warehouseAddressContainer: _tempWareAddress,
            returnAddressContainer: _tempRetAddress,
            branchAddressContainer: _tempBrnAddress,
          })
        );
      } else if (type === "wholesaler") {
        dispatch(
          registerWholesalerDetails(detailswholesaler._id, {
            warehouseAddressContainer: _tempWareAddress,
            returnAddressContainer: _tempRetAddress,
            branchAddressContainer: _tempBrnAddress,
          })
        );
      }
    }
    handleClose();
  };

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }

  async function loadProvince(value) {
    const data = loadProvinceList(value, newProvince);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, newDistrict);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, newMuncipality);
    return data;
  }

  useEffect(() => {
    if (success) {
      if (type === "vendor") dispatch(getVendorDetails(name));
      else if (type === "wholesaler") dispatch(getWholesalerDetails(name));
      dispatch({ type: VENDOR_DETAILS_REGISTRATION_RESET });
      dispatch({ type: WHOLESALER_DETAILS_REGISTRATION_RESET });

      setSuccessMessage("Updated Successfully ");
      setMessage("");
    }
    if (detailsUser) {
      setShopName(detailsUser?.shopName);
      setTagline(detailsUser?.tagLine);

      setAccountName(detailsUser?.bankInformation?.accountName);
      setAccountNumber(detailsUser?.bankInformation?.accountNumber);
      setBankName(detailsUser?.bankInformation?.bankName);
      setBranchName(detailsUser?.bankInformation?.branchName);
      setUploadCheck(detailsUser?.bankInformation?.uploadCheck);
      setCitizenship(detailsUser?.documents?.citizenship);
      setPanVat(detailsUser?.documents?.panVat);
    } else {
      if (type === "vendor") dispatch(getVendorDetails(name));
      else if (type === "wholesaler") dispatch(getWholesalerDetails(name));
    }
  }, [
    dispatch,
    name,
    type,
    detailsUser,
    userInfo?._id,
    success,
    detailsUser?.shopName,
    detailsUser?.returnAddress,
    detailsUser?.bankInformation,
    detailsUser?.contactInformation,
    detailsUser?.warehouseAddress,
  ]);
  useEffect(() => {
    loadRegion();
    if (type === "vendor") dispatch(getVendorDetails(name));
    else if (type === "wholesaler") dispatch(getWholesalerDetails(name));
    if (
      vendorAddressDeactivationSuccess ||
      wholesalerAddressDeactivationSuccess
    )
      dispatch({ type: VENDOR_ADDRESS_DEACTIVATE_RESET });
    dispatch({ type: WHOLESALER_ADDRESS_DEACTIVATE_RESET });
  }, [
    dispatch,
    vendorAddressDeactivationSuccess,
    wholesalerAddressDeactivationSuccess,
    name,
    type,
  ]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showBank, setShowBank] = useState(false);
  const handleCloseBank = () => setShowBank(false);
  const handleShowBank = () => {
    setShowBank(true);
  };

  return (
    <>
      <Meta title="Vendor Registration" />
      {message && <Message variant="danger">{message}</Message>}
      {successMessage && <Message variant="success">{successMessage}</Message>}

      <div className="VendorRegistrationContent">
        <Tabs
          id="vendor-registration-tab"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="shopNameRegistration" title="Shop Name Registration">
            <Row>
              <Col md={10} className="p-5">
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={submitNameHandler}
                >
                  <Form.Label className="registrationFormLabel">
                    Shop Name
                  </Form.Label>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter Shop Name"
                      value={shopName}
                      required
                      disabled={
                        detailsUser?.status === "approved" ? true : false
                      }
                      onChange={(e) => shopNameTest(e.target.value)}
                      style={{
                        border:
                          shopNameTestResult === "passed"
                            ? "1px solid green "
                            : "1px solid red ",
                      }}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label className="registrationFormLabel">
                    TagLine
                  </Form.Label>
                  <Form.Group className="mb-4">
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter TagLine"
                      value={tagline}
                      onChange={(e) => setTagline(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Button type="submit" className="vendorRegistrationBtn">
                    Next
                  </Button>
                </Form>
              </Col>
            </Row>
          </Tab>

          <Tab eventKey="addressInformation" title="Address Information">
            <Row>
              <Col md={10} className="p-5">
                <Row>
                  <Col md={6} className="mb-2">
                    <div className="addAddressBoxAdd p-5">
                      <Button
                        type="submit"
                        className="addAddressRoundBtn"
                        onClick={handleShow}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                      <p className="addAddressInfoText">
                        Click on the button to add your address information
                      </p>

                      <Modal show={show} onHide={handleClose}>
                        <Row>
                          <Col md={6}>
                            <h1 className="addAddressTitle">Add Address </h1>
                          </Col>
                          <Col md={6}>
                            <Button
                              className="modelCloseBtn"
                              onClick={handleClose}
                            >
                              <i className="fas fa-times fa-lg"></i>
                            </Button>
                          </Col>
                        </Row>
                        <hr />
                        <Modal.Body className="p-5">
                          <Row>
                            <Col md={10}>
                              <Form.Group className="mb-4">
                                <Form.Label className="registrationFormLabel">
                                  Address Type
                                </Form.Label>

                                <select
                                  className="addressTypeSelect"
                                  name={newAddressType}
                                  onChange={(e) =>
                                    setNewAddressType(e.target.value)
                                  }
                                >
                                  <option className="mr-5">
                                    Select Address Type
                                  </option>
                                  {!detailsUser?.headOffice && (
                                    <option value="HeadOffice">
                                      Head Office Address
                                    </option>
                                  )}
                                  <option value="Warehouse">
                                    Warehouse Address
                                  </option>
                                  <option value="Return">Return Address</option>
                                  <option value="Branch">Branch Address</option>
                                </select>
                              </Form.Group>
                            </Col>
                          </Row>

                          <Row>
                            <Form onSubmit={submitNewAddress}>
                              <Col md={10} className="py-2">
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Name
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter Name"
                                    required
                                    value={newName}
                                    onChange={(e) => setNewName(e.target.value)}
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Country
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter your country"
                                    required
                                    value={newCountry}
                                    onChange={(e) =>
                                      setNewCountry(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Region
                                  </Form.Label>
                                  <Select
                                    className="basic-single"
                                    options={option}
                                    onChange={(e) => {
                                      setNewProvince(e);
                                    }}
                                    placeholder="Choose your region"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Province
                                  </Form.Label>
                                  <AsyncSelect
                                    className="registerFormInput"
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={(e) => e}
                                    getOptionValue={(e) => e}
                                    loadOptions={loadProvince}
                                    onChange={(e) => {
                                      setNewDistrict(e);
                                    }}
                                    placeholder="Type characters for dropdown to appear"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Municipality
                                  </Form.Label>

                                  <AsyncSelect
                                    className="registerFormInput"
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={(e) => e}
                                    getOptionValue={(e) => e}
                                    loadOptions={loadMunicipality}
                                    onChange={(e) => {
                                      setNewMuncipality(e);
                                    }}
                                    placeholder="Type characters for dropdown to appear"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Barangay
                                  </Form.Label>

                                  <AsyncSelect
                                    className="registerFormInput"
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={(e) => e}
                                    getOptionValue={(e) => e}
                                    loadOptions={loadBarangay}
                                    onChange={(e) => {
                                      setNewWardNo(e);
                                    }}
                                    placeholder="Type characters for dropdown to appear"
                                  />
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Street Address
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter your Street Address"
                                    required
                                    value={newStreetAddress}
                                    onChange={(e) =>
                                      setNewStreetAddress(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Additional Address Information
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter your Address"
                                    value={newAddress}
                                    onChange={(e) =>
                                      setNewAddress(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Postal Code
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter your Postal Code"
                                    value={newPostalCode}
                                    onChange={(e) =>
                                      setNewPostalCode(e.target.value)
                                    }
                                  ></Form.Control>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Phone No
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter your Phone Code"
                                    required
                                    value={newPhoneNo}
                                    onChange={(e) =>
                                      setNewPhoneNo(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Button
                                  type="submit"
                                  className="vendorRegistrationBtn"
                                >
                                  Add
                                </Button>
                              </Col>
                            </Form>
                          </Row>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </Col>
                  <Row>
                    <VendorAddress detailsUser={detailsUser} />
                  </Row>
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="vendorRegistrationBtn mt-2"
                        onClick={() => setKey("bankInformation")}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Tab>

          <Tab eventKey="bankInformation" title="Bank Information">
            <Row>
              <Col md={10} className="p-5">
                <Row>
                  {detailsUser?.bankInformation ? (
                    <Col md={6}>
                      <div className="bankInfoBox p-3 ml-2">
                        <p className="addAddressInfoText">
                          <strong>
                            {detailsUser?.bankInformation?.accountName}
                          </strong>
                        </p>
                        <p className="addAddressInfoText">
                          {detailsUser?.bankInformation?.accountNumber}
                        </p>
                        <p className="addAddressInfoText mb-3">
                          <strong>
                            {detailsUser?.bankInformation?.bankName},{" "}
                            {detailsUser?.bankInformation?.branchName}
                          </strong>
                        </p>
                        {detailsUser?.bankInformation?.uploadCheck && (
                          <a
                            href={detailsUser?.bankInformation?.uploadCheck}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={detailsUser?.bankInformation?.uploadCheck}
                              alt="cheque"
                              style={{ width: "100px" }}
                            ></img>
                          </a>
                        )}
                        <div className="addressBoxEditBtn">
                          <Button
                            className="editAddressRoundBtn"
                            onClick={handleShowBank}
                          >
                            <i className="fas fa-edit fa-lg"></i>
                          </Button>
                          <Modal show={showBank} onHide={handleCloseBank}>
                            <Modal.Header closeButton>
                              <Modal.Title>Edit Bank Information</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Col md={10} className="p-5">
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Account Name
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter Account Title"
                                    value={accountName}
                                    required
                                    onChange={(e) =>
                                      setAccountName(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Account number
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter Account Number"
                                    value={accountNumber}
                                    required
                                    onChange={(e) =>
                                      setAccountNumber(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Bank name
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter Bank Name"
                                    value={bankName}
                                    required
                                    onChange={(e) =>
                                      setBankName(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                  <Form.Label className="registrationFormLabel">
                                    Branch name
                                  </Form.Label>
                                  <Form.Control
                                    className="registerFormInput"
                                    type="text"
                                    placeholder="Enter Branch Name"
                                    value={branchName}
                                    required
                                    onChange={(e) =>
                                      setBranchName(e.target.value)
                                    }
                                  ></Form.Control>
                                  <Form.Control.Feedback type="invalid">
                                    This field is required
                                  </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Label className="registrationFormLabel">
                                  Image
                                </Form.Label>
                                <Form.Group className="mb-4">
                                  <input
                                    id="image-file"
                                    label="Choose File"
                                    type="file"
                                    custom
                                    onChange={(e) =>
                                      uploadFileHandler(e, "uploadCheck")
                                    }
                                  ></input>
                                </Form.Group>
                                <Button
                                  className="vendorRegistrationBtn"
                                  onClick={submitPaymentDetailsHandler}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Modal.Body>
                          </Modal>
                        </div>
                      </div>
                    </Col>
                  ) : (
                    <Col md={6} className="mb-2">
                      <div className="addBankInfoBox p-5">
                        <Button
                          type="submit"
                          className="addAddressRoundBtn"
                          onClick={handleShowBank}
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                        <p className="addAddressInfoText">
                          Click on the button to add your bank information
                        </p>

                        <Modal show={showBank} onHide={handleCloseBank}>
                          <Modal.Header closeButton>
                            <Modal.Title>Add Bank Information</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Col md={10} className="p-5">
                              <Form.Group className="mb-4">
                                <Form.Label className="registrationFormLabel">
                                  Account Name
                                </Form.Label>
                                <Form.Control
                                  className="registerFormInput"
                                  type="text"
                                  placeholder="Enter Account Title"
                                  value={accountName}
                                  required
                                  onChange={(e) =>
                                    setAccountName(e.target.value)
                                  }
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  This field is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-4">
                                <Form.Label className="registrationFormLabel">
                                  Account number
                                </Form.Label>
                                <Form.Control
                                  className="registerFormInput"
                                  type="text"
                                  placeholder="Enter Account Number"
                                  value={accountNumber}
                                  required
                                  onChange={(e) =>
                                    setAccountNumber(e.target.value)
                                  }
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  This field is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-4">
                                <Form.Label className="registrationFormLabel">
                                  Bank name
                                </Form.Label>
                                <Form.Control
                                  className="registerFormInput"
                                  type="text"
                                  placeholder="Enter Bank Name"
                                  value={bankName}
                                  required
                                  onChange={(e) => setBankName(e.target.value)}
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  This field is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Group className="mb-4">
                                <Form.Label className="registrationFormLabel">
                                  Branch name
                                </Form.Label>
                                <Form.Control
                                  className="registerFormInput"
                                  type="text"
                                  placeholder="Enter Branch Name"
                                  value={branchName}
                                  required
                                  onChange={(e) =>
                                    setBranchName(e.target.value)
                                  }
                                ></Form.Control>
                                <Form.Control.Feedback type="invalid">
                                  This field is required
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Form.Label className="registrationFormLabel">
                                Image
                              </Form.Label>
                              <Form.Group className="mb-4">
                                <input
                                  id="image-file"
                                  label="Choose File"
                                  type="file"
                                  custom
                                  onChange={(e) =>
                                    uploadFileHandler(e, "uploadCheck")
                                  }
                                ></input>
                              </Form.Group>
                              <Button
                                className="vendorRegistrationBtn"
                                onClick={submitPaymentDetailsHandler}
                              >
                                Add
                              </Button>
                            </Col>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </Col>
                  )}

                  <Row>
                    <Col>
                      <Button
                        onClick={() => setKey("documents")}
                        className="vendorRegistrationBtn mt-3 ml-2"
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          </Tab>

          <Tab eventKey="documents" title="Documents">
            <Row>
              <Col md={10} className="p-5">
                <Form onSubmit={submitDocumentsHandler}>
                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Id
                    </Form.Label>
                    <input
                      id="image-file"
                      label="Choose File"
                      type="file"
                      custom
                      onChange={(e) => uploadFileHandler(e, "citizenship")}
                    ></input>
                  </Form.Group>
                  {citizenship && (
                    <img
                      src={citizenship}
                      alt="checkimage"
                      style={{
                        width: "80px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <Form.Group className="mb-4 mt-4">
                    <Form.Label className="registrationFormLabel">
                      Tax/Vat
                    </Form.Label>

                    <input
                      className="registerFormInput"
                      id="image-file"
                      label="Choose File"
                      type="file"
                      custom
                      onChange={(e) => uploadFileHandler(e, "panVat")}
                    ></input>
                  </Form.Group>
                  {panVat && (
                    <img
                      src={panVat}
                      alt="checkimage"
                      style={{
                        width: "80px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                  <Button
                    className="vendorRegistrationBtn mt-2 ml-2  mb-2"
                    type="submit"
                  >
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <Row>
          <Col className="vendorRegistrationSaveBtn">
            {detailsUser?.status === "Not Registered" && (
              <>
                <Button
                  onClick={(e) => submitHandler(e, "draft")}
                  className="vendorRegistrationBtn mt-2 ml-2  mb-2"
                >
                  Save as Draft
                </Button>
                <Button
                  onClick={(e) => submitHandler(e, "submitted")}
                  className="vendorRegistrationBtn mt-2 ml-2  mb-2"
                >
                  Submit
                </Button>
              </>
            )}

            {detailsUser?.status === "submitted" && (
              <>
                <Link to={`/vendor/${shopName}/dashboard`}>
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    Go to Dashboard
                  </Button>
                </Link>
                <Link to="/shopProfile">
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    View Shop Details
                  </Button>
                </Link>
              </>
            )}
            {detailsUser?.status === "approved" && (
              <>
                <Link to={`/vendor/${shopName}/dashboard`}>
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    Go to Dashboard
                  </Button>
                </Link>
                <Link to="/shopProfile">
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    View Shop Details
                  </Button>
                </Link>
              </>
            )}

            {detailsUser?.status === "draft" && (
              <>
                <Button
                  onClick={(e) => submitHandler(e, "submitted")}
                  className="vendorRegistrationBtn mt-2 ml-2  mb-2"
                >
                  Submit
                </Button>
                <Link to={`/vendor/${shopName}/dashboard`}>
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    Go to Dashboard
                  </Button>
                </Link>
                <Link to="/shopProfile">
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    View Shop Details
                  </Button>
                </Link>
              </>
            )}
            {detailsUser?.status === "disapproved" && (
              <>
                <Button
                  onClick={(e) => submitHandler(e, "submitted")}
                  className="vendorRegistrationBtn mt-2 ml-2  mb-2"
                >
                  Submit
                </Button>
                <Link to={`/vendor/${shopName}/dashboard`}>
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    Go to Dashboard
                  </Button>
                </Link>
                <Link to="/shopProfile">
                  <Button className="vendorRegistrationBtn mt-2 ml-2  mb-2">
                    View Shop Details
                  </Button>
                </Link>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdminShopEditScreen;
