import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import { registerCustomer } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";

const CustomerCreateScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [phone, setPhone] = useState("");
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const customerRegister = useSelector((state) => state.customerRegister);
  const { success, error } = customerRegister;

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (password !== confirmPassword) {
        setMessage("Passwords do not Match");
      } else {
        dispatch(registerCustomer(name, email, password, phone));
      }
    }
  };

  useEffect(() => {
    if (success) {
      history.push("/create/order");
    }
    if (error) {
      setMessage(error);
    }
  }, [success, error]);
  return (
    <FormContainer>
      <Container className="mt-5 mb-5">
        <Row className="h-100">
          <Col className="align-items-center registerForm text-center">
            <Col>
              <h1 className="registerHeader mt-5">Create customer account</h1>
            </Col>
            {message && <Message variant="danger">{message}</Message>}

            <Form
              onSubmit={submitHandler}
              noValidate
              validated={validated}
              className="col-sm-9 mx-auto"
            >
              <Form.Group controlId="name">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Name</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mb-2"
                  type="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Password</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Confirm</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="phone">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Phone</Form.Label>
                  </Col>
                </Row>

                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="number"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <div className="d-grid gap-2">
                <Button type="submit" className="registerBtn mt-5 mb-3">
                  Register
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default CustomerCreateScreen;
