import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form } from "react-bootstrap";
// import { USER_CREATE_ADDRESSBOOK_RESET } from "../../constants/addressConstants";
import { updateAddress, detailAddress } from "../../actions/addressBookAction";
import "../../index.css";
import { USER_UPDATE_ADDRESSBOOK_RESET } from "../../constants/addressConstants";

import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../actions/philipinesAddressAction";

const AddressEditScreen = ({ history, match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  // dispatch({ type: USER_CREATE_ADDRESSBOOK_RESET });
  const addressUpdate = useSelector((state) => state.addressUpdate);
  const { success: successUpdate } = addressUpdate;

  const addressDetails = useSelector((state) => state.addressDetails);
  const { address } = addressDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [option, setOption] = useState([]);

  const [addressLine1, setAddressLine1] = useState(address?.addressLine1);
  const [additional, setAdditional] = useState("");
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philipines");
  const [postalCode, setPostalCode] = useState("");
  const [place, setPlace] = useState("");
  const [ward, setWard] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [isShipping, setIsShipping] = useState(true);

  const submitHandler = async (e) => {
    e.preventDefault();

    dispatch(
      updateAddress({
        id,
        addressLine1,
        additional,
        muncipality,
        province,
        district,
        country,
        postalCode,
        ward,
        place,
        isBilling,
        isShipping,
      })
    );
    // history.push(`/address/book/${userInfo._id}`);
  };

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }
  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  useEffect(() => {
    loadRegion();
    if (!successUpdate) {
      dispatch(detailAddress(id));
      setAddressLine1(address?.addressLine1);
      setMuncipality(address?.muncipality);
      setProvince(address?.province);
      setDistrict(address?.district);
      setCountry(address?.country);
      setPostalCode(address?.postalCode);
      setPlace(address?.place);
      setWard(address?.ward);
      setAdditional(address?.additional);
      setIsBilling(address?.isBilling);
      setIsShipping(address?.isShipping);
    } else {
      history.push(`/address/book/${userInfo._id}`);
    }
    dispatch({ type: USER_UPDATE_ADDRESSBOOK_RESET });
  }, [
    dispatch,
    history,
    address?.addressLine1,
    address?.addressLine2,
    address?.province,
    address?.district,
    address?.country,
    address?.postalCode,
    address?.place,
    address?.ward,
    address?.isBilling,
    address?.isShipping,
    address?.additional,
    id,
    successUpdate,
    userInfo,
    address?.muncipality,
  ]);

  return (
    <div
      style={{
        boxShadow: "0 0 25px rgba(0, 0, 0, 0.25)",
        marginLeft: "auto",
        paddingTop: "5%",
        paddingBottom: "10%",
        marginTop: "5%",
        marginBottom: "5%",
        paddingLeft: "20%",
        height: "auto",
      }}
    >
      <Col md={9} sm={12} className="">
        <h3>Edit My Address</h3>

        <Form onSubmit={submitHandler}>
          <Form.Group controlId="country">
            <Form.Label style={{ fontSize: "13px" }}>Country</Form.Label>

            <Form.Control
              type="text"
              value={country}
              disabled
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="province">
            <Form.Label style={{ fontSize: "13px" }}>Region</Form.Label>
            <Select
              className="orderCreateInputField"
              options={option}
              onChange={(e) => {
                setProvince(e.value);
              }}
              placeholder="Choose your region"
            />
            {/* <Form.Control
              as="select"
              aria-label="Default select example"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            >
              <option value="">Please Select Province</option>
              <option value="Province 1">Province 1</option>
              <option value="Province 2">Province 2</option>
              <option value="Bagmati Province">Bagmati Province</option>
              <option value="Gandaki Province">Gandaki Province</option>
              <option value="Lumbini Province">Lumbini Province</option>
              <option value="Karnali Province">Karnali Province</option>
              <option value="Sudurpaschim Province">
                Sudurpaschim Province
              </option>
            </Form.Control> */}
          </Form.Group>
          <Form.Group controlId="district">
            <Form.Label style={{ fontSize: "13px" }}>Province</Form.Label>

            {/* <Form.Control
              type="text"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            ></Form.Control> */}

            <AsyncSelect
              className="orderCreateInputField"
              loadOptions={loadProvince}
              onChange={(e) => {
                setDistrict(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />
          </Form.Group>
          <Form.Group controlId="muncipality">
            <Form.Label style={{ fontSize: "13px" }}>Municipality</Form.Label>

            <AsyncSelect
              className="orderCreateInputField"
              cacheOptions
              defaultOptions
              loadOptions={loadMunicipality}
              onChange={(e) => {
                setMuncipality(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />
            {/* <Form.Control
              type="text"
              value={muncipality}
              onChange={(e) => setMuncipality(e.target.value)}
            ></Form.Control> */}
          </Form.Group>
          <Form.Group controlId="ward">
            <Form.Label style={{ fontSize: "13px" }}>Barangay</Form.Label>

            {/* <Form.Control
              type="text"
              value={ward}
              onChange={(e) => setWard(e.target.value)}
            ></Form.Control> */}

            <AsyncSelect
              className="orderCreateInputField"
              cacheOptions
              defaultOptions
              loadOptions={loadBarangay}
              onChange={(e) => {
                setWard(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />
          </Form.Group>
          <Form.Group controlId="postalcode">
            <Form.Label style={{ fontSize: "13px" }}>ZIP Code</Form.Label>

            <Form.Control
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="address">
            <Form.Label style={{ fontSize: "13px" }}>Street Address</Form.Label>

            <Form.Control
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="additional">
            <Form.Label style={{ fontSize: "13px" }}>
              Additional Address Information
            </Form.Label>

            <Form.Control
              type="text"
              value={additional}
              onChange={(e) => setAdditional(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="place">
            <Form.Label style={{ fontSize: "13px" }}>Place</Form.Label>

            <Form.Control
              as="select"
              aria-label="Default select example"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            >
              <option value="">Please Select the Place</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="defaultfor">
            <Form.Label style={{ fontSize: "13px" }}>Default For</Form.Label>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Shipping Address"
              value={isShipping}
              checked={isShipping}
              onChange={(e) => setIsShipping(e.target.checked)}
            />
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Billing Address"
              value={isBilling}
              checked={isBilling}
              onChange={(e) => setIsBilling(e.target.checked)}
            />
          </Form.Group>
          <button
            type="submit"
            className="btn btn-outline-primary "
            style={{ float: "right", marginTop: "2rem", width: "200px" }}
          >
            Update <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </Form>
      </Col>
    </div>
  );
};

export default AddressEditScreen;
