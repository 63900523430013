import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Product.css";

const RatingWithoutSold = ({ value, color, size }) => {
  return (
    <div className="rating">
      <Row>
        <Col className="col-sm-7 cardRating">
          <span>
            <i
              style={{ color, fontSize: size }}
              className={
                value >= 1
                  ? "fas fa-star"
                  : value >= 0.5
                  ? "fas fa-star-half-alt"
                  : "far fa-star"
              }
            ></i>
          </span>
          <span>
            <i
              style={{ color, fontSize: size }}
              className={
                value >= 2
                  ? "fas fa-star"
                  : value >= 1.5
                  ? "fas fa-star-half-alt"
                  : "far fa-star"
              }
            ></i>
          </span>
          <span>
            <i
              style={{ color, fontSize: size }}
              className={
                value >= 3
                  ? "fas fa-star"
                  : value >= 2.5
                  ? "fas fa-star-half-alt"
                  : "far fa-star"
              }
            ></i>
          </span>
          <span>
            <i
              style={{ color, fontSize: size }}
              className={
                value >= 4
                  ? "fas fa-star"
                  : value >= 3.5
                  ? "fas fa-star-half-alt"
                  : "far fa-star"
              }
            ></i>
          </span>
          <span>
            <i
              style={{ color, fontSize: size }}
              className={
                value >= 5
                  ? "fas fa-star"
                  : value >= 4.5
                  ? "fas fa-star-half-alt"
                  : "far fa-star"
              }
            ></i>
          </span>
        </Col>
      </Row>
    </div>
  );
};

RatingWithoutSold.defaultProps = {
  color: "#FFBF00",
  size: "16px",
};

export default RatingWithoutSold;
