import React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

const CardSocialTraffic = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dashboardCard = useSelector((state) => state.dashboardCard);
  const { last5order, wlast5order } = dashboardCard;

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3 className="font-semibold text-base text-blueGray-700">
                Last 5 Orders
              </h3>
            </div>
            <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
              <button
                className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
              >
                See all
              </button>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}

          <table className="items-center w-full bg-transparent border-collapse">
            <thead className="thead-light">
              <tr>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Product Name
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
                  Amount
                </th>
                <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left min-w-140-px">
                  status
                </th>
              </tr>
            </thead>
            {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
              <tbody>
                {last5order?.map((product) => (
                  <tr>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {product.orderItems.map((item) => (
                        <Row className="text-wrap">
                          <Col md={4}>
                            <div
                              style={{
                                height: "50px",
                                width: "50px",
                              }}
                            >
                              <img
                                src={item.image}
                                alt={item.name}
                                style={{
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={8}>
                            <p className="text-wrap">{item.name}</p>
                          </Col>
                        </Row>
                      ))}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ₱ {product.totalPrice}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {product.isPaid}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
            {userInfo && !userInfo.isAdmin && userInfo.type === "wholesaler" ? (
              <tbody>
                {wlast5order?.map((product) => (
                  <tr>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left">
                      {product.orderItems.map((item) => (
                        <p>
                          <img
                            className="VendorOrderImage"
                            style={{
                              marginRight: "10px",
                              height: "40px",
                              width: "40px",
                              objectFit: "contain",
                            }}
                            src={item.image}
                            alt={item.name}
                          />
                          {item.name}
                        </p>
                      ))}
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      ₱ {product.totalPrice}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {product.isPaid}
                    </td>
                  </tr>
                ))}
              </tbody>
            ) : null}
          </table>
        </div>
      </div>
    </>
  );
};

export default CardSocialTraffic;
