import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Pagination } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { listBlog, listUnpublishedBlog } from "../../actions/blogAction";
import {
  BLOG_CREATE_RESET,
  BLOG_EDIT_RESET,
} from "../../constants/blogConstants";
import parse from "html-react-parser";
import { Link } from "react-router-dom";

const BlogList = ({ history }) => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const { unpublished, pageNumber } = queryString.parse(search);

  const blogList = useSelector((state) => state.blogList);
  const { blog, pages, page } = blogList;

  const blogListUnpublished = useSelector((state) => state.blogListUnpublished);
  const {
    blog: unpublishedBlogs,
    pages: unpublishedPages,
    page: unpublishedPage,
  } = blogListUnpublished;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createBlogHandler = () => {
    history.push("/blog-create");
  };

  let paginationClickedValue;
  const test = (e) => {
    paginationClickedValue = e.target.text;
    if (!unpublished) {
      history.push(`/blog-list?pageNumber=${paginationClickedValue}`);
    }
    if (unpublished) {
      history.push(
        `/blog-list?unpublished=unpublished/pageNumber=${paginationClickedValue}`
      );
    }
  };
  let items = [];
  if (unpublished) {
    for (let num = 1; num <= unpublishedPages; num++) {
      items.push(
        <Pagination.Item
          key={num}
          active={num === unpublishedPage}
          onClick={test}
        >
          {num}
        </Pagination.Item>
      );
    }
  } else {
    for (let num = 1; num <= pages; num++) {
      items.push(
        <Pagination.Item key={num} active={num === page} onClick={test}>
          {num}
        </Pagination.Item>
      );
    }
  }

  useEffect(() => {
    dispatch({ type: BLOG_CREATE_RESET });
    dispatch({ type: BLOG_EDIT_RESET });
    if (userInfo && !unpublished) {
      dispatch(listBlog(pageNumber || 1));
    } else if (userInfo && unpublished) {
      dispatch(listUnpublishedBlog(pageNumber || 1));
    }
    dispatch({ type: BLOG_CREATE_RESET });
  }, [history, dispatch, userInfo, unpublished, pageNumber]);

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="blogScreenH1 mt-5"> Blog List</h2>
        </Col>
      </Row>
      <Row>
        <Col md={1} className="mr-10">
          <Link to={`/blog-list`}>
            <Button role="Button">Published</Button>
          </Link>
        </Col>
        <Col md={1}>
          <Link to={`/blog-list?unpublished=unpublished`}>
            <Button role="Button">Unpublished</Button>
          </Link>
        </Col>
        <Col md={7}></Col>

        <Col md={2}>
          <button
            role="Button"
            className="btn btn-outline-primary"
            onClick={(e) => createBlogHandler()}
          >
            <i className="fas fa-plus me-2"></i>Create New Blog
          </button>
        </Col>
      </Row>
      <Row className="my-10">
        {unpublished ? (
          <>
            {unpublishedBlogs?.map((list, index) => (
              <Col md={4} key={index} className="blogScreenArticleBox">
                <p className="blogScreenDate">
                  {list?.createdAt.toLocaleString()}
                </p>
                <h2 className="blogScreenBlogTitle">{list.title}</h2>
                <img
                  src={list.image}
                  alt="About Us"
                  style={{ height: "360px", width: "450px" }}
                />
                <p className="blogScreenBlogdescription">
                  {parse(list.content.substring(0, 140))}
                </p>
                <Row>
                  <Col md={10}>
                    <h4 className="BlogScreenReadMore">
                      Detail View<i className="fas fa-long-arrow-alt-right"></i>
                    </h4>
                  </Col>
                  <Col md={2}>
                    <Link
                      to={`/edit-blog/${list._id}`}
                      className="BlogScreenReadMore"
                    >
                      Edit<i className="fas fa-edit"></i>
                    </Link>
                  </Col>
                </Row>
              </Col>
            ))}
          </>
        ) : (
          <>
            {blog?.map((list, index) => (
              <Col md={4} key={index} className="blogScreenArticleBox">
                <p className="blogScreenDate">
                  {list?.createdAt.toLocaleString()}
                </p>
                <h2 className="blogScreenBlogTitle">{list.title}</h2>
                <img
                  src={list.image}
                  alt="About Us"
                  style={{ height: "360px", width: "450px" }}
                />
                <p className="blogScreenBlogdescription">
                  {parse(list.content.substring(0, 140))}
                </p>
                <Row>
                  <Col md={10}>
                    <h4 className="BlogScreenReadMore">
                      Detail View<i className="fas fa-long-arrow-alt-right"></i>
                    </h4>
                  </Col>
                  <Col md={2}>
                    <Link
                      to={`/edit-blog/${list._id}`}
                      className="BlogScreenReadMore"
                    >
                      Edit<i className="fas fa-edit"></i>
                    </Link>
                  </Col>
                </Row>
              </Col>
            ))}
          </>
        )}
      </Row>
      <Pagination>{items}</Pagination>
    </Container>
  );
};

export default BlogList;
