import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { getSpecificUserDetails, updateUser } from "../../actions/userActions";
import { USER_UPDATE_RESET } from "../../constants/userConstants";
import FormContainer from "../../components/FormContainer";
import Meta from "../../components/Meta";

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id;
  const [name, setName] = useState("");

  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [type, setType] = useState("");

  const dispatch = useDispatch();

  const userSpecificDetails = useSelector((state) => state.userSpecificDetails);
  const { loading, user, error } = userSpecificDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    success: successUpdate,
    error: errorUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      history.push("/admin/userlist");
    } else {
      if (!user?.name || user?._id !== userId) {
        dispatch(getSpecificUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    }
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ _id: userId, name, email, isAdmin, type }));
  };
  return (
    <>
      <Meta title="Edit User" />

      <Link to="/admin/userlist" className="btn btn-light my-3">
        <i
          className="fas fa-angle-left p-2 mr-1"
          style={{ border: "1px solid whitesmoke" }}
        ></i>
        Go back
      </Link>
      <FormContainer>
        <h1>Edit User</h1>
        {loadingUpdate && <Loader></Loader>}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="name"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Form.Group controlId="isAdmin">
              <Form.Check
                type="checkbox"
                label="Is Admin"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Form.Group controlId="type">
              <Form.Label>Account Type</Form.Label>
              <Col>
                <Form.Check
                  type="radio"
                  label="vendor"
                  id="vendor"
                  name="type"
                  value="vendor"
                  onChange={(e) => setType(e.target.value)}
                ></Form.Check>
              </Col>
              <Col>
                <Form.Check
                  type="radio"
                  label="customer"
                  id="customer"
                  name="type"
                  value="customer"
                  onChange={(e) => setType(e.target.value)}
                ></Form.Check>
                <Form.Check
                  type="radio"
                  label="Wholesaler"
                  id="wholesaler"
                  name="type"
                  value="wholesaler"
                  onChange={(e) => setType(e.target.value)}
                ></Form.Check>
              </Col>
            </Form.Group>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  );
};

export default UserEditScreen;
