import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { createBlog } from "../../actions/blogAction";

const BlogCreate = ({ history }) => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [image, setImage] = useState("");
  const [deletedImage, setDeletedImage] = useState("");
  //   const [slug, setSlug] = useState("");
  const editorRef = useRef(null);
  const [requestPublish, setRequestPublish] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const blogCreate = useSelector((state) => state.blogCreate);
  const { success } = blogCreate;

  const deleteHandlerImage = (image) => {
    if (window.confirm("Are you sure")) {
      setImage("");
      setDeletedImage([...deletedImage, image]);
    }
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "codevibek");

    Axios.post(
      "https://api.cloudinary.com/v1_1/codevibek/image/upload",
      formData
    ).then((res) => {
      if (res.data.width > 512 && res.data.height > 512) {
        setImage(res.data.url);
      } else {
        setImage(
          res.data.url.split("upload")[0] +
            `/upload/b_white,c_pad,h_512,w_512` +
            res.data.url.split("upload")[1]
        );
      }
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (deletedImage.length > 0) {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
        await Axios.post(
          `/api/products/image/delete/`,
          { files: deletedImage },
          config
        );
      }
      dispatch(
        createBlog({
          image,
          title,
          content: editorRef.current.getContent(),
          author,
          status: requestPublish,
          category,
          tags,
        })
      );
    }
  };

  useEffect(() => {
    if (!userInfo?.isAdmin) {
      history.push("/login");
    }
    if (success) {
      if (requestPublish) {
        history.push("/blog-list?");
      } else {
        history.push("/blog-list?unpublished=unpublished");
      }
    }
  }, [history, userInfo, success]);

  return (
    <>
      {/* <Meta title="Create Blog" /> */}
      <div
        style={{
          border: "1px solid white",
          padding: "10px 20px",
          margin: "20px 0px",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
      >
        <h1 className="productCreateHeading">Create Blog</h1>
        <Form noValidate validated={validated} onSubmit={submitHandler}>
          <Row className="my-3">
            <div id="nav-basic-information" className="product-tabs my-4 ps-4">
              <Row className="inputBox">
                <Col sm={2}>
                  {" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  Blog Title
                </Col>
                <Col sm={10}>
                  <Form.Control
                    className="inputField"
                    type="text"
                    placeholder="Enter blog title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="ml-2">
                <Col sm={2}>
                  {" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  Content of Blog
                </Col>
                <Col sm={10} className="DescriptionField">
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
                    initialValue={"<p>Write your blog Content.</p>"}
                    init={{
                      height: 500,
                      menubar: true,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </Col>
              </Row>

              <Row className="inputBox">
                <Col sm={2}>
                  {""}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  Blog Author
                </Col>
                <Col sm={10}>
                  <Form.Control
                    className="inputField"
                    type="text"
                    placeholder="Enter author name"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row className="inputBox mt-3">
                <Col sm={2}>
                  {" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  Blog Category
                </Col>
                <Col sm={10}>
                  <Form.Control
                    className="inputField"
                    type="text"
                    placeholder="Enter Blog Category"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    required
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="inputBox">
                <Col sm={2}>Tags for Blog</Col>

                <Col sm={10}>
                  <Form.Control
                    className="inputField"
                    type="text"
                    placeholder="Enter Blogs tags sepreating with comma "
                    value={tags}
                    onChange={(e) => setTags(e.target.value)}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className="imageBox mb-3">
                <Col sm={2}>
                  {" "}
                  <span style={{ color: "red", fontSize: "20px" }}>*</span>
                  Feature Image
                </Col>
                <Col sm={10}>
                  <input
                    className="imageField "
                    id="image-file"
                    label="Choose File"
                    type="file"
                    custom
                    onChange={uploadFileHandler}
                    required
                  ></input>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                  <div>
                    {image && (
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          position: "relative",
                        }}
                      >
                        <img
                          className="imagesBoxes mb-3"
                          alt=""
                          src={image}
                          style={{
                            width: "100px",
                            height: "100px",
                            objectFit: "cover",
                            border: "1px solid black",
                          }}
                        />
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          onClick={(e) => deleteHandlerImage(image)}
                          style={{
                            fontSize: "16px",
                            color: "red",
                            cursor: "pointer",
                            display: "flex",
                            flexDirection: "column",
                            position: "absolute",
                            right: 4,
                            top: 2,
                          }}
                        ></i>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>

          <Row className="my-3">
            <div id="nav-tabContent white-background">
              <div id="nav-submit" className="product-tabs my-5 ps-4"></div>
              <Row>
                <Col>
                  <Button
                    variant="light"
                    className="ProductSubmitBtn btn-sm"
                    onClick={() => setRequestPublish(true)}
                    type="submit"
                  >
                    Submit for Approval
                  </Button>
                  <Button
                    variant="light"
                    className="ProductSaveBtn btn-sm"
                    onClick={() => setRequestPublish(false)}
                    type="submit"
                  >
                    <i className="far fa-copy"> </i> Save as Draft
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default BlogCreate;
