import React from "react";
import { Switch, Route, Link } from "react-router-dom";

// components

import "../../screens/assets/styles/tailwind.css";

import Dashboard from "./WholesalerDashboard/Dashboard";
import FooterAdmin from "../../components/Dashboard/Footer/FooterAdmin";
import ProductCreateScreen from "./WholesalerDashboard/Products/ProductCreateScreen";
import ProductListScreen from "./WholesalerDashboard/Products/ProductlistScreen";
import WholesalerOrderListScreen from "./WholesalerDashboard/Orders/WholesalerOrderListScreen";
import Chat from "./Chat";
import ManageStaffs from "./ManageStaffs";
import WholesalerRegistrationScreen from "../Register/WholesalerRegistrationScreen";
import ProductEditScreen from "../Product/ProductEditScreen";
import ProfileScreen from "../User/ProfileScreens";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailsStaff } from "../../actions/staffActions";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";
import Message from "../../components/Message";
import ShopProfileScreen from "../User/ShopProfileScreen";
import ShopBannerScreen from "../User/ShopBannerScreen";

const WholesalerDashboard = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);
  useEffect(() => {
    if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler")
      dispatch(detailsStaff(userInfo?._id));
  }, [dispatch, userInfo?._id, userInfo?.type]);
  return (
    <>
      {/* <Sidebar shopName={userDetails?.shopName} />
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar history={history} className="mb-20" /> */}
      {/* Header */}
      {userDetails && userDetails?.status === "Not Registered" && (
        <Message variant="info" style={{ marginTop: "100px" }}>
          You need to register your shop to put your products for sale. Please
          follow the link to register the shop.
          <Link
            to="/wholesaler/registration"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Register
          </Link>
        </Message>
      )}
      <div
        className="px-4 md:px-10 mx-auto w-full mt-30"
        style={{ minHeight: "70vh", maxHeight: "fitContent" }}
      >
        <Switch>
          <Route
            path="/wholesaler/:shopName/dashboard"
            exact
            component={Dashboard}
          />
          <Route
            path="/wholesaler/product/create"
            exact
            component={ProductCreateScreen}
          />

          <Route
            path="/wholesaler/productlist"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/wholesaler/productlist/search/:keyword"
            component={ProductListScreen}
          />
          <Route
            path="/wholesaler/productlist/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/wholesaler/productlist/search/:keyword/page/:pageNumber"
            component={ProductListScreen}
          />

          <Route
            path="/wholesaler/productlist/:unpublished"
            component={ProductListScreen}
            exact
          />
          <Route
            path="/wholesaler/productlist/:unpublished/search/:keyword"
            component={ProductListScreen}
          />
          <Route
            path="/wholesaler/productlist/:unpublished/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/wholesaler/productlist/:unpublished/search/:keyword/page/:pageNumber"
            component={ProductListScreen}
          />
          <Route
            path="/wholesaler/orderlist"
            component={WholesalerOrderListScreen}
            exact
          />

          <Route path="/wholesaler/dashboard/Chat" component={Chat} exact />
          <Route
            path="/wholesaler/dashboard/ManageStaffs"
            component={ManageStaffs}
            exact
          />

          <Route
            path="/wholesaler/orderlist/page/:pageNumber"
            component={WholesalerOrderListScreen}
            exact
          />
          <Route
            path="/wholesaler/registration"
            component={WholesalerRegistrationScreen}
            exact
          />
          <Route path="/wholesaler/profile" component={ProfileScreen} exact />
          <Route
            path="/wholesaler/product/:id/:userId/edit"
            component={ProductEditScreen}
            exact
          />
          <Route path="/wholesaler/shopprofile" component={ShopProfileScreen} />
          <Route path="/wholesaler/banner" component={ShopBannerScreen} />
        </Switch>
      </div>
      <FooterAdmin />
      {/* </div> */}
    </>
  );
};

export default WholesalerDashboard;
