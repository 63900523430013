import React from "react";
import CardLineChart from "../../../components/Dashboard/Cards/CardLineChart.js";
import CardBarChart from "../../../components/Dashboard/Cards/CardBarChart.js";
import CardPageVisits from "../../../components/Dashboard/Cards/CardPageVisits.js";
import CardSocialTraffic from "../../../components/Dashboard/Cards/CardSocialTraffic.js";
import DashboardCardStats from "../../../components/Dashboard/DashboardCardStats.js";

export default function Dashboard() {
  return (
    <>
      <DashboardCardStats />
      <div className="flex flex-wrap">
        <div className="w-full xl:w-7/12 mb-12 xl:mb-0 px-4">
          <CardLineChart />
        </div>
        <div className="w-full xl:w-5/12 px-4">
          <CardBarChart />
        </div>
      </div>
      <div className="flex flex-wrap mt-4">
        <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
          <CardPageVisits />
        </div>
        <div className="w-full xl:w-4/12 px-4">
          <CardSocialTraffic />
        </div>
      </div>
    </>
  );
}
