import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./SearchBox.css";

import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";

const SearchBox = ({ url, history, placing, placeHolder }) => {
  const [keyword, setKeyword] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      if (url === "") {
        history.push(`${url}/search/${keyword}`);
      } else history.push(`/${url}/search/${keyword}`);
    } else {
      if (url === "") {
        history.push(`${url}`);
      } else history.push(`/${url}`);
    }

    const tagManagerArgs = {
      gtmId: "GTM-P27XCP3",
      dataLayer: {
        event: "Search", // event to be picked up by GTM
        keyword,
        userId: userInfo?._id || "",
        time: Date.now(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  };
  return (
    <Form
      className={`${placing ? "searchFormProductList" : "searchFormHeader"}`}
      onSubmit={submitHandler}
      inline
    >
      <Form.Control
        type="text"
        name="q"
        onChange={(e) => setKeyword(e.target.value)}
        className=" mr-sm-2 ml-sm-5"
        placeholder={`Search ${placeHolder ? placeHolder : "Products..."}`}
      ></Form.Control>
      <Button
        type="submit"
        variant="outline-success"
        className="GreenSearchBtn p-2"
      >
        Search
      </Button>
    </Form>
  );
};

export default SearchBox;
