import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import "./ShopProfile.css";

const ShopProfile = ({ history, data, userInfo }) => {
  return (
    <>
      <div>
        <Row className="rowLine mt-3">
          <Col md={4}>
            <h1 className="shopProfileHeading mt-3 mb-2"> Shop Details </h1>
          </Col>

          <Col md={{ span: 4, offset: 4 }} className="shopProfileBtnCol">
            <div className="shopProfileHeading mt-3">
              <div className="accountProfileButtons">
                {userInfo?.type === "vendor" && (
                  <Button
                    className="shopProfileEditBtn"
                    onClick={() => {
                      history.push("/vendor/registration");
                    }}
                  >
                    <i className="fas fa-edit"></i>{" "}
                    {data?.status === "approved"
                      ? "Shop Details Edit"
                      : "Shop Registration"}
                  </Button>
                )}
                {userInfo?.type === "wholesaler" && (
                  <Button
                    className="shopProfileEditBtn"
                    onClick={() => {
                      history.push("/wholesaler/registration");
                    }}
                  >
                    <i className="fas fa-edit"></i>{" "}
                    {data?.status === "approved"
                      ? "Shop Details Edit"
                      : "Shop Registration"}
                  </Button>
                )}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="shopProfileContent">
          <h5 to="#" className="shopProfileStoreName">
            <i className="fas fa-store storeIcon"></i> {data?.shopName}
          </h5>

          <p className="shopStatus ml-3"> {data?.status}</p>
        </Row>
        <Row className="shopProfileDetails">
          <Col md={6} className="p-3">
            <h5 className="headning5"> Bank Information</h5>
            <div className="bankBox">
              <p> {data?.bankInformation?.accountName} </p>
              <p className="shopProfileAcNo">
                {data?.bankInformation?.accountNumber}
              </p>
              <h5 className="shopProfileBankName">
                {data?.bankInformation?.bankName} ,
                {data?.bankInformation?.branchName}
              </h5>
              {data?.bankInformation?.uploadCheck && (
                <img
                  src={data?.bankInformation?.uploadCheck}
                  alt="uploadCheck"
                ></img>
              )}
            </div>
          </Col>
          <Col md={6} className="p-3">
            <h5 className="headning5"> Head Office Address</h5>
            <div className="addressBox">
              <Row className="shopProfileAddressInfo">
                <Col md={1}>
                  <i className="fas fa-map-marker-alt shopProfileIcons"></i>
                </Col>
                <Col md={11}>
                  <p>
                    {data?.headOffice?.addressLine1}{" "}
                    {data?.headOffice?.postalCode}
                  </p>
                  <p>
                    {data?.headOffice?.muncipality} {data?.headOffice?.district}{" "}
                    {data?.headOffice?.province} {data?.headOffice?.country}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <i className="fas fa-home shopProfileIcons"></i>
                </Col>
                <Col md={11}>
                  <p>{data?.headOffice?.district}</p>
                </Col>
              </Row>
              <Row>
                <Col md={1}>
                  <i className="fas fa-phone shopProfileIcons"></i>
                </Col>
                <Col md={11}>
                  <p>{data?.headOffice?.phone}</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="shopProfileDetails">
          <Col md={12} className="p-3">
            <h5 className="headning5"> Branch Address </h5>
            {data?.branchAddress.length === 0 ? (
              <div className="BranchAddressBox">
                <p> No branch address added</p>
              </div>
            ) : (
              <div className="BranchAddressBox">
                <ol>
                  {data?.branchAddress?.map((branch) => {
                    return (
                      <Row>
                        <Col md={9}>
                          <li>
                            {branch.name}, {branch.addressLine1}{" "}
                            {branch.muncipality} {branch.province}{" "}
                            {branch.district} {branch.country}{" "}
                            {branch.postalCode}
                          </li>
                        </Col>
                        <Col md={3}>
                          <i className="fas fa-phone"> </i> {branch.phone}
                        </Col>
                      </Row>
                    );
                  })}
                </ol>
              </div>
            )}
          </Col>
        </Row>
        <Row className="shopProfileDetails">
          <Col md={12} className="p-3">
            <h5 className="headning5"> Warehouse Address </h5>
            {data?.warehouseAddress.length === 0 ? (
              <div className="BranchAddressBox">
                <p> No warehouse address added </p>{" "}
              </div>
            ) : (
              <div className="BranchAddressBox">
                <ol>
                  {data?.warehouseAddress?.map((warehouse) => (
                    <Row>
                      <Col md={9}>
                        <li>
                          {warehouse.name}, {warehouse.addressLine1}{" "}
                          {warehouse.muncipality} {warehouse.district}{" "}
                          {warehouse.province} {warehouse.country}{" "}
                          {warehouse.postalCode}
                        </li>
                      </Col>
                      <Col md={3}>
                        <i className="fas fa-phone"></i>
                        {warehouse.phone}
                      </Col>
                    </Row>
                  ))}
                </ol>
              </div>
            )}
          </Col>
        </Row>
        <Row className="shopProfileDetails">
          <Col md={12} className="p-3">
            <h5 className="headning5"> Return Address </h5>
            {data?.returnAddress.length === 0 ? (
              <div className="BranchAddressBox">
                <p> No return address added</p>
              </div>
            ) : (
              <div className="BranchAddressBox">
                <ol>
                  {data?.returnAddress?.map((ret) => {
                    return (
                      <Row>
                        <Col md={9}>
                          <li>
                            {ret.name}, {ret.id} {ret.address} {ret.city}{" "}
                            {ret.country} {ret.postalCode}
                          </li>
                        </Col>
                        <Col md={3}>
                          <i className="fas fa-phone"> </i>
                          {ret.phone}
                        </Col>
                      </Row>
                    );
                  })}
                </ol>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ShopProfile;
