import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";
import "./ProfileScreen.css";
import DismissibleMessage from "../../components/DismissibleMessage";
import ShopProfile from "../../components/ShopProfile";

const ShopProfileScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  useEffect(() => {
    if (!userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);
  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);
  return (
    <>
      {shop && (
        <DismissibleMessage variant="info">{`Your shop Registration status is: ${shop.status}`}</DismissibleMessage>
      )}
      {shopInfo && (
        <DismissibleMessage variant="info">{`Your shop Registration status is: ${shopInfo.status}`}</DismissibleMessage>
      )}
      <ShopProfile
        data={userInfo.type === "vendor" ? shop : shopInfo}
        userInfo={userInfo}
        history={history}
      ></ShopProfile>
    </>
  );
};
export default ShopProfileScreen;

// import React, { useEffect } from "react";
// import { Button } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";

// import {
//   getVendorDetails,
//   getWholesalerDetails,
// } from "../../actions/userActions";
// import "./ProfileScreen.css";
// import DismissibleMessage from "../../components/DismissibleMessage";

// const ShopProfileScreen = ({ history }) => {
//   const dispatch = useDispatch();

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const vendorDetails = useSelector((state) => state.vendorDetails);
//   const { user: shop } = vendorDetails;

//   const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
//   const { user: shopInfo } = wholesalerDetails;

//   useEffect(() => {
//     if (!userInfo) {
//       history.push("/");
//     }
//   }, [history, userInfo]);
//   useEffect(() => {
//     if (userInfo?.type === "vendor") {
//       dispatch(getVendorDetails(userInfo._id));
//     } else if (userInfo?.type === "wholesaler") {
//       dispatch(getWholesalerDetails(userInfo._id));
//     }
//   }, [dispatch, userInfo._id, userInfo?.type]);

//   return (
//     <>
//       <div className="accountProfileButtons">
//         {userInfo?.type === "vendor" && (
//           <Button
//             onClick={() => {
//               history.push("/vendor/registration");
//             }}
//           >
//             {shop?.status === "approved"
//               ? "Shop Details Edit"
//               : "Shop Registration"}
//           </Button>
//         )}
//         {userInfo?.type === "wholesaler" && (
//           <Button
//             onClick={() => {
//               history.push("/wholesaler/registration");
//             }}
//           >
//             {shopInfo?.status === "approved"
//               ? "Shop Details Edit"
//               : "Shop Registration"}
//           </Button>
//         )}
//       </div>
//       {shop && (
//         <DismissibleMessage variant="info">{`Your shop Registration status is: ${shop.status}`}</DismissibleMessage>
//       )}
//       {shopInfo && (
//         <DismissibleMessage variant="info">{`Your shop Registration status is: ${shopInfo.status}`}</DismissibleMessage>
//       )}

//       {userInfo.type === "vendor" && (
//         <div className="shopDetails">
//           <h3>Shop Details</h3>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Shopname</div>
//             <div className="shopInformation">{shop?.shopName}</div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Bank Information</div>
//             <div className="shopInformation">
//               AccountName:{shop?.bankInformation?.accountName}
//               <br />
//               AccountNumber:{shop?.bankInformation?.accountNumber}
//               <br />
//               BankName:{shop?.bankInformation?.bankName}
//               <br />
//               BankInformation{shop?.bankInformation?.branchName}
//               <br />
//               <div style={{ height: "100px", width: "100px" }}>
//                 <img
//                   style={{
//                     height: "100%",
//                     width: "100%",
//                     objectFit: "contain",
//                   }}
//                   src={shop?.bankInformation?.uploadCheck}
//                   alt={shop?.shopName}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Head Office Address</div>
//             <div className="shopInformation">
//               <div
//                 style={{
//                   border: "1px solid whitesmoke",
//                   margin: "10px",
//                   padding: "10px",
//                   boxShadow: "0 0 5px",
//                 }}
//               >
//                 Address: {shop?.headOffice?.addressLine1}
//                 <br />
//                 Province: {shop?.headOffice?.province}
//                 <br />
//                 District: {shop?.headOffice?.district}
//                 <br />
//                 Country: {shop?.headOffice?.country}
//                 <br />
//                 Muncipality: {shop?.headOffice?.muncipality}
//                 <br />
//                 Phone: {shop?.headOffice?.phone}
//                 <br />
//                 Postal Code: {shop?.headOffice?.postalCode}
//                 <br />
//               </div>
//             </div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Branch Address</div>
//             <div className="shopInformation">
//               {shop?.branchAddress?.map((branch) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {branch.name}
//                     <br />
//                     Address: {branch.addressLine1}
//                     <br />
//                     Province: {branch.province}
//                     <br />
//                     District: {branch.district}
//                     <br />
//                     Country: {branch.country}
//                     <br />
//                     Muncipality: {branch.muncipality}
//                     <br />
//                     Phone: {branch.phone}
//                     <br />
//                     Postal Code: {branch.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>

//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Warehouse Address</div>
//             <div className="shopInformation">
//               {shop?.warehouseAddress?.map((warehouse) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {warehouse.name}
//                     <br />
//                     Address: {warehouse.addressLine1}
//                     <br />
//                     Province: {warehouse.province}
//                     <br />
//                     District: {warehouse.district}
//                     <br />
//                     Country: {warehouse.country}
//                     <br />
//                     Muncipality: {warehouse.muncipality}
//                     <br />
//                     Phone: {warehouse.phone}
//                     <br />
//                     Postal Code: {warehouse.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>

//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Return Address</div>
//             <div className="shopInformation">
//               {shop?.returnAddress?.map((ret) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {ret.name}
//                     <br />
//                     Address: {ret.addressLine1}
//                     <br />
//                     Province: {ret.province}
//                     <br />
//                     District: {ret.district}
//                     <br />
//                     Country: {ret.country}
//                     <br />
//                     Muncipality: {ret.muncipality}
//                     <br />
//                     Phone: {ret.phone}
//                     <br />
//                     Postal Code: {ret.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       )}

//       {userInfo.type === "wholesaler" && (
//         <div className="shopDetails">
//           <h3>Shop Details</h3>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Shopname</div>
//             <div className="shopInformation">{shopInfo?.shopName}</div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Bank Information</div>
//             <div className="shopInformation">
//               AccountName:{shopInfo?.bankInformation?.accountName}
//               <br />
//               AccountNumber:{shopInfo?.bankInformation?.accountNumber}
//               <br />
//               BankName:{shopInfo?.bankInformation?.bankName}
//               <br />
//               BankInformation{shopInfo?.bankInformation?.branchName}
//               <br />
//               <div style={{ height: "100px", width: "100px" }}>
//                 <img
//                   style={{
//                     height: "100%",
//                     width: "100%",
//                     objectFit: "contain",
//                   }}
//                   src={shopInfo?.bankInformation?.uploadCheck}
//                   alt={shopInfo?.shopName}
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Head Office Address</div>
//             <div className="shopInformation">
//               <div
//                 style={{
//                   border: "1px solid whitesmoke",
//                   margin: "10px",
//                   padding: "10px",
//                   boxShadow: "0 0 5px",
//                 }}
//               >
//                 Address: {shopInfo?.headOffice?.addressLine1}
//                 <br />
//                 Province: {shopInfo?.headOffice?.province}
//                 <br />
//                 District: {shopInfo?.headOffice?.district}
//                 <br />
//                 Country: {shopInfo?.headOffice?.country}
//                 <br />
//                 Muncipality: {shopInfo?.headOffice?.muncipality}
//                 <br />
//                 Phone: {shopInfo?.headOffice?.phone}
//                 <br />
//                 Postal Code: {shopInfo?.headOffice?.postalCode}
//                 <br />
//               </div>
//             </div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Branch Address</div>
//             <div className="shopInformation">
//               {shopInfo?.branchAddress?.map((branch) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {branch.name}
//                     <br />
//                     Address: {branch.addressLine1}
//                     <br />
//                     Province: {branch.province}
//                     <br />
//                     District: {branch.district}
//                     <br />
//                     Country: {branch.country}
//                     <br />
//                     Muncipality: {branch.muncipality}
//                     <br />
//                     Phone: {branch.phone}
//                     <br />
//                     Postal Code: {branch.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Warehouse Address</div>
//             <div className="shopInformation">
//               {shopInfo?.warehouseAddress?.map((warehouse) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {warehouse.name}
//                     <br />
//                     Address: {warehouse.addressLine1}
//                     <br />
//                     Province: {warehouse.province}
//                     <br />
//                     District: {warehouse.district}
//                     <br />
//                     Country: {warehouse.country}
//                     <br />
//                     Muncipality: {warehouse.muncipality}
//                     <br />
//                     Phone: {warehouse.phone}
//                     <br />
//                     Postal Code: {warehouse.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//           ]
//           <div className="shopInformationRow">
//             <div className="shopinformationTitle">Return Address</div>
//             <div className="shopInformation">
//               {shopInfo?.returnAddress?.map((ret) => {
//                 return (
//                   <div
//                     style={{
//                       border: "1px solid whitesmoke",
//                       margin: "10px",
//                       padding: "10px",
//                       boxShadow: "0 0 5px",
//                     }}
//                   >
//                     Name: {ret.name}
//                     <br />
//                     Address: {ret.addressLine1}
//                     <br />
//                     Province: {ret.province}
//                     <br />
//                     District: {ret.district}
//                     <br />
//                     Country: {ret.country}
//                     <br />
//                     Muncipality: {ret.muncipality}
//                     <br />
//                     Phone: {ret.phone}
//                     <br />
//                     Postal Code: {ret.postalCode}
//                     <br />
//                   </div>
//                 );
//               })}
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default ShopProfileScreen;
