import axios from "axios";
import React, { useEffect, useState } from "react";
import Message from "../../components/Message";

const AccountVerificationScreen = ({ match, history }) => {
  const id = match.params.id;
  const token = match.params.token;
  const [message, setMessage] = useState("");

  const verifyAccount = async () => {
    await axios
      .post("/api/users/verify", {
        id,
        token,
      })
      .then((res) => setMessage(res.data));
  };
  useEffect(() => {
    verifyAccount();
    if (message === "verified" || "This user is already verified") {
      history.push("/login?verified=true");
    }
  }, [message, history]);

  return (
    <div>
      {message !== "verified" &&
        message !== "This user is already verified" && (
          <Message>Sorry! something went wrong</Message>
        )}
    </div>
  );
};

export default AccountVerificationScreen;
