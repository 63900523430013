import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Image, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import {
  getVendorOrderDetails,
  deliverVendorOrder,
  paymentVendorOrder,
} from "../../actions/orderActions";
import Meta from "../../components/Meta";
import "./VendorWiseOrderScreen.css";

const VendorWiseOrderScreen = ({ match, history }) => {
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const orderId = match.params.id;

  const dispatch = useDispatch();
  const orderVendorDetails = useSelector((state) => state.orderVendorDetails);
  const { orders, loading } = orderVendorDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorOrderDeliver = useSelector((state) => state.vendorOrderDeliver);
  const { success: successDeliver } = vendorOrderDeliver;

  const orderVendorPay = useSelector((state) => state.orderVendorPay);
  const { success: successPay } = orderVendorPay;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };
    orders &&
      (orders.itemsPrice = addDecimals(
        orders?.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
      ));
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    dispatch(getVendorOrderDetails(orderId));
  }, [dispatch, orderId, history, userInfo, successDeliver, successPay]);

  const deliverHandler = () => {
    let cancelMessage = "";
    if (deliveryStatus === "Cancelled") {
      cancelMessage = prompt(
        "Please enter the reason why order is being cancelled",
        "Please dont spam"
      );
    }
    dispatch(deliverVendorOrder(deliveryStatus, cancelMessage, orderId));
  };

  const paymentHandler = () => {
    dispatch(paymentVendorOrder(paymentStatus, orderId));
  };
  return (
    <>
      <div className="container">
        <Button
          className="bg-white text-secondary p-0 mt-2"
          onClick={() => {
            history.push("/vendor/orderlist");
          }}
        >
          <i
            className="fas fa-angle-left p-2 mr-1"
            style={{ border: "1px solid whitesmoke" }}
          ></i>
          Go Back
        </Button>
        <Meta title="Order Screen" />
        <h1 className="VendorWiseH1 mt-3">Order Details</h1>
        <Row>
          <Col className="VendorWiseOrderIdContainer">
            <h5 className="VendorWiseH5">
              {orders?.user?.name}
              <span className="VendorWiseH5Blue"> {orders?.identifier} </span>
            </h5>
            <p className="VendorWise-date">
              {new Date(orders?.createdAt).toLocaleString()}
            </p>
          </Col>
        </Row>
        <div className="VendorWiseOrderDetailsContainer">
          <Row>
            <Col md={7}>
              <h5 className="VendorWiseH5">Shipping Address</h5>
              <p className="VendorWise-p">{orders?.user?.name}</p>
              <p className="VendorWise-p">
                <a href={`mailto:${orders?.user?.email}`}>
                  {orders?.user?.email}
                </a>
              </p>
              <p className="VendorWise-p">
                {orders?.address?.shipping_addressLine1},
                {orders?.address?.shipping_city}{" "}
                {orders?.address?.shipping_muncipality}{" "}
                {orders?.address?.shipping_district}{" "}
                {orders?.address?.shipping_province},
                {orders?.address?.shipping_country}
              </p>
              <p className="VendorWise-p">{orders?.address?.shipping_phone}</p>
            </Col>
            <Col md={5}>
              {/* <h5 className="VendorWiseH5">Billing Address</h5>
              <p className="VendorWise-p">{orders?.user?.name}</p>
              <p className="VendorWise-p">
                <a href={`mailto:${orders?.user?.email}`}>
                  {orders?.user?.email}
                </a>
              </p>
              <p className="VendorWise-p">
                {orders?.address?.billing_addressLine1},
                {orders?.address?.billing_city}{" "}
                {orders?.address?.billing_muncipality}{" "}
                {orders?.address?.billing_district}{" "}
                {orders?.address?.billing_province},
                {orders?.address?.billing_country}
              </p>
              <p className="VendorWise-p">+{orders?.address?.billing_phone}</p> */}
            </Col>
          </Row>
          <hr></hr>
          <Row className="mt-5 mb-5 align-items-center">
            <h5 className="VendorWiseH5">Ordered Products</h5>
            {orders?.orderItems?.map((item, index) => (
              <>
                <Col md={1}>
                  <Image src={item.image} alt={item.name} fluid rounded />
                </Col>
                <Col md={4}>
                  <p className="VendorWise-p p-2">
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                  </p>
                </Col>
                <Col md={2}>
                  <p className="VendorWise-p">
                    {item.qty} X ₱ {item.price}{" "}
                  </p>
                </Col>
                <Col md={5}>
                  <p className="VendorWise-p">
                    ₱ {(item.qty * item.price).toFixed(2)}
                  </p>
                </Col>
              </>
            ))}
          </Row>
          <hr></hr>
          {orders?.isPaid === "Pending" && (
            <Message variant="danger">Payment is Pending </Message>
          )}
          {orders?.isPaid === "Completed" && (
            <Message variant="success">Payment is Completed </Message>
          )}
          {orders?.isPaid === "Failed" && (
            <Message variant="danger">Payment is Failed </Message>
          )}
          {orders?.isPaid === "Refunded" && (
            <Message variant="warning">Payment is Refunded </Message>
          )}
          {orders?.isPaid === "Cancelled" && (
            <Message variant="danger">Payment is Cancelled </Message>
          )}
          {orders?.isPaid === "Other" && (
            <Message variant="info">Not paid for Other Reason </Message>
          )}

          {orders?.delivery?.isDelivered === "Delivered" && (
            <Message variant="success">
              Delivered on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "Packed" && (
            <Message variant="info">
              Packed on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "Shipped" && (
            <Message variant="info">
              Shipped on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "In Transit" && (
            <Message variant="info">
              In Transit on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "Returned" && (
            <Message variant="danger">
              Returned on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "Cancelled" && (
            <Message variant="danger">
              Cancelled because {orders?.delivery?.cancelMessage}
            </Message>
          )}
          {orders?.delivery?.isDelivered === "Other" && (
            <Message variant="warning">
              Other on {new Date(orders.createdAt).toLocaleString()}
            </Message>
          )}
          <Row>
            <Col md={3}>
              <h5 className="VendorWiseH5 mb-3">Payment Status</h5>
              <Form>
                <Form.Group controlId="paymentStaus">
                  <Form.Check
                    type="radio"
                    label="Pending"
                    name="paymentStatus"
                    value="Pending"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>

                  <Form.Check
                    type="radio"
                    label="Completed"
                    name="paymentStatus"
                    value="Completed"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Failed"
                    name="paymentStatus"
                    value="Failed"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Refunded"
                    name="paymentStatus"
                    value="Refunded"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>

                  <Form.Check
                    type="radio"
                    label="Cancelled"
                    name="paymentStatus"
                    value="Cancelled"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Other"
                    name="paymentStatus"
                    value="Other"
                    onChange={(e) => setPaymentStatus(e.target.value)}
                  ></Form.Check>

                  <Button
                    type="button"
                    className="VendorWiseUpdateBtn"
                    onClick={paymentHandler}
                  >
                    Update
                  </Button>
                </Form.Group>
              </Form>
            </Col>
            <Col md={4}>
              <h5 className="VendorWiseH5 mb-3">Update Status</h5>
              <Form>
                <Form.Group controlId="staus">
                  <Form.Check
                    type="radio"
                    label="Packed"
                    name="deliveryStatus"
                    value="Packed"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>

                  <Form.Check
                    type="radio"
                    label="Shipped"
                    name="deliveryStatus"
                    value="Shipped"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="In Transit"
                    name="deliveryStatus"
                    value="In Transit"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Delivered"
                    name="deliveryStatus"
                    value="Delivered"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Returned"
                    name="deliveryStatus"
                    value="Returned"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>
                  <Form.Check
                    type="radio"
                    label="Cancelled"
                    name="deliveryStatus"
                    value="Cancelled"
                    onChange={(e) => setDeliveryStatus(e.target.value)}
                  ></Form.Check>
                  <Button
                    type="button"
                    className="VendorWiseUpdateBtn"
                    onClick={deliverHandler}
                    disabled={
                      orders?.delivery?.isDelivered === "Delivered" ||
                      orders?.delivery?.isDelivered === "Returned"
                      // orders?.delivery?.isDelivered === "Cancelled"
                    }
                  >
                    Update
                  </Button>
                </Form.Group>
              </Form>
            </Col>
            <Col md={5}>
              <h5 className="VendorWiseH5 mb-3">Order Summary</h5>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Sub Total</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">₱ {orders?.itemsPrice}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Discount</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">
                    ₱{" "}
                    {orders?.orderItems?.reduce(
                      (acc, item) => item.discount + acc,
                      0
                    ) || 0}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Shipping</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">₱ {orders?.shippingPrice}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Tax</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">₱ {orders?.taxPrice}</p>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Platform Coins</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">₱ {orders?.platformCoins || 0}</p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Platform Voucher</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">
                    ₱ {orders?.platformVoucher || 0}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Seller Voucher</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">₱ {orders?.sellerVoucher || 0}</p>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mb-5">
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Total</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p text-danger">
                    ₱ {orders?.totalPrice}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Additional Shipping</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p ">
                    ₱ {orders?.additionalShipping || 0}
                  </p>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Fees and Charges</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p ">
                    ₱ {orders?.feesAndCharges || 0}
                  </p>
                </Col>
              </Row>
              <Row>
                <h5 className="VendorWiseH5 mb-3">Payment Method</h5>
                <Col sm={6}>
                  <p className="VendorWise-p-strong">Method</p>
                </Col>
                <Col sm={6}>
                  <p className="VendorWise-p">{orders?.paymentMethod}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default VendorWiseOrderScreen;
