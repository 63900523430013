import React, { useState, useEffect } from "react";

import DashboardCart from "./DashboardCart";
import VendorDashboardProfile from "../Navbar/VendorDashboardProfile";
import WholesalerDashboardProfile from "../Navbar/WholesalerDashboardProfile";
import { Navbar, Nav } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getVendorDetails,
  getWholesalerDetails,
  logout,
} from "../../../actions/userActions";
import "./Navbar.css";
import Sidebar from "../Sidebar/Sidebar";
import WholesalerDashboard from "../../../screens/Dashboard/WholesalerDashboard";
import VendorDashboard from "../../../screens/Dashboard/VendorDashboard";

const AdminNavbar = ({ history }) => {
  const dispatch = useDispatch();

  const [showFull, setShowFull] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: userDetail } = vendorDetails;

  const logoutHandler = () => {
    dispatch(logout());
    history.push("/");
  };

  const changeNavbarSize = () => {
    if (showFull === false) {
      setShowFull(true);
    } else {
      setShowFull(false);
    }
  };

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);

  return (
    <>
      {userInfo?.type === "wholesaler" && (
        <Sidebar shopName={userDetails?.shopName} setShowFull={showFull} />
      )}

      {userInfo?.type === "vendor" && (
        <Sidebar shopName={userDetail?.shopName} setShowFull={showFull} />
      )}
      <Navbar collapseOnSelect className="dashboardNavBar p-2">
        <Navbar.Brand className="dashboardBrand  ml-5" style={{ zIndex: 1000 }}>
          {showFull ? (
            <div>
              <i
                className="fas fa-chevron-right text-2xl  ml-10 top-nav-icon"
                onClick={() => changeNavbarSize()}
              ></i>
            </div>
          ) : (
            <div>
              <i
                className="fas fa-chevron-left text-2xl ml-52 ml-4 top-nav-icon"
                onClick={() => changeNavbarSize()}
                style={{ top: 0 }}
              ></i>
            </div>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <DashboardCart />

            {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
              <VendorDashboardProfile
                id={userInfo?._id}
                name={userInfo?.name}
                logoutHandler={logoutHandler}
              />
            ) : null}
            {userInfo && !userInfo.isAdmin && userInfo.type === "wholesaler" ? (
              <WholesalerDashboardProfile
                id={userInfo?._id}
                name={userInfo?.name}
                logoutHandler={logoutHandler}
              />
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {userInfo?.type === "wholesaler" && (
        <div
          className={
            showFull
              ? "relative md:ml-24 bg-blueGray-100"
              : "relative md:ml-64 bg-blueGray-100"
          }
        >
          <WholesalerDashboard />
        </div>
      )}

      {userInfo?.type === "vendor" && (
        <div
          className={
            showFull
              ? "relative md:ml-24 bg-blueGray-100"
              : "relative md:ml-64 bg-blueGray-100"
          }
        >
          <VendorDashboard />
        </div>
      )}
    </>
  );
};

export default AdminNavbar;
