import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { listMyOrders } from "../../actions/orderActions";
import Paginate from "../../components/Paginate";
import Meta from "../../components/Meta";
import "./MyOrderListScreen.css";
import { ORDER_CREATE_RESET } from "../../constants/orderConstants";
import {
  VENDOR_ORDER_ADDRESS_RESET,
  WHOLESELLER_ORDER_ADDRESS_RESET,
} from "../../constants/addressConstants";

const MyOrderListScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading, error, orders, pages, page } = orderListMy;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(listMyOrders(pageNumber));
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
      dispatch({ type: WHOLESELLER_ORDER_ADDRESS_RESET });
    }
  }, [history, dispatch, userInfo, pageNumber]);
  return (
    <div div style={{ marginBottom: 30 }}>
      <Meta title="My Orders" />

      <h1 className="myOrderListHeading ">My Order</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table responsive="md" striped>
          <thead>
            <tr className="tableRowHeading">
              <th>Order List</th>

              <th
                style={{
                  textAlign: "center",
                }}
              >
                Date
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Total
              </th>
              <th> </th>
            </tr>
          </thead>
          <tbody className="tableBody">
            {orders?.map((order) => (
              <tr key={order._id}>
                <td>
                  {order.orderItems.map((item) => (
                    <Row>
                      <img
                        className="img-thumbnail col-md-4"
                        src={item.image}
                        alt={item.name}
                        style={{ width: 75 }}
                      />
                      <Col md={7}>
                        <div
                          className="col-md-8 ml-2"
                          style={{
                            justifyContent: "left",
                            alignSelf: "center",
                            paddingLeft: "20px",
                          }}
                        >
                          {item.name}
                        </div>
                      </Col>
                      <Col md={3}>
                        <div
                          className="col-md-4 ml-2"
                          style={{
                            justifyContent: "left",

                            alignSelf: "center",

                            paddingLeft: "10px",
                          }}
                        >
                          x{" "}
                          <strong
                            style={{
                              fontSize: "16px",

                              fontWeight: "600",
                            }}
                          >
                            {item.qty}
                          </strong>
                        </div>
                      </Col>
                    </Row>
                  ))}
                </td>

                <td
                  style={{
                    justifyContent: "left",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {new Date(order.createdAt).toLocaleString()}
                  </div>
                </td>
                <td
                  style={{
                    justifyContent: "left",
                    textAlign: "center",
                  }}
                >
                  ₱ {order.totalPrice}
                </td>

                <td
                  style={{
                    justifyContent: "left",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button
                        variant="light"
                        className="myOrderDetailBtn btn-sm"
                      >
                        Details
                      </Button>
                    </LinkContainer>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <Paginate url="/myorders" pages={pages} page={page} />
          </tfoot>
        </Table>
      )}
    </div>
  );
};

export default MyOrderListScreen;
// import React, { useEffect } from "react";
// import { LinkContainer } from "react-router-bootstrap";
// import { Table, Button, Row } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";
// import { listMyOrders } from "../../actions/orderActions";
// import Paginate from "../../components/Paginate";
// import Meta from "../../components/Meta";
// import "./MyOrderListScreen.css";

// const MyOrderListScreen = ({ match, history }) => {
//   const dispatch = useDispatch();
//   const pageNumber = match.params.pageNumber || 1;
//   const orderListMy = useSelector((state) => state.orderListMy);
//   const { loading, error, orders, pages, page } = orderListMy;
//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   useEffect(() => {
//     if (userInfo) {
//       dispatch(listMyOrders(pageNumber));
//     }
//   }, [history, dispatch, userInfo, pageNumber]);
//   return (
//     <div div style={{ marginBottom: 30 }}>
//       <Meta title="My Orders" />

//       <h3 className="mt-3">My Orders</h3>
//       {loading ? (
//         <Loader />
//       ) : error ? (
//         <Message variant="danger">{error}</Message>
//       ) : (
//         <Table responsive className="table-sm">
//           <thead>
//             <tr className="tableRowHeading">
//               <th
//                 style={{
//                   textAlign: "center",
//                 }}
//               >
//                 Order List
//               </th>
//               <th
//                 style={{
//                   textAlign: "center",
//                 }}
//               >
//                 Date
//               </th>
//               <th
//                 style={{
//                   textAlign: "center",
//                 }}
//               >
//                 Total
//               </th>
//               <th> </th>
//               {/* <th>PAID</th>
// 							<th>DELIVERED</th> */}
//             </tr>
//           </thead>
//           <tbody className="tableBody">
//             {orders?.map((order) => (
//               <tr key={order._id}>
//                 <td>
//                   {" "}
//                   {order.orderItems.map((item) => (
//                     <Row>
//                       <img
//                         className="img-thumbnail col-md-4"
//                         src={item.image}
//                         alt={item.name}
//                         style={{ width: 75 }}
//                       />
//                       <p
//                         className="col-md-8 ml-2"
//                         style={{
//                           justifyContent: "left",
//                           alignSelf: "center",
//                           paddingLeft: "20px",
//                         }}
//                       >
//                         {item.name}
//                       </p>
//                     </Row>
//                   ))}
//                 </td>
//                 <td
//                   style={{
//                     justifyContent: "left",
//                     alignSelf: "center",
//                   }}
//                 >
//                   <div
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     {new Date(order.createdAt).toLocaleString()}
//                   </div>
//                 </td>
//                 <td
//                   style={{
//                     justifyContent: "left",
//                     textAlign: "center",
//                   }}
//                 >
//                   ${order.totalPrice}
//                 </td>
//                 {/* <td>
// 									{order.isPaid ? (
// 										order.paidAt?.substring(0, 10)
// 									) : (
// 										<i className="fas fa-times" style={{ color: "red" }}></i>
// 									)}
// 								</td>
// 								<td>
// 									{order.isDelivered ? (
// 										order.deliveredAt?.substring(0, 10)
// 									) : (
// 										<i className="fas fa-times" style={{ color: "red" }}></i>
// 									)}
// 								</td> */}
//                 <td
//                   style={{
//                     justifyContent: "left",
//                     alignSelf: "center",
//                   }}
//                 >
//                   <div
//                     style={{
//                       textAlign: "center",
//                     }}
//                   >
//                     <LinkContainer to={`/order/${order._id}`}>
//                       <Button
//                         variant="light"
//                         className="myOrderDetailBtn btn-sm"
//                       >
//                         Details
//                       </Button>
//                     </LinkContainer>
//                   </div>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <Paginate url="/myorders" pages={pages} page={page} />
//           </tfoot>
//         </Table>
//       )}
//     </div>
//   );
// };

// export default MyOrderListScreen;
