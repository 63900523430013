import React from "react";
import PropTypes from "prop-types";

export default function CardStats({
  statSubtitle,
  statTotalProduct,
  statActiveProduct,
  statActiveColor,
  statActiveDescripiron,
  statInActiveProduct,
  statInActiveColor,
  statInActiveDescripiron,
  statIconName,
  statIconColor,
}) {
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              <span className="font-semibold text-xl text-blueGray-700">
                {statTotalProduct}
              </span>
            </div>
            <div className="relative w-auto pl-4 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          <p className="text-sm text-blueGray-400 mt-4">
            <span className={statActiveColor + " mr-2 text-xl"}>
              {statActiveProduct}
            </span>
            <span className={statActiveColor + " whitespace-nowrap"}>
              {statActiveDescripiron}
            </span>
          </p>
          <p className="text-sm text-blueGray-400 mt-4">
            <span className={statInActiveColor + " mr-2 text-xl"}>
              {statInActiveProduct}
            </span>
            <span className="whitespace-nowrap text-red-500">
              {statInActiveDescripiron}
            </span>
          </p>
        </div>
      </div>
    </>
  );
}

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTotalProduct: PropTypes.array,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statActiveProduct: PropTypes.array,
  statActiveColor: PropTypes.string,
  statActiveDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  statInActiveProduct: PropTypes.array,
  statInActiveColor: PropTypes.string,
  statInActiveDescripiron: PropTypes.string,
  statIconColor: PropTypes.string,
};
