import {
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_LIST_REQUEST,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_LIST_MY_REQUEST,
  PRODUCT_LIST_MY_FAIL,
  PRODUCT_LIST_MY_SUCCESS,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS,
  PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL,
  PRODUCT_PUBLISH_REQUEST,
  PRODUCT_PUBLISH_SUCCESS,
  PRODUCT_PUBLISH_FAIL,
  PRODUCT_TOP_WHOLESALE_REQUEST,
  PRODUCT_TOP_WHOLESALE_SUCCESS,
  PRODUCT_TOP_WHOLESALE_FAIL,
  PRODUCT_LIST_MY_PUBLISHED_REQUEST,
  PRODUCT_LIST_MY_PUBLISHED_SUCCESS,
  PRODUCT_LIST_MY_PUBLISHED_FAIL,
  PRODUCT_WHOLESALEONLY_LIST_REQUEST,
  PRODUCT_WHOLESALEONLY_LIST_SUCCESS,
  PRODUCT_WHOLESALEONLY_LIST_FAIL,
  CREATE_WISHLIST_REQUEST,
  CREATE_WISHLIST_SUCCESS,
  CREATE_WISHLIST_FAIL,
  REMOVE_WISHLIST_REQUEST,
  REMOVE_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_FAIL,
  PRODUCT_WISHLIST_REQUEST,
  PRODUCT_WISHLIST_SUCCESS,
  PRODUCT_WISHLIST_FAIL,
  BEST_OFFERS_PRODUCT_LIST_REQUEST,
  BEST_OFFERS_PRODUCT_LIST_SUCCESS,
  BEST_OFFERS_PRODUCT_LIST_FAIL,
  MOST_POPULAR_PRODUCT_LIST_REQUEST,
  MOST_POPULAR_PRODUCT_LIST_SUCCESS,
  MOST_POPULAR_PRODUCT_LIST_FAIL,
  RECOMMENDED_PRODUCT_LIST_REQUEST,
  RECOMMENDED_PRODUCT_LIST_SUCCESS,
  RECOMMENDED_PRODUCT_LIST_FAIL,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  CREATE_WISHLIST_RESET,
  REMOVE_WISHLIST_RESET,
  ADD_PRODUCT_FAQ_REQUEST,
  ADD_PRODUCT_FAQ_SUCCESS,
  ADD_PRODUCT_FAQ_FAIL,
  ADD_PRODUCT_FAQ_RESET,
  PRODUCT_PUBLISH_RESET,
  PRODUCT_LIST_ADMIN_REQUEST,
  PRODUCT_LIST_ADMIN_SUCCESS,
  PRODUCT_LIST_ADMIN_FAIL,
  SEARCH_VENDOR_PRODUCT_LIST_REQUEST,
  SEARCH_VENDOR_PRODUCT_LIST_SUCCESS,
  SEARCH_VENDOR_PRODUCT_LIST_FAIL,
  SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST,
  SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS,
  SEARCH_WHOLESALER_PRODUCT_LIST_FAIL,
} from "../constants/productConstants";
import {
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
} from "../constants/productConstants";

export const productListReducer = (
  state = { products: [], shops: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [], shop: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products.products,
        shops: action.payload.products.shops,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wholesaleProductListReducer = (
  state = { products: [], shops: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [], shops: [] };
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products.products,
        shops: action.payload.products.shops,
      };
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wholesaleProductOnlyListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_WHOLESALEONLY_LIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_WHOLESALEONLY_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_WHOLESALEONLY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const allProductListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_ADMIN_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_ADMIN_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_LIST_ADMIN_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const categoryWiseProductListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
        count: action.payload.count,
        brands: action.payload.brands,
      };
    case PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const productListMyReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_MY_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_MY_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_LIST_MY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const productListMyPublishedReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_MY_PUBLISHED_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_LIST_MY_PUBLISHED_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case PRODUCT_LIST_MY_PUBLISHED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const searchVendorProductsListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_VENDOR_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case SEARCH_VENDOR_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products.products,
        pages: action.payload.products.pages,
        page: action.payload.products.page,
        categories: action.payload.products.categories,
        brands: action.payload.products.brands,
        warrantyTypes: action.payload.products.warrantyTypes,
        shops: action.payload.products.shops,
      };
    case SEARCH_VENDOR_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const searchWholesalerProductsListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products.products,
        pages: action.payload.products.pages,
        page: action.payload.products.page,
        categories: action.payload.products.categories,
        brands: action.payload.products.brands,
        warrantyTypes: action.payload.products.warrantyTypes,
        shops: action.payload.products.shops,
      };
    case SEARCH_WHOLESALER_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true };
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true };
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_UPDATE_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productUpdateFAQReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case ADD_PRODUCT_FAQ_REQUEST:
      return { loading: true };
    case ADD_PRODUCT_FAQ_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case ADD_PRODUCT_FAQ_FAIL:
      return { loading: false, error: action.payload };
    case ADD_PRODUCT_FAQ_RESET:
      return { product: {} };
    default:
      return state;
  }
};

export const productPublishReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PUBLISH_REQUEST:
      return { loading: true };
    case PRODUCT_PUBLISH_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_PUBLISH_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_PUBLISH_RESET:
      return {};

    default:
      return state;
  }
};

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case PRODUCT_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_TOP_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const wholesaleProductTopRatedReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_TOP_WHOLESALE_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_TOP_WHOLESALE_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_TOP_WHOLESALE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const wishlistListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_WISHLIST_REQUEST:
      return { loading: true, products: [] };
    case PRODUCT_WISHLIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case PRODUCT_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const wishlistDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case REMOVE_WISHLIST_REQUEST:
      return { loading: true };
    case REMOVE_WISHLIST_SUCCESS:
      return { loading: false, success: true };
    case REMOVE_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    case REMOVE_WISHLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const wishlistCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WISHLIST_REQUEST:
      return { loading: true };
    case CREATE_WISHLIST_SUCCESS:
      return { loading: false, success: true };
    case CREATE_WISHLIST_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_WISHLIST_RESET:
      return {};
    default:
      return state;
  }
};

export const newProductListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case NEW_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case NEW_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case NEW_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const recommendedProductListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case RECOMMENDED_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case RECOMMENDED_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case RECOMMENDED_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const mostPopularProductListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case MOST_POPULAR_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case MOST_POPULAR_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case MOST_POPULAR_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const bestOffersProductListReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case BEST_OFFERS_PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] };
    case BEST_OFFERS_PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case BEST_OFFERS_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
