import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Row,
  ButtonGroup,
  Col,
  Table,
  Toast,
} from "react-bootstrap";
import Message from "../../components/Message";
import {
  addCartItemToServer,
  editCheckedCartItemFromServer,
  getCartFromServer,
  removeCartItemFromServer,
  removeCheckedCartItemsFromServer,
} from "../../actions/cartActions";
import "./CartScreen.css";
import Meta from "../../components/Meta";
import {
  USER_DETAILS_ADDRESSBOOK_RESET,
  VENDOR_ORDER_ADDRESS_RESET,
  WHOLESELLER_ORDER_ADDRESS_RESET,
} from "../../constants/addressConstants";
import { addToWishlist } from "../../actions/productActions";
import {
  CART_ADD_TO_SERVER_RESET,
  CART_EDIT_CHECKED_SERVER_RESET,
  CART_REMOVE_CHECKED_FROM_SERVER_RESET,
  CART_REMOVE_FROM_SERVER_RESET,
} from "../../constants/cartConstants";
import Loader from "../../components/Loader";
import TagManager from "react-gtm-module";

const CartScreen = ({ match, location, history }) => {
  const [showToast, setShowToast] = useState(false);
  // const [checkedForCheckoutUpdated, setCheckedForCheckoutUpdated] =
  //   useState(false);

  const productId = match.params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const getCartItems = useSelector((state) => state.getCartItems);
  const { cart: cartItems, shop } = getCartItems;

  const cartItemAdd = useSelector((state) => state.cartItemAdd);
  const { success: addSuccess } = cartItemAdd;

  const cartItemDelete = useSelector((state) => state.cartItemDelete);
  const { success: deleteSuccess } = cartItemDelete;

  const cartItemsDelete = useSelector((state) => state.cartItemsDelete);
  const { success: allremoveSuccess } = cartItemsDelete;

  const cartItemEditChecked = useSelector((state) => state.cartItemEditChecked);
  const { success: checkSuccess, loading: checkLoading } = cartItemEditChecked;

  let checkoutButtonDisabler = false;
  let errormessage = "";
  const checkedCartItems = cartItems?.products?.filter(
    (c) => c.checkedForCheckout === true
  );

  const errorMessageHandler = () => {
    let qtyAmt = false;
    cartItems?.products?.forEach((item) => {
      const flag =
        item.product.minOrderQuantity > item.qty ||
        item.qty > item.product.countInStock;
      qtyAmt = qtyAmt || flag;
    });
    checkoutButtonDisabler = checkedCartItems?.length === 0 || qtyAmt;

    if (qtyAmt) {
      errormessage =
        "Ordered quantity must be within the range of Minimum Order Quantity and Count In Stock";
    } else {
      errormessage = "";
    }
  };
  errorMessageHandler();

  useEffect(() => {
    if (allremoveSuccess) {
      dispatch(getCartFromServer(userInfo?._id));
      dispatch({ type: CART_REMOVE_CHECKED_FROM_SERVER_RESET });
    }
    if (checkSuccess) {
      dispatch(getCartFromServer(userInfo?._id));
      dispatch({ type: CART_EDIT_CHECKED_SERVER_RESET });
    }
    if (deleteSuccess || addSuccess) {
      dispatch(getCartFromServer(userInfo?._id));
      dispatch({ type: CART_REMOVE_FROM_SERVER_RESET });
      dispatch({ type: CART_ADD_TO_SERVER_RESET });
    } else {
      dispatch(getCartFromServer(userInfo?._id));
    }
  }, [
    dispatch,
    deleteSuccess,
    addSuccess,
    checkSuccess,
    allremoveSuccess,
    userInfo?._id,
  ]);

  useEffect(() => {
    dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
    dispatch({ type: WHOLESELLER_ORDER_ADDRESS_RESET });
    dispatch({ type: USER_DETAILS_ADDRESSBOOK_RESET });
  }, [dispatch, productId, qty]);

  const changeCheckoutStatus = (prodId) => {
    dispatch(editCheckedCartItemFromServer(userInfo?._id, prodId));
  };
  const buyLaterHandler = (item) => {
    dispatch(addToWishlist(item.product));
    dispatch(removeFromCartHandler(item.product._id));

    const tagManagerArgs = {
      gtmId: "GTM-W4LFMFN",
      dataLayer: {
        event: "Add to wishlist", // event to be picked up by GTM
        productId: item?._id || "", // anything you want here
        userId: userInfo?._id || "",
      },
    };

    TagManager.dataLayer(tagManagerArgs);

    setShowToast(true);
  };
  const removeFromCartHandler = (id) => {
    dispatch(removeCartItemFromServer(userInfo?._id, id));
    // dispatch(removeFromCart(id));
  };

  const removeCheckedHandler = () => {
    dispatch(removeCheckedCartItemsFromServer());
  };
  const checkOutHandler = () => {
    if (userInfo.type === "customer") {
      history.push("/customer/shipping/detail");
    } else if (userInfo.type === "vendor") {
      history.push("/retail/shipping/detail");
    } else if (userInfo.type === "wholesaler") {
      history.push("/wholesale/shipping/detail");
    }
  };

  return (
    <Row>
      {showToast && (
        <Toast
          position="top-end"
          className="p-3 position-absolute bg-success"
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
        >
          <Toast.Body>Product added to the wishlist</Toast.Body>
        </Toast>
      )}
      <Meta title="Pinoycanbuy-Cart" />
      {errormessage && <Message variant="danger">{errormessage}</Message>}
      <h3 className="cartPageHeading mt-5 mb-3">Your cart</h3>
      {cartItems?.products?.length === 0 ? (
        <Message>
          Your cart is empty
          <Link to="/">
            <i
              className="fas fa-angle-left p-2 mr-1"
              style={{ border: "1px solid whitesmoke" }}
            ></i>
            Go back
          </Link>
        </Message>
      ) : (
        <>
          {checkLoading && <Loader />}

          <Row>
            <Col>
              <div
                style={{ cursor: "pointer" }}
                onClick={removeCheckedHandler}
                className="delectAllLink mb-1"
              >
                <span className="fas fa-trash ml-2  cartScreenTrash text-danger">
                  {" "}
                </span>
              </div>
            </Col>
          </Row>
          <Table responsive>
            <thead className="cartScreenTable">
              <tr>
                <th>
                  <Row>
                    <Col sm={2}></Col>
                    <Col sm={10}>Product Name</Col>
                  </Row>
                </th>
                <th>Price</th>
                <th className="CartScreenAlignCenter">Qty</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cartItems?.products?.map((item, index) => (
                <tr key={item.product?._id} className="cartScreenTd">
                  <td className="cartScreenTd">
                    <Row>
                      <Col sm={1}>
                        <Form.Check
                          checked={item.checkedForCheckout}
                          onChange={() =>
                            changeCheckoutStatus(item.product?._id)
                          }
                        ></Form.Check>
                      </Col>
                      <Col sm={11}>
                        <Row>
                          <div className="col-md-3 p-2">
                            <img
                              src={item.product?.image}
                              alt={item.product?.name}
                              className="cart-productImage img-thumbnail"
                            />
                          </div>

                          <div className="col-md-9">
                            <Link
                              className="link"
                              to={`/product/${item.product?.name}/${item.product?._id}`}
                            >
                              <p style={{ display: "block" }}>
                                {item.product?.name}
                                <br />
                              </p>
                            </Link>
                            {item.product?.uploaderType === "wholesaler" ? (
                              <i className="fas fa-industry me-2 mb-1 vendor-icon"></i>
                            ) : (
                              <i className="fas fa-store me-2 mb-1 vendor-icon"></i>
                            )}
                            {shop[index].shopName}
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </td>
                  <td>
                    <p className="boldLatter">
                      ₱ {item.product?.sellingPrice || item.product?.price}
                    </p>
                  </td>
                  <td className="CartScreenAlignCenter">
                    <>
                      <Row className="smallLetter">
                        <p>Min Order Qty = {item.product?.minOrderQuantity}</p>
                      </Row>
                      <ButtonGroup
                        aria-label="Basic example"
                        className="cartScreenQtyBtn"
                      >
                        <Button
                          variant="secondary"
                          onClick={() => {
                            dispatch(
                              addCartItemToServer(
                                item.product._id,
                                item.qty - 1
                              )
                            );
                          }}
                        >
                          -
                        </Button>
                        <Form.Control
                          className="qtyInput"
                          type="number"
                          value={
                            item.qty > item.product?.minOrderQuantity
                              ? item.qty
                              : item.product?.minOrderQuantity
                          }
                          min={item.product.minOrderQuantity}
                          onChange={(e) => {
                            dispatch(
                              addCartItemToServer(
                                item.product?._id,
                                Number(e.target.value)
                              )
                            );
                          }}
                        ></Form.Control>
                        <Button
                          variant="secondary"
                          onClick={() => {
                            dispatch(
                              addCartItemToServer(
                                item.product?._id,
                                Number(item.qty + 1)
                              )
                            );
                          }}
                        >
                          +
                        </Button>
                      </ButtonGroup>
                      <Row className="smallLetterInStock">
                        <p>{item.product?.countInStock} In Stock</p>
                      </Row>
                    </>
                  </td>
                  <td className="boldLatter">
                    <Row>
                      <Col sm={8}>
                        ₱
                        {(item.product?.sellingPrice || item.product?.price) *
                          item.qty}
                      </Col>
                      <Col sm={2}>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => buyLaterHandler(item)}
                        >
                          <i className="far fa-heart"></i>
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            removeFromCartHandler(item.product?._id)
                          }
                        >
                          <i
                            className="far fa-trash-alt text-danger ml-2"
                            style={{ marginTop: "-7px" }}
                          ></i>
                        </div>
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      <Row
        className="totalContainer"
        style={{ marginLeft: "0%", paddingBottom: "2%" }}
      >
        <div className="col-md-6 .d-none .d-md-block .d-lg-block"></div>
        <div className="col-md-6">
          <div className="subtotaling">
            <p>
              <span className="divisions">Subtotal</span>
              <span className="subTotal">
                ₱
                {checkedCartItems &&
                  checkedCartItems
                    .reduce(
                      (acc, item) =>
                        acc +
                        item.qty *
                          (item.product?.sellingPrice || item.product?.price),
                      0
                    )
                    .toFixed(2)}
              </span>
            </p>
          </div>
        </div>
        <Row>
          <div className="col-md-6 offset-md-6 totalingButtons align-items-left">
            <Button
              type="button"
              className="bt-block white-btn"
              onClick={() => history.push("/")}
            >
              Continue Shopping
            </Button>

            <Button
              type="button"
              className="bt-block blue-btn"
              disabled={checkoutButtonDisabler}
              onClick={checkOutHandler}
            >
              Proceed to checkout
            </Button>
          </div>
        </Row>
      </Row>
    </Row>
  );
};

export default CartScreen;
