import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container } from "react-bootstrap";
import JsBarcode from "jsbarcode";
import { getDeliveryLabelDetails } from "../../actions/orderActions";

const DeliveryLabelScreen = ({ match }) => {
  const dispatch = useDispatch();
  const orderId = match.params.id;

  const deliveryLabelDetails = useSelector(
    (state) => state.deliveryLabelDetails
  );
  const { details } = deliveryLabelDetails;

  useEffect(() => {
    dispatch(getDeliveryLabelDetails(orderId));
  }, [orderId, dispatch]);
  JsBarcode("#barcode", details?._id, {
    width: 1.5,
    height: 60,
    displayValue: false,
  });
  return (
    <Container className="mx-20 mt-5">
      <Row>
        <Col
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
          className="m-0 px-5 border border-dark"
        >
          <svg id="barcode"></svg>
        </Col>
      </Row>
      <Row style={{ height: "150px" }}>
        <Col sm={1} className=" border border-dark">
          <div
            style={{
              transform: "rotate(90deg)",
              marginTop: "60px",
              fontSize: "25px",
            }}
          >
            Buyer
          </div>
        </Col>
        <Col sm={11} className=" border border-dark p-2">
          {details?.user?.name} {details?.user?._id} <br />
          {details?.address?.shipping_addressLine1},
          {details?.address?.shipping_muncipality},
          {details?.address?.shipping_district} <br />
          {details?.address?.shipping_provice},
          {details?.address?.shipping_country}
          {details?.address?.shipping_postalCode}
          <br />
          <Row>
            <Col sm={10}></Col>
            <Col sm={2}>{details?.address?.shipping_place}</Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: "150px" }}>
        <Col sm={1} className=" border border-dark">
          <div
            style={{
              transform: "rotate(90deg)",
              marginTop: "60px",
              fontSize: "25px",
            }}
          >
            Seller
          </div>
        </Col>
        <Col sm={11} className=" border border-dark p-2">
          {details?.shopId?.shopName} <br />
          {details?.shopId?.headOffice?.district},{" "}
          {details?.shopId?.headOffice?.province},
          {details?.shopId?.headOffice?.country}
          <Row>
            <Col sm={10}></Col>
            <Col sm={2}>
              SBD
              <br />
              {new Date(details?.createdAt).toLocaleDateString()}
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ height: "150px" }}>
        <Col
          sm={3}
          className=" border border-dark"
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={`http://qrcode.nepalcanmove.com/?data=${details?._id}`}
            alt="delivery qrcode"
          />
        </Col>
        <Col sm={9} className=" border border-dark ">
          <Col className=" border border-dark p-2 h-50">
            Product Quantity:{" "}
            {details?.orderItems?.reduce((acc, item) => item.qty + acc, 0)}
            <br />
            Weight: 0.2kg
          </Col>
          <Col className=" border border-dark p-2 h-50">
            COD Amount:
            <br />
            {details?.totalPrice}
          </Col>
        </Col>
      </Row>
      <Row className="border border-dark p-2" style={{ height: "80px" }}>
        Thank you for using our service
      </Row>
    </Container>
  );
};

export default DeliveryLabelScreen;
