import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Row, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "../../components/Paginate";
import "./ProductListScreen.css";
import { deleteProduct, listMyProducts } from "../../actions/productActions";
import {
  PRODUCT_CREATE_RESET,
  PRODUCT_UPDATE_RESET,
} from "../../constants/productConstants";
import SearchBox from "../../components/SearchBox";
import {
  getVendorDetails,
  getWholesalerDetails,
} from "../../actions/userActions";
import Meta from "../../components/Meta";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;
  const keyword = match.params.keyword || "";
  const unpublished = match.params.unpublished || "";

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productListMy = useSelector((state) => state.productListMy);
  const {
    products: productsMyList,
    page: mypage,
    pages: mypages,
    loading,
  } = productListMy;
  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: detailsUser } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: detailUser } = wholesalerDetails;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    success: successDelete,
    // loading: loadingDelete,
    // error: errorDelete,
  } = productDelete;

  const productPublish = useSelector((state) => state.productPublish);
  const { success: successPublish } = productPublish;
  const publishButtonDisabler =
    detailsUser?.status === "approved" || detailUser?.status === "approved";
  const userId = userInfo?._id;
  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
    dispatch({ type: PRODUCT_CREATE_RESET });
    dispatch({ type: PRODUCT_UPDATE_RESET });

    if (!userInfo?.type === "vendor" && !userInfo?.type === "wholesaler") {
      history.push("/login");
    }

    dispatch(listMyProducts(userId, keyword, pageNumber, unpublished));
  }, [
    history,
    dispatch,
    userInfo,
    successDelete,
    pageNumber,
    userId,
    successPublish,
    keyword,
    userInfo?._id,
    unpublished,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteProduct(id));
    }
  };

  const createproducthandler = (e) => {
    if (userInfo?.type === "vendor") {
      history.push("/vendor/product/create");
    }
    if (userInfo?.type === "wholesaler") {
      history.push("/wholesaler/product/create");
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <Meta title="Product List" />
      {!publishButtonDisabler && (
        <Message variant="info">
          Your account needs to be approved to publish the products.
        </Message>
      )}

      <div>
        <h1 className="productCreateHeading">Create Product</h1>

        <Row className="align-items-center mt-2">
          <Col md={4}></Col>
          <Col md={8}>
            <Row>
              <Col md={10}>
                <SearchBox
                  url={`${
                    userInfo?.type === "vendor"
                      ? unpublished
                        ? "vendor/productlist/:unpublished"
                        : "vendor/productlist"
                      : unpublished
                      ? "wholesaler/productlist/:unpublished"
                      : "wholesaler/productlist"
                  }`}
                  history={history}
                  placing="productlist"
                  placeHolder="In Product List"
                />
              </Col>

              <Col md={2} className="addProduct">
                <Button
                  onClick={createproducthandler}
                  className="addProductBtn"
                >
                  <i className="far fa-plus-square productCreateIcon"></i>{" "}
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <LinkContainer
          to={`${
            userInfo?.type === "vendor"
              ? "/vendor/productlist"
              : "/wholesaler/productlist"
          }`}
        >
          <Button
            role="Button"
            className={
              unpublished ? "adminProductListBtn" : "adminProductListBtnActive"
            }
          >
            Published
          </Button>
        </LinkContainer>
        <LinkContainer
          to={`${
            userInfo?.type === "vendor"
              ? "/vendor/productlist/unpublished"
              : "/wholesaler/productlist/unpublished"
          }`}
        >
          <Button
            role="Button"
            className={
              unpublished ? "adminProductListBtnActive" : "adminProductListBtn"
            }
          >
            Unpublished
          </Button>
        </LinkContainer>
        <Row className="mt-5 mb-5">
          <Table responsive>
            <thead>
              <tr className="productListRowHeading">
                <th>PRODUCT LIST</th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th>PRICE</th>
                <th>EDIT/DELETE</th>
              </tr>
            </thead>
            <tbody className="productListTableBody">
              {productsMyList?.map((product) => (
                <tr>
                  <td>
                    <Row>
                      <Col md={2}>
                        <div className="productImageContainer">
                          <img
                            src={product.image}
                            alt={product.name}
                            className="productImage"
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="productInfo">
                          <Link
                            className="productInfoLink"
                            to={`/product/${product.name}/${product._id}`}
                          >
                            {product.name}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  </td>
                  <td> {product.category}</td>
                  <td>{product.brand}</td>
                  <td>${product.price}</td>
                  <td>
                    <LinkContainer
                      to={`/vendor/product/${product._id}/${userId}/edit`}
                    >
                      <Button className="btn-sm productListeditButton">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      className="btn-sm productListdeleteButton"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      </div>
      {(userInfo?.type === "vendor" || userInfo?.type === "wholesaler") && (
        <Paginate
          keyword={keyword}
          url={`${
            userInfo?.type === "vendor"
              ? "/vendor/productlist"
              : "/wholesaler/productlist"
          }`}
          pages={mypages}
          page={mypage}
        />
      )}
    </>
  );
};

export default ProductListScreen;

// import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
// import { LinkContainer } from "react-router-bootstrap";
// import { Button } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Paginate from "../../components/Paginate";
// import "./ProductListScreen.css";
// import { deleteProduct, listMyProducts } from "../../actions/productActions";
// import {
//   PRODUCT_CREATE_RESET,
//   PRODUCT_UPDATE_RESET,
// } from "../../constants/productConstants";
// import SearchBox from "../../components/SearchBox";
// import {
//   getVendorDetails,
//   getWholesalerDetails,
// } from "../../actions/userActions";
// import Meta from "../../components/Meta";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";

// const ProductListScreen = ({ history, match }) => {
//   const pageNumber = match.params.pageNumber || 1;
//   const keyword = match.params.keyword || "";
//   const dispatch = useDispatch();

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const productListMy = useSelector((state) => state.productListMy);
//   const {
//     products: productsMyList,
//     page: mypage,
//     pages: mypages,
//     loading,
//   } = productListMy;
//   const vendorDetails = useSelector((state) => state.vendorDetails);
//   const { user: detailsUser } = vendorDetails;

//   const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
//   const { user: detailUser } = wholesalerDetails;

//   const productDelete = useSelector((state) => state.productDelete);
//   const {
//     success: successDelete,
//     // loading: loadingDelete,
//     // error: errorDelete,
//   } = productDelete;

//   const productPublish = useSelector((state) => state.productPublish);
//   const { success: successPublish } = productPublish;
//   dispatch({ type: PRODUCT_UPDATE_RESET });
//   const publishButtonDisabler =
//     detailsUser?.status === "approved" || detailUser?.status === "approved";
//   const userId = userInfo?._id;
//   useEffect(() => {
//     if (userInfo?.type === "vendor") {
//       dispatch(getVendorDetails(userInfo?._id));
//     } else if (userInfo?.type === "wholesaler") {
//       dispatch(getWholesalerDetails(userInfo?._id));
//     }
//     dispatch({ type: PRODUCT_CREATE_RESET });

//     if (!userInfo?.type === "vendor" && !userInfo?.type === "wholesaler") {
//       history.push("/login");
//     }

//     dispatch(listMyProducts(userId, keyword, pageNumber));
//   }, [
//     history,
//     dispatch,
//     userInfo,
//     successDelete,
//     pageNumber,
//     userId,
//     successPublish,
//     keyword,
//     userInfo?._id,
//   ]);

//   const deleteHandler = (id) => {
//     if (window.confirm("Are you sure")) {
//       dispatch(deleteProduct(id));
//     }
//   };

//   const createproducthandler = (e) => {
//     if (userInfo?.type === "vendor") {
//       history.push("/vendor/product/create");
//     }
//     if (userInfo?.type === "wholesaler") {
//       history.push("/wholesaler/product/create");
//     }
//   };

//   return loading ? (
//     <Loader />
//   ) : (
//     <>
//       <Meta title="Product List" />
//       {!publishButtonDisabler && (
//         <Message variant="info">
//           Your account needs to be approved to publish the products.
//         </Message>
//       )}
//       <SearchBox
//         url={`${
//           userInfo?.type === "vendor"
//             ? "vendor/productlist"
//             : "wholesaler/productlist"
//         }`}
//         history={history}
//         placing="productlist"
//         placeHolder="In Product List"
//       />
//       <div className="myProductsContainer">
//         {/* <div className="productlistHeading">My Products List</div> */}
//         <div className="myProductsCreateButton">
//           <Button className="createButton" onClick={createproducthandler}>
//             <i className="fas fa-plus"></i>Create Product
//           </Button>
//         </div>

//         <>
//           <div className="productTableHeadingRow">
//             <div className="productName">Product</div>
//             <div className="productPrice">Price</div>
//             <div className="productCategory">Category</div>
//             <div className="productBrand">Brand</div>
//             <div className="productChangingButtons">Edit/Delete</div>
//           </div>
//           {productsMyList?.map((product) => (
//             <div className="productTableRow" key={product._id}>
//               <div className="productName">
//                 <div className="productImageContainer">
//                   <img
//                     src={product.image}
//                     alt={product.name}
//                     className="productImage"
//                   />
//                 </div>
//                 <div className="productInfo">
//                   <Link
//                     className="productInfoLink"
//                     to={`/product/${product.name}/${product._id}`}
//                   >
//                     {product.name}
//                   </Link>
//                 </div>
//               </div>
//               <div className="productPrice">
//                 <span>${product.price}</span>
//               </div>
//               <div className="productCategory">
//                 <span>{product.category}</span>
//               </div>

//               <div className="productBrand">
//                 <span>{product.brand}</span>
//               </div>
//               <div className="productChangingButtons">
//                 {" "}
//                 {/* <Button
//                     disabled={
//                       !publishButtonDisabler || product.unPublishedByAdmin
//                     }
//                     className="btn-sm publishButton"
//                     onClick={() => publishProduct(product._id)}
//                   >
//                     {product.publish === true ? (
//                       <i className="fa fa-download" aria-hidden="true"></i>
//                     ) : (
//                       <i className="fa fa-upload" aria-hidden="true"></i>
//                     )}
//                     <span className="publishTooltip">
//                       {product.publishRequested === true
//                         ? "Requested"
//                         : "Request for publish"}
//                     </span>
//                   </Button>{" "} */}
//                 <LinkContainer
//                   to={`/vendor/product/${product._id}/${userId}/edit`}
//                 >
//                   <Button variant="light" className="btn-sm editButton">
//                     <i className="fas fa-edit"></i>
//                   </Button>
//                 </LinkContainer>
//                 <Button
//                   variant="danger"
//                   className="btn-sm deleteButton"
//                   onClick={() => deleteHandler(product._id)}
//                 >
//                   <i className="fas fa-trash"></i>
//                 </Button>
//               </div>
//             </div>
//           ))}
//         </>
//       </div>
//       {(userInfo?.type === "vendor" || userInfo?.type === "wholesaler") && (
//         <Paginate
//           url={`${
//             userInfo?.type === "vendor"
//               ? "/vendor/productlist"
//               : "/wholesaler/productlist"
//           }`}
//           pages={mypages}
//           page={mypage}
//         />
//       )}
//     </>
//   );
// };

// export default ProductListScreen;
