import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getPromotion, joinThePromotion } from "../../actions/promotionActions";
import Message from "../../components/Message";
import { PROMOTION_JOIN_RESET } from "../../constants/promotionConstants";

const Promotions = ({ history }) => {
  const dispatch = useDispatch();
  const promotionList = useSelector((state) => state.promotionList);
  const { promotions } = promotionList;

  const promotionJoin = useSelector((state) => state.promotionJoin);
  const { success, error } = promotionJoin;

  const joinPromotion = (id) => {
    dispatch(joinThePromotion(id));
    if (success) {
      history.push(`/promotion/${id}`);
      dispatch({ type: PROMOTION_JOIN_RESET });
    }
  };
  useEffect(() => {
    dispatch(getPromotion());
  }, [dispatch]);
  return (
    <div>
      {error && <Message varaint="danger">{error}</Message>}
      <Table
        striped
        bordered
        hover
        responsive
        size="sm"
        style={{ fontSize: "13px" }}
      >
        <thead>
          <tr>
            <th>Title</th>
            <th>Banner</th>
            <th>Link To</th>
            <th>To</th>
            <th>From</th>
          </tr>
        </thead>
        <tbody>
          {promotions?.map((promotion) => (
            <tr key={promotion._id}>
              <td>{promotion.title}</td>
              <td style={{ height: "100px", width: "100px" }}>
                <img
                  src={promotion.promotionBanner}
                  alt={promotion.title}
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </td>
              <td>{promotion.linkTo}</td>
              <td>{new Date(promotion.to).toLocaleString()}</td>
              <td>{new Date(promotion.from).toLocaleString()}</td>

              <td>
                <Button onClick={() => joinPromotion(promotion._id)}>
                  Join Promotion
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Promotions;
