import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./bootstrap.min.css";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import WholesalerRoutes from "./routes/WholesalerRoutes";
import { Container } from "react-bootstrap";
import { getCategoryHeadings } from "./actions/categoryActions";
import { getVendorDetails, getWholesalerDetails } from "./actions/userActions";
import LoginRoutes from "./routes/LoginRoutes";
import PromotionRoutes from "./routes/PromotionRoutes";
import HomeRoutes from "./routes/HomeRoutes";
import ProductRoutes from "./routes/ProductRoutes";
import { detailsStaff } from "./actions/staffActions";
import InvoiceScreen from "./screens/Prints/InvoiceScreen";
import DeliveryLabelScreen from "./screens/Prints/DeliveryLabelScreen";
import OrderRoutes from "./routes/OrderRoutes";
import AddressRoutes from "./routes/AddressRoutes";
import UserRoutes from "./routes/UserRoutes";
import StoreRoutes from "./routes/StoreRoutes";
import RegisterRoutes from "./routes/RegisterRoutes";
import CategoryRoutes from "./routes/CategoryRoutes";
import { getCartFromServer } from "./actions/cartActions";
import { listWishlist } from "./actions/productActions";
import ReceiptScreen from "./screens/Prints/ReceiptScreen";
import AdminNavbar from "./components/Dashboard/Navbar/AdminNavbar";
import BlogRoutes from "./routes/BlogRoutes";

import axios from "axios";

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  let eventList = [];
  let newEventsList = { list: [], prevLength: 0 };
  function datalayerOnLocalhost() {
    eventList = window.dataLayer.filter(
      (d) =>
        d.event === "Add to cart" ||
        d.event === "Add to wishlist" ||
        d.event === "View Product" ||
        d.event === "Search" ||
        d.event === "Order Created"
    );
    if (newEventsList.prevLength === 0) {
      newEventsList = { list: eventList, prevLength: eventList.length };
    } else if (newEventsList.prevLength !== 0) {
      newEventsList = {
        list: eventList.slice(newEventsList.prevLength),
        prevLength: eventList.length,
      };
    }

    if (newEventsList.list.length !== 0) {
      axios
        .post("/api/records", { newEventsList })
        .then((res) => console.log(res.data));
    }
  }

  setInterval(datalayerOnLocalhost, 10000);

  useEffect(() => {
    dispatch(getCategoryHeadings());
    dispatch(listWishlist());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getCartFromServer(userInfo?._id));
  }, [dispatch, userInfo?._id]);

  useEffect(() => {
    if (userInfo?.type === "vendor") {
      dispatch(getVendorDetails(userInfo?._id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(getWholesalerDetails(userInfo?._id));
    }
  }, [dispatch, userInfo?._id, userInfo?.type]);
  useEffect(() => {
    if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler")
      dispatch(detailsStaff(userInfo?._id));
  }, [dispatch, userInfo?._id, userInfo?.type]);
  return (
    <Router>
      <Switch>
        <Route path="/invoice/:id" component={InvoiceScreen} />
        <Route path="/receipt/:id" component={ReceiptScreen} />

        <Route path="/deliverylabel/:id" component={DeliveryLabelScreen} />
        {/* <DashboardRoutes /> */}
        <Route path="/vendor/:shopName" component={AdminNavbar} />
        <Route path="/wholesaler/:shopName" component={AdminNavbar} />
        <Fragment>
          <Route render={({ history }) => <Header history={history} />} />
          <main>
            <HomeRoutes />
            <CategoryRoutes />
            <Container>
              <BlogRoutes />
              <PromotionRoutes />
              <LoginRoutes />
              <RegisterRoutes />
              <StoreRoutes />
              <ProductRoutes />
              <UserRoutes />
              <OrderRoutes />
              <AddressRoutes />
              <WholesalerRoutes />
              {/* <Route component={NotFound} /> */}
            </Container>
          </main>
          <Footer />
        </Fragment>
      </Switch>
    </Router>
  );
}

export default App;
