import {
  FOLLOW_A_SHOP_RESET,
  LIST_THE_FOLLOWERS_FAIL,
  LIST_THE_FOLLOWERS_REQUEST,
  LIST_THE_FOLLOWERS_SUCCESS,
  LIST_THE_FOLLOWINGS_FAIL,
  LIST_THE_FOLLOWINGS_REQUEST,
  LIST_THE_FOLLOWINGS_SUCCESS,
  LIST_THE_FOLLOWING_PRODUCTS_FAIL,
  LIST_THE_FOLLOWING_PRODUCTS_REQUEST,
  LIST_THE_FOLLOWING_PRODUCTS_SUCCESS,
  UNFOLLOW_A_SHOP_REQUEST,
  UNFOLLOW_A_SHOP_RESET,
} from "../constants/followConstants";
import {
  FOLLOW_A_SHOP_FAIL,
  FOLLOW_A_SHOP_REQUEST,
  FOLLOW_A_SHOP_SUCCESS,
  UNFOLLOW_A_SHOP_FAIL,
  UNFOLLOW_A_SHOP_SUCCESS,
} from "../constants/userConstants";

export const followAShopReducer = (state = {}, action) => {
  switch (action.type) {
    case FOLLOW_A_SHOP_REQUEST:
      return { loading: true };
    case FOLLOW_A_SHOP_SUCCESS:
      return { loading: false, success: true };
    case FOLLOW_A_SHOP_FAIL:
      return { loading: false, error: action.payload };
    case FOLLOW_A_SHOP_RESET:
      return {};

    default:
      return state;
  }
};

export const unfollowAShopReducer = (state = {}, action) => {
  switch (action.type) {
    case UNFOLLOW_A_SHOP_REQUEST:
      return { loading: true };
    case UNFOLLOW_A_SHOP_SUCCESS:
      return { loading: false, success: true };
    case UNFOLLOW_A_SHOP_FAIL:
      return { loading: false, error: action.payload };
    case UNFOLLOW_A_SHOP_RESET:
      return {};

    default:
      return state;
  }
};

export const getFollowersReducer = (state = { followers: [] }, action) => {
  switch (action.type) {
    case LIST_THE_FOLLOWERS_REQUEST:
      return { loading: true, followers: [] };
    case LIST_THE_FOLLOWERS_SUCCESS:
      return {
        loading: false,
        followers: action.payload.followers,
      };
    case LIST_THE_FOLLOWERS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFollowingsReducer = (state = { followings: [] }, action) => {
  switch (action.type) {
    case LIST_THE_FOLLOWINGS_REQUEST:
      return { loading: true, followings: [] };
    case LIST_THE_FOLLOWINGS_SUCCESS:
      return {
        loading: false,
        followings: action.payload,
      };
    case LIST_THE_FOLLOWINGS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getFollowingProductsReducer = (
  state = { products: [] },
  action
) => {
  switch (action.type) {
    case LIST_THE_FOLLOWING_PRODUCTS_REQUEST:
      return { loading: true, products: [] };
    case LIST_THE_FOLLOWING_PRODUCTS_SUCCESS:
      return {
        loading: false,
        products: action.payload,
      };
    case LIST_THE_FOLLOWING_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
