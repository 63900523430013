import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Toast, Alert, Button } from "react-bootstrap";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import { listWholesaleProductsOnly } from "../../actions/productActions";
import Paginate from "../../components/Paginate.js";
import Meta from "../../components/Meta.js";
import SearchBox from "../../components/SearchBox.js";

const Homescreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const wholesaleProductOnly = useSelector(
    (state) => state.wholesaleProductOnly
  );
  const { loading, error, products, page, pages } = wholesaleProductOnly;

  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };

  useEffect(() => {
    dispatch(listWholesaleProductsOnly(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <Meta title="wholesale Products" />
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}
      <Button
        className="bg-white text-secondary p-0 mt-2"
        onClick={() => {
          history.push("/");
        }}
      >
        <i
          className="fas fa-angle-left p-2 mr-1"
          style={{ border: "1px solid whitesmoke" }}
        ></i>{" "}
        Go Back
      </Button>
      <h1>Wholesale Products</h1>
      <SearchBox
        history={history}
        placing="wholesalerProducts"
        url={`wholesaleProducts`}
        placeHolder="In Wholesale"
      />

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Row>
            {products?.map((product) => {
              return (
                <Col key={product._id} sm={6} md={3} xl={2}>
                  <Product
                    toastToggler={toastToggler}
                    history={history}
                    product={product}
                  />
                </Col>
              );
            })}
          </Row>
          <Paginate
            placing="wholesaleProducts"
            url={`/wholesaleProducts`}
            pages={pages}
            page={page}
            keyword={keyword}
          />
        </>
      )}
    </>
  );
};

export default Homescreen;
