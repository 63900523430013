import React, { useEffect } from "react";
import { Table, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { listOrders } from "../../actions/orderActions";
import Paginate from "../../components/Paginate";
import Meta from "../../components/Meta";
import { Link } from "react-router-dom";
import "./OrderScreen.css";

const OrderListScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, order, pages, page } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(pageNumber));
    } else {
      history.push("/login");
    }
  }, [history, dispatch, userInfo, pageNumber]);
  return (
    <>
      <Meta title="Order List" />

      <h1 className="staffListHeading mt-3">Order List</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row className="mt-3 mb-5">
          <Table responsive>
            <thead>
              <tr className="productListRowHeading">
                <th>ID</th>
                <th>CUSTOMER</th>
                <th>DATE</th>
                <th>TOTAL </th>
                <th className="orderListTdCenter">DETAILS</th>
              </tr>
            </thead>
            <tbody className="orderListTableData">
              {order?.map((ord) => (
                <tr key={ord._id} className="orderListTableData">
                  <td className="orderListOrderID">{ord.identifier}</td>
                  <td className="orderListCustomerName">{ord.userName}</td>
                  <td className="orderListDate">
                    {new Date(ord.createdAt).toLocaleString()}
                  </td>
                  <td className="orderListCustomerName">₱ {ord.totalPrice}</td>
                  <td className="orderListTdCenter">
                    <Link to={`/order/${ord._id}`}>
                      <i className="fas fa-align-left text-info"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      )}
      <Paginate url="/admin/orderlist" pages={pages} page={page} />
    </>
  );
};
// import React, { useEffect } from "react";
// import { LinkContainer } from "react-router-bootstrap";
// import { Table, Button } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";
// import { listOrders } from "../../actions/orderActions";
// import Paginate from "../../components/Paginate";
// import Meta from "../../components/Meta";

// const OrderListScreen = ({ match, history }) => {
// 	const dispatch = useDispatch();
// 	const pageNumber = match.params.pageNumber || 1;

// 	const orderList = useSelector((state) => state.orderList);
// 	const { loading, error, order, pages, page } = orderList;

// 	const userLogin = useSelector((state) => state.userLogin);
// 	const { userInfo } = userLogin;
// 	useEffect(() => {
// 		if (userInfo && userInfo.isAdmin) {
// 			dispatch(listOrders(pageNumber));
// 		} else {
// 			history.push("/login");
// 		}
// 	}, [history, dispatch, userInfo, pageNumber]);
// 	return (
// 		<>
// 			<Meta title="Order List" />

// 			<h1>Orders</h1>
// 			{loading ? (
// 				<Loader />
// 			) : error ? (
// 				<Message variant="danger">{error}</Message>
// 			) : (
// 				<Table striped bordered hover responsive className="table-sm">
// 					<thead>
// 						<tr>
// 							<th>ORDERS</th>
// 							<th>USER</th>
// 							<th>DATE</th>
// 							<th>TOTAL</th>
// 							<th>PAID</th>
// 							<th>DELIVERED</th>
// 						</tr>
// 					</thead>
// 					<tbody>
// 						{order?.map((ord) => (
// 							<tr key={ord._id}>
// 								<td>
// 									{ord?.orderItems.map((item) => (
// 										<p className="productList">
// 											<img
// 												className="orderItemsList"
// 												style={{
// 													height: "40px",
// 													width: "40px",
// 													objectFit: "fill",
// 												}}
// 												src={item.image}
// 												alt={item.name}
// 											/>
// 										</p>
// 									))}
// 								</td>
// 								<td>{ord.userName}</td>
// 								<td>{ord.createdAt?.substring(0, 10)}</td>
// 								<td>${ord.totalPrice}</td>
// 								<td>
// 									{ord.isPaid ? (
// 										ord.paidAt?.substring(0, 10)
// 									) : (
// 										<i className="fas fa-times" style={{ color: "red" }}></i>
// 									)}
// 								</td>
// 								<td>
// 									{ord.isDelivered ? (
// 										ord.deliveredAt?.substring(0, 10)
// 									) : (
// 										<i className="fas fa-times" style={{ color: "red" }}></i>
// 									)}
// 								</td>
// 								<td>
// 									<LinkContainer to={`/order/${ord._id}`}>
// 										<Button variant="light" className="btn-sm">
// 											Details
// 										</Button>
// 									</LinkContainer>
// 								</td>
// 							</tr>
// 						))}
// 					</tbody>
// 				</Table>
// 			)}
// 			<Paginate url="/admin/orderlist" pages={pages} page={page} />
// 		</>
// 	);
// };

export default OrderListScreen;
