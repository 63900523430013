import React, { useState } from "react";
import { Container, Form, Row, Col, Button, Card } from "react-bootstrap";
import axios from "axios";

const ContactUsScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const sendContactUsEmail = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      const headers = {
        "User-Agent": "Mozilla/5.0",
        "content-type": "application/x-www-form-urlencoded",
      };
      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "client_credentials");
      urlencoded.append("client_id", process.env.CLIENT_ID);
      urlencoded.append("client_secret", process.env.CLIENT_SECRET);
      urlencoded.append("resource", "https://graph.microsoft.com");
      const getToken = async () => {
        const { data } = await axios({
          url: `https://login.microsoftonline.com/${process.env.TENANT_ID}/oauth2/token`,
          method: "post",
          headers: headers,
          data: urlencoded,
        });
        return data;
      };
      const token = await getToken();
      const sendFrom = "order@pinoycanbuy.com";
      const mailUrl = `https://graph.microsoft.com/v1.0/users/${sendFrom}/sendMail`;
      const content = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <style>
        @media only screen and (max-width: 620px){
            h1{
                font-size:20px;
                padding:5px;
            }
        }
        </style>
        </head>
        <body>
        <div>
        <div style="max-width:620px; margin:0 auto; font-family:sans-serif; color:#272727:">
        <h1 style="background:#f6f6f6; padding:10px; text-align:center;
        color:#272727;">Name:${
          email + " " + firstName + " " + lastName + " " + phone
        }<br/></h1>
        <p>${message}</p>
        </div>
        </div>
        </body>
        </html>

    `;
      const mailData = {
        message: {
          subject: "Test Message from APP",
          body: {
            contentType: "HTML",
            content: content,
          },
          toRecipients: [
            {
              emailAddress: {
                address: "order@pinoycanbuy.com",
              },
            },
          ],
        },
        saveToSentItems: "true",
      };
      const data = await axios({
        method: "post",
        url: mailUrl,
        data: mailData,
        headers: {
          Authorization: `Bearer ${token.access_token}`,
          "Content-type": "application/json",
        },
      });
    }
  };

  return (
    <>
      <Container fluid style={{ padding: 0 }}>
        <img
          src="/images/contactus.jpg"
          alt="About Us"
          style={{ height: "50vh", width: "100%" }}
        />
      </Container>
      <Container>
        <Row className="my-5">
          <Col sm={12} md={6} style={{ paddingRight: "40px" }}>
            <div className="p-10 my-1" style={{ background: "whitesmoke" }}>
              <h3 className="">Contact Us</h3>
              <Form
                noValidate
                validated={validated}
                onSubmit={sendContactUsEmail}
                className="m-4"
              >
                <Row>
                  <Col className="mr-3">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        className="border rounded my-4"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First name"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        className="border rounded my-4"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Last name"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Control
                    type="email"
                    className="border rounded my-3"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    This field is required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    type="number"
                    className="border rounded my-3"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Phone Number"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    as="textArea"
                    className="border rounded my-3"
                    rows={3}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                  />
                </Form.Group>

                <Button
                  type="submit"
                  className="bg-info rounded p-2 "
                  style={{ float: "right" }}
                >
                  Send Message
                </Button>
              </Form>
            </div>
          </Col>
          <Col sm={12} md={6} className="my-1 ml-md-5 mr-md-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.883439947483!2d120.53656131484094!3d14.662555589763027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd0ca8cb19e31623!2zMTTCsDM5JzQ1LjIiTiAxMjDCsDMyJzE5LjUiRQ!5e0!3m2!1sen!2snp!4v1644832721906!5m2!1sen!2snp"
              title="location in map"
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </Col>
        </Row>
        <Row>
          <div className="d-flex align-items-center justify-content-center justify-content-center">
            <p
              style={{
                color: "red",
                fontSize: "25px",
                letterSpacing: "1px",
                fontWeight: "800",
                margin: "10px auto",
              }}
            >
              Our Branches
            </p>
          </div>
        </Row>
        <Row>
          <Col sm={8} md={4} className="px-4 py-3">
            Head Office
            <Card className="border overflow-hidden">
              <Row className="h-60">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3859.883439947483!2d120.53656131484094!3d14.662555589763027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd0ca8cb19e31623!2zMTTCsDM5JzQ1LjIiTiAxMjDCsDMyJzE5LjUiRQ!5e0!3m2!1sen!2snp!4v1645078642530!5m2!1sen!2snp"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                ></iframe>
              </Row>
              <Row className="px-4 py-3">
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-map-marker-alt text-danger"></i>
                  </Col>
                  <Col sm={10}>
                    #676 Austria Subdivision, Maluya Street, Central, Balanga
                    City, Bataan
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-phone text-danger"></i>
                  </Col>
                  <Col sm={10}>+63 9369904560, +63 9618311990</Col>
                </Row>
                <br />
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-envelope text-danger"></i>
                  </Col>
                  <Col sm={10}>order@pinoycanbuy.com</Col>
                </Row>
                <br />
                <Row>
                  <Col sm={2}>
                    <i className="bi bi-messenger text-danger"></i>
                  </Col>
                  <Col sm={10}> m.me/pinoycanbuy</Col>
                </Row>
              </Row>
            </Card>
          </Col>
          <Col sm={8} md={4} className="px-4 py-3">
            Baguio Branch
            <Card className="border overflow-hidden">
              <Row className="h-60">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d956.7758217738433!2d120.5831923!3d16.4195799!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3391a1eb632b161b%3A0x7ccbf7c295bd5b8d!2sBadihoy%20extension!5e0!3m2!1sen!2snp!4v1645078680312!5m2!1sen!2snp"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                ></iframe>
              </Row>
              <Row className="px-4 py-3">
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-map-marker-alt text-danger"></i>
                  </Col>
                  <Col sm={10}>
                    #73B Badihoy Ext., Guisad Sorong, Baguio City, Philippines
                  </Col>
                </Row>

                <Row>
                  <Col sm={2}>
                    <i className="fas fa-phone text-danger"></i>
                  </Col>
                  <Col sm={10}>+63 9369904560,+63 9618311990</Col>
                </Row>

                <Row>
                  <Col sm={2}>
                    <i className="fas fa-envelope text-danger"></i>
                  </Col>
                  <Col sm={10}>order@pinoycanbuy.com</Col>
                </Row>

                <Row>
                  <Col sm={2}>
                    <i className="bi bi-messenger text-danger"></i>
                  </Col>
                  <Col sm={10}>m.me/pinoycanbuy</Col>
                </Row>
              </Row>
            </Card>
          </Col>
          <Col sm={8} md={4} className="px-4 py-3">
            Cavite Branch
            <Card className="border overflow-hidden">
              <Row className="h-60">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3866.454583915376!2d120.9982557148365!3d14.284999990004138!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5867c205c70b9c5!2zMTTCsDE3JzA2LjAiTiAxMjHCsDAwJzAxLjYiRQ!5e0!3m2!1sen!2snp!4v1645078696611!5m2!1sen!2snp"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                ></iframe>
              </Row>
              <Row className="px-4 py-3">
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-map-marker-alt text-danger"></i>
                  </Col>
                  <Col md={10}>
                    Block 2, Lot 35, Brgy. San Gabriel, GMA, Cavite, Philippines
                  </Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-phone text-danger"></i>
                  </Col>
                  <Col md={10}>+63 9369904560,+63 9618311990</Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <i className="fas fa-envelope text-danger"></i>
                  </Col>
                  <Col md={10}>order@pinoycanbuy.com</Col>
                </Row>
                <Row>
                  <Col sm={2}>
                    <i className="bi bi-messenger text-danger"></i>
                  </Col>
                  <Col md={10}>m.me/pinoycanbuy</Col>
                </Row>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUsScreen;
