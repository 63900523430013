import React from "react";
import DashboardSidebar from "../../components/Dashboard/DashboardSidebar";

const Chat = () => {
  return (
    <div style={{ display: "flex" }}>
      <DashboardSidebar />

      <div className="dashBoardChat">Hello from Chat</div>
    </div>
  );
};

export default Chat;
