import React, { useState, useEffect } from "react";
import { Alert, Button } from "react-bootstrap";

const DismissibleMessage = ({ variant, children }) => {
  const [show, setShow] = useState(true);
  useEffect(() => {
    setShow(JSON.parse(localStorage.getItem("dismissibleMessageInProfile")));
  }, []);

  if (show) {
    return (
      <Alert variant={variant}>
        <Alert.Heading>Welcome</Alert.Heading>
        <p>{children}</p>
        <div className="d-flex justify-content-end">
          <Button
            onClick={() => {
              setShow(false);
              localStorage.setItem("dismissibleMessageInProfile", false);
            }}
            variant="outline-success"
          >
            Close
          </Button>
        </div>
      </Alert>
    );
  } else return "";
};
DismissibleMessage.dfaultProps = {
  variant: "info",
};

export default DismissibleMessage;
