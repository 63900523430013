import React, { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { Link } from "react-router-dom";
import Axios from "axios";
import parse from "html-react-parser";

import { Form, Button, Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listProductDetails,
  publishTheProduct,
  updateProduct,
  updateProductFAQ,
} from "../../actions/productActions";
import Meta from "../../components/Meta";
import {
  ADD_PRODUCT_FAQ_RESET,
  PRODUCT_PUBLISH_RESET,
} from "../../constants/productConstants";

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [unit, setUnit] = useState("Kilogram");
  const [measurements, setMeasurements] = useState("");
  const [discount, setDiscount] = useState("");
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [minOrderQuantity, setMinOrderQuantity] = useState(1);
  const [colour, setColour] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [guarantee, setGuarantee] = useState("");
  const [warranty, setWarranty] = useState("");
  const [returnScheme, setReturnScheme] = useState("");
  const [description, setDescription] = useState("");
  const [warehouseLocation, setWarehouseLocation] = useState("");
  const [branchLocation, setBranchLocation] = useState("");
  const [vendorCategory, setVendorCategory] = useState("");
  const editorRef = useRef(null);
  const [discountType, setDiscountType] = useState("Amount");
  const [externalCheckoutLink, setExternalCheckoutLink] = useState("");
  // const [netweightType, setNetWeightType] = useState("Kilogram");
  const [deleteStatus, setDeleteStatus] = useState("");

  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  // const netWeightvalue = product?.netWeight?.split(" ");
  // const grossWeightvalue = product?.grossWeight?.split(" ");

  const [netWeight, setNetWeight] = useState("");

  const [grossWeight, setGrossWeight] = useState("");

  const [size, setSize] = useState([]);

  const [removed, setRemoved] = useState("");
  const [customSize, setCustomSize] = useState("");
  const [deletedImage, setDeletedImage] = useState([]);

  const discountTypes = [
    { label: "amt", value: "Amount" },
    { label: "%", value: "Percent" },
  ];

  const unitTypes = [
    { label: "K.G", value: "Kilogram" },
    { label: "GRAM", value: "Gram" },
    { label: "PIECE", value: "Piece" },
    { label: "LITER", value: "Liter" },
    { label: "Pair", value: "Pair" },
  ];

  // const netweight = `${netWeight} ${netweightType.value}`;

  // const grossweight = `${grossWeight} ${netweightType.value}`;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingupdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;
  const productUpdateFAQ = useSelector((state) => state.productUpdateFAQ);
  const { success } = productUpdateFAQ;

  const productPublish = useSelector((state) => state.productPublish);
  const { success: successPublishRequested } = productPublish;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  const publishButtonDisabler =
    shop?.status === "approved" || shopInfo?.status === "approved";

  useEffect(() => {
    if (!successUpdate) {
      if (!product?.name || product?._id !== productId) {
        dispatch(listProductDetails(productId));
      } else {
        setName(product?.name);
        setPrice(product?.price);
        setImage(product?.image);
        setImages(product?.images);
        setBrand(product?.brand);
        setCategory(product?.category);
        setCountInStock(product?.countInStock);
        setMinOrderQuantity(product?.minOrderQuantity);
        setDescription(product?.description);
        setDiscount(product?.discount);
        setVendorCategory(product?.vendorSpecificCategory);
        setMeasurements(product?.measurements);
        setUnit(product?.unit);
        setColour(product?.colour);
        setGuarantee(product?.guarantee);
        setWarranty(product?.warranty);
        setReturnScheme(product?.returnScheme);
        setWarehouseLocation(product?.location?.warehouseLocation);
        setBranchLocation(product?.location?.branchLocation);
        setLength(product?.dimension?.length);
        setHeight(product?.dimension?.height);
        setBreadth(product?.dimension?.breadth);
        setNetWeight(product?.netWeight);
        setGrossWeight(product?.grossWeight);
        setSize(product?.size);
        setCustomSize(product?.customSize);
        setExternalCheckoutLink(product?.externalCheckoutLink);
      }
    } else {
      if (userInfo?.isAdmin) {
        history.push("/admin/productList");
      }
      if (userInfo?.type === "vendor") {
        history.push("/vendor/productList");
      }
      if (userInfo?.type === "wholesaler") {
        history.push("/wholesaler/productList");
      }
    }
  }, [
    dispatch,
    userInfo?.isAdmin,
    userInfo?.type,
    history,
    product,
    productId,
    successUpdate,
  ]);
  useEffect(() => {
    dispatch(listProductDetails(productId));
    dispatch({ type: ADD_PRODUCT_FAQ_RESET });
    dispatch({ type: PRODUCT_PUBLISH_RESET });
  }, [dispatch, productId, success, successPublishRequested]);

  useEffect(() => {
    dispatch(listProductDetails(productId));
    setDeleteStatus("");
  }, [dispatch, deleteStatus, productId]);

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  let productCategoryList = [];

  categories?.forEach((element) => {
    productCategoryList.push({
      label: element.name,
      value: element.name,
    });
  });
  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      setImage(res.data.url);
    });
  };
  const uploadFilesHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const formData = new FormData();
      formData.append("file", e.target.files[i]);
      formData.append("upload_preset", "pinoy-can-group");

      Axios.post(
        "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
        formData
      ).then((res) => {
        setImages((prevState) => [...prevState, res.data.url]);
      });
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    var discountAmount;
    if (discountType === "Percent") {
      (((discountAmount = (discount * price) / 100) * 100) / 100).toFixed(2);
    } else if (discountType === "Amount") {
      discountAmount = discount;
    }
    if (deletedImage.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios.post(
        `/api/products/image/delete/`,
        { files: deletedImage },
        config
      );
    }

    dispatch(
      updateProduct({
        _id: productId,
        name,
        price,
        discount: discountAmount,
        unit,
        dimension: { length, breadth, height },
        netWeight,
        grossWeight,
        size,
        customSize,
        colour,
        image,
        images,
        brand,
        category: category,
        description: editorRef.current.getContent(),
        countInStock,
        minOrderQuantity,
        updatedById: product?.updatedById,
        guarantee,
        warranty,
        returnScheme,
        warehouseLocation,
        branchLocation,
        vendorCategory,
        externalCheckoutLink,
      })
    );
  };
  const submitpublishRequest = (e) => {
    e.preventDefault();
    dispatch(publishTheProduct(productId));
  };

  const submitFAQS = (e) => {
    e.preventDefault();
    if (question && answer) {
      dispatch(updateProductFAQ(question, answer, productId));
    }
    setAnswer("");
    setQuestion("");
  };
  const deleteFAQ = async (e, id) => {
    e.preventDefault();
    await Axios.delete(`/api/products/FAQ/${productId}/${id}`).then((res) =>
      setDeleteStatus(res.data)
    );
  };

  async function loadCategory(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const cat = await Axios.get(`/api/category/search/${value}`, config);
      return cat.data;
    }
  }

  const deleteHandlerImage = (im) => {
    if (window.confirm("Are you sure")) {
      setImage("");
      setDeletedImage([...deletedImage, im]);
    }
  };
  const deleteHandlerImages = (e, i, link) => {
    e.preventDefault();
    if (window.confirm("Are you sure")) {
      setDeletedImage([...deletedImage, link]);
      setRemoved(images?.splice(i, 1));
      setImages(images);
    }
  };
  return (
    <>
      <Meta title="Edit Product" />
      {!publishButtonDisabler && (
        <Message variant="info">
          Your account needs to be approved to publish the products.
        </Message>
      )}
      {userInfo?.isAdmin && (
        <Link to="/admin/productlist" className="productEditGoBackBtn my-3">
          <i
            className="fas fa-angle-left p-2 mr-1"
            style={{ border: "1px solid whitesmoke" }}
          ></i>
          Go Back
        </Link>
      )}
      {userInfo?.type === "vendor" && (
        <Link to="/vendor/productlist" className="productEditGoBackBtn  my-3">
          <i
            className="fas fa-angle-left p-2 mr-1"
            style={{ border: "1px solid whitesmoke" }}
          ></i>{" "}
          Go Back
        </Link>
      )}

      {userInfo?.type === "wholesaler" && (
        <Link
          to="/wholesaler/productlist"
          className="productEditGoBackBtn  my-3"
        >
          <i className="fa-solid fa-angle-left"></i>Go Back
        </Link>
      )}

      <div>
        <h1 className="productCreateHeading">Edit Product</h1>
        {loadingupdate && <Loader />}
        {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            {product?.publishRequested && !product?.publish && (
              <Message variant="info">
                Publish request for this product has been sent
              </Message>
            )}

            <Row className="mt-2">
              <Nav className="nav nav-justified product-nav mt-5">
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-basic-information"
                >
                  Basic Information
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-additional-information"
                >
                  Additional Information
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-measurements"
                >
                  Measurements
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-category-specific"
                >
                  Category Specific
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-guarantee-warranty"
                >
                  Guarantee/ Warranty
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-local-management"
                >
                  Location Management
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-faqs"
                >
                  FAQs
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-product-details"
                >
                  Product Details
                </Nav.Link>
                <Nav.Link
                  className="nav-link my-nav-product-create"
                  href="#nav-submit"
                >
                  Submit
                </Nav.Link>
              </Nav>
            </Row>
            <Form onSubmit={submitHandler}>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-basic-information"
                    className="product-tabs my-4 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Basic Information
                    </h4>
                    <Row className="inputBox">
                      <Col sm={2}>Name</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter name"
                          value={name}
                          onChange={(e) =>
                            setName(e.target.value.replaceAll("/", "-"))
                          }
                        ></Form.Control>
                      </Col>
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Category</Col>
                      <Col sm={10}>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          getOptionLabel={(e) => e}
                          getOptionValue={(e) => e[1]}
                          className="basic-single"
                          loadOptions={loadCategory}
                          onChange={(e) => {
                            setCategory(e[1]);
                          }}
                          placeholder="Type 3 characters for dropdown to appear"
                        />
                      </Col>
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Vendor Category</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter Vendor Category"
                          value={vendorCategory}
                          onChange={(e) => setVendorCategory(e.target.value)}
                        ></Form.Control>
                        <span>
                          ( Here you can specify your own category which can be
                          used as filter in your store )
                        </span>
                      </Col>
                    </Row>

                    <Row className="inputBox mt-3">
                      <Col sm={2}>Price</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="number"
                          placeholder="Enter Price"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Discount</Col>

                      <Col sm={6}>
                        <Form.Control
                          className="inputField"
                          type="number"
                          placeholder="Enter Discount "
                          value={discount}
                          onChange={(e) => setDiscount(e.target.value)}
                        ></Form.Control>
                      </Col>
                      <Col sm={4} className="discountType">
                        <Select
                          onChange={(e) => {
                            setDiscountType(e.value);
                          }}
                          options={discountTypes}
                        />
                      </Col>
                    </Row>
                    <Row className="imageBox mb-3">
                      <Col sm={2}>Feature Image</Col>
                      <Col sm={10}>
                        <input
                          className="imageField"
                          id="image-file"
                          label="Choose File"
                          type="file"
                          custom
                          onChange={uploadFileHandler}
                        ></input>
                        <div>
                          {image && (
                            <div
                              style={{
                                width: "100px",
                                height: "100px",
                                position: "relative",
                              }}
                            >
                              <img
                                className="imagesBoxes mb-3"
                                alt=""
                                src={image}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  objectFit: "cover",
                                  border: "1px solid black",
                                }}
                              />
                              <i
                                className="fa fa-times"
                                aria-hidden="true"
                                onClick={(e) => deleteHandlerImage(image)}
                                style={{
                                  fontSize: "16px",
                                  color: "red",
                                  cursor: "pointer",
                                  display: "flex",
                                  flexDirection: "column",
                                  position: "absolute",
                                  right: 4,
                                  top: 2,
                                }}
                              ></i>
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Additional Image (Max 6)</Col>
                      <Col sm={10}>
                        <input
                          id="image-files"
                          label="Choose Files"
                          type="file"
                          custom
                          multiple
                          onChange={uploadFilesHandler}
                        ></input>
                        <Row>
                          {images.map((im, index) => (
                            <Col md={2} className="mt-3">
                              <div
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  position: "relative",
                                }}
                              >
                                <img
                                  alt=""
                                  src={im}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "contain",
                                  }}
                                />
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    deleteHandlerImages(e, index, im)
                                  }
                                  style={{
                                    fontSize: "16px",
                                    color: "red",
                                    cursor: "pointer",
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "absolute",
                                    right: 4,
                                    top: 2,
                                  }}
                                ></i>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                    <Row className="ml-2">
                      <Col sm={2}>Description</Col>
                      <Col sm={10} className="DescriptionField">
                        <Editor
                          onInit={(evt, editor) => (editorRef.current = editor)}
                          apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
                          initialValue={description}
                          init={{
                            height: 500,
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | formatselect | " +
                              "bold italic backcolor | alignleft aligncenter " +
                              "alignright alignjustify | bullist numlist outdent indent | " +
                              "removeformat | help",
                            content_style:
                              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-additional-information"
                    className="product-tabs my-5 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Additional Information
                    </h4>
                    <Row className="inputBox">
                      <Col sm={2}>Unit</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          as="select"
                          value={unit}
                          onChange={(e) => setUnit(e.target.value)}
                        >
                          <option value="">Choose Unit</option>
                          {unitTypes?.map((w) => (
                            <option value={w.value}>{w.label}</option>
                          ))}
                        </Form.Control>
                      </Col>
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Color/Variant</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter colour (e.g. red,blue,green..)"
                          value={colour}
                          onChange={(e) => setColour(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Brands</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter Brand"
                          value={brand}
                          onChange={(e) => setBrand(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Minimun Order Qty</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="number"
                          placeholder="Enter Minimum Order Quantity"
                          value={minOrderQuantity}
                          onChange={(e) => setMinOrderQuantity(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Count In Stock</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="number"
                          placeholder="Enter Count In Stock"
                          value={countInStock}
                          onChange={(e) => setCountInStock(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>External Checkout Link</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter external link"
                          value={externalCheckoutLink}
                          onChange={(e) =>
                            setExternalCheckoutLink(e.target.value)
                          }
                        ></Form.Control>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>

              <Row className="my-3">
                <div id="nav-tabContent">
                  <div id="nav-measurements" className="product-tabs my-5 ps-4">
                    <h4 className="productCreateSubHeading mb-3">
                      Measurements
                    </h4>

                    <Row className="inputBox">
                      <Col sm={2}>Dimension</Col>
                      <Col sm={10} style={{ display: "flex" }}>
                        <Form.Control
                          className="createProductNumberField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                            width: "200px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                          type="number"
                          placeholder="length cm"
                          value={length}
                          onChange={(e) => setLength(e.target.value)}
                        ></Form.Control>
                        <Form.Control
                          className="createProductNumberField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                            width: "200px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                          type="number"
                          placeholder="width cm"
                          value={breadth}
                          onChange={(e) => setBreadth(e.target.value)}
                        ></Form.Control>
                        <Form.Control
                          className="createProductNumberField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                            width: "200px",
                            borderRadius: "10px",
                            marginRight: "10px",
                          }}
                          type="number"
                          placeholder="height cm"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Net Weight</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="createProductNumberField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                          }}
                          type="text"
                          placeholder="Product weight without packaging"
                          value={netWeight}
                          onChange={(e) => setNetWeight(e.target.value)}
                        ></Form.Control>
                      </Col>
                      {/* <Col sm={4} className="discountType">
												<Select
													value={netweightType}
													onChange={(e) => {
														setNetWeightType(e);
													}}
													options={netweightTypes}
												/>
											</Col> */}
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Gross Weight</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="createProductNumberField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                          }}
                          type="text"
                          placeholder="Product weight with packaging"
                          value={grossWeight}
                          onChange={(e) => setGrossWeight(e.target.value)}
                        ></Form.Control>
                      </Col>
                      {/* <Col sm={4} className="discountType">
												<Select
													value={netweightType}
													onChange={(e) => {
														setNetWeightType(e);
													}}
													options={netweightTypes}
												/>
											</Col> */}
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Size</Col>
                      <Col sm={10}>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                          style={{ display: "flex" }}
                        >
                          <Form.Check
                            value="free"
                            checked={size.indexOf("free") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("free");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          Free
                          <Form.Check
                            checked={size.indexOf("sm") !== -1}
                            value="sm"
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("sm");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          SM
                          <Form.Check
                            value="md"
                            checked={size.indexOf("md") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("md");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          MD
                          <Form.Check
                            value="lg"
                            checked={size.indexOf("lg") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("lg");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          LG
                          <Form.Check
                            value="xl"
                            checked={size.indexOf("xl") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("xl");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          XL
                          <Form.Check
                            value="xxl"
                            checked={size.indexOf("xxl") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("xxl");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          XXL
                          <Form.Check
                            value="xxxl"
                            checked={size.indexOf("xxxl") !== -1}
                            type="checkbox"
                            style={{ marginLeft: "30px" }}
                            onClick={(e) => {
                              let i = size.indexOf("xxxl");
                              if (i === -1) {
                                setSize([e.target.value, ...size]);
                              } else {
                                setRemoved(size.splice(i, 1));
                                setSize(size);
                              }
                            }}
                          />
                          XXXL
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="inputBox">
                      <Col sm={2}>Custom Size</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          style={{
                            backgroundColor: "#fff",
                            margin: "0.5rem 0rem",
                            border: "2px solid rgb(236, 235, 235)",
                          }}
                          type="text"
                          placeholder="Custom size"
                          value={customSize}
                          onChange={(e) => setCustomSize(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-category-specific"
                    className="product-tabs my-5 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Category Specific
                    </h4>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-guarantee-warranty"
                    className="product-tabs my-5 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Guarantee/Warranty
                    </h4>

                    <Row className="inputBox">
                      <Col sm={2}>Return Scheme</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter Return Scheme"
                          value={returnScheme}
                          onChange={(e) => setReturnScheme(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Guarantee</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter Gurantee Scheme"
                          value={guarantee}
                          onChange={(e) => setGuarantee(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Warranty</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          type="text"
                          placeholder="Enter Warranty Scheme"
                          value={warranty}
                          onChange={(e) => setWarranty(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-local-management"
                    className="product-tabs my-5 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Location Management
                    </h4>
                    <Row className="inputBox">
                      <Col sm={2}>Warehouse</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          as="select"
                          value={warehouseLocation}
                          onChange={(e) => setWarehouseLocation(e.target.value)}
                        >
                          <option value="">
                            Choose Warehouse that contains the product
                          </option>
                          {userInfo?.type === "vendor" &&
                            shop?.warehouseAddress?.map((w) => (
                              <option value={w.name}>{w.name}</option>
                            ))}
                          {userInfo?.type === "wholesaler" &&
                            shopInfo?.warehouseAddress?.map((w) => (
                              <option value={w.name}>{w.name}</option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Row>
                    <Row className="inputBox">
                      <Col sm={2}>Branch</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField"
                          as="select"
                          value={branchLocation}
                          onChange={(e) => setBranchLocation(e.target.value)}
                        >
                          <option value="">
                            Choose Branch that handles the product
                          </option>
                          {userInfo?.type === "vendor" &&
                            shop?.branchAddress?.map((w) => (
                              <option value={w.name}>{w.name}</option>
                            ))}
                          {userInfo?.type === "wholesaler" &&
                            shopInfo?.branchAddress?.map((w) => (
                              <option value={w.name}>{w.name}</option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3 align-items-center">
                <div id="nav-tabContent">
                  <div id="nav-faqs" className="product-tabs my-5 ps-4 pe-5">
                    <h4 className="productCreateSubHeading mb-3">FAQs</h4>

                    <Row className="faqBox mb-5">
                      <Row className="align-items-center">
                        <Col sm={2}>Question</Col>
                        <Col sm={10}>
                          <Form.Control
                            className="inputField "
                            type="text"
                            placeholder="Enter Question"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                          ></Form.Control>
                        </Col>
                      </Row>

                      <Row className="align-items-center">
                        <Col sm={2}>Answer</Col>
                        <Col sm={10}>
                          <Form.Control
                            className="inputField "
                            type="text"
                            placeholder="Enter Answer"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          ></Form.Control>
                        </Col>
                      </Row>

                      <Button
                        type="submit"
                        variant="primary"
                        className="productEditaddFaqBtn"
                        onClick={(e) => submitFAQS(e)}
                        style={{ margin: "10px", width: "150px" }}
                      >
                        <i className="fas fa-plus mr-2"></i>
                        Add
                      </Button>

                      {product?.FAQs?.map((faq) => {
                        return (
                          <Row>
                            <Row>
                              <Col md={2}>
                                <strong>Question:</strong>
                              </Col>
                              <Col md={9}>{faq.question} </Col>
                              <Col md={1}>
                                <i
                                  className="fa fa-times"
                                  aria-hidden="true"
                                  onClick={(e) => deleteFAQ(e, faq._id)}
                                  style={{
                                    fontSize: "20px",
                                    color: "red",
                                    marginLeft: "auto",
                                    cursor: "pointer",
                                  }}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md={2}>
                                <strong>Answer:</strong>
                              </Col>
                              <Col md={10}>{faq.answer} </Col>
                            </Row>

                            <br />
                          </Row>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent">
                  <div
                    id="nav-product-details"
                    className="product-tabs my-5 ps-4"
                  >
                    <h4 className="productCreateSubHeading mb-3">
                      Product Details
                    </h4>
                    <Row>
                      <Col md={2}>Name</Col>
                      <Col md={10}>{name}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Price</Col>
                      <Col md={10}>{price}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Discount</Col>
                      <Col md={10}>{discount}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Unit</Col>
                      <Col md={10}>{unit}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Measurements</Col>
                      <Col md={10}>{measurements}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Color/Variant</Col>
                      <Col md={10}>{colour}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Brand</Col>
                      <Col md={10}>{brand}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Category</Col>
                      <Col md={10}>{category}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Description</Col>
                      <Col md={10}>
                        {editorRef?.current?.getContent() &&
                          parse(editorRef?.current?.getContent())}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>Count In Stock</Col>
                      <Col md={10}>{countInStock}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Min Order Qty</Col>
                      <Col md={10}>{minOrderQuantity}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Guarantee</Col>
                      <Col md={10}>{guarantee}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Warranty</Col>
                      <Col md={10}>{warranty}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Return Scheme</Col>
                      <Col md={10}>{returnScheme}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>WareHouse Location</Col>
                      <Col md={10}>{warehouseLocation}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Branch Location</Col>
                      <Col md={10}>{branchLocation}</Col>
                    </Row>
                    <Row>
                      <Col md={2}>Vendor Category</Col>
                      <Col md={10}>{vendorCategory}</Col>
                    </Row>
                  </div>
                </div>
              </Row>
              <Row className="my-3">
                <div id="nav-tabContent white-background">
                  <div id="nav-submit" className="product-tabs my-5 ps-4"></div>
                  <Row>
                    <Col>
                      <Button
                        type="submit"
                        className="ProductEditScreenSubmitBtn"
                      >
                        Update
                      </Button>

                      {product?.publishRequested || product?.publish ? (
                        <Button
                          className="ProductEditScreenSubmitBtn"
                          onClick={(e) => {
                            submitpublishRequest(e);
                          }}
                        >
                          Request for Unpublish
                        </Button>
                      ) : (
                        <Button
                          className="ProductEditScreenSubmitBtn"
                          disabled={!publishButtonDisabler}
                          onClick={(e) => {
                            submitpublishRequest(e);
                          }}
                        >
                          Request for Publish
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </Row>
            </Form>
          </>
        )}
      </div>
    </>
    // <>
    //   <Meta title="Edit Product" />
    //   {!publishButtonDisabler && (
    //     <Message variant="info">
    //       Your account needs to be approved to publish the products.
    //     </Message>
    //   )}
    //   {userInfo?.isAdmin && (
    //     <Link to="/admin/productlist" className="btn btn-light my-3">
    //       Go Back
    //     </Link>
    //   )}
    //   {userInfo?.type === "vendor" && (
    //     <Link to="/vendor/productlist" className="btn btn-light my-3">
    //       Go Back
    //     </Link>
    //   )}

    //   {userInfo?.type === "wholesaler" && (
    //     <Link to="/wholesaler/productlist" className="btn btn-light my-3">
    //       Go Back
    //     </Link>
    //   )}

    //   <div>
    //     <h1>Edit Product</h1>
    //     {loadingupdate && <Loader />}
    //     {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}

    //     {loading ? (
    //       <Loader />
    //     ) : error ? (
    //       <Message variant="danger">{error}</Message>
    //     ) : (
    //       <>
    //         {product?.publishRequested && !product?.publish && (
    //           <Message variant="info">
    //             Publish request for this product has been sent
    //           </Message>
    //         )}
    //         <Tab.Container id="left-tabs-example" defaultActiveKey="first">
    //           <Row>
    //             <Col sm={3}>
    //               <Nav variant="pills" className="flex-column">
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="first">Basic Information</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="second">
    //                     Additional Information
    //                   </Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="third">FAQS</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="fourth">Category Specific</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="fifth">Guarantee/Warranty</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="sixth">Location Management</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="seventh">Product Details</Nav.Link>
    //                 </Nav.Item>
    //                 <Nav.Item>
    //                   <Nav.Link eventKey="eighth">Submit</Nav.Link>
    //                 </Nav.Item>
    //               </Nav>
    //             </Col>
    //             <Col sm={9}>
    //               <Tab.Content>
    //                 <Tab.Pane eventKey="first">
    //                   <Form onSubmit={submitHandler}>
    //                     <Form.Label>Name</Form.Label>
    //                     <Form.Group controlId="name">
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter name"
    //                         value={name}
    //                         onChange={(e) =>
    //                           setName(e.target.value.replaceAll("/", "-"))
    //                         }
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     {/* <Form.Group controlId="maincategory">
    //                       <Form.Label>
    //                         <span style={{ color: "red" }}>*</span>Main Category
    //                       </Form.Label>

    //                       <Select
    //                         value={mainCategory}
    //                         onChange={(e) => {
    //                           setMainCategory(e);
    //                         }}
    //                         options={unique}
    //                       />
    //                     </Form.Group> */}

    //                     <Form.Group controlId="subcategory">
    //                       <Form.Label>
    //                         <span style={{ color: "red" }}>*</span>Category
    //                       </Form.Label>

    //                       <AsyncSelect
    //                         cacheOptions
    //                         defaultOptions
    //                         getOptionLabel={(e) => e}
    //                         getOptionValue={(e) => e[1]}
    //                         className="basic-single"
    //                         loadOptions={loadCategory}
    //                         onChange={(e) => {
    //                           setCategory(e[1]);
    //                         }}
    //                         placeholder="Type 3 characters for dropdown to appear"
    //                         style={{ fontSize: "10px" }}
    //                       />

    //                       <Form.Label>Vendor Specific Category</Form.Label>
    //                       <br />
    //                       <span>
    //                         Here you can specify your own category which can be
    //                         used as filter in your store
    //                       </span>
    //                       <Form.Group controlId="vendorcategory">
    //                         <Form.Control
    //                           type="text"
    //                           placeholder="Enter Vendor Category"
    //                           value={vendorCategory}
    //                           onChange={(e) =>
    //                             setVendorCategory(e.target.value)
    //                           }
    //                         ></Form.Control>
    //                       </Form.Group>
    //                     </Form.Group>
    //                     <Form.Group controlId="price">
    //                       <Form.Label>Price</Form.Label>

    //                       <Form.Control
    //                         type="number"
    //                         placeholder="Enter Price"
    //                         value={price}
    //                         onChange={(e) => setPrice(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>

    //                     <Form.Group controlId="discount">
    //                       <Form.Label>Discount</Form.Label>
    //                       <div style={{ display: "flex" }}>
    //                         <Form.Control
    //                           style={{ width: "70%" }}
    //                           type="number"
    //                           placeholder="Enter Discount "
    //                           value={discount}
    //                           onChange={(e) => {
    //                             setDiscount(e.target.value);
    //                           }}
    //                         ></Form.Control>
    //                         <div style={{ width: "30%" }}>
    //                           <Select
    //                             value={discountType}
    //                             onChange={(e) => {
    //                               setDiscountType(e);
    //                             }}
    //                             options={discountTypes}
    //                           />
    //                         </div>
    //                       </div>
    //                     </Form.Group>

    //                     <Form.Label>Image</Form.Label>

    //                     <Form.Group controlId="image">
    //                       <span style={{ color: "red" }}>*</span>
    //                       {image && (
    //                         <div style={{ width: "100px", height: "100px" }}>
    //                           <img
    //                             alt=""
    //                             src={image}
    //                             style={{
    //                               width: "100px",
    //                               height: "100px",
    //                               objectFit: "cover",
    //                             }}
    //                           />
    //                         </div>
    //                       )}

    //                       <input
    //                         id="image-file"
    //                         label="Choose File"
    //                         type="file"
    //                         custom
    //                         onChange={uploadFileHandler}
    //                       ></input>
    //                     </Form.Group>
    //                     <Form.Label>Descriptive Image</Form.Label>

    //                     <Form.Group
    //                       controlId="imageCollection"
    //                       style={{ display: "flex", flexWrap: "wrap" }}
    //                     >
    //                       {images.map((im) => (
    //                         <div style={{ width: "100px", height: "100px" }}>
    //                           <img
    //                             alt=""
    //                             src={im}
    //                             style={{
    //                               width: "100px",
    //                               height: "100px",
    //                               objectFit: "contain",
    //                             }}
    //                           />
    //                         </div>
    //                       ))}

    //                       <input
    //                         id="image-files"
    //                         label="Choose Files"
    //                         type="file"
    //                         custom
    //                         multiple
    //                         onChange={uploadFilesHandler}
    //                       ></input>
    //                     </Form.Group>

    //                     <Form.Group controlId="countInStock">
    //                       <Form.Label>Count In Stock</Form.Label>

    //                       <Form.Control
    //                         type="number"
    //                         placeholder="Enter CountInStock"
    //                         value={countInStock}
    //                         onChange={(e) => setCountInStock(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Form.Group>
    //                       <Form.Label>Minimum Order Quantity</Form.Label>

    //                       <Form.Control
    //                         type="number"
    //                         placeholder="Enter Minimum Order Quantity"
    //                         value={minOrderQuantity}
    //                         onChange={(e) =>
    //                           setMinOrderQuantity(e.target.value)
    //                         }
    //                       ></Form.Control>
    //                     </Form.Group>

    //                     <Form.Label>Description</Form.Label>

    //                     <Editor
    //                       onInit={(evt, editor) => (editorRef.current = editor)}
    //                       apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
    //                       initialValue={
    //                         description
    //                           ? description
    //                           : "<p>Describe your product here.</p>"
    //                       }
    //                       init={{
    //                         height: 500,
    //                         menubar: false,
    //                         plugins: [
    //                           "advlist autolink lists link image charmap print preview anchor",
    //                           "searchreplace visualblocks code fullscreen",
    //                           "insertdatetime media table paste code help wordcount",
    //                         ],
    //                         toolbar:
    //                           "undo redo | formatselect | " +
    //                           "bold italic backcolor | alignleft aligncenter " +
    //                           "alignright alignjustify | bullist numlist outdent indent | " +
    //                           "removeformat | help",
    //                         content_style:
    //                           "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
    //                       }}
    //                     />
    //                     <Button type="submit" variant="primary">
    //                       Update
    //                     </Button>
    //                   </Form>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="second">
    //                   <Form onSubmit={submitHandler}>
    //                     <Form.Group controlId="unit">
    //                       <Form.Label>Unit</Form.Label>

    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Unit (e.g. kg, piece)"
    //                         value={unit}
    //                         onChange={(e) => setUnit(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Form.Group controlId="measurements">
    //                       <Form.Label>Measurements</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Measurements (e.g. sm, lg, x, xl) "
    //                         value={measurements}
    //                         onChange={(e) => setMeasurements(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Form.Group controlId="colour">
    //                       <Form.Label>Colour</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Colours (e.g. blue,red,green) "
    //                         value={colour}
    //                         onChange={(e) => setColour(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Form.Group controlId="brand">
    //                       <Form.Label>Brand</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Brand"
    //                         value={brand}
    //                         onChange={(e) => setBrand(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Button type="submit" variant="primary">
    //                       Update
    //                     </Button>
    //                   </Form>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="third">
    //                   <Form onSubmit={submitFAQS}>
    //                     <Form.Group controlId="faq">
    //                       <Form.Label>FAQ</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Question"
    //                         value={question}
    //                         onChange={(e) => setQuestion(e.target.value)}
    //                       ></Form.Control>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Answer"
    //                         value={answer}
    //                         onChange={(e) => setAnswer(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Button
    //                       type="submit"
    //                       variant="primary"
    //                       style={{ margin: "10px" }}
    //                     >
    //                       Add
    //                     </Button>
    //                   </Form>
    //                   <ListGroup>
    //                     {product?.FAQs?.map((faq) => {
    //                       return (
    //                         <div
    //                           style={{
    //                             display: "flex",
    //                             justifyContent: "space-around",
    //                             alignItems: "center",
    //                             boxShadow: "0 0 10px lightgray",
    //                           }}
    //                         >
    //                           <ListGroup.Item>
    //                             <strong>Question:</strong> {faq.question}
    //                             <br />
    //                             <strong>Answer:</strong> {faq.answer}
    //                           </ListGroup.Item>
    //                           <i
    //                             className="fa fa-times"
    //                             aria-hidden="true"
    //                             onClick={(e) => deleteFAQ(e, faq._id)}
    //                             style={{ fontSize: "30px" }}
    //                           />
    //                         </div>
    //                       );
    //                     })}
    //                   </ListGroup>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="fourth">
    //                   <Form onSubmit={submitHandler}></Form>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="fifth">
    //                   <Form onSubmit={submitHandler}>
    //                     <Form.Group controlId="guarantee">
    //                       <Form.Label>Guarantee/Warranty</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Guarantee Scheme"
    //                         value={guarantee}
    //                         onChange={(e) => setGuarantee(e.target.value)}
    //                       ></Form.Control>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Warranty Scheme"
    //                         value={warranty}
    //                         onChange={(e) => setWarranty(e.target.value)}
    //                       ></Form.Control>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Return Scheme"
    //                         value={returnScheme}
    //                         onChange={(e) => setReturnScheme(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Button type="submit" variant="primary">
    //                       Update
    //                     </Button>
    //                   </Form>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="sixth">
    //                   <Form onSubmit={submitHandler}>
    //                     <Form.Group controlId="location">
    //                       <Form.Label>Location Management</Form.Label>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Warehouse where the product is stored in"
    //                         value={warehouseLocation}
    //                         onChange={(e) =>
    //                           setWarehouseLocation(e.target.value)
    //                         }
    //                       ></Form.Control>
    //                       <Form.Control
    //                         type="text"
    //                         placeholder="Enter Branch which handles the product"
    //                         value={branchLocation}
    //                         onChange={(e) => setBranchLocation(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                     <Button type="submit" variant="primary">
    //                       Update
    //                     </Button>
    //                   </Form>
    //                 </Tab.Pane>

    //                 <Tab.Pane
    //                   eventKey="seventh"
    //                   style={{ overflowWrap: "break-word ", padding: "10px" }}
    //                 >
    //                   Name: {product?.name}
    //                   <br />
    //                   Price: {product?.price} <br />
    //                   Image:{" "}
    //                   <div
    //                     style={{
    //                       width: "100px",
    //                       height: "100px",
    //                       margin: "15px",
    //                     }}
    //                   >
    //                     <img
    //                       src={product?.image}
    //                       style={{
    //                         height: "100%",
    //                         width: "100%",
    //                         objectFit: "contain",
    //                       }}
    //                       alt={product?.name}
    //                     />
    //                   </div>{" "}
    //                   <br />
    //                   Images:{" "}
    //                   <div style={{ display: "flex", margin: "15px" }}>
    //                     {product?.images?.map((image) => (
    //                       <div
    //                         style={{
    //                           width: "100px",
    //                           height: "100px",
    //                           margin: "0 10px",
    //                         }}
    //                       >
    //                         <img
    //                           src={image}
    //                           style={{
    //                             height: "100%",
    //                             width: "100%",
    //                             objectFit: "contain",
    //                           }}
    //                           alt={product?.name}
    //                         />
    //                       </div>
    //                     ))}
    //                   </div>
    //                   <br />
    //                   Brand: {product?.brand} <br />
    //                   Category: {product?.category} <br />
    //                   CountInStock: {product?.countInStock} <br />
    //                   MinOrderQuantity: {product?.minOrderQuantity} <br />
    //                   Description:{" "}
    //                   {product?.description && parse(product.description)}{" "}
    //                   <br />
    //                   Discount: {product?.discount} <br />
    //                   VendorSpecificCategory: {
    //                     product?.vendorSpecificCategory
    //                   }{" "}
    //                   <br />
    //                   Measurements: {product?.measurements} <br />
    //                   Unit: {product?.unit} <br />
    //                   Colour: {product?.colour} <br />
    //                   Guarantee: {product?.guarantee} <br />
    //                   Warranty: {product?.warranty} <br />
    //                   ReturnSchema: {product?.returnScheme} <br />
    //                   WarehouseLocation: {
    //                     product?.location?.warehouseLocation
    //                   }{" "}
    //                   <br />
    //                   BranchLocation: {product?.location?.branchLocation} <br />
    //                   PublishRequested: {product?.publishRequested} <br />
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey="eighth">
    //                   {product?.publishRequested || product?.publish ? (
    //                     <Button
    //                       onClick={(e) => {
    //                         submitpublishRequest(e);
    //                       }}
    //                     >
    //                       Request for Unpublish
    //                     </Button>
    //                   ) : (
    //                     <Button
    //                       disabled={!publishButtonDisabler}
    //                       onClick={(e) => {
    //                         submitpublishRequest(e);
    //                       }}
    //                     >
    //                       Request for Publish
    //                     </Button>
    //                   )}
    //                 </Tab.Pane>
    //               </Tab.Content>
    //             </Col>
    //           </Row>
    //         </Tab.Container>
    //       </>
    //     )}
    //   </div>
    // </>
  );
};

export default ProductEditScreen;
