import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_RESET,
  CART_GET_FROM_SERVER_REQUEST,
  CART_GET_FROM_SERVER_SUCCESS,
  CART_GET_FROM_SERVER_FAIL,
  CART_ADD_TO_SERVER_REQUEST,
  CART_ADD_TO_SERVER_SUCCESS,
  CART_ADD_TO_SERVER_FAIL,
  CART_REMOVE_FROM_SERVER_REQUEST,
  CART_REMOVE_FROM_SERVER_SUCCESS,
  CART_REMOVE_FROM_SERVER_FAIL,
  CART_GET_CHECKED_FROM_SERVER_REQUEST,
  CART_GET_CHECKED_FROM_SERVER_SUCCESS,
  CART_GET_CHECKED_FROM_SERVER_FAIL,
  CART_REMOVE_CHECKED_FROM_SERVER_REQUEST,
  CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS,
  CART_REMOVE_CHECKED_FROM_SERVER_FAIL,
  CART_EDIT_CHECKED_SERVER_REQUEST,
  CART_EDIT_CHECKED_SERVER_SUCCESS,
  CART_EDIT_CHECKED_SERVER_FAIL,
} from "../constants/cartConstants";

export const getCartFromServer = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CART_GET_FROM_SERVER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/cart/${id}`, config);
    dispatch({
      type: CART_GET_FROM_SERVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_GET_FROM_SERVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCheckedCartFromServer = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CART_GET_CHECKED_FROM_SERVER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/cart/checked/cartItems`, config);

    dispatch({
      type: CART_GET_CHECKED_FROM_SERVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CART_GET_CHECKED_FROM_SERVER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addCartItemToServer =
  (productId, qty) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_ADD_TO_SERVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/cart`,
        { productId, qty },
        config
      );
      dispatch({
        type: CART_ADD_TO_SERVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_ADD_TO_SERVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCartItemFromServer =
  (id, productId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_REMOVE_FROM_SERVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/cart/${id}`,
        { productId },
        config
      );
      dispatch({
        type: CART_REMOVE_FROM_SERVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_REMOVE_FROM_SERVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const removeCheckedCartItemsFromServer =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_REMOVE_CHECKED_FROM_SERVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(`/api/cart/delete/checked`, {}, config);
      dispatch({
        type: CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_REMOVE_CHECKED_FROM_SERVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const editCheckedCartItemFromServer =
  (id, productId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CART_EDIT_CHECKED_SERVER_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(`/api/cart`, { id, productId }, config);

      dispatch({
        type: CART_EDIT_CHECKED_SERVER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CART_EDIT_CHECKED_SERVER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addToCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/products/${id}`);
  let price;
  if (data.discount) {
    price = data.price - data.discount;
  } else {
    price = data.price;
  }
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: data.image,
      price: price,
      countInStock: data.countInStock,
      qty,
      minOrderQuantity: data.minOrderQuantity,
      updatedById: data.updatedById,
      uploaderType: data.uploaderType,
      shopId: data.vendorShop?._id || data.wholesalerShop?._id,
      netWeight: data.netWeight,
      grossWeight: data.grossWeight,
    },
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (id) => async (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => async (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });
  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

export const resetCart = (data) => async (dispatch) => {
  dispatch({
    type: CART_RESET,
    payload: data,
  });
  localStorage.setItem("cartItems", "");
};
