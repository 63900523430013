import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../../components/Meta";
import Message from "../../../components/Message";
import {
  detailAddressOrder,
  createAddressBook,
  getAddressBook,
} from "../../../actions/addressBookAction";

import {
  USER_CREATE_ADDRESSBOOK_RESET,
  USER_UPDATE_ADDRESSBOOK_RESET,
} from "../../../constants/addressConstants";
import { updateAddress } from "../../../actions/addressBookAction";
import { store, useGlobalState } from "state-pool";

import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../../actions/philipinesAddressAction";

store.setState("changeId", 0);

export const ShippingScreen = ({ history }) => {
  const dispatch = useDispatch();
  const addressOrderDetails = useSelector((state) => state.addressOrderDetails);
  const { address } = addressOrderDetails;

  const addressUpdate = useSelector((state) => state.addressUpdate);
  const { success: successUpdate } = addressUpdate;

  const addressbook = useSelector((state) => state.addressBook);
  const { addressBook } = addressbook;

  const addressCreate = useSelector((state) => state.addressCreate);
  const { success, error } = addressCreate;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [addressLine1, setAddressLine1] = useState(address?.addressLine1);
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philippines");
  const [postalCode, setPostalCode] = useState("");
  const [place, setPlace] = useState("");
  const [ward, setWard] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [isShipping, setIsShipping] = useState(true);

  const [additional, setAdditional] = useState("");
  const [show, setShow] = useState(false);
  const [option, setOption] = useState([]);
  const [changeId, setCount] = useGlobalState("changeId");
  const [validated, setValidated] = useState(false);
  const [shipValidated, setShipValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = async (e) => {
    setShow(true);
    e.preventDefault();
    setCount(e.target.id);
  };

  const [newshow, newsetShow] = useState(false);
  const newhandleClose = () => newsetShow(false);
  const newhandleShow = () => newsetShow(true);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setShipValidated(true);
    } else {
      dispatch(
        createAddressBook({
          addressLine1,
          additional,
          muncipality,
          province,
          district,
          country,
          postalCode,
          place,
          ward,
          isBilling: true,
          isShipping: true,
        })
      );

      setAddressLine1("");
      setMuncipality("");
      setProvince("");
      setDistrict("");
      setCountry("");
      setPostalCode("");
      setPlace("");
      setWard("");
      setIsBilling(true);
      setIsShipping(true);
      newhandleClose();
      history.push("/customer/shipping/detail");
    }
  };
  const submitPaymentHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setShipValidated(true);
    } else {
      history.push("/payment");
    }
  };

  const selectAddress = async (e) => {
    e.preventDefault();
    const id = e.target.id;

    if (address[1] !== undefined) {
      if (address[0]._id === changeId) {
        if (address[0].isShipping === true) {
          dispatch(
            updateAddress({
              id,
              isBilling: false,
              isShipping: true,
            })
          );
        } else if (address[0].isBilling === true) {
          dispatch(
            updateAddress({
              id,
              isBilling: true,
              isShipping: false,
            })
          );
        }
      }
      if (address[1]._id === changeId) {
        if (address[1].isShipping === true) {
          dispatch(
            updateAddress({
              id,
              isBilling: false,
              isShipping: true,
            })
          );
        } else if (address[1].isBilling === true) {
          dispatch(
            updateAddress({
              id,
              isBilling: true,
              isShipping: false,
            })
          );
        }
      }
    } else {
      if (address[0].isBilling === false && address[0].isShipping === true) {
        dispatch(
          updateAddress({
            id,
            isBilling: false,
            isShipping: true,
          })
        );
      } else if (
        address[0].isBilling === true &&
        address[0].isShipping === true
      ) {
        dispatch(
          updateAddress({
            id,
            isBilling: true,
            isShipping: true,
          })
        );
      } else if (
        address[0].isBilling === true &&
        address[0].isShipping === false
      ) {
        dispatch(
          updateAddress({
            id,
            isBilling: true,
            isShipping: false,
          })
        );
      }
    }
    history.push("/customer/shipping/detail");
    handleClose();
  };

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }

  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  useEffect(() => {
    loadRegion();
    if (userInfo) {
      dispatch(detailAddressOrder());
      dispatch({ type: USER_UPDATE_ADDRESSBOOK_RESET });
      dispatch(getAddressBook(userInfo?._id));
    }
    if (!successUpdate) {
      setAddressLine1(address?.addressLine1);
      setMuncipality(address?.muncipality);
      setProvince(address?.province);
      setDistrict(address?.district);
      setCountry(address?.country);
      setPostalCode(address?.postalCode);
      setPlace(address?.place);
      setWard(address?.ward);
      setIsBilling(address?.isBilling);
      setIsShipping(address?.isShipping);
    }
  }, [
    history,
    dispatch,
    userInfo,
    address?._id,
    address?.addressLine1,
    address?.addressLine2,
    address?.province,
    address?.district,
    address?.country,
    address?.postalCode,
    address?.muncipality,
    address?.place,
    address?.ward,
    address?.isBilling,
    address?.isShipping,
    successUpdate,
  ]);
  useEffect(() => {
    if (success) {
      dispatch(detailAddressOrder());
      dispatch({ type: USER_UPDATE_ADDRESSBOOK_RESET });
      dispatch(getAddressBook(userInfo?._id));
      dispatch({ type: USER_CREATE_ADDRESSBOOK_RESET });
    }
  }, [dispatch, success, userInfo?._id]);
  return (
    <>
      <Meta title="Shipping Details" />
      {/* <ChectoutSteps step1 step2 /> */}
      <h1>Shipping</h1>
      {error && <Message variant="danger">{error}</Message>}
      <Container style={{ height: "auto", paddingBottom: "5%" }}>
        {address?.length === 0 && (
          <Button className=" addShippingBtn" onClick={newhandleShow}>
            Add Shipping Address
          </Button>
        )}
        {userInfo?.type === "customer" && (
          <>
            <Form
              noValidate
              validated={validated}
              onSubmit={submitPaymentHandler}
            >
              <Row className="mb-3">
                {address?.map((addressData) => (
                  <Col md={5}>
                    <Card
                      style={{
                        boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
                      }}
                      className="pt-5 pb-4 px-3"
                    >
                      {addressData.isShipping === true &&
                      addressData.isBilling === false ? (
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                          }}
                          className="bg-dark"
                        >
                          Shipping Address
                        </span>
                      ) : addressData.isBilling === true &&
                        addressData.isShipping === false ? (
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                          }}
                          className="bg-info"
                        >
                          Billing Address
                        </span>
                      ) : addressData.isBilling === true &&
                        addressData.isShipping === true ? (
                        <div>
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              color: "#ffffff",
                              padding: "2px 8px",
                              textTransform: "uppercase",
                              fontSize: "0.85rem",
                            }}
                            className="bg-warning"
                          >
                            Billing {"&"} Shipping Address
                          </span>
                        </div>
                      ) : (
                        <span></span>
                      )}
                      <span style={{ position: "absolute", top: 5, right: 5 }}>
                        <div
                          onClick={handleShow}
                          id={addressData._id}
                          style={{
                            fontSize: 16,
                            cursor: "pointer",
                          }}
                        >
                          Change&nbsp;
                          <i
                            className="fas fa-edit"
                            style={{
                              color: "black",
                              float: "right",
                              fontSize: 18,
                            }}
                          ></i>
                        </div>
                      </span>
                      <ul style={{ listStyle: "none" }}>
                        <li>
                          <b>Country: </b>
                          {addressData.country}
                        </li>
                        <li>
                          <b>Region: </b>
                          {addressData.province}
                        </li>
                        <li>
                          <b>Province: </b>
                          {addressData.district}
                        </li>
                        <li>
                          <b>Muncipality: </b>
                          {addressData.muncipality}
                        </li>
                        <li>
                          <b>Barangay </b>
                          {addressData.ward}
                        </li>
                        <li>
                          <b>Postal Code: </b>
                          {addressData.postalCode}
                        </li>
                        <li>
                          <b>Street Address: </b>
                          {addressData.addressLine1}
                        </li>
                      </ul>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col md={10}></Col>
                <Col md={2}>
                  <button type="submit" className="addShippingBtnSubmit">
                    Proceed to Payment{" "}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </button>
                </Col>
              </Row>
            </Form>

            <Modal show={show} onHide={handleClose}>
              <Modal.Body className="table-responsive">
                <Row className="mt-4">
                  <Col md={9} sm={12}>
                    <h1>Your Address Book</h1>
                  </Col>
                  <Col md={3} sm={12}>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={newhandleShow}
                    >
                      Add New Address
                    </button>
                  </Col>
                </Row>
                <table className="table table-hover table-striped ">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Street Address</th>
                      <th>Province</th>
                      <th>District</th>
                      <th>Country</th>
                      <th>Postal Code</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addressBook?.map((address) => (
                      <tr key={address._id}>
                        <td>{address.user.name}</td>
                        <td>
                          {address.addressLine1} <br />
                          {address.isShipping === true &&
                          address.isBilling === false ? (
                            <span className="badge rounded-pill bg-dark ms-2">
                              Shipping Address
                            </span>
                          ) : address.isBilling === true &&
                            address.isShipping === false ? (
                            <span className="badge rounded-pill bg-light ms-2">
                              Billing Address
                            </span>
                          ) : address.isBilling === true &&
                            address.isShipping === true ? (
                            <div>
                              <span className="badge rounded-pill bg-light ms-2">
                                Billing Address
                              </span>
                              <span className="badge rounded-pill bg-dark ms-2">
                                Shipping Address
                              </span>
                            </div>
                          ) : (
                            <span></span>
                          )}
                          {address.place === "Home" ? (
                            <span className="badge rounded-pill bg-success ms-2">
                              {address.place}
                            </span>
                          ) : address.place === "Office" ? (
                            <span className="badge rounded-pill bg-info ms-2">
                              {address.place}
                            </span>
                          ) : (
                            <span className="badge rounded-pill bg-warning ms-2">
                              {address.place}
                            </span>
                          )}
                        </td>
                        <td>{address.province}</td>
                        <td>{address.district}</td>
                        <td>{address.country}</td>
                        <td>{address.postalCode}</td>
                        <td>
                          <button
                            id={address._id}
                            type="button"
                            className="btn btn-info"
                            onClick={selectAddress}
                          >
                            Select Address
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  onClick={handleClose}
                  type="button"
                  className="btn btn-danger "
                  style={{
                    float: "right",
                    marginTop: "2rem",
                    width: "200px",
                  }}
                >
                  Cancel{" "}
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </button>
              </Modal.Body>
            </Modal>
            <Modal show={newshow} onHide={newhandleClose}>
              <Modal.Body>
                <Col md={12} sm={12} className="px-4">
                  <h3 className="shippingHeading">Add New Address</h3>
                  <Form
                    noValidate
                    shipValidated={shipValidated}
                    onSubmit={submitHandler}
                  >
                    <Form.Group controlId="country" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Country
                      </Form.Label>

                      <Form.Control
                        className="orderCreateInputField"
                        type="text"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      ></Form.Control>
                    </Form.Group>
                    <Form.Group controlId="province" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Region
                      </Form.Label>

                      <Select
                        className="orderCreateInputField"
                        options={option}
                        onChange={(e) => {
                          setProvince(e.value);
                        }}
                        placeholder="Choose your region"
                      />
                    </Form.Group>
                    <Form.Group controlId="district" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Province
                      </Form.Label>
                      <AsyncSelect
                        className="orderCreateInputField"
                        loadOptions={loadProvince}
                        onChange={(e) => {
                          setDistrict(e.value);
                        }}
                        placeholder="Type characters for dropdown to appear"
                      />
                    </Form.Group>
                    <Form.Group controlId="muncipality" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Municipality
                      </Form.Label>

                      <AsyncSelect
                        className="orderCreateInputField"
                        cacheOptions
                        defaultOptions
                        loadOptions={loadMunicipality}
                        onChange={(e) => {
                          setMuncipality(e.value);
                        }}
                        placeholder="Type characters for dropdown to appear"
                      />
                    </Form.Group>
                    <Form.Group controlId="ward" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Barangay
                      </Form.Label>

                      <AsyncSelect
                        className="orderCreateInputField"
                        cacheOptions
                        defaultOptions
                        loadOptions={loadBarangay}
                        onChange={(e) => {
                          setWard(e.value);
                        }}
                        placeholder="Type characters for dropdown to appear"
                      />

                      {/* <Form.Control
                        type="text"
                        value={ward}
                        onChange={(e) => setWard(e.target.value)}
                      ></Form.Control> */}
                    </Form.Group>

                    <Form.Group controlId="streetAddress" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Street Address
                      </Form.Label>

                      <Form.Control
                        className="orderCreateInputField"
                        type="text"
                        value={addressLine1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="streetAddress" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Additional Address Information
                      </Form.Label>

                      <Form.Control
                        className="orderCreateInputField"
                        type="text"
                        value={additional}
                        onChange={(e) => setAdditional(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="postalcode" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        ZIP Code
                      </Form.Label>

                      <Form.Control
                        className="orderCreateInputField"
                        type="text"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="place" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Address Type
                      </Form.Label>

                      <Form.Control
                        className="orderCreateInputField"
                        as="select"
                        aria-label="Default select example"
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                      >
                        <option value="">Please Select the Place</option>
                        <option value="Home">Home</option>
                        <option value="Office">Office</option>
                        <option value="Other">Other</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="defaultfor" className="mt-3">
                      <Form.Label className="shippingScreenLabel">
                        Default For
                      </Form.Label>
                      <Row>
                        <Col xs={1}>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked
                            disabled
                            value={isShipping}
                            onChange={(e) => setIsShipping(e.target.checked)}
                          />
                        </Col>

                        <Col xs={11}>Shipping Address</Col>
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            defaultChecked
                            disabled
                            value={isBilling}
                            onChange={(e) => setIsBilling(e.target.checked)}
                          />
                        </Col>

                        <Col xs={11}>Billing Address</Col>
                      </Row>
                    </Form.Group>
                    <button
                      onClick={newhandleClose}
                      type="button"
                      className="addShippingBtnCancel ml-2"
                    >
                      Cancel{" "}
                    </button>
                    <button type="submit" className="addShippingBtnSubmit">
                      Submit{" "}
                    </button>
                  </Form>
                </Col>
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
};

export default ShippingScreen;
