import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Footer.css";
export default function FooterAdmin() {
  return (
    <>
      <footer>
        <Container>
          <Row>
            <Col md={4} className="footerLogo">
              <img src="/images/logo.jpg" alt="logo" className="headerImage" />
              <span className="footerLogoTitle"> PINOY CAN BUY</span>
            </Col>
          </Row>
          <Row>
            <Col md={4} className="footer-column">
              <ul className="nav flex-column">
                <li className="nav-item footer-nav-item mb-2">
                  <span className="footer-title">COMPANY</span>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    About Us
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/contactus"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    Contact Us
                  </Link>
                </li>

                <li className="nav-item footer-nav-item">
                  <Link
                    to="/privacypolicy"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    Privacy and Policy
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="termsandconditions"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    Terms and Conditions
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md={4} className="footer-column">
              <ul className="nav flex-column">
                <li className="nav-item footer-nav-item mb-2">
                  <span className="footer-title">QUICK LINKS</span>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/blog"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/howtobuy"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    How To Buy
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/howtosell"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    How To Sell
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="/helpcenter"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    Help Center
                  </Link>
                </li>
              </ul>
            </Col>

            <Col md={4} className="footer-column">
              <ul className="nav flex-column">
                <li className="nav-item footer-nav-item mb-2">
                  <span className="footer-title">CONTACT & SUPPORT</span>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="#"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    <i className="fas fa-phone me-2"></i>+977 01-5199684
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="#"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    <i className="fas fa-comments me-2"></i>Live chat
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="#"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    <i className="fas fa-envelope me-2"></i>
                    support@pinoycanbuy.com
                  </Link>
                </li>
                <li className="nav-item footer-nav-item">
                  <Link
                    to="#"
                    style={{ textDecoration: "none" }}
                    className="nav-item footer-nav-item"
                  >
                    <i className="fas fa-star me-2"></i>Give feedback
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
      <footer className="copyRightSection">
        <Container>
          <div className="text-center">
            <i className="fas fa-ellipsis-h" style={{ color: "white" }}></i>
          </div>

          <Row>
            <Col md={4} className="footer-column">
              <span className="copyright quick-links copyRightText">
                Copyright &copy;
                {new Date().getFullYear()} Pinoy Can
              </span>
            </Col>
            <Col md={4} className="footer-column">
              <ul className="list-inline social-buttons">
                <li className="list-inline-item">
                  <a href="https://twitter.com/pinoycanbuy">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/pinoycanbuy">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/pinoy-can-buy/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/pinoycanbuy/">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={4} className="footer-column copyRightText">
              Developed By:{" "}
              <a
                href="https://www.thecanbrand.com/"
                style={{ textDecoration: "none", color: "gray" }}
              >
                The Can Brand
              </a>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
