import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { listFollowingProducts } from "../../actions/followActions";
import { Row, Col, Toast, Alert } from "react-bootstrap";
import "./FollowedProductsProductScreen.css";
import { Link } from "react-router-dom";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";

const FollowedProductsProductScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };
  const getFollowingProduct = useSelector((state) => state.getFollowingProduct);
  const { loading, error, products } = getFollowingProduct;

  useEffect(() => {
    dispatch(listFollowingProducts());
  }, [dispatch]);
  return (
    <div>
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}
      <h1 className="followedStoreHeading mt-3"> My Followed Shops </h1>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Row className="mb-5 mx-3">
          {products?.length === 0 && <h2>No Shops Followed</h2>}

          {products?.map((product, index) => (
            <Row className="followedStoreContent" key={index}>
              <Row className="followedStoreInfo justify-content-between p-3">
                <Col md={4}>
                  <Row className="followedStoreName">
                    <Col md={3}>
                      <i className="fas fa-store fa-3x followedStoreShopIcon"></i>
                    </Col>
                    <Col md={9} className="mt-2">
                      <h4> {product.shop}</h4>
                      <p> </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <Link
                    to={
                      product?.products[0]?.uploaderType === "vendor"
                        ? `/shop/${product.shop}`
                        : product?.products[0]?.uploaderType === "wholesaler"
                        ? `/wholesaleshop/${product.shop}`
                        : ""
                    }
                    className="followedStoreIcon"
                  >
                    <i className="fas fa-store mb-3 followedStoreIcon"></i> Go
                    to Store
                  </Link>
                </Col>
              </Row>
              <Row className="mb-5 mx-md-3">
                {product?.products?.map((product) => {
                  return (
                    <Col key={product._id} sm={6} md={2}>
                      <Product
                        product={product}
                        history={history}
                        toastToggler={toastToggler}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Row>
          ))}
        </Row>
      )}
    </div>
  );
};

export default FollowedProductsProductScreen;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { listFollowingProducts } from "../../actions/followActions";
// import { Row, Col } from "react-bootstrap";
// import "./FollowedProductsProductScreen.css";
// import { Link } from "react-router-dom";

// const FollowedProductsProductScreen = () => {
//   const dispatch = useDispatch();

//   const getFollowingProduct = useSelector((state) => state.getFollowingProduct);
//   const { products } = getFollowingProduct;

//   useEffect(() => {
//     dispatch(listFollowingProducts());
//   }, [dispatch]);
//   return (
//     <div>
//       <h1 className="followedStoreHeading mt-3"> My Followed Store </h1>

//       {products?.map((product) => (
//         <Row className="followedStoreContent">
//           <Row className="followedStoreInfo justify-content-between p-3">
//             <Col md={4}>
//               <Row className="followedStoreName">
//                 <Col md={3}>
//                   <i className="fas fa-store fa-3x followedStoreShopIcon"></i>
//                 </Col>
//                 <Col md={9} className="mt-2">
//                   <h4> {product.shop}</h4>
//                   <p> Sponsered by NCM</p>
//                 </Col>
//               </Row>
//             </Col>
//             <Col md={3}>
//               <Link to={`/shop/${product.shop}`} className="followedStoreIcon">
//                 <i className="fas fa-store mb-3 followedStoreIcon"></i> Go to Store
//               </Link>
//               {/* <br></br>
//               <Link to="#">
//                 <i className="fas fa-check followedStoreIcon"></i> Following
//               </Link> */}
//             </Col>
//           </Row>
//           {product.products?.map((p) => (
//             <Link to={`/${p.name}/${p._id}`} key={p._id}>
//               <Col className="py-3">
//                 <img
//                   className="followedStoreImages"
//                   src={p.image}
//                   alt="image"
//                 ></img>
//                 <p className="followedStorePrice"> {p.price}</p>
//               </Col>
//             </Link>
//           ))}
//         </Row>
//       ))}
//     </div>
//   );
// };

// export default FollowedProductsProductScreen;
