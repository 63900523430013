export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const CUSTOMER_REGISTER_REQUEST = "CUSTOMER_REGISTER_REQUEST";
export const CUSTOMER_REGISTER_FAIL = "CUSTOMER_REGISTER_FAIL";
export const CUSTOMER_REGISTER_SUCCESS = "CUSTOMER_REGISTER_SUCCESS";
export const CUSTOMER_REGISTER_RESET = "CUSTOMER_REGISTER_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_SPECIFIC_DETAILS_REQUEST = "USER_SPECIFIC_DETAILS_REQUEST";
export const USER_SPECIFIC_DETAILS_FAIL = "USER_SPECIFIC_DETAILS_FAIL";
export const USER_SPECIFIC_DETAILS_SUCCESS = "USER_SPECIFIC_DETAILS_SUCCESS";
export const USER_SPECIFIC_DETAILS_RESET = "USER_SPECIFIC_DETAILS_RESET";

export const USER_VENDOR_DETAILS_REQUEST = "USER_VENDOR_DETAILS_REQUEST";
export const USER_VENDOR_DETAILS_FAIL = "USER_VENDOR_DETAILS_FAIL";
export const USER_VENDOR_DETAILS_SUCCESS = "USER_VENDOR_DETAILS_SUCCESS";
export const USER_VENDOR_DETAILS_RESET = "USER_VENDOR_DETAILS_RESET";

export const SHOP_DETAILS_BY_ID_REQUEST = "SHOP_DETAILS_BY_ID_REQUEST";
export const SHOP_DETAILS_BY_ID_SUCCESS = "SHOP_DETAILS_BY_ID_SUCCESS";
export const SHOP_DETAILS_BY_ID_FAIL = "SHOP_DETAILS_BY_ID_FAIL";

export const USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST =
  "USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST";
export const USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL =
  "USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL";
export const USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS =
  "USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS";

export const USER_WHOLESALER_DETAILS_REQUEST =
  "USER_WHOLESALER_DETAILS_REQUEST";
export const USER_WHOLESALER_DETAILS_FAIL = "USER_WHOLESALER_DETAILS_FAIL";
export const USER_WHOLESALER_DETAILS_SUCCESS =
  "USER_WHOLESALER_DETAILS_SUCCESS";
export const USER_WHOLESALER_DETAILS_RESET = "USER_WHOLESALER_DETAILS_RESET";

export const USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST =
  "USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST";
export const USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL =
  "USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL";
export const USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS =
  "USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS";
export const USER_WHOLESALER_DETAILS_BY_SHOPNAME_RESET =
  "USER_WHOLESALER_DETAILS_BY_SHOPNAME_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const VENDOR_LIST_REQUEST = "VENDOR_LIST_REQUEST";
export const VENDOR_LIST_FAIL = "VENDOR_LIST_FAIL";
export const VENDOR_LIST_SUCCESS = "VENDOR_LIST_SUCCESS";
export const VENDOR_LIST_RESET = "VENDOR_LIST_RESET";

export const WHOLESALER_LIST_REQUEST = "WHOLESALER_LIST_REQUEST";
export const WHOLESALER_LIST_FAIL = "WHOLESALER_LIST_FAIL";
export const WHOLESALER_LIST_SUCCESS = "WHOLESALER_LIST_SUCCESS";
export const WHOLESALER_LIST_RESET = "WHOLESALER_LIST_RESET";

export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_RESET = "USER_UPDATE_RESET";

export const VENDOR_REGISTRATION_REQUEST = "VENDOR_REGISTRATION_REQUEST";
export const VENDOR_REGISTRATION_FAIL = "VENDOR_REGISTRATION_FAIL";
export const VENDOR_REGISTRATION_SUCCESS = "VENDOR_REGISTRATION_SUCCESS";

export const VENDOR_DETAILS_REGISTRATION_REQUEST =
  "VENDOR_DETAILS_REGISTRATION_REQUEST";
export const VENDOR_DETAILS_REGISTRATION_FAIL =
  "VENDOR_DETAILS_REGISTRATION_FAIL";
export const VENDOR_DETAILS_REGISTRATION_SUCCESS =
  "VENDOR_DETAILS_REGISTRATION_SUCCESS";
export const VENDOR_DETAILS_REGISTRATION_RESET =
  "VENDOR_DETAILS_REGISTRATION_RESET";
export const WHOLESALER_DETAILS_REGISTRATION_REQUEST =
  "VENDOR_DETAILS_REGISTRATION_REQUEST";
export const WHOLESALER_DETAILS_REGISTRATION_FAIL =
  "VENDOR_DETAILS_REGISTRATION_FAIL";
export const WHOLESALER_DETAILS_REGISTRATION_SUCCESS =
  "VENDOR_DETAILS_REGISTRATION_SUCCESS";
export const WHOLESALER_DETAILS_REGISTRATION_RESET =
  "VENDOR_DETAILS_REGISTRATION_RESET";

export const VENDOR_ADDRESS_DEACTIVATE_REQUEST =
  "VENDOR_ADDRESS_DEACTIVATE_REQUEST";
export const VENDOR_ADDRESS_DEACTIVATE_FAIL = "VENDOR_ADDRESS_DEACTIVATE_FAIL";
export const VENDOR_ADDRESS_DEACTIVATE_SUCCESS =
  "VENDOR_ADDRESS_DEACTIVATE_SUCCESS";
export const VENDOR_ADDRESS_DEACTIVATE_RESET =
  "VENDOR_ADDRESS_DEACTIVATE_RESET";
export const WHOLESALER_ADDRESS_DEACTIVATE_REQUEST =
  "VENDOR_ADDRESS_DEACTIVATE_REQUEST";
export const WHOLESALER_ADDRESS_DEACTIVATE_FAIL =
  "VENDOR_ADDRESS_DEACTIVATE_FAIL";
export const WHOLESALER_ADDRESS_DEACTIVATE_SUCCESS =
  "VENDOR_ADDRESS_DEACTIVATE_SUCCESS";
export const WHOLESALER_ADDRESS_DEACTIVATE_RESET =
  "VENDOR_ADDRESS_DEACTIVATE_RESET";

export const APPROVE_FOR_SELLING_REQUEST = "APPROVE_FOR_SELLING_REQUEST";
export const APPROVE_FOR_SELLING_FAIL = "APPROVE_FOR_SELLING_FAIL";
export const APPROVE_FOR_SELLING_SUCCESS = "APPROVE_FOR_SELLING_SUCCESS";
export const APPROVE_FOR_SELLING_RESET = "APPROVE_FOR_SELLING_RESET";

export const DISAPPROVE_FOR_SELLING_REQUEST = "DISAPPROVE_FOR_SELLING_REQUEST";
export const DISAPPROVE_FOR_SELLING_FAIL = "DISAPPROVE_FOR_SELLING_FAIL";
export const DISAPPROVE_FOR_SELLING_SUCCESS = "DISAPPROVE_FOR_SELLING_SUCCESS";
export const DISAPPROVE_FOR_SELLING_RESET = "DISAPPROVE_FOR_SELLING_RESET";

export const WHOLESALER_REGISTRATION_REQUEST =
  "WHOLESALER_REGISTRATION_REQUEST";
export const WHOLESALER_REGISTRATION_FAIL = "WHOLESALER_REGISTRATION_FAIL";
export const WHOLESALER_REGISTRATION_SUCCESS =
  "WHOLESALER_REGISTRATION_SUCCESS";

export const VENDOR_NOUSER_REGISTRATION_REQUEST =
  "VENDOR_NOUSER_REGISTRATION_REQUEST";
export const VENDOR_NOUSER_REGISTRATION_FAIL =
  "VENDOR_NOUSER_REGISTRATION_FAIL";
export const VENDOR_NOUSER_REGISTRATION_SUCCESS =
  "VENDOR_NOUSER_REGISTRATION_SUCCESS";

export const WHOLESALER_NOUSER_REGISTRATION_REQUEST =
  "WHOLESALER_NOUSER_REGISTRATION_REQUEST";
export const WHOLESALER_NOUSER_REGISTRATION_FAIL =
  "WHOLESALER_NOUSER_REGISTRATION_FAIL";
export const WHOLESALER_NOUSER_REGISTRATION_SUCCESS =
  "WHOLESALER_NOUSER_REGISTRATION_SUCCESS";

export const SELLER_CREATE_REVIEW_REQUEST = "SELLER_CREATE_REVIEW_REQUEST";
export const SELLER_CREATE_REVIEW_FAIL = "SELLER_CREATE_REVIEW_FAIL";
export const SELLER_CREATE_REVIEW_SUCCESS = "SELLER_CREATE_REVIEW_SUCCESS";
export const SELLER_CREATE_REVIEW_RESET = "SELLER_CREATE_REVIEW_RESET";

export const FOLLOW_A_SHOP_REQUEST = "FOLLOW_A_SHOP_REQUEST";
export const FOLLOW_A_SHOP_SUCCESS = "FOLLOW_A_SHOP_SUCCESS";
export const FOLLOW_A_SHOP_FAIL = "FOLLOW_A_SHOP_FAIL";

export const UNFOLLOW_A_SHOP_REQUEST = "UNFOLLOW_A_SHOP_REQUEST";
export const UNFOLLOW_A_SHOP_SUCCESS = "UNFOLLOW_A_SHOP_SUCCESS";
export const UNFOLLOW_A_SHOP_FAIL = "UNFOLLOW_A_SHOP_FAIL";

export const WHOLESALER_SHOP_LIST_REQUEST = "WHOLESALER_SHOP_LIST_REQUEST";
export const WHOLESALER_SHOP_LIST_FAIL = "WHOLESALER_SHOP_LIST_FAIL";
export const WHOLESALER_SHOP_LIST_SUCCESS = "WHOLESALER_SHOP_LIST_SUCCESS";
export const WHOLESALER_SHOP_LIST_RESET = "WHOLESALER_SHOP_LIST_RESET";

export const VENDOR_SHOP_LIST_REQUEST = "VENDOR_SHOP_LIST_REQUEST";
export const VENDOR_SHOP_LIST_FAIL = "VENDOR_SHOP_LIST_FAIL";
export const VENDOR_SHOP_LIST_SUCCESS = "VENDOR_SHOP_LIST_SUCCESS";
export const VENDOR_SHOP_LIST_RESET = "VENDOR_SHOP_LIST_RESET";
