import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";

import { categoryDelete, getCategory } from "../../actions/categoryActions";
import { CATEGORY_UPDATE_RESET } from "../../constants/categoryConstants";
import Paginate from "../../components/Paginate";
import "./CategoryScreen.css";

const CategoryFormScreen = ({ match }) => {
  const pageNumber = match.params.pageNumber;
  const dispatch = useDispatch();

  dispatch({ type: CATEGORY_UPDATE_RESET });
  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories, page, pages } = categoryList;

  const deleteCategoryHandler = (id) => {
    dispatch(categoryDelete(id));
  };
  useEffect(() => {
    dispatch(getCategory(pageNumber));
  }, [dispatch, pageNumber]);

  return (
    <>
      <div className="container mt-3">
        <h1 className="categoryScreenH1"> Category List</h1>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Table responsive>
            <thead>
              <tr className="productListRowHeading">
                <th>LEVEL 1</th>
                <th>LEVEL 2</th>
                <th>LEVEL 3</th>
                <th>LEVEL 4</th>
                <th>LEVEL 5</th>
                <th>LEVEL 6</th>
                <th>EDIT/DELETE</th>
              </tr>
            </thead>
            <tbody className="productListTableBody">
              {categories?.map((category) => (
                <tr>
                  <td>{category.level1}</td>
                  <td>{category.level2}</td>
                  <td>{category.level3}</td>
                  <td>{category.level4}</td>
                  <td>{category.level5}</td>
                  <td>{category.level6}</td>
                  <td>
                    <Link
                      to={`/edit/category/${category._id}`}
                      className="categoryIconMargin"
                    >
                      <i className="fas fa-edit text-info"></i>
                    </Link>

                    <Link onClick={() => deleteCategoryHandler(category._id)}>
                      <i className="fas fa-trash text-danger"></i>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <Paginate url="/create/category" pages={pages} page={page} />
      </div>
    </>
  );
};

export default CategoryFormScreen;

// import React, { useEffect } from "react";
// import { Button, Table, Row, Col } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";

// import { categoryDelete, getCategory } from "../../actions/categoryActions";
// import { CATEGORY_UPDATE_RESET } from "../../constants/categoryConstants";
// import Paginate from "../../components/Paginate";

// const CategoryFormScreen = ({ match }) => {
//   const pageNumber = match.params.pageNumber;
//   const dispatch = useDispatch();

//   dispatch({ type: CATEGORY_UPDATE_RESET });
//   const categoryList = useSelector((state) => state.categoryList);
//   const { categories, page, pages } = categoryList;

//   const deleteCategoryHandler = (id) => {
//     dispatch(categoryDelete(id));
//   };
//   useEffect(() => {
//     dispatch(getCategory(pageNumber));
//   }, [dispatch, pageNumber]);

//   return (
//     <Row style={{ height: "90vh" }}>
//       <Col md="12" style={{ height: "500px", overflow: "scroll" }}>
//         <h3>Category List</h3>

//         <Table
//           striped
//           bordered
//           hover
//           responsive
//           size="sm"
//           style={{ fontSize: "13px" }}
//         >
//           <thead>
//             <tr>
//               <th>Level1</th>
//               <th>Level2</th>
//               <th>Level3</th>
//               <th>Level4</th>

//               <th>Level5</th>
//               <th>Level6</th>
//             </tr>
//           </thead>
//           <tbody>
//             {categories?.map((category) => (
//               <tr key={category._id}>
//                 <td>{category.level1}</td>
//                 <td>{category.level2}</td>
//                 <td>{category.level3}</td>
//                 <td>{category.level4}</td>
//                 <td>{category.level5}</td>
//                 <td>{category.level6}</td>

//                 <td>
//                   <Link to={`/edit/category/${category._id}`}>Edit</Link>
//                 </td>
//                 <td>
//                   <Button onClick={() => deleteCategoryHandler(category._id)}>
//                     Delete
//                   </Button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>
//       </Col>
//       <Paginate url="/create/category" pages={pages} page={page} />
//     </Row>
//   );
// };

// export default CategoryFormScreen;
