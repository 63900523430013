import React, { useEffect } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAddressBook } from "../../actions/addressBookAction";
import {
  USER_CREATE_ADDRESSBOOK_RESET,
  USER_UPDATE_ADDRESSBOOK_RESET,
} from "../../constants/addressConstants";

const AddressBookScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const addressbook = useSelector((state) => state.addressBook);
  const { addressBook } = addressbook;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createAddressHandler = () => {
    history.push("/create/address/book");
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(getAddressBook(userInfo?._id));
    }
    dispatch({ type: USER_CREATE_ADDRESSBOOK_RESET });
  }, [history, dispatch, userInfo]);

  dispatch({ type: USER_UPDATE_ADDRESSBOOK_RESET });

  return (
    <Container>
      <Row className="mt-4">
        <Col md={9} sm={12}>
          <h1>Your Address Book</h1>
        </Col>
        <Col md={3} sm={12}>
          <Button onClick={createAddressHandler}>
            <i className="fas fa-plus me-2"></i>Add New Address
          </Button>
        </Col>
      </Row>
      <div className="table-responsive">
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Name</th>
              <th>Street Address</th>
              <th>Region</th>
              <th>Province</th>
              <th>Country</th>
              <th>Postal Code</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {addressBook?.map((address) => (
              <tr key={address._id}>
                <td>{address.user.name}</td>
                <td>
                  {address.addressLine1} <br />
                  {address.isShipping === true &&
                  address.isBilling === false ? (
                    <span className="badge rounded-pill bg-dark ms-2">
                      Shipping Address
                    </span>
                  ) : address.isBilling === true &&
                    address.isShipping === false ? (
                    <span className="badge rounded-pill bg-light ms-2">
                      Billing Address
                    </span>
                  ) : address.isBilling === true &&
                    address.isShipping === true ? (
                    <div>
                      <span className="badge rounded-pill bg-light ms-2">
                        Billing Address
                      </span>
                      <span className="badge rounded-pill bg-dark ms-2">
                        Shipping Address
                      </span>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {address.place === "Home" ? (
                    <span className="badge rounded-pill bg-success ms-2">
                      {address.place}
                    </span>
                  ) : address.place === "Office" ? (
                    <span className="badge rounded-pill bg-info ms-2">
                      {address.place}
                    </span>
                  ) : (
                    <span className="badge rounded-pill bg-warning ms-2">
                      {address.place}
                    </span>
                  )}
                </td>
                <td>{address.province}</td>
                <td>{address.district}</td>
                <td>{address.country}</td>
                <td>{address.postalCode}</td>
                <td>
                  <Link
                    to={`/address/book/${address._id}/edit`}
                    className="btn btn-info"
                  >
                    Edit
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
          {/* <tfoot>
					<Paginate url="/myorders" pages={pages} page={page} />
				</tfoot> */}
        </table>
      </div>
    </Container>
  );
};

export default AddressBookScreen;
