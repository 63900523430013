import React from "react";
import { Route } from "react-router-dom";
import AboutUsScreen from "../screens/Home/AboutUsScreen";
import BestOfferScreen from "../screens/Home/BestOffer";
import BlogScreen from "../screens/Home/BlogScreen";
import ContactUsScreen from "../screens/Home/ContactUsScreen";
import HelpCenterScreen from "../screens/Home/HelpCenterScreen";
import Homescreen from "../screens/Home/Homescreen";
import HowToBuy from "../screens/Home/HowToBuy";
import HowToSell from "../screens/Home/HowToSell";
import MostPopularScreen from "../screens/Home/MostPopular";
import NewProductScreen from "../screens/Home/NewProduct";
import PrivacyPolicyScreen from "../screens/Home/PrivacyPolicyScreen";
import SearchHomeScreen from "../screens/Home/SearchHomeScreen";
import ShopSearchHomeScreen from "../screens/Home/SearchShopHomeScreen";
import TermsAndConditionsScreen from "../screens/Home/TermsAndConditionsScreen";
import AccountVerificationScreen from "../screens/Register/AccountVerificationScreen";
import EnterEmailScreen from "../screens/Register/EnterEmailScreen";
import PasswordResetScreen from "../screens/Register/PasswordResetScreen";

const HomeRoutes = () => {
  return (
    <>
      <Route path="/" component={Homescreen} exact />
      <Route path="/about" component={AboutUsScreen} />
      <Route path="/blog" component={BlogScreen} />
      <Route path="/contactus" component={ContactUsScreen} />
      <Route path="/helpcenter" component={HelpCenterScreen} />

      <Route path="/howtobuy" component={HowToBuy} />
      <Route path="/howtosell" component={HowToSell} />
      <Route path="/privacypolicy" component={PrivacyPolicyScreen} />
      <Route path="/termsandconditions" component={TermsAndConditionsScreen} />
      <Route path="/search/:keyword" component={SearchHomeScreen} />

      <Route path="/search-shop" component={ShopSearchHomeScreen} />

      <Route path="/new" component={NewProductScreen} exact />
      <Route path="/new/page/:pageNumber" component={NewProductScreen} />
      <Route path="/best-offer" component={BestOfferScreen} exact />
      <Route path="/best-offer/page/:pageNumber" component={BestOfferScreen} />
      <Route path="/most-popular" component={MostPopularScreen} exact />
      <Route
        path="/most-popular/page/:pageNumber"
        component={MostPopularScreen}
      />
      <Route
        path="/verifyEmail/:id/:token"
        component={AccountVerificationScreen}
      />
      <Route path="/enteremail" component={EnterEmailScreen} />
      <Route path="/resetPassword" component={PasswordResetScreen} />
    </>
  );
};

export default HomeRoutes;
