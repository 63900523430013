import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  registerVendor,
  registerWholesaler,
} from "../actions/userActions";
import SearchBox from "./SearchBox";

import "./Header.css";
import CustomerHeader from "./HeaderComponents/CustomerHeader";
import NoUserHeader from "./HeaderComponents/NoUserHeader";
import VendorHeader from "./HeaderComponents/VendorHeader";
import WholesalerHeader from "./HeaderComponents/WholesalerHeader";
import AdminHeader from "./HeaderComponents/AdminHeader";
import { listWishlist } from "../actions/productActions";
import { CREATE_WISHLIST_RESET } from "../constants/productConstants";
import {
  CART_ADD_TO_SERVER_RESET,
  CART_REMOVE_CHECKED_FROM_SERVER_RESET,
  CART_REMOVE_FROM_SERVER_RESET,
} from "../constants/cartConstants";
import { getCartFromServer } from "../actions/cartActions";

const Header = ({ history }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: userDetails } = wholesalerDetails;

  const wholesalerRegister = useSelector((state) => state.wholesalerRegister);
  const { success: wholesalerRegistrationSuccess } = wholesalerRegister;

  const vendorRegister = useSelector((state) => state.vendorRegister);
  const { success: vendorRegistrationSuccess } = vendorRegister;

  const wishlistList = useSelector((state) => state.wishlistList);
  const { products: wishListProducts } = wishlistList;

  const cartItemAdd = useSelector((state) => state.cartItemAdd);
  const { success: addSuccess } = cartItemAdd;

  const cartItemDelete = useSelector((state) => state.cartItemDelete);
  const { success: deleteItemSuccess } = cartItemDelete;

  const cartItemsDelete = useSelector((state) => state.cartItemsDelete);
  const { success: deleteSuccess } = cartItemsDelete;

  const wishlistCreate = useSelector((state) => state.wishlistCreate);
  const { success: createSuccess } = wishlistCreate;

  const wishlistDelete = useSelector((state) => state.wishlistDelete);
  const { success: deleteWishlistSuccess } = wishlistDelete;

  const sellProducts = (type) => {
    if (type === "retail") {
      if (window.confirm("Are you sure you want to register as a Retailer?")) {
        dispatch(registerVendor());
      }
    } else {
      if (
        window.confirm("Are you sure you want to register as a wholesaler?")
      ) {
        dispatch(registerWholesaler());
      }
    }
  };
  useEffect(() => {
    if (createSuccess || deleteWishlistSuccess) {
      dispatch(listWishlist());
      dispatch({ type: CREATE_WISHLIST_RESET });
    }
  }, [dispatch, createSuccess, deleteWishlistSuccess]);
  useEffect(() => {
    if (addSuccess || deleteSuccess || deleteItemSuccess) {
      dispatch(getCartFromServer(userInfo?._id));
      dispatch({ type: CART_ADD_TO_SERVER_RESET });
      dispatch({ type: CART_REMOVE_FROM_SERVER_RESET });

      dispatch({ type: CART_REMOVE_CHECKED_FROM_SERVER_RESET });
    }
  }, [dispatch, addSuccess, deleteSuccess, deleteItemSuccess, userInfo?._id]);

  useEffect(() => {
    if (vendorRegistrationSuccess || wholesalerRegistrationSuccess)
      history.push("/");
  }, [vendorRegistrationSuccess, wholesalerRegistrationSuccess, history]);

  // const cart = useSelector((state) => state.cart);
  // const { cartItems } = cart;

  const getCartItems = useSelector((state) => state.getCartItems);
  const { cart: cartItems } = getCartItems;

  const items = cartItems?.products?.length;
  const wishlistItems = wishListProducts?.length;

  const logoutHandler = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <div className="navContainer">
      <header style={{ position: "sticky", top: 0, zIndex: "10" }}>
        <Navbar
          className="mainHeader py-2"
          variant="dark"
          expand="lg"
          collapseOnSelect
        >
          <div className="container">
            {userInfo?.type === "customer" && (
              <NavDropdown
                title="Sell on Pinoy Can Buy"
                id="headerSellOnReseller"
              >
                <NavDropdown.Item
                  onClick={() => {
                    sellProducts("retail");
                  }}
                >
                  Retail Products
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={() => {
                    sellProducts("wholesaler");
                  }}
                >
                  Wholesale Products
                </NavDropdown.Item>
              </NavDropdown>
            )}
            {!userInfo && (
              <Link to="/login" id="headerSellOnReseller">
                Sell on Pinoy Can Buy
              </Link>
            )}
            <Link to="/" className="topHeaderLink">
              Customer Care <i className="far fa-question-circle"></i>
            </Link>
            <Navbar.Collapse className="justify-content-end d-none d-md-block">
              <div className="topHeaderLinkFollowUs">
                Follow us on
                <span className="topHeaderSocialIcons">
                  <a
                    href="https://www.facebook.com/pinoycanbuy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-facebook-square SocialIcons"></i>
                  </a>
                </span>
                <span>
                  <a
                    href="https://www.linkedin.com/company/pinoy-can-buy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin SocialIcons ml-2"></i>
                  </a>
                </span>
              </div>
              {userInfo &&
              !userInfo.isAdmin &&
              userInfo.type !== "vendor" &&
              userInfo.type !== "wholesaler" ? (
                <CustomerHeader
                  history={history}
                  logoutHandler={logoutHandler}
                  name={userInfo.name}
                  id={userInfo?._id}
                />
              ) : (
                <>{!userInfo && <NoUserHeader history={history} />}</>
              )}

              {userInfo && userInfo.isAdmin ? (
                <AdminHeader
                  id={userInfo?._id}
                  name={userInfo?.name}
                  logoutHandler={logoutHandler}
                />
              ) : null}

              {userInfo && !userInfo.isAdmin && userInfo.type === "vendor" ? (
                <VendorHeader
                  shopName={user?.shopName}
                  id={userInfo?._id}
                  name={userInfo?.name}
                  logoutHandler={logoutHandler}
                />
              ) : null}

              {userInfo &&
              !userInfo.isAdmin &&
              userInfo.type === "wholesaler" ? (
                <WholesalerHeader
                  id={userInfo?._id}
                  shopName={userDetails?.shopName}
                  name={userInfo?.name}
                  logoutHandler={logoutHandler}
                />
              ) : null}
            </Navbar.Collapse>
          </div>
        </Navbar>
        <Navbar
          className="mainHeader py-2"
          variant="dark"
          expand="lg"
          collapseOnSelect
        >
          <div className="container">
            <LinkContainer to="/">
              <Navbar.Brand className="header__logo">
                <img
                  src="/images/logo.jpg"
                  alt="logo"
                  className="headerImage"
                />
                <span className="headerNavTitle"> Pinoy Can Buy</span>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <SearchBox url="" history={history} />
              {/* } /> */}
              <Nav style={{ marginLeft: "auto" }}>
                <Link to="/" className="HeaderIcons">
                  <i className="far fa-bell"></i>
                  <Badge bg="light" text="dark" className="headerBadge">
                    2
                  </Badge>
                </Link>

                <Link to={`/cart`} className="HeaderIcons">
                  <i className="fas fa-shopping-cart"></i>
                  <Badge bg="light" text="dark" className="headerBadge">
                    {items ? items : ""}
                  </Badge>
                </Link>
                <Link to="/mywishlist" className="HeaderIcons">
                  <i className="fas fa-heart"></i>
                  <Badge bg="light" text="dark" className="headerBadge">
                    {wishlistItems ? wishlistItems : ""}
                  </Badge>
                </Link>
                <div className="d-inline-block d-md-none HeaderIcons float-right">
                  {userInfo &&
                  !userInfo.isAdmin &&
                  userInfo.type !== "vendor" &&
                  userInfo.type !== "wholesaler" ? (
                    <CustomerHeader
                      history={history}
                      logoutHandler={logoutHandler}
                      name={userInfo.name}
                      id={userInfo?._id}
                    />
                  ) : (
                    <>{!userInfo && <NoUserHeader history={history} />}</>
                  )}

                  {userInfo && userInfo.isAdmin ? (
                    <AdminHeader
                      id={userInfo?._id}
                      name={userInfo?.name}
                      logoutHandler={logoutHandler}
                    />
                  ) : null}

                  {userInfo &&
                  !userInfo.isAdmin &&
                  userInfo.type === "vendor" ? (
                    <VendorHeader
                      shopName={user?.shopName}
                      id={userInfo?._id}
                      name={userInfo?.name}
                      logoutHandler={logoutHandler}
                    />
                  ) : null}

                  {userInfo &&
                  !userInfo.isAdmin &&
                  userInfo.type === "wholesaler" ? (
                    <WholesalerHeader
                      id={userInfo?._id}
                      shopName={userDetails?.shopName}
                      name={userInfo?.name}
                      logoutHandler={logoutHandler}
                    />
                  ) : null}
                </div>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </header>
    </div>
  );
};

export default Header;
