import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Button,
  Carousel,
  Toast,
  Alert,
} from "react-bootstrap";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import Meta from "../../components/Meta.js";
import { getWholesalerDetailsByShopName } from "../../actions/userActions";
import Select from "react-select";

import { listFollowers, listFollowings } from "../../actions/followActions";
import { listMyPublishedProductsWholesaler } from "../../actions/productActions.js";
import StoreProfilePage from "../../components/StoreProfilePage.js";
import Paginate from "../../components/Paginate.js";
import SearchBox from "../../components/SearchBox.js";
import "./StorePageScreen.css";
import { getVendorCategories } from "../../actions/categoryActions.js";

import FollowButton from "../../components/FollowButton.js";

const WholesaleStorepageScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const id = match.params.shopname;
  const vendorCategory = match.params.vendorCategory;

  const pageNumber = match.params.pageNumber || 1;
  const keyword = match.params.keyword || "";

  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);
  const [userAvail, setUserAvail] = useState(false);

  const wholesalerDetailsByShopName = useSelector(
    (state) => state.wholesalerDetailsByShopName
  );
  const { user } = wholesalerDetailsByShopName;

  const getFollowers = useSelector((state) => state.getFollowers);
  const { followers } = getFollowers;

  const vendorCategories = useSelector((state) => state.vendorCategories);
  const { categories } = vendorCategories;
  let option = [{ value: "", label: "All" }];

  categories?.map((c) => option.push({ value: c, label: c }));

  const productListMyPublished = useSelector(
    (state) => state.productListMyPublished
  );
  const { loading, error, products, pages, page } = productListMyPublished;

  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };
  useEffect(() => {
    dispatch(getWholesalerDetailsByShopName(id));
    dispatch(
      listMyPublishedProductsWholesaler(id, keyword, pageNumber, vendorCategory)
    );
  }, [dispatch, id, keyword, pageNumber, vendorCategory]);

  useEffect(() => {
    if (user) {
      setUserAvail(true);
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(getVendorCategories(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(listFollowings());
    dispatch(listFollowers(id));
  }, [dispatch, id]);
  var positiveRatingPercentage;
  if (user) {
    const totalRatings = user.reviews.length;
    const positiveRatings = user.reviews.filter((r) => r.rating > 2);
    positiveRatingPercentage = (positiveRatings.length / totalRatings) * 100;
  }
  return (
    <div>
      <Meta title={id && id} />
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}

      {userAvail ? (
        <>
          <Row className="headerContainer align-items-center justify-contect-center mt-3">
            <Col md={4}>
              <Row className="storeInfo align-items-center justify-contect-center">
                <Col md={4}>
                  <i className="fas fa-store fa-5x text-danger"></i>
                </Col>
                <Col md={8}>
                  <h3 className="mr-2">{id}</h3>
                  <div className="mt-3">
                    <Row>
                      <Col sm={5}>
                        <FollowButton id={id} type="wholesaler" />
                      </Col>
                      <Col sm={7}>
                        <Button type="button" className="followBtn">
                          <i className="far fa-comments"></i> Chat
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={8} className="VenderInfo px-3 py-2">
              <Row>
                <Col md={4} className="VenderInfo">
                  <div>
                    <i className="fas fa-users fa-lg"> </i> {followers?.length}{" "}
                    Followers
                  </div>
                </Col>
                <Col md={4} className="VenderInfo">
                  <div>
                    <i className="far fa-smile-beam fa-lg text-warning"></i>{" "}
                    {positiveRatingPercentage}
                    Positive Rating
                  </div>
                </Col>
                <Col md={4} className="VenderInfo">
                  <div>
                    <i className="fas fa-archive fa-lg"></i> {products?.length}{" "}
                    Products
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr></hr>
          <div className="mt-3">
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Home">
                <div className="container-fluid storeContainer">
                  <Row>
                    <Carousel>
                      {user?.homeBannerImages?.length === 0 && (
                        <Carousel.Item interval={5000}>
                          <img
                            className="carouselImage"
                            src="https://amazon-asin.com/blog/wp-content/uploads/2021/03/create-amazon-listing.jpg"
                            alt="Second slide"
                          />
                        </Carousel.Item>
                      )}

                      {user?.homeBannerImages?.map((image) => {
                        return (
                          <Carousel.Item key={image} interval={5000}>
                            <img
                              className="carouselImage"
                              src={image}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </Row>
                  <Row>
                    <Col md={3} className="mt-3 px-3 SearchInStore">
                      <Select
                        onChange={(e) => {
                          history.push(`/wholesaleshop/${id}/${e.value}`);
                        }}
                        options={option}
                      />
                    </Col>
                    <Col md={3}></Col>
                    <Col md={6} className="mt-3 px-3">
                      <SearchBox
                        placing="allProductsStore"
                        history={history}
                        url={`wholesaleshop/${id}`}
                        placeHolder="In Store"
                      />
                    </Col>
                  </Row>

                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <>
                      <Row className="mx-3">
                        <div className="mt-3 mr-5">
                          {products?.length === 0 ? (
                            <h3> NO PRODUCT FOUND </h3>
                          ) : (
                            <h3> ALL PRODUCTS </h3>
                          )}
                        </div>
                      </Row>
                      <Row className="mb-5 mx-3">
                        {products?.map((product) => {
                          return (
                            <Col key={product._id} sm={6} md={2}>
                              <Product
                                toastToggler={toastToggler}
                                key={product._id}
                                history={history}
                                product={product}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                  <Paginate
                    url={`/wholesaleshop/${id}`}
                    pages={pages}
                    page={page}
                    keyword={keyword ? keyword : ""}
                  />
                </div>
              </Tab>

              <Tab eventKey="profile" title="Profile">
                <StoreProfilePage
                  id={id}
                  seller={user && user}
                  type="wholesaler"
                />
              </Tab>
            </Tabs>
          </div>
        </>
      ) : (
        <h1 style={{ fontSize: "40px" }}>404 Not Found</h1>
      )}
    </div>
  );
};

export default WholesaleStorepageScreen;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Row, Col, Tab, Tabs } from "react-bootstrap";
// import Product from "../../components/Product.js";
// import Message from "../../components/Message.js";
// import Loader from "../../components/Loader.js";
// import Meta from "../../components/Meta.js";
// import { getWholesalerDetailsByShopName } from "../../actions/userActions";

// import {
//   followAShop,
//   listFollowers,
//   listFollowings,
//   unfollowAShop,
// } from "../../actions/followActions";
// import { listMyPublishedProductsWholesaler } from "../../actions/productActions.js";
// import StoreHomePage from "../../components/StoreHomePage.js";
// import StoreProfilePage from "../../components/StoreProfilePage.js";
// import Paginate from "../../components/Paginate.js";
// import SearchBox from "../../components/SearchBox.js";
// import Select from "react-select";

// import "../Store/StorePageScreen.css";
// import { getVendorCategories } from "../../actions/categoryActions.js";
// import {
//   FOLLOW_A_SHOP_RESET,
//   UNFOLLOW_A_SHOP_RESET,
// } from "../../constants/followConstants.js";
// import FollowButton from "../../components/FollowButton.js";

// const StorePageScreen = ({ match, history }) => {
//   const dispatch = useDispatch();
//   const id = match.params.shopname;
//   const vendorCategory = match.params.vendorcategory;
//   const pageNumber = match.params.pageNumber || 1;
//   const keyword = match.params.keyword || "";

//   const wholesalerDetailsByShopName = useSelector(
//     (state) => state.wholesalerDetailsByShopName
//   );
//   const { user } = wholesalerDetailsByShopName;
//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;
//   const followShop = useSelector((state) => state.followShop);
//   const { success: followSuccess } = followShop;
//   const unfollowShop = useSelector((state) => state.unfollowShop);
//   const { success: unfollowSuccess } = unfollowShop;

//   const getFollowings = useSelector((state) => state.getFollowings);
//   const { followings } = getFollowings;

//   const getFollowers = useSelector((state) => state.getFollowers);
//   const { followers } = getFollowers;

//   const vendorCategories = useSelector((state) => state.vendorCategories);
//   const { categories } = vendorCategories;
//   let option = [];
//   {
//     categories?.map((c) => {
//       option.push({ value: c, label: c });
//     });
//   }

//   const productListMyPublished = useSelector(
//     (state) => state.productListMyPublished
//   );
//   const { loading, error, products, pages, page } = productListMyPublished;

//   const handleFollowButton = () => {
//     // dispatch(unfollowAShop(id));
//     if (followed?.length !== 0) dispatch(unfollowAShop(id));
//     else dispatch(followAShop(id));
//     // window.location.reload();
//   };
//   const followed = followings?.filter((f) => f.shop === user?._id);

//   useEffect(() => {
//     dispatch(getWholesalerDetailsByShopName(id));
//     dispatch(
//       listMyPublishedProductsWholesaler(id, keyword, pageNumber, vendorCategory)
//     );
//   }, [dispatch, id, keyword, pageNumber, vendorCategory]);
//   useEffect(() => {
//     dispatch(getVendorCategories(id));
//   }, [dispatch, id]);
//   useEffect(() => {
//     if (followSuccess || unfollowSuccess) {
//       dispatch({ type: FOLLOW_A_SHOP_RESET });
//       dispatch({ type: UNFOLLOW_A_SHOP_RESET });
//     }
//     dispatch(listFollowings());
//     dispatch(listFollowers(id));
//   }, [dispatch, id, followSuccess, unfollowSuccess]);

//   return (
//     <>
//       <Meta title={id && id} />
//       <div className="headerContainer">
//         <div className="topContainer">
//           <p
//             style={{ color: "white", fontFamily: "cursive", fontSize: "20px" }}
//           >
//             {id}
//           </p>
//           <div className="topButtonContainer">
//             <FollowButton id={id} type="wholesaler" />
//             {/* <button
//               className="topButton"
//               variant="info"
//               onClick={handleFollowButton}
//             >
//               {followed?.length !== 0 ? "UnFollow" : "Follow"}
//             </button> */}
//             <button className="topButton" variant="info">
//               Chat
//             </button>
//           </div>
//         </div>
//         <div className="topMiddleContainer">
//           <p>Product:{products?.length}</p>
//           <p>Following:</p>
//           <p>Chat Performance:</p>
//         </div>
//         <div className="topEndContainer">
//           <p>Followers:{user?.followers?.length}</p>
//           <p>
//             Rating:
//             {user?.reviews?.reduce((acc, item) => item.rating + acc, 0) /
//               user?.reviews?.length}
//           </p>
//           <p>Joined:{new Date(user?.createdAt).toLocaleDateString()}</p>
//         </div>
//       </div>

//       <Tabs
//         defaultActiveKey="home"
//         id="uncontrolled-tab-example"
//         className="mb-3 storePageTabs"
//       >
//         <Tab eventKey="home" title="HomeScreen">
//           <StoreHomePage />
//           {loading ? (
//             <Loader />
//           ) : error ? (
//             <Message variant="danger">{error}</Message>
//           ) : (
//             <>
//               <h3
//                 style={{
//                   fontFamily: "Montserrat",
//                   fontSize: 36,
//                   color: "#DC1E3E",
//                   textAlign: "center",
//                   marginTop: 20,
//                 }}
//               >
//                 Our{" "}
//                 <span
//                   style={{
//                     fontFamily: "Montserrat",
//                     fontSize: 36,
//                     color: "#003893",
//                   }}
//                 >
//                   Product{" "}
//                 </span>
//               </h3>
//               <Row className="mb-5">
//                 {products?.map((product) => {
//                   return (
//                     <Col key={product._id} sm={6} md={3} xl={2}>
//                       <Product product={product} />
//                     </Col>
//                   );
//                 })}
//               </Row>
//             </>
//           )}
//         </Tab>
//         <Tab eventKey="products" title="All Products">
//           {loading ? (
//             <Loader />
//           ) : error ? (
//             <Message variant="danger">{error}</Message>
//           ) : (
//             <>
//               <div
//                 style={{
//                   width: "500px",
//                   display: "flex",
//                 }}
//               >
//                 <div
//                   style={{
//                     width: "250px",
//                   }}
//                 >
//                   <Select
//                     onChange={(e) => {
//                       history.push(`/wholesaleshop/${id}/${e.value}`);
//                     }}
//                     options={option}
//                   />
//                 </div>
//                 <SearchBox
//                   placing="allProductsStore"
//                   history={history}
//                   url={`wholesaleshop/${id}`}
//                   placeHolder="In Store"
//                 />
//               </div>
//               <Row>
//                 {products?.map((product) => {
//                   return (
//                     <Col key={product._id} sm={6} md={3} xl={2}>
//                       <Product product={product} />
//                     </Col>
//                   );
//                 })}
//               </Row>
//               <Paginate
//                 url={`/wholesaleshop/${id}`}
//                 pages={pages}
//                 page={page}
//                 keyword={keyword ? keyword : ""}
//               />
//             </>
//           )}
//         </Tab>

//         <Tab eventKey="profile" title="Profile">
//           <StoreProfilePage id={id} seller={user} type="wholesaler" />
//         </Tab>
//       </Tabs>
//     </>
//   );
// };

// export default StorePageScreen;
