import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Button, Form } from "react-bootstrap";
import Modal from "react-modal";

import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { getOrderDetails } from "../../actions/orderActions";
import { ORDER_CREATE_RESET } from "../../constants/orderConstants";
import { CART_REMOVE_CHECKED_FROM_SERVER_RESET } from "../../constants/cartConstants";
import {
  USER_DETAILS_ADDRESSBOOK_RESET,
  VENDOR_ORDER_ADDRESS_RESET,
  WHOLESELLER_ORDER_ADDRESS_RESET,
} from "../../constants/addressConstants";
import Axios from "axios";
import { createProductReview } from "../../actions/productActions";
import {
  createSellerReview,
  createWholesalerReview,
} from "../../actions/userActions";
import { SELLER_CREATE_REVIEW_RESET } from "../../constants/userConstants";
import { PRODUCT_CREATE_REVIEW_RESET } from "../../constants/productConstants";
import "./PlaceOrderScreen.css";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    background: "white",
    padding: "30px",
    boxShadow: "0 0 25px black",
  },
};

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const [images, setImages] = useState("");
  const [id, setId] = useState("");
  const [shopId, setShopId] = useState("");
  const [shopRating, setShopRating] = useState(null);
  const [shopComment, setShopComment] = useState("");
  const [shopImages, setShopImages] = useState("");
  const [type, setType] = useState("");
  const [validated, setValidated] = useState(false);

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { success } = orderCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const { error: errorProductReview, success: successProductReview } =
    productReviewCreate;

  const sellerReview = useSelector((state) => state.sellerReview);
  const { error: errorSellerReview, success: successSellerReview } =
    sellerReview;

  const cartItemsDelete = useSelector((state) => state.cartItemsDelete);
  const { success: allremoveSuccess } = cartItemsDelete;

  if (!loading) {
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.order1.itemsPrice = addDecimals(
      order?.order1?.orderItems?.reduce(
        (acc, item) => acc + item.price * item.qty,
        0
      )
    );
  }
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(e) {
    setIsOpen(false);
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      submitProductReview(e);
      submitShopReview(e);
    }
  }
  const submitProductReview = (e) => {
    e.preventDefault();

    dispatch(createProductReview(id, { rating, comment, images }));
  };
  const submitShopReview = (e) => {
    if (shopComment) {
      if (type === "vendor") {
        dispatch(
          createSellerReview(shopId, { shopRating, shopComment, shopImages })
        );
      } else if (type === "wholesaler") {
        dispatch(
          createWholesalerReview(shopId, {
            shopRating,
            shopComment,
            shopImages,
          })
        );
      }
    }
  };
  const uploadFilesHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const formData = new FormData();
      formData.append("file", e.target.files[i]);
      formData.append("upload_preset", "pinoy-can-group");

      Axios.post(
        "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
        formData
      ).then((res) => {
        setImages((prevState) => [...prevState, res.data.url]);
      });
    }
  };
  const uploadShopFilesHandler = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      const formData = new FormData();
      formData.append("file", e.target.files[i]);
      formData.append("upload_preset", "pinoy-can-group");

      Axios.post(
        "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
        formData
      ).then((res) => {
        setImages((prevState) => [...prevState, res.data.url]);
      });
    }
  };
  useEffect(() => {
    if (allremoveSuccess) {
      dispatch({ type: CART_REMOVE_CHECKED_FROM_SERVER_RESET });
    }
    if (!userInfo) {
      history.push("/login");
    }
    dispatch(getOrderDetails(orderId));
    if (success) {
      alert("Dear customer, your order has been created");
    }
    dispatch({ type: ORDER_CREATE_RESET });
    dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
    dispatch({ type: WHOLESELLER_ORDER_ADDRESS_RESET });
    dispatch({ type: USER_DETAILS_ADDRESSBOOK_RESET });
  }, [dispatch, orderId, history, userInfo, allremoveSuccess, success]);
  useEffect(() => {
    if (successProductReview) {
      alert("Review Submitted!");
      setRating(0);
      setComment("");
      setImages([]);
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
    }
    if (successSellerReview) {
      alert("Review Submitted!");
      setShopRating(0);
      setShopComment("");
      setShopImages([]);
      dispatch({ type: SELLER_CREATE_REVIEW_RESET });
    }
  }, [successProductReview, dispatch, successSellerReview]);

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Container>
      {errorProductReview && (
        <Message variant="danger">{errorProductReview}</Message>
      )}
      {errorProductReview && (
        <Message variant="danger">{errorSellerReview}</Message>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Review"
      >
        <Form
          className="productRatingBox"
          onSubmit={closeModal}
          noValidate
          validated={validated}
        >
          <Row>
            <Col md={6} className="productRatingBoxBorder py-2 px-2">
              <Form.Group className="productRatingFormGroup" controlId="rating">
                <Form.Label className="productRatingLabel mainLabel">
                  Product Rating
                </Form.Label>
                <Form.Control
                  className="productRatingInputField"
                  as="select"
                  value={rating}
                  onChange={(e) => setRating(e.target.value)}
                >
                  <option value="0">Select...</option>
                  <option value="1">1 - Poor</option>
                  <option value="2">2 - Fair</option>
                  <option value="3">3 - Good</option>
                  <option value="4">4 - Very Good</option>
                  <option value="5">5 - Excellent</option>
                </Form.Control>
              </Form.Group>
              <Form.Group
                className="productRatingFormGroup"
                controlId="comment"
              >
                <Form.Label className="productRatingLabel">Comment</Form.Label>
                <Form.Control
                  className="productRatingInputField"
                  as="textarea"
                  row="3"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group
                className="productRatingFormGroup"
                controlId="imageCollection"
              >
                <Form.Label className="productRatingLabel">
                  Image
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Control
                  className="productRatingInputField"
                  type="text"
                  placeholder="Enter image urls"
                  value={images}
                  onChange={(e) => setImages(e.target.value)}
                ></Form.Control>
                <Form.Label className="productRatingLabel float-right">
                  or
                </Form.Label>
                <input
                  className="mt-2"
                  id="image-files"
                  label="Choose Files"
                  type="file"
                  custom
                  multiple
                  onChange={uploadFilesHandler}
                ></input>
              </Form.Group>
            </Col>
            <Col md={6} className="py-2 px-2">
              <div>
                <Form.Group
                  className="productRatingFormGroup"
                  controlId="rating"
                >
                  <Form.Label className="productRatingLabel mainLabel">
                    Shop Rating
                  </Form.Label>
                  <Form.Control
                    className="productRatingInputField"
                    as="select"
                    value={shopRating}
                    onChange={(e) => setShopRating(e.target.value)}
                  >
                    <option value="0">Select...</option>
                    <option value="1">1 - Poor</option>
                    <option value="2">2 - Fair</option>
                    <option value="3">3 - Good</option>
                    <option value="4">4 - Very Good</option>
                    <option value="5">5 - Excellent</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  className="productRatingFormGroup"
                  controlId="comment"
                >
                  <Form.Label className="productRatingLabel">
                    Comment
                  </Form.Label>
                  <Form.Control
                    className="productRatingInputField"
                    as="textarea"
                    row="3"
                    value={shopComment}
                    onChange={(e) => setShopComment(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="imageCollection">
                  <Form.Label className="productRatingLabel">
                    Image
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Control
                    className="productRatingInputField"
                    type="text"
                    placeholder="Enter image urls"
                    value={shopImages}
                    onChange={(e) => setShopImages(e.target.value)}
                  ></Form.Control>
                  <Form.Label className="productRatingLabel float-right">
                    or
                  </Form.Label>

                  <input
                    className="mt-2"
                    id="image-files"
                    label="Choose Files"
                    type="file"
                    custom
                    multiple
                    onChange={uploadShopFilesHandler}
                  ></input>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Button
            className="productRatingSubmitBtn"
            type="submit"
            variant="primary"
          >
            Submit
          </Button>
        </Form>
      </Modal>
      <Button
        className="bg-white text-secondary p-0 mt-2"
        onClick={() => {
          history.push("/myorders");
        }}
      >
        <i
          className="fas fa-angle-left p-2 mr-1"
          style={{ border: "1px solid whitesmoke" }}
        ></i>
        Go Back
      </Button>
      <h4 className="placeorder-heading mt-4">Order Details</h4>
      <Row className="mt-5  mb-2 placeorder-row py-4">
        <Col md={10}>
          <Row className="mx-3">
            <Col md={1}>
              <h6 className="placeorder-order-summary">Order</h6>
            </Col>
            <Col md={6} style={{ color: "#25a3d9" }}>
              #{order?.order1?.identifier}
            </Col>
            <Col md={12}>{order?.order1?.createdAt?.substring(0, 10)}</Col>
          </Row>
        </Col>
        <Col md={2}>
          <b style={{ fontWeight: 800 }}>Total : </b>₱
          <b style={{ color: "#FF0000" }}>{order?.order1?.totalPrice}</b>
        </Col>
      </Row>
      <Row className="mt-5 placeorder-row py-4 mb-4">
        <Row className="mb-5">
          <Col md={6}>
            <Row className="mx-5 mt-4">
              <h5 className="placeorder-address-heading mx-5">
                Shipping Address
              </h5>
              <Col md={12} className="mt-1">
                <i className="fas fa-user me-3 placeorder-fa"></i>
                {order?.order1?.user?.name}
              </Col>
              <Col md={12} className="mt-1">
                <i className="fas fa-envelope me-3 placeorder-fa"></i>
                {order?.order1?.user?.email}
              </Col>
              <Col md={12} className="mt-1">
                <i className="fas fa-map-marker-alt me-3 placeorder-fa"></i>
                {order?.order1?.address?.shipping_addressLine1},{" "}
                {order?.order1?.address?.shipping_province}{" "}
                {order.order1?.address?.shipping_district},{" "}
                {order?.order1?.address?.shipping_country}
              </Col>
              <Col md={12} className="my-1">
                <i className="fas fa-phone-alt me-3 placeorder-fa"></i>
                {order?.order1?.user?.phone}
              </Col>
            </Row>
          </Col>
          <Col md={6}></Col>
        </Row>

        <hr />
        <Row className="my-4">
          <h5 className="placeorder-address-heading mx-5 mb-3">Order Items</h5>
          {order?.order1?.orderItems?.length === 0 ? (
            <Message>Order is Empty</Message>
          ) : (
            <>
              {order?.vendorOrder?.length !== 0 && (
                <Col md={12}>
                  {order?.vendorOrder.map((vendor, index) => {
                    return (
                      <Row className="ms-5 border">
                        <Row className="my-2">{vendor.shopId?.shopName}</Row>
                        <hr />
                        <>
                          {vendor.orderItems?.map((item, index) => (
                            <Row className="my-2">
                              <Col md={1}>
                                <Image
                                  src={item.image}
                                  alt={item.name}
                                  fluid
                                  rounded
                                />
                              </Col>
                              <Col md={5} className="ml-3">
                                <Link
                                  to={`/product/${item.name}/${item.product}`}
                                >
                                  {item.name}
                                </Link>
                              </Col>
                              <Col md={1}>₱ {item.price}</Col>
                              <Col md={1}>Qty : {item.qty}</Col>
                              <Col md={2}>
                                ₱ {(item.qty * item.price).toFixed(2)}
                              </Col>
                              <Col md={2}>
                                {vendor.delivery.isDelivered ===
                                  "Delivered" && (
                                  <Button
                                    onClick={() => {
                                      setId(item.product);
                                      setType("vendor");
                                      setShopId(vendor.shopId._id);
                                      openModal();
                                    }}
                                  >
                                    Review
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </>
                        <hr />
                        <Row className="mb-2">
                          <Col>
                            Delivery Status:{vendor.delivery.isDelivered}
                          </Col>
                          <Col>
                            Payment Status:
                            {vendor.isPaid ? "Paid" : "Not Paid"}
                          </Col>
                        </Row>
                        {vendor.delivery.isDelivered === "Cancelled" && (
                          <Row style={{ color: "red" }}>
                            Cancelled because {vendor.delivery.cancelMessage}
                          </Row>
                        )}
                      </Row>
                    );
                  })}
                </Col>
              )}
              {order?.wholesaler.length !== 0 && (
                <Col md={12}>
                  {order?.wholesaler?.map((wholesale, index) => (
                    <Row className="ms-5" key={index}>
                      <Row>{wholesale?.shopId?.shopName}</Row>
                      <>
                        {wholesale.orderItems?.map((item, index) => (
                          <Row key={index}>
                            <Col md={1}>
                              <Image
                                src={item.image}
                                alt={item.name}
                                fluid
                                rounded
                              />
                            </Col>
                            <Col md={5} className="ml-3">
                              <Link
                                to={`/product/${item.name}/${item.product}`}
                              >
                                {item.name}
                              </Link>
                            </Col>
                            <Col md={1}>₱ {item.price}</Col>
                            <Col md={1}>Qty : {item.qty}</Col>
                            <Col md={2}>
                              ₱ {(item.qty * item.price).toFixed(2)}
                            </Col>
                            <Col md={2}>
                              {wholesale.delivery.isDelivered ===
                                "Delivered" && (
                                <Button
                                  onClick={() => {
                                    setId(item.product);
                                    setType("wholesaler");
                                    setShopId(wholesale.shopId._id);
                                    openModal();
                                  }}
                                >
                                  Review
                                </Button>
                              )}
                            </Col>
                          </Row>
                        ))}
                      </>
                      <Row>
                        <Col>
                          Delivery Status:{wholesale.delivery.isDelivered}
                        </Col>
                        <Col>
                          Payment Status:
                          {wholesale.isPaid ? "Paid" : "Not Paid"}
                        </Col>
                      </Row>
                      {wholesale.delivery.isDelivered === "Cancelled" && (
                        <Row>
                          Cancelled because {wholesale.delivery.cancelMessage}
                        </Row>
                      )}
                    </Row>
                  ))}
                </Col>
              )}
            </>
          )}
        </Row>
        <hr />
        <Row className="my-4">
          <Col md={6}>
            <h5 className="placeorder-address-heading mx-5">Payment Method</h5>
            <Row>
              <Col md={2}>
                <h6 className="placeorder-address-heading mt-3 mx-5">
                  Method:
                </h6>
              </Col>
              <Col md={9}>
                <h6 className="mt-4 mx-5"> {order?.order1?.paymentMethod}</h6>
              </Col>
            </Row>
            {/* <Link to="/payment">Edit</Link> */}
          </Col>
          <Col md={6}>
            <h5 className="placeorder-address-heading mx-5">Order Summary</h5>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Items</h6>
              </Col>
              <Col>₱ {order?.order1?.itemsPrice}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Shipping</h6>
              </Col>
              <Col>₱ {order?.order1?.shippingPrice}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Tax</h6>
              </Col>
              <Col>₱ {order?.order1?.taxPrice}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Platform Coins</h6>
              </Col>
              <Col>₱ {order?.order1?.platformCoins || 0}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Platform Voucher</h6>
              </Col>
              <Col>₱ {order?.order1?.platformVoucher || 0}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Seller Voucher</h6>
              </Col>
              <Col>₱ {order?.order1?.sellerVoucher || 0}</Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Total</h6>
              </Col>
              <Col style={{ color: "#FF0000" }}>
                ₱ {order?.order1?.totalPrice}
              </Col>
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default OrderScreen;
