import { combineReducers, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productListMyReducer,
  categoryWiseProductListReducer,
  productPublishReducer,
  allProductListReducer,
  wholesaleProductListReducer,
  wholesaleProductTopRatedReducer,
  productListMyPublishedReducer,
  wholesaleProductOnlyListReducer,
  wishlistListReducer,
  wishlistDeleteReducer,
  wishlistCreateReducer,
  newProductListReducer,
  recommendedProductListReducer,
  mostPopularProductListReducer,
  bestOffersProductListReducer,
  productUpdateFAQReducer,
  searchVendorProductsListReducer,
  searchWholesalerProductsListReducer,
} from "./reducers/productReducers";
import {
  cartItemAddReducer,
  cartItemDeleteReducer,
  cartItemEditCheckedReducer,
  cartItemsDeleteReducer,
  cartReducer,
  getCartItemsReducer,
  getCheckedCartItemsReducer,
} from "./reducers/cartReducers";
import {
  customerRegisterReducer,
  followShopReducer,
  sellerReviewCreateReducer,
  sellerStatusDisapproveReducer,
  sellerStatusUpdateReducer,
  unfollowShopReducer,
  userDeleteReducer,
  userDetailsReducer,
  userListReducer,
  userLoginReducer,
  userRegisterReducer,
  userSpecificDetailsReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
  vendorAddressDeactivationReducer,
  vendorDetailsByShopNameReducer,
  vendorDetailsReducer,
  vendorListReducer,
  vendorNoUserRegisterReducer,
  vendorRegisterReducer,
  vendorShopListReducer,
  vendorUpdateDetailsReducer,
  wholesalerAddressDeactivationReducer,
  wholesalerDetailsByShopNameReducer,
  wholesalerDetailsReducer,
  wholesalerListReducer,
  wholesalerNoUserRegisterReducer,
  wholesalerRegisterReducer,
  wholesalerShopListReducer,
  wholesalerUpdateDetailsReducer,
} from "./reducers/userReducers";
import {
  addOrderItemReducer,
  deliveryLabelDetailsReducer,
  invoiceInformationReducer,
  orderCreateReducer,
  orderCreateVendorReducer,
  orderCreateWholesalerReducer,
  orderDeleteReducer,
  orderDeliverReducer,
  orderDetailsReducer,
  orderEditVendorReducer,
  orderEditWholesalerReducer,
  orderItemDeleteReducer,
  orderListMyReducer,
  orderListReducer,
  orderPayReducer,
  orderVendorDeliverReducer,
  orderVendorDetailsReducer,
  orderVendorPayReducer,
  orderWholesalerDeliverReducer,
  orderWholesalerDetailsReducer,
  orderWholesalerPayReducer,
  vendorOrderListReducer,
  wholesalerOrderListReducer,
} from "./reducers/orderReducers";
import {
  categoryCreateReducer,
  categoryDeleteReducer,
  categoryDetailsReducer,
  categoryHeadingsListReducer,
  categoryListReducer,
  categoryUpdateReducer,
  subCategoriesReducer,
  vendorCategoriesReducer,
} from "./reducers/categoryReducers";
import {
  displayPromotionListReducer,
  displayWholesalerPromotionListReducer,
  promotionCreateReducer,
  promotionDeleteReducer,
  promotionDetailsReducer,
  promotionJoinReducer,
  promotionListReducer,
  promotionUpdateReducer,
} from "./reducers/promotionReducers";
import {
  staffCreateReducer,
  staffDeleteReducer,
  staffDetailsByStaffIdReducer,
  staffDetailsReducer,
  staffListReducer,
  staffUpdateReducer,
} from "./reducers/staffReducers";
import {
  getFollowersReducer,
  getFollowingProductsReducer,
  getFollowingsReducer,
} from "./reducers/followReducers";
import {
  addressCreateReducer,
  addressListReducer,
  addressUpdateReducer,
  addressDetailsReducer,
  addressOrderDetailsReducer,
  addressVendorOrderReducer,
  addressVendorOrderGetReducer,
  addressWholesalerOrderReducer,
  addressWholesalerOrderGetReducer,
} from "./reducers/addressReducers";
import {
  blogCreateReducer,
  blogListReducer,
  blogUpdateReducer,
  blogDetailsReducer,
  blogListFeaturedReducer,
  blogListNewReducer,
  blogListUnpublishedReducer,
} from "./reducers/blogReducer";

import { dashboardCardReducer } from "./reducers/dashboardReducer";
const reducer = combineReducers({
  productList: productListReducer,
  productListMy: productListMyReducer,
  productDetails: productDetailsReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  productPublish: productPublishReducer,
  productListMyPublished: productListMyPublishedReducer,
  searchVendorProductsList: searchVendorProductsListReducer,
  searchWholesalerProductsList: searchWholesalerProductsListReducer,

  orderCreateWholesaler: orderCreateWholesalerReducer,
  wholesaleProductList: wholesaleProductListReducer,
  wholesalerOrderList: wholesalerOrderListReducer,
  wholesalerOrderDeliver: orderWholesalerDeliverReducer,
  orderWholesalerDetails: orderWholesalerDetailsReducer,
  wholesaleProductTopRated: wholesaleProductTopRatedReducer,
  wholesalerRegister: wholesalerRegisterReducer,
  wholesalerNoUserRegister: wholesalerNoUserRegisterReducer,

  wholesalerDetails: wholesalerDetailsReducer,
  orderWholesalerPay: orderWholesalerPayReducer,
  wholesaleProductOnly: wholesaleProductOnlyListReducer,

  categoryWiseProductList: categoryWiseProductListReducer,
  categoryList: categoryListReducer,
  categoryCreate: categoryCreateReducer,
  cateoryDelete: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryHeadingsList: categoryHeadingsListReducer,
  categoryDetails: categoryDetailsReducer,
  vendorCategories: vendorCategoriesReducer,

  cart: cartReducer,
  getCartItems: getCartItemsReducer,
  cartItemDelete: cartItemDeleteReducer,
  cartItemAdd: cartItemAddReducer,
  getCheckedCartItems: getCheckedCartItemsReducer,
  cartItemsDelete: cartItemsDeleteReducer,
  cartItemEditChecked: cartItemEditCheckedReducer,

  customerRegister: customerRegisterReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userSpecificDetails: userSpecificDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  sellerReview: sellerReviewCreateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  productDelete: productDeleteReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,
  orderCreateVendor: orderCreateVendorReducer,
  orderVendorDetails: orderVendorDetailsReducer,
  orderEditWholesaler: orderEditWholesalerReducer,
  orderEditVendor: orderEditVendorReducer,
  orderDelete: orderDeleteReducer,
  orderItemDelete: orderItemDeleteReducer,
  addOrderItem: addOrderItemReducer,

  addressCreate: addressCreateReducer,
  addressBook: addressListReducer,
  addressUpdate: addressUpdateReducer,
  addressDetails: addressDetailsReducer,
  addressOrderDetails: addressOrderDetailsReducer,

  vendorOrderDeliver: orderVendorDeliverReducer,
  vendorOrderList: vendorOrderListReducer,
  vendorRegister: vendorRegisterReducer,
  vendorNoUserRegister: vendorNoUserRegisterReducer,
  vendorDetails: vendorDetailsReducer,
  orderVendorPay: orderVendorPayReducer,
  addressVendorOrder: addressVendorOrderReducer,
  addressVendorOrderGet: addressVendorOrderGetReducer,
  addressWholesalerOrder: addressWholesalerOrderReducer,
  addressWholesalerOrderGet: addressWholesalerOrderGetReducer,

  wishlistList: wishlistListReducer,
  wishlistDelete: wishlistDeleteReducer,
  wishlistCreate: wishlistCreateReducer,

  allProductList: allProductListReducer,
  wholesalerUpdateDetails: wholesalerUpdateDetailsReducer,
  vendorUpdateDetails: vendorUpdateDetailsReducer,
  vendorList: vendorListReducer,
  wholesalerList: wholesalerListReducer,
  wholesalerDetailsByShopName: wholesalerDetailsByShopNameReducer,
  vendorDetailsByShopName: vendorDetailsByShopNameReducer,

  sellerStatusUpdate: sellerStatusUpdateReducer,
  sellerStatusDisapprove: sellerStatusDisapproveReducer,

  newProductList: newProductListReducer,
  recommendedProductList: recommendedProductListReducer,
  mostPopularProductList: mostPopularProductListReducer,
  bestOffersProductList: bestOffersProductListReducer,

  promotionCreate: promotionCreateReducer,
  deletePromotion: promotionDeleteReducer,
  promotionUpdate: promotionUpdateReducer,
  promotionDetails: promotionDetailsReducer,
  promotionList: promotionListReducer,
  promotionJoin: promotionJoinReducer,
  displayWholesalerPromotionList: displayWholesalerPromotionListReducer,
  displayPromotionList: displayPromotionListReducer,

  staffCreate: staffCreateReducer,
  deleteStaff: staffDeleteReducer,
  staffUpdate: staffUpdateReducer,
  staffDetails: staffDetailsReducer,
  staffList: staffListReducer,
  staffDetailsByStaffId: staffDetailsByStaffIdReducer,

  productUpdateFAQ: productUpdateFAQReducer,
  followShop: followShopReducer,
  unfollowShop: unfollowShopReducer,
  getFollowings: getFollowingsReducer,
  getFollowingProduct: getFollowingProductsReducer,
  getFollowers: getFollowersReducer,
  invoiceInformation: invoiceInformationReducer,
  vendorShopList: vendorShopListReducer,
  wholesalerShopList: wholesalerShopListReducer,
  deliveryLabelDetails: deliveryLabelDetailsReducer,

  dashboardCard: dashboardCardReducer,
  subCategories: subCategoriesReducer,

  vendorAddressDeactivation: vendorAddressDeactivationReducer,
  wholesalerAddressDeactivation: wholesalerAddressDeactivationReducer,

  blogCreate: blogCreateReducer,
  blogList: blogListReducer,
  blogUpdate: blogUpdateReducer,
  blogDetails: blogDetailsReducer,
  blogListFeatured: blogListFeaturedReducer,
  blogListNew: blogListNewReducer,
  blogListUnpublished: blogListUnpublishedReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};
const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};
const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
