import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import { NavDropdown } from "react-bootstrap";

const AdminHeader = ({ name, logoutHandler }) => {
  return (
    <NavDropdown title={name} id="adminmenu" className="navTopHeaderDropdown">
      <LinkContainer to="/admin/userlist">
        <NavDropdown.Item>Users</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/admin/productlist">
        <NavDropdown.Item>Products</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/admin/promotions">
        <NavDropdown.Item>Promotions</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/admin/orderlist">
        <NavDropdown.Item>Orders</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/profile">
        <NavDropdown.Item>Profile</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/create/category/page/1">
        <NavDropdown.Item>Category</NavDropdown.Item>
      </LinkContainer>
      <LinkContainer to="/blog-list">
        <NavDropdown.Item>Blog</NavDropdown.Item>
      </LinkContainer>
      <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
    </NavDropdown>
  );
};

export default AdminHeader;

// import React from "react";
// import { LinkContainer } from "react-router-bootstrap";

// import { NavDropdown } from "react-bootstrap";

// const AdminHeader = ({ name, logoutHandler }) => {
//   return (
//     <NavDropdown title={name} id="adminmenu">
//       <LinkContainer to="/admin/userlist">
//         <NavDropdown.Item>Users</NavDropdown.Item>
//       </LinkContainer>
//       <LinkContainer to="/admin/productlist">
//         <NavDropdown.Item>Products</NavDropdown.Item>
//       </LinkContainer>
//       <LinkContainer to="/admin/promotions">
//         <NavDropdown.Item>Promotions</NavDropdown.Item>
//       </LinkContainer>

//       <LinkContainer to="/admin/orderlist">
//         <NavDropdown.Item>Orders</NavDropdown.Item>
//       </LinkContainer>
//       <LinkContainer to="/profile">
//         <NavDropdown.Item>User Profile</NavDropdown.Item>
//       </LinkContainer>
//       <LinkContainer to="/create/category/page/1">
//         <NavDropdown.Item>Category</NavDropdown.Item>
//       </LinkContainer>
//       <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
//     </NavDropdown>
//   );
// };

// export default AdminHeader;
