import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Product.css";

const Rating = ({ value, minOrderQuantity, color, size, uploaderType }) => {
  return (
    <div className="rating">
      <Row>
        {uploaderType === "wholesaler" ? (
          <Col className="cl-sm-7">
            {minOrderQuantity && (
              <p className="minQty">
                Min. Qty <span> ({minOrderQuantity}) </span>
              </p>
            )}
          </Col>
        ) : (
          <Col className="col-sm-7 cardRating">
            <span>
              <i
                style={{ color, fontSize: size }}
                className={
                  value >= 1
                    ? "fas fa-star"
                    : value >= 0.5
                    ? "fas fa-star-half-alt"
                    : "far fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                style={{ color, fontSize: size }}
                className={
                  value >= 2
                    ? "fas fa-star"
                    : value >= 1.5
                    ? "fas fa-star-half-alt"
                    : "far fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                style={{ color, fontSize: size }}
                className={
                  value >= 3
                    ? "fas fa-star"
                    : value >= 2.5
                    ? "fas fa-star-half-alt"
                    : "far fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                style={{ color, fontSize: size }}
                className={
                  value >= 4
                    ? "fas fa-star"
                    : value >= 3.5
                    ? "fas fa-star-half-alt"
                    : "far fa-star"
                }
              ></i>
            </span>
            <span>
              <i
                style={{ color, fontSize: size }}
                className={
                  value >= 5
                    ? "fas fa-star"
                    : value >= 4.5
                    ? "fas fa-star-half-alt"
                    : "far fa-star"
                }
              ></i>
            </span>
            <span>{value}</span>
          </Col>
        )}
        <Col className="col-sm-5 text-end cardSoldNumber">
          <span className="uploaderType">
            {uploaderType === "wholesaler" && "W"}
          </span>
        </Col>
      </Row>
    </div>
  );
};

Rating.defaultProps = {
  color: "#FFBF00",
  size: "10px",
};

export default Rating;

// import React from "react";
// import { Row, Col } from "react-bootstrap";
// import "./Product.css";

// const Rating = ({ value, text, color, size }) => {
//   return (
//     <div className="rating">
//       <Row>
//         <Col className="col-sm-7 cardRating">
//           <span>
//             <i
//               style={{ color, fontSize: size }}
//               className={
//                 value >= 1
//                   ? "fas fa-star"
//                   : value >= 0.5
//                   ? "fas fa-star-half-alt"
//                   : "far fa-star"
//               }
//             ></i>
//           </span>
//           <span>
//             <i
//               style={{ color, fontSize: size }}
//               className={
//                 value >= 2
//                   ? "fas fa-star"
//                   : value >= 1.5
//                   ? "fas fa-star-half-alt"
//                   : "far fa-star"
//               }
//             ></i>
//           </span>
//           <span>
//             <i
//               style={{ color, fontSize: size }}
//               className={
//                 value >= 3
//                   ? "fas fa-star"
//                   : value >= 2.5
//                   ? "fas fa-star-half-alt"
//                   : "far fa-star"
//               }
//             ></i>
//           </span>
//           <span>
//             <i
//               style={{ color, fontSize: size }}
//               className={
//                 value >= 4
//                   ? "fas fa-star"
//                   : value >= 3.5
//                   ? "fas fa-star-half-alt"
//                   : "far fa-star"
//               }
//             ></i>
//           </span>
//           <span>
//             <i
//               style={{ color, fontSize: size }}
//               className={
//                 value >= 5
//                   ? "fas fa-star"
//                   : value >= 4.5
//                   ? "fas fa-star-half-alt"
//                   : "far fa-star"
//               }
//             ></i>
//           </span>
//         </Col>
//         <Col className="col-sm-5 text-end cardSoldNumber">
//           <span>{text && text}Sold</span>
//         </Col>
//       </Row>
//     </div>
//   );
// };

// Rating.defaultProps = {
//   color: "#FFBF00",
//   size: "10px",
// };

// export default Rating;
