export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const SEARCH_VENDOR_PRODUCT_LIST_REQUEST =
  "SEARCH_VENDOR_PRODUCT_LIST_REQUEST";
export const SEARCH_VENDOR_PRODUCT_LIST_SUCCESS =
  "SEARCH_VENDOR_PRODUCT_LIST_SUCCESS";
export const SEARCH_VENDOR_PRODUCT_LIST_FAIL =
  "SEARCH_VENDOR_PRODUCT_LIST_FAIL";

export const SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST =
  "SEARCH_WHOLESALER_PRODUCT_LIST_REQUEST";
export const SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS =
  "SEARCH_WHOLESALER_PRODUCT_LIST_SUCCESS";
export const SEARCH_WHOLESALER_PRODUCT_LIST_FAIL =
  "SEARCH_WHOLESALER_PRODUCT_LIST_FAIL";

export const PRODUCT_LIST_ADMIN_REQUEST = "PRODUCT_LIST_ADMIN_REQUEST";
export const PRODUCT_LIST_ADMIN_SUCCESS = "PRODUCT_LIST_ADMIN_SUCCESS";
export const PRODUCT_LIST_ADMIN_FAIL = "PRODUCT_LIST_ADMIN_FAIL";

export const PRODUCT_WHOLESALEONLY_LIST_REQUEST =
  "PRODUCT_WHOLESALEONLY_LIST_REQUEST";
export const PRODUCT_WHOLESALEONLY_LIST_SUCCESS =
  "PRODUCT_WHOLESALEONLY_LIST_SUCCESS";
export const PRODUCT_WHOLESALEONLY_LIST_FAIL =
  "PRODUCT_WHOLESALEONLY_LIST_FAIL";

export const PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST =
  "PRODUCT_CATEGORYWISE_PRODUCTLIST_REQUEST";
export const PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS =
  "PRODUCT_CATEGORYWISE_PRODUCTLIST_SUCCESS";
export const PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL =
  "PRODUCT_CATEGORYWISE_PRODUCTLIST_FAIL";

export const PRODUCT_LIST_MY_REQUEST = "PRODUCT_LIST_MY_REQUEST";
export const PRODUCT_LIST_MY_SUCCESS = "PRODUCT_LIST_MY_SUCCESS";
export const PRODUCT_LIST_MY_FAIL = "PRODUCT_LIST_MY_FAIL";

export const PRODUCT_LIST_MY_PUBLISHED_REQUEST =
  "PRODUCT_LIST_MY_PUBLISHED_REQUEST";
export const PRODUCT_LIST_MY_PUBLISHED_SUCCESS =
  "PRODUCT_LIST_MY_PUBLISHED_SUCCESS";
export const PRODUCT_LIST_MY_PUBLISHED_FAIL = "PRODUCT_LIST_MY_PUBLISHED_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_PUBLISH_REQUEST = "PRODUCT_PUBLISH_REQUEST";
export const PRODUCT_PUBLISH_SUCCESS = "PRODUCT_PUBLISH_SUCCESS";
export const PRODUCT_PUBLISH_FAIL = "PRODUCT_PUBLISH_FAIL";
export const PRODUCT_PUBLISH_RESET = "PRODUCT_PUBLISH_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_TOP_REQUEST = "PRODUCT_TOP_REQUEST";
export const PRODUCT_TOP_SUCCESS = "PRODUCT_TOP_SUCCESS";
export const PRODUCT_TOP_FAIL = "PRODUCT_TOP_FAIL";

export const PRODUCT_WISHLIST_REQUEST = "PRODUCT_WISHLIST_REQUEST";
export const PRODUCT_WISHLIST_SUCCESS = "PRODUCT_WISHLIST_SUCCESS";
export const PRODUCT_WISHLIST_FAIL = "PRODUCT_WISHLIST_FAIL";
export const PRODUCT_WISHLIST_RESET = "PRODUCT_WISHLIST_RESET";

export const CREATE_WISHLIST_REQUEST = "CREATE_WISHLIST_REQUEST";
export const CREATE_WISHLIST_SUCCESS = "CREATE_WISHLIST_SUCCESS";
export const CREATE_WISHLIST_FAIL = "CREATE_WISHLIST_FAIL";
export const CREATE_WISHLIST_RESET = "CREATE_WISHLIST_RESET";

export const REMOVE_WISHLIST_REQUEST = "REMOVE_WISHLIST_REQUEST";
export const REMOVE_WISHLIST_SUCCESS = "REMOVE_WISHLIST_SUCCESS";
export const REMOVE_WISHLIST_FAIL = "REMOVE_WISHLIST_FAIL";
export const REMOVE_WISHLIST_RESET = "REMOVE_WISHLIST_RESET";

export const PRODUCT_TOP_WHOLESALE_REQUEST = "PRODUCT_TOP_WHOLESALE_REQUEST";
export const PRODUCT_TOP_WHOLESALE_SUCCESS = "PRODUCT_TOP_WHOLESALE_SUCCESS";
export const PRODUCT_TOP_WHOLESALE_FAIL = "PRODUCT_TOP_WHOLESALE_FAIL";

export const NEW_PRODUCT_LIST_REQUEST = "NEW_PRODUCT_LIST_REQUEST";
export const NEW_PRODUCT_LIST_SUCCESS = "NEW_PRODUCT_LIST_SUCCESS";
export const NEW_PRODUCT_LIST_FAIL = "NEW_PRODUCT_LIST_FAIL";

export const RECOMMENDED_PRODUCT_LIST_REQUEST =
  "RECOMMENDED_PRODUCT_LIST_REQUEST";
export const RECOMMENDED_PRODUCT_LIST_SUCCESS =
  "RECOMMENDED_PRODUCT_LIST_SUCCESS";
export const RECOMMENDED_PRODUCT_LIST_FAIL = "RECOMMENDED_PRODUCT_LIST_FAIL";

export const BEST_OFFERS_PRODUCT_LIST_REQUEST =
  "BEST_OFFERS_PRODUCT_LIST_REQUEST";
export const BEST_OFFERS_PRODUCT_LIST_SUCCESS =
  "BEST_OFFERS_PRODUCT_LIST_SUCCESS";
export const BEST_OFFERS_PRODUCT_LIST_FAIL = "BEST_OFFERS_PRODUCT_LIST_FAIL";

export const MOST_POPULAR_PRODUCT_LIST_REQUEST =
  "MOST_POPULAR_PRODUCT_LIST_REQUEST";
export const MOST_POPULAR_PRODUCT_LIST_SUCCESS =
  "MOST_POPULAR_PRODUCT_LIST_SUCCESS";
export const MOST_POPULAR_PRODUCT_LIST_FAIL = "MOST_POPULAR_PRODUCT_LIST_FAIL";

export const NEW_WHOLESALE_PRODUCT_LIST_REQUEST =
  "NEW_WHOLESALE_PRODUCT_LIST_REQUEST";
export const NEW_WHOLESALE_PRODUCT_LIST_SUCCESS =
  "NEW_WHOLESALE_PRODUCT_LIST_SUCCESS";
export const NEW_WHOLESALE_PRODUCT_LIST_FAIL =
  "NEW_WHOLESALE_PRODUCT_LIST_FAIL";

export const RECOMMENDED_WHOLESALE_PRODUCT_LIST_REQUEST =
  "RECOMMENDED_WHOLESALE_PRODUCT_LIST_REQUEST";
export const RECOMMENDED_WHOLESALE_PRODUCT_LIST_SUCCESS =
  "RECOMMENDED_WHOLESALE_PRODUCT_LIST_SUCCESS";
export const RECOMMENDED_WHOLESALE_PRODUCT_LIST_FAIL =
  "RECOMMENDED_WHOLESALE_PRODUCT_LIST_FAIL";

export const BEST_OFFERS_WHOLESALE_PRODUCT_LIST_REQUEST =
  "BEST_OFFERS_WHOLESALE_PRODUCT_LIST_REQUEST";
export const BEST_OFFERS_WHOLESALE_PRODUCT_LIST_SUCCESS =
  "BEST_OFFERS_WHOLESALE_PRODUCT_LIST_SUCCESS";
export const BEST_OFFERS_WHOLESALE_PRODUCT_LIST_FAIL =
  "BEST_OFFERS_WHOLESALE_PRODUCT_LIST_FAIL";

export const MOST_POPULAR_WHOLESALE_PRODUCT_LIST_REQUEST =
  "MOST_POPULAR_WHOLESALE_PRODUCT_LIST_REQUEST";
export const MOST_POPULAR_WHOLESALE_PRODUCT_LIST_SUCCESS =
  "MOST_POPULAR_WHOLESALE_PRODUCT_LIST_SUCCESS";
export const MOST_POPULAR_WHOLESALE_PRODUCT_LIST_FAIL =
  "MOST_POPULAR_WHOLESALE_PRODUCT_LIST_FAIL";

export const ADD_PRODUCT_FAQ_REQUEST = "ADD_PRODUCT_FAQ_REQUEST";
export const ADD_PRODUCT_FAQ_SUCCESS = "ADD_PRODUCT_FAQ_SUCCESS";
export const ADD_PRODUCT_FAQ_FAIL = "ADD_PRODUCT_FAQ_FAIL";
export const ADD_PRODUCT_FAQ_RESET = "ADD_PRODUCT_FAQ_RESET";
