import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Message from "../../components/Message";
import { Form, Row, Col, Button, Container } from "react-bootstrap";
import queryString from "query-string";
import FormContainer from "../../components/FormContainer";

const PasswordResetScreen = ({ history }) => {
  const { search } = useLocation();
  const { id } = queryString.parse(search);

  const [message, setMessage] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validated, setValidated] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (newPassword !== confirmPassword) {
        setMessage("Passwords do not Match");
      } else {
        const message = await axios.post("api/users/resetPassword", {
          password: newPassword,
          id,
        });
        if (message.status === 200) {
          history.push("/login?passwordChanged=successfull");
        } else {
          setMessage("Oops!! Something went wrong");
        }
      }
    }
  };

  return (
    <FormContainer>
      <Container className="mt-5 mb-5">
        <Row className="h-100">
          <Col className="align-items-center registerForm text-center">
            {message && <Message variant="danger">{message}</Message>}

            <Col>
              <h1 className="registerHeader mt-5 mb-5">Change Password</h1>
            </Col>
            <Form noValidate validated={validated} onSubmit={submitHandler}>
              <Form.Group controlId="password">
                <Row>
                  <Col sm={8} className="ResetPasswordEmailField mx-auto">
                    <Form.Control
                      className="registerFields mb-2"
                      type="password"
                      placeholder="Enter new password"
                      value={newPassword}
                      required
                      onChange={(e) => setNewPassword(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col sm={8} className="ResetPasswordEmailField mx-auto">
                    <Form.Control
                      className="registerFields mb-2"
                      type="password"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      required
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Form.Group>
              <Button type="submit" className="registerBtn mb-2 mt-2">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default PasswordResetScreen;
