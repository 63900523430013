import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./SearchShopHomeScreen.css";
import { Button, Row, Col, Tabs, Tab, Pagination } from "react-bootstrap";
import {
  listVendorShops,
  listWholesalerShops,
} from "../../actions/userActions.js";
import FollowButton from "../../components/FollowButton.js";

const ShopSearchHomeScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { keyword, pageNumber } = queryString.parse(search);

  const vendorShopList = useSelector((state) => state.vendorShopList);
  const {
    shops: vendorShops,
    pages: vendorPages,
    page: vendorPage,
  } = vendorShopList;

  let paginationClickedValue;
  const test = (e) => {
    paginationClickedValue = e.target.text;
    history.push(
      `/search-shop?keyword=${keyword}&pageNumber=${paginationClickedValue}`
    );
  };

  let items = [];
  for (let num = 1; num <= vendorPages; num++) {
    items.push(
      <Pagination.Item key={num} active={num === vendorPage} onClick={test}>
        {num}
      </Pagination.Item>
    );
  }

  const wholesalerShopList = useSelector((state) => state.wholesalerShopList);
  const { shops: wholesalerShops } = wholesalerShopList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch(listVendorShops(keyword, pageNumber));
    if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler") {
      dispatch(listWholesalerShops(keyword, pageNumber));
    }
  }, [dispatch, pageNumber, keyword, userInfo?.type]);

  return (
    <div className="container px-0 py-3">
      <Button
        className="bg-white text-secondary p-0 mt-2"
        onClick={() => {
          history.push("/");
        }}
      >
        <i
          className="fas fa-angle-left p-2 mr-1"
          style={{ border: "1px solid whitesmoke" }}
        ></i>
        Go Back
      </Button>
      <h4 className="SearchRelated">
        Search related to <span className="IconInfoColor"> "{keyword}" </span>
      </h4>
      <hr></hr>

      <Tabs
        defaultActiveKey="vendor"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        {userInfo && userInfo.type !== "customer" && (
          <Tab eventKey="wholesale" title="Wholesale Shop">
            <Row className="shopContainer">
              {wholesalerShops?.map((s) => (
                <>
                  <Row className="storeJumbotron ml-md-5 my-2 align-items-center">
                    <Col md="1" sm="6" className="storeIcon">
                      <i className="fas fa-store fa-3x"></i>
                    </Col>
                    <Col md="3" sm="6" className="">
                      <Link to={`/wholesaleshop/${s.shop.shopName}`}>
                        <h4 className="shopName">
                          {s.shop?.shopName.substring(0, 25)}
                        </h4>
                      </Link>
                      <p className="shopSmallFont"> {s.shop.tagLine}</p>
                      <p className="shopMediumFont">
                        <span className="IconInfoColor">
                          {s.followers ? s.followers : 0}
                        </span>{" "}
                        Followers
                      </p>
                    </Col>
                    <Col md="3" sm="6" className="ShopInfo StoreInfo">
                      <p className="shopMediumFont">
                        <i className="fas fa-archive IconInfoColor"></i>{" "}
                        {s.productsCount} Products
                      </p>
                      <p className="shopMediumFont">
                        <i className="far fa-comment-dots IconInfoColor"></i>{" "}
                        80% Response Rate
                      </p>
                    </Col>
                    <Col md="3" sm="6" className="ShopInfo">
                      <p className="shopMediumFont">
                        <i className="fas fa-star IconInfoColor"></i>{" "}
                        {s.shop?.reviews?.length === 0
                          ? "0"
                          : s.shop?.reviews?.reduce(
                              (acc, item) => item.rating + acc,
                              0
                            ) / s.shop?.reviews?.length}{" "}
                        Rating
                      </p>
                      <p className="shopMediumFont">
                        <i className="fas fa-map-marker-alt IconInfoColor"> </i>
                        {
                          (s.shop?.headOffice?.addressLine1,
                          s.shop?.headOffice?.province,
                          s.shop?.headOffice?.country)
                        }
                      </p>
                    </Col>
                    <Col md="2" sm="6" className="ShopInfo">
                      <Button
                        className="ShopFollowBtn"
                        onClick={() =>
                          history.push(`/wholesaleshop/${s.shop.shopName}`)
                        }
                      >
                        View Shop
                      </Button>
                      <br></br>

                      <FollowButton id={s.shop.shopName} type="wholesaler" />
                    </Col>
                  </Row>
                </>
              ))}
              <Pagination>{items}</Pagination>
            </Row>
          </Tab>
        )}

        <Tab eventKey="vendor" title="Vendor Shop">
          <Row className="shopContainer">
            {vendorShops?.map((s) => (
              <>
                <Row className="storeJumbotron ml-md-5 my-2 align-items-center">
                  <Col md="1" sm="6" className="storeIcon">
                    <i className="fas fa-store fa-3x"></i>
                  </Col>
                  <Col md="3" sm="6" className="">
                    <Link to={`/shop/${s.shop.shopName}`}>
                      <h4 className="shopName">
                        {s.shop.shopName.substring(0, 20)}
                      </h4>
                    </Link>
                    <p className="shopSmallFont"> {s.shop?.tagLine}</p>
                    <p className="shopMediumFont">
                      <span className="IconInfoColor">
                        {" "}
                        {s.followers ? s.followers : 0}{" "}
                      </span>{" "}
                      Followers
                    </p>
                  </Col>
                  <Col md="3" sm="6" className="ShopInfo StoreInfo">
                    <p className="shopMediumFont">
                      <i className="fas fa-archive IconInfoColor"></i>{" "}
                      {s.productsCount} Products
                    </p>
                    <p className="shopMediumFont">
                      <i className="far fa-comment-dots IconInfoColor"></i> 80%
                      Response Rate
                    </p>
                  </Col>
                  <Col md="3" sm="6" className="ShopInfo">
                    <p className="shopMediumFont">
                      <i className="fas fa-star IconInfoColor"></i>{" "}
                      {s.shop?.reviews?.length === 0
                        ? "0"
                        : s.shop?.reviews?.reduce(
                            (acc, item) => item.rating + acc,
                            0
                          ) / s.shop?.reviews?.length}{" "}
                      Rating
                    </p>
                    <p className="shopMediumFont">
                      <i className="fas fa-map-marker-alt IconInfoColor"> </i>{" "}
                      {
                        (s.shop?.headOffice?.addressLine1,
                        s.shop?.headOffice?.province,
                        s.shop?.headOffice?.country)
                      }
                    </p>
                  </Col>
                  <Col md="2" sm="6" className="ShopInfo">
                    <Button
                      className="ShopFollowBtn"
                      onClick={() => history.push(`/shop/${s.shop.shopName}`)}
                    >
                      View Shop
                    </Button>
                    <br></br>

                    <FollowButton id={s.shop.shopName} type="vendor" />
                  </Col>
                </Row>
              </>
            ))}
            <Pagination>{items}</Pagination>
          </Row>
        </Tab>
      </Tabs>
    </div>
  );
};
export default ShopSearchHomeScreen;

// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link, useLocation } from "react-router-dom";
// import queryString from "query-string";
// import Message from "../../components/Message.js";
// import Loader from "../../components/Loader.js";
// import "./SearchHomeScreen.css";

// import { Button, Row, Col, Form } from "react-bootstrap";
// import Paginate from "../../components/Paginate.js";
// import {
//   listVendorShops,
//   listWholesalerShops,
// } from "../../actions/userActions.js";

// const ShopSearchHomeScreen = ({ match, history }) => {
//   const dispatch = useDispatch();
//   const { search } = useLocation();
//   const { keyword, pageNumber } = queryString.parse(search);

//   const vendorShopList = useSelector((state) => state.vendorShopList);
//   const {
//     shops: vendorShops,
//     pages: vendorPages,
//     page: vendorPage,
//   } = vendorShopList;

//   const wholesalerShopList = useSelector((state) => state.wholesalerShopList);
//   const {
//     shops: wholesalerShops,
//     pages: wholesalerPages,
//     page: wholesalerPage,
//   } = wholesalerShopList;

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   useEffect(() => {
//     dispatch(listVendorShops(keyword, pageNumber));
//     if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler") {
//       dispatch(listWholesalerShops(keyword, pageNumber));
//     }
//   }, [pageNumber, keyword, userInfo?.type]);

//   return (
//     <div className="container px-0 py-3">
//       {vendorShops?.slice(0, 2).map((s) => (
//         <>
//           <Row className="storeJumbotron ml-5 my-2 align-items-center">
//             <Col md="2" sm="6" className="storeIcon">
//               <i className="fas fa-store fa-3x"></i>
//             </Col>
//             <Col md="10" sm="6" className="storeInfo">
//               <Link to={`/shop/${s.shopName}`}>
//                 <h3> {s.shopName} </h3>
//               </Link>
//             </Col>
//           </Row>
//         </>
//       ))}
//       <Paginate url={`/search-shop`} pages={vendorPages} page={vendorPage} />
//     </div>
//   );
// };
// export default ShopSearchHomeScreen;
