import {
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_SUCCESS,
  BLOG_DETAILS_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_SUCCESS,
  BLOG_VIEW_FAIL,
  BLOG_VIEW_FEATURED_FAIL,
  BLOG_VIEW_FEATURED_REQUEST,
  BLOG_VIEW_FEATURED_SUCCESS,
  BLOG_VIEW_NEW_FAIL,
  BLOG_VIEW_NEW_REQUEST,
  BLOG_VIEW_NEW_SUCCESS,
  BLOG_VIEW_REQUEST,
  BLOG_VIEW_SUCCESS,
  BLOG_VIEW_UNPUBLISHED_FAIL,
  BLOG_VIEW_UNPUBLISHED_REQUEST,
  BLOG_VIEW_UNPUBLISHED_SUCCESS,
} from "../constants/blogConstants";

import axios from "axios";

export const createBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/blog/blog-create`, blog, config);
    dispatch({
      type: BLOG_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listBlog =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BLOG_VIEW_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/blog/blog-list?pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: BLOG_VIEW_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BLOG_VIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listUnpublishedBlog =
  (pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: BLOG_VIEW_UNPUBLISHED_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/blog/blog-list/unpublished?pageNumber=${pageNumber}`,
        config
      );

      dispatch({
        type: BLOG_VIEW_UNPUBLISHED_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: BLOG_VIEW_UNPUBLISHED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const listNewBlog = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_VIEW_NEW_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/blog/blog-list/new`, config);

    dispatch({
      type: BLOG_VIEW_NEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_VIEW_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFeaturedBlog = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_VIEW_FEATURED_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/blog/blog-list/featured`, config);

    dispatch({
      type: BLOG_VIEW_FEATURED_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_VIEW_FEATURED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BLOG_EDIT_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/blog/edit-blog/${blog.id}`, blog, config);
    dispatch({
      type: BLOG_EDIT_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: BLOG_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailBlog = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BLOG_DETAILS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/blog/edit-blog/${id}`, config);

    dispatch({ type: BLOG_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: BLOG_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
