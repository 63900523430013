export const CART_ADD_ITEM = "Cart_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";
export const CART_RESET = "CART_RESET";

export const CART_ADD_TO_SERVER_REQUEST = "CART_ADD_TO_SERVER_REQUEST";
export const CART_ADD_TO_SERVER_SUCCESS = "CART_ADD_TO_SERVER_SUCCESS";
export const CART_ADD_TO_SERVER_FAIL = "CART_ADD_TO_SERVER_FAIL";
export const CART_ADD_TO_SERVER_RESET = "CART_ADD_TO_SERVER_RESET";

export const CART_GET_FROM_SERVER_REQUEST = "CART_GET_FROM_SERVER_REQUEST";
export const CART_GET_FROM_SERVER_SUCCESS = "CART_GET_FROM_SERVER_SUCCESS";
export const CART_GET_FROM_SERVER_FAIL = "CART_GET_FROM_SERVER_FAIL";

export const CART_EDIT_CHECKED_SERVER_REQUEST =
  "CART_EDIT_CHECKED_SERVER_REQUEST";
export const CART_EDIT_CHECKED_SERVER_SUCCESS =
  "CART_EDIT_CHECKED_SERVER_SUCCESS";
export const CART_EDIT_CHECKED_SERVER_FAIL = "CART_EDIT_CHECKED_SERVER_FAIL";
export const CART_EDIT_CHECKED_SERVER_RESET = "CART_EDIT_CHECKED_SERVER_RESET";

export const CART_GET_CHECKED_FROM_SERVER_REQUEST =
  "CART_GET_CHECKED_FROM_SERVER_REQUEST";
export const CART_GET_CHECKED_FROM_SERVER_SUCCESS =
  "CART_GET_CHECKED_FROM_SERVER_SUCCESS";
export const CART_GET_CHECKED_FROM_SERVER_FAIL =
  "CART_GET_CHECKED_FROM_SERVER_FAIL";

export const CART_REMOVE_FROM_SERVER_REQUEST =
  "CART_REMOVE_FROM_SERVER_REQUEST";
export const CART_REMOVE_FROM_SERVER_SUCCESS =
  "CART_REMOVE_FROM_SERVER_SUCCESS";
export const CART_REMOVE_FROM_SERVER_FAIL = "CART_REMOVE_FROM_SERVER_FAIL";
export const CART_REMOVE_FROM_SERVER_RESET = "CART_REMOVE_FROM_SERVER_RESET";

export const CART_REMOVE_CHECKED_FROM_SERVER_REQUEST =
  "CART_REMOVE_CHECKED_FROM_SERVER_REQUEST";
export const CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS =
  "CART_REMOVE_CHECKED_FROM_SERVER_SUCCESS";
export const CART_REMOVE_CHECKED_FROM_SERVER_FAIL =
  "CART_REMOVE_CHECKED_FROM_SERVER_FAIL";
export const CART_REMOVE_CHECKED_FROM_SERVER_RESET =
  "CART_REMOVE_CHECKED_FROM_SERVER_RESET";
