export const USER_ADDRESSBOOK_REQUEST = "USER_ADDRESSBOOK_REQUEST";
export const USER_ADDRESSBOOK_FAIL = "USER_ADDRESSBOOK_FAIL";
export const USER_ADDRESSBOOK_SUCCESS = "USER_ADDRESSBOOK_SUCCESS";
export const USER_ADDRESSBOOK_RESET = "USER_ADDRESSBOOK_RESET";

export const USER_CREATE_ADDRESSBOOK_REQUEST =
  "USER_CREATE_ADDRESSBOOK_REQUEST";
export const USER_CREATE_ADDRESSBOOK_FAIL = "USER_CREATE_ADDRESSBOOK_FAIL";
export const USER_CREATE_ADDRESSBOOK_SUCCESS =
  "USER_CREATE_ADDRESSBOOK_SUCCESS";
export const USER_CREATE_ADDRESSBOOK_RESET = "USER_CREATE_ADDRESSBOOK_RESET";

export const USER_UPDATE_ADDRESSBOOK_REQUEST =
  "USER_UPDATE_ADDRESSBOOK_REQUEST";
export const USER_UPDATE_ADDRESSBOOK_FAIL = "USER_UPDATE_ADDRESSBOOK_FAIL";
export const USER_UPDATE_ADDRESSBOOK_SUCCESS =
  "USER_UPDATE_ADDRESSBOOK_SUCCESS";
export const USER_UPDATE_ADDRESSBOOK_RESET = "USER_UPDATE_ADDRESSBOOK_RESET";

export const USER_DETAILS_ADDRESSBOOK_REQUEST =
  "USER_DETAILS_ADDRESSBOOK_REQUEST";
export const USER_DETAILS_ADDRESSBOOK_FAIL = "USER_DETAILS_ADDRESSBOOK_FAIL";
export const USER_DETAILS_ADDRESSBOOK_SUCCESS =
  "USER_DETAILS_ADDRESSBOOK_SUCCESS";
export const USER_DETAILS_ADDRESSBOOK_RESET = "USER_DETAILS_ADDRESSBOOK_RESET";

export const ORDER_USER_ADDRESS_REQUEST = "ORDER_USER_ADDRESS_REQUEST";
export const ORDER_USER_ADDRESS_FAIL = "ORDER_USER_ADDRESS_FAIL";
export const ORDER_USER_ADDRESS_SUCCESS = "ORDER_USER_ADDRESS_SUCCESS";

export const VENDOR_ORDER_ADDRESS_REQUEST = "VENDOR_ORDER_ADDRESS_REQUEST";
export const VENDOR_ORDER_ADDRESS_FAIL = "VENDOR_ORDER_ADDRESS_FAIL";
export const VENDOR_ORDER_ADDRESS_SUCCESS = "VENDOR_ORDER_ADDRESS_SUCCESS";
export const VENDOR_ORDER_ADDRESS_RESET = "VENDOR_ORDER_ADDRESS_RESET";

export const VENDOR_ORDER_ADDRESS_GET_REQUEST =
  "VENDOR_ORDER_ADDRESS_GET_REQUEST";
export const VENDOR_ORDER_ADDRESS_GET_FAIL = "VENDOR_ORDER_ADDRESS_GET_FAIL";
export const VENDOR_ORDER_ADDRESS_GET_SUCCESS =
  "VENDOR_ORDER_ADDRESS_GET_SUCCESS";

export const WHOLESELLER_ORDER_ADDRESS_REQUEST =
  "WHOLESELLER_ORDER_ADDRESS_REQUEST";
export const WHOLESELLER_ORDER_ADDRESS_FAIL = "WHOLESELLER_ORDER_ADDRESS_FAIL";
export const WHOLESELLER_ORDER_ADDRESS_SUCCESS =
  "WHOLESELLER_ORDER_ADDRESS_SUCCESS";
export const WHOLESELLER_ORDER_ADDRESS_RESET =
  "WHOLESELLER_ORDER_ADDRESS_RESET";

export const WHOLESELLER_ORDER_ADDRESS_GET_REQUEST =
  "WHOLESELLER_ORDER_ADDRESS_GET_REQUEST";
export const WHOLESELLER_ORDER_ADDRESS_GET_FAIL =
  "WHOLESELLER_ORDER_ADDRESS_GET_FAIL";
export const WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS =
  "WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS";
export const WHOLESALER_ORDER_ADDRESS_RESET = "WHOLESALER_ORDER_ADDRESS_RESET";
