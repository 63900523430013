import {
  ORDER_USER_ADDRESS_FAIL,
  ORDER_USER_ADDRESS_REQUEST,
  ORDER_USER_ADDRESS_SUCCESS,
  USER_ADDRESSBOOK_RESET,
  USER_ADDRESSBOOK_FAIL,
  USER_ADDRESSBOOK_REQUEST,
  USER_ADDRESSBOOK_SUCCESS,
  USER_CREATE_ADDRESSBOOK_FAIL,
  USER_CREATE_ADDRESSBOOK_REQUEST,
  USER_CREATE_ADDRESSBOOK_RESET,
  USER_CREATE_ADDRESSBOOK_SUCCESS,
  USER_DETAILS_ADDRESSBOOK_FAIL,
  USER_DETAILS_ADDRESSBOOK_REQUEST,
  USER_DETAILS_ADDRESSBOOK_SUCCESS,
  USER_UPDATE_ADDRESSBOOK_FAIL,
  USER_UPDATE_ADDRESSBOOK_REQUEST,
  USER_UPDATE_ADDRESSBOOK_RESET,
  USER_UPDATE_ADDRESSBOOK_SUCCESS,
  VENDOR_ORDER_ADDRESS_REQUEST,
  VENDOR_ORDER_ADDRESS_SUCCESS,
  VENDOR_ORDER_ADDRESS_FAIL,
  VENDOR_ORDER_ADDRESS_GET_REQUEST,
  VENDOR_ORDER_ADDRESS_GET_SUCCESS,
  VENDOR_ORDER_ADDRESS_GET_FAIL,
  WHOLESELLER_ORDER_ADDRESS_REQUEST,
  WHOLESELLER_ORDER_ADDRESS_SUCCESS,
  WHOLESELLER_ORDER_ADDRESS_FAIL,
  WHOLESELLER_ORDER_ADDRESS_GET_REQUEST,
  WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS,
  WHOLESELLER_ORDER_ADDRESS_GET_FAIL,
  VENDOR_ORDER_ADDRESS_RESET,
  WHOLESELLER_ORDER_ADDRESS_RESET,
} from "../constants/addressConstants";

export const addressListReducer = (state = { addressBook: [] }, action) => {
  switch (action.type) {
    case USER_ADDRESSBOOK_REQUEST:
      return { loading: true };

    case USER_ADDRESSBOOK_SUCCESS:
      return { loading: false, addressBook: action.payload };

    case USER_ADDRESSBOOK_FAIL:
      return { loading: false, error: action.payload };

    case USER_ADDRESSBOOK_RESET:
      return { addressBook: [] };

    default:
      return state;
  }
};

export const addressCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_CREATE_ADDRESSBOOK_REQUEST:
      return { loading: true };

    case USER_CREATE_ADDRESSBOOK_SUCCESS:
      return { loading: false, success: true, addressBook: action.payload };

    case USER_CREATE_ADDRESSBOOK_FAIL:
      return { loading: false, error: action.payload };

    case USER_CREATE_ADDRESSBOOK_RESET:
      return {};

    default:
      return state;
  }
};

export const addressUpdateReducer = (state = { address: {} }, action) => {
  switch (action.type) {
    case USER_UPDATE_ADDRESSBOOK_REQUEST:
      return {
        loading: true,
      };
    case USER_UPDATE_ADDRESSBOOK_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case USER_UPDATE_ADDRESSBOOK_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case USER_UPDATE_ADDRESSBOOK_RESET:
      return { address: [] };
    default:
      return state;
  }
};

export const addressDetailsReducer = (state = { address: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_ADDRESSBOOK_REQUEST:
      return { loading: true, ...state };
    case USER_DETAILS_ADDRESSBOOK_SUCCESS:
      return { loading: false, success: true, address: action.payload };
    case USER_DETAILS_ADDRESSBOOK_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addressOrderDetailsReducer = (state = { address: [] }, action) => {
  switch (action.type) {
    case ORDER_USER_ADDRESS_REQUEST:
      return { loading: true };
    case ORDER_USER_ADDRESS_SUCCESS:
      return { loading: false, address: action.payload };
    case ORDER_USER_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const addressVendorOrderReducer = (
  state = { addressVendor: [] },
  action
) => {
  switch (action.type) {
    case VENDOR_ORDER_ADDRESS_REQUEST:
      return { loading: true };
    case VENDOR_ORDER_ADDRESS_SUCCESS:
      return { loading: false, success: true, addressVendor: action.payload };
    case VENDOR_ORDER_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    case VENDOR_ORDER_ADDRESS_RESET:
      return {};
    default:
      return state;
  }
};

export const addressVendorOrderGetReducer = (
  state = { addressVendorData: [] },
  action
) => {
  switch (action.type) {
    case VENDOR_ORDER_ADDRESS_GET_REQUEST:
      return { loading: true };
    case VENDOR_ORDER_ADDRESS_GET_SUCCESS:
      return {
        loading: false,
        addressVendorData: action.payload,
      };
    case VENDOR_ORDER_ADDRESS_GET_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};

export const addressWholesalerOrderReducer = (
  state = { addressWholesaler: [] },
  action
) => {
  switch (action.type) {
    case WHOLESELLER_ORDER_ADDRESS_REQUEST:
      return { loading: true };
    case WHOLESELLER_ORDER_ADDRESS_SUCCESS:
      return {
        loading: false,
        success: true,
        addressWholesaler: action.payload,
      };
    case WHOLESELLER_ORDER_ADDRESS_FAIL:
      return { loading: false, error: action.payload };
    case WHOLESELLER_ORDER_ADDRESS_RESET:
      return {};
    default:
      return state;
  }
};

export const addressWholesalerOrderGetReducer = (
  state = { addressWholesalerData: [] },
  action
) => {
  switch (action.type) {
    case WHOLESELLER_ORDER_ADDRESS_GET_REQUEST:
      return { loading: true };
    case WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS:
      return {
        loading: false,
        addressWholesalerData: action.payload,
      };
    case WHOLESELLER_ORDER_ADDRESS_GET_FAIL:
      return { loading: false, error: action.payload };
    case WHOLESELLER_ORDER_ADDRESS_RESET:
      return { loading: false, success: false };
    default:
      return state;
  }
};
