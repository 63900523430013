import "./ShareButton.css";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
} from "react-share";

const ShareButton = () => {
  const shareUrl = window.location.href;
  return (
    <span
      className="shareButton"
      style={{
        padding: "0",
        margin: "0",
        background: "transparent",
        border: "none",
        outline: "none",
        cursor: "pointer",
      }}
    >
      <div className="shareButtonTooltip">
        <LinkedinShareButton url={shareUrl}>
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={42} round={true} />
        </FacebookShareButton>

        <EmailShareButton url={shareUrl}>
          <EmailIcon size={42} round={true} />
        </EmailShareButton>

        <RedditShareButton url={shareUrl}>
          <RedditIcon size={42} round={true} />
        </RedditShareButton>

        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={42} round={true} />
        </TelegramShareButton>

        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>

        <ViberShareButton url={shareUrl}>
          <ViberIcon size={42} round={true} />
        </ViberShareButton>

        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={42} round={true} />
        </WhatsappShareButton>
      </div>
      <i
        style={{
          padding: "10px",
          color: "#000000",
          fontSize: "20px",
        }}
        className="fas fa-share-alt"
      ></i>
    </span>
  );
};

export default ShareButton;
