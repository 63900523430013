export const BLOG_CREATE_REQUEST = "BLOG_CREATE_REQUEST";
export const BLOG_CREATE_FAIL = "BLOG_CREATE_FAIL";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET";

export const BLOG_VIEW_UNPUBLISHED_REQUEST = "BLOG_VIEW_UNPUBLISHED_REQUEST";
export const BLOG_VIEW_UNPUBLISHED_FAIL = "BLOG_VIEW_UNPUBLISHED_FAIL";
export const BLOG_VIEW_UNPUBLISHED_SUCCESS = "BLOG_VIEW_UNPUBLISHED_SUCCESS";
export const BLOG_VIEW_UNPUBLISHED_RESET = "BLOG_VIEW_UNPUBLISHED_RESET";

export const BLOG_VIEW_NEW_REQUEST = "BLOG_VIEW_NEW_REQUEST";
export const BLOG_VIEW_NEW_FAIL = "BLOG_VIEW_NEW_FAIL";
export const BLOG_VIEW_NEW_SUCCESS = "BLOG_VIEW_NEW_SUCCESS";
export const BLOG_VIEW_NEW_RESET = "BLOG_VIEW_NEW_RESET";

export const BLOG_VIEW_FEATURED_REQUEST = "BLOG_VIEW_FEATURED_REQUEST";
export const BLOG_VIEW_FEATURED_FAIL = "BLOG_VIEW_FEATURED_FAIL";
export const BLOG_VIEW_FEATURED_SUCCESS = "BLOG_VIEW_FEATURED_SUCCESS";
export const BLOG_VIEW_FEATURED_RESET = "BLOG_VIEW_FEATURED_RESET";

export const BLOG_VIEW_REQUEST = "BLOG_VIEW_REQUEST";
export const BLOG_VIEW_FAIL = "BLOG_VIEW_FAIL";
export const BLOG_VIEW_SUCCESS = "BLOG_VIEW_SUCCESS";
export const BLOG_VIEW_RESET = "BLOG_VIEW_RESET";

export const BLOG_EDIT_REQUEST = "BLOG_EDIT_REQUEST";
export const BLOG_EDIT_FAIL = "BLOG_EDIT_FAIL";
export const BLOG_EDIT_SUCCESS = "BLOG_EDIT_SUCCESS";
export const BLOG_EDIT_RESET = "BLOG_EDIT_RESET";

export const BLOG_DETAILS_REQUEST = "BLOG_DETAILS_REQUEST";
export const BLOG_DETAILS_FAIL = "BLOG_DETAILS_FAIL";
export const BLOG_DETAILS_SUCCESS = "BLOG_DETAILS_SUCCESS";
export const BLOG_DETAILS_RESET = "BLOG_DETAILS_RESET";
