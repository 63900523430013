import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { LinkContainer } from "react-router-bootstrap";
import { Nav, Form, Button } from "react-bootstrap";
import Modal from "react-modal";
import {
  registerVendorWithNoLogIn,
  registerWholesalerWithNoLogIn,
} from "../../actions/userActions";

//styles for sell modal
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    zIndex: "1000",
    background: "white",
    padding: "30px",
    boxShadow: "0 0 25px black",
  },
};

const NoUsereader = ({ history }) => {
  const dispatch = useDispatch();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [name, setName] = React.useState("");

  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [type, setType] = React.useState("");

  // function openModal() {
  //   setIsOpen(true);
  // }

  function closeModal() {
    setIsOpen(false);

    if ((name, phone, email, password, type)) {
      if (password === confirmPassword) {
        sellProductsWithoutUser();
      } else {
        alert("Passwords do not match");
      }
    } else {
      alert("Fill all the empty slots");
    }
  }

  const vendorNoUserRegister = useSelector(
    (state) => state.vendorNoUserRegister
  );
  const { success: vendorNoUserRegistrationSuccess } = vendorNoUserRegister;

  const wholesalerNoUserRegister = useSelector(
    (state) => state.wholesalerNoUserRegister
  );
  const { success: wholesalerNoUserRegistrationSuccess } =
    wholesalerNoUserRegister;

  const sellProductsWithoutUser = () => {
    if (type === "retail") {
      dispatch(registerVendorWithNoLogIn({ name, email, password, phone }));
    } else {
      dispatch(registerWholesalerWithNoLogIn({ name, email, password, phone }));
    }
  };

  useEffect(() => {
    if (vendorNoUserRegistrationSuccess || wholesalerNoUserRegistrationSuccess)
      history.push("/");
  }, [
    vendorNoUserRegistrationSuccess,
    wholesalerNoUserRegistrationSuccess,
    history,
  ]);
  return (
    <>
      {/* Modal component */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Sell on Pinoy Can Buy"
      >
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <div
              style={{
                marginRight: "20px",
                borderRight: "1px solid lightgray",
                paddingRight: "10px",
              }}
            >
              <Form.Group className="mb-3" controlId="name">
                <Form.Label
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "cursive",
                  }}
                >
                  Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter name"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "cursive",
                  }}
                >
                  Email address
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "cursive",
                  }}
                >
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter Password"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="password">
                <Form.Label
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "cursive",
                  }}
                >
                  Confirm Password
                </Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Enter Password Again"
                />
              </Form.Group>
            </div>
            <div>
              <Form.Group className="mb-3" controlId="phone">
                <Form.Label
                  style={{
                    color: "black",
                    fontWeight: "600",
                    fontFamily: "cursive",
                  }}
                >
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Enter phone"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="radioButton">
                <Form.Check
                  type="radio"
                  id="radioId"
                  name="radioId"
                  onClick={(e) => setType("retail")}
                  label="vendor"
                />
                <Form.Check
                  type="radio"
                  id="radioId"
                  name="radioId"
                  onClick={(e) => setType("wholesale")}
                  label="Wholesaler"
                />
              </Form.Group>
            </div>
          </div>
          <div>
            <Button variant="info" type="submit" onClick={closeModal}>
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      {/* <Nav.Link onClick={openModal} style={{ marginRight: "2rem" }}>
				Sign Up
			</Nav.Link> */}
      <LinkContainer to="/register" className="topHeaderLink">
        <Nav.Link>Signup</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/login" className="topHeaderLink">
        <Nav.Link>Login</Nav.Link>
      </LinkContainer>
    </>
  );
};

export default NoUsereader;
