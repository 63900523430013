import React from "react";
import { Pagination } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
const Paginate = ({ url, pages, page, keyword = "" }) => {
  return (
    pages > 1 && (
      <Pagination>
        {/* {[...Array(pages).keys()].map((x) => ( */}
        {page !== 1 && (
          <LinkContainer
            to={
              keyword
                ? `${url}/search/${keyword}/page/${page - 1}`
                : `${url}/page/${page - 1}`
            }
          >
            <Pagination.Item>Prev</Pagination.Item>
          </LinkContainer>
        )}

        <LinkContainer
          to={
            keyword
              ? `${url}/search/${keyword}/page/${page}`
              : `${url}/page/${page}`
          }
        >
          <Pagination.Item active>{page}</Pagination.Item>
        </LinkContainer>

        {page !== pages && (
          <LinkContainer
            to={
              keyword
                ? `${url}/search/${keyword}/page/${page + 1}`
                : `${url}/page/${page + 1}`
            }
          >
            <Pagination.Item>Next</Pagination.Item>
          </LinkContainer>
        )}

        <LinkContainer
          to={
            keyword
              ? `${url}/search/${keyword}/page/${pages}`
              : `${url}/page/${pages}`
          }
        >
          <Pagination.Item>Last</Pagination.Item>
        </LinkContainer>
        {/* ))} */}
      </Pagination>
    )
  );
};

export default Paginate;
