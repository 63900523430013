import React from "react";
import { Route } from "react-router-dom";

import ProductListScreen from "../screens/Dashboard/WholesalerDashboard/Products/ProductlistScreen";
import ProductEditScreen from "../screens/Product/ProductEditScreen";
import WholesalerOrderListScreen from "../screens/Dashboard/WholesalerDashboard/Orders/WholesalerOrderListScreen";
import WholesalerRegistrationScreen from "../screens/Register/WholesalerRegistrationScreen";
import WholesaleProductScreen from "../screens/Product/WholesaleProductScreen";
import ProductCreateScreen from "../screens/Dashboard/WholesalerDashboard/Products/ProductCreateScreen";
import WholesaleShippingScreen from "../screens/Order/WholesaleShippingScreen";
import WholesaleStorePageScreen from "../screens/Store/WholesaleStorepageScreen";
import WholesalerWiseOrderScreen from "../screens/Order/WholesalerWiseOrderScreen";

const WholesalerRoutes = () => {
  return (
    <>
      <Route
        path="/wholesaler/product/create"
        component={ProductCreateScreen}
      />
      <Route path="/wholesaleshipping" component={WholesaleShippingScreen} />

      <Route
        path="/wholesaler/productlist"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/search/:keyword"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/page/:pageNumber"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/search/:keyword/page/:pageNumber"
        component={ProductListScreen}
        exact
      />

      <Route
        path="/wholesaler/productlist/:unpublished"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/:unpublished/search/:keyword"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/:unpublished/page/:pageNumber"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/wholesaler/productlist/:unpublished/search/:keyword/page/:pageNumber"
        component={ProductListScreen}
        exact
      />

      <Route
        path="/order/wholesaler/:id"
        component={WholesalerWiseOrderScreen}
      />

      <Route
        path="/wholesaleproducts"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/wholesaleproducts/search/:keyword"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/wholesaleproducts/page/:pageNumber"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/wholesaleproducts/search/:keyword/page/:pageNumber"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/wholesaler/orderlist"
        component={WholesalerOrderListScreen}
        exact
      />
      <Route
        path="/order/wholesaler/page/:pageNumber"
        component={WholesalerOrderListScreen}
        exact
      />
      <Route
        path="/wholesaler/registration"
        component={WholesalerRegistrationScreen}
      />
      <Route
        path="/wholesaler/product/:id/edit"
        component={ProductEditScreen}
        exact
      />
      <Route
        path="/wholesaleshop/:shopname/search/:keyword/page/:pageNumber"
        component={WholesaleStorePageScreen}
        exact
      />
      <Route
        path="/wholesaleshop/:shopname/search/:keyword"
        component={WholesaleStorePageScreen}
        exact
      />
      <Route
        path="/wholesaleshop/:shopname/page/:pageNumber"
        component={WholesaleStorePageScreen}
        exact
      />
      <Route
        path="/wholesaleshop/:shopname/:vendorCategory"
        component={WholesaleStorePageScreen}
        exact
      />
      <Route
        path="/wholesaleshop/:shopname"
        component={WholesaleStorePageScreen}
        exact
      />
    </>
  );
};

export default WholesalerRoutes;
