import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import {
  VENDOR_DETAILS_REGISTRATION_RESET,
  WHOLESALER_DETAILS_REGISTRATION_RESET,
} from "../../constants/userConstants";
import Axios from "axios";
import "./ShopBannerScreen.css";
import {
  registerVendorDetails,
  registerWholesalerDetails,
} from "../../actions/userActions";

const ShopBannerScreen = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: detailsUser } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user } = wholesalerDetails;

  const vendorUpdateDetails = useSelector((state) => state.vendorUpdateDetails);
  const { success: successVendor } = vendorUpdateDetails;

  const wholesalerUpdateDetails = useSelector(
    (state) => state.wholesalerUpdateDetails
  );
  const { success: successWholesaler } = wholesalerUpdateDetails;

  const [banner, setBanner] = useState([]);
  const [image, setImage] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);

  const submitBannerHandler = async (e) => {
    e.preventDefault();
    if (deletedImage.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await Axios.post(
        `/api/products/image/delete/`,
        { files: deletedImage },
        config
      );
    }
    if (userInfo?.type === "vendor") {
      dispatch(
        registerVendorDetails(detailsUser?._id, {
          homeBannerImages: banner,
          profileBannerImage: image,
        })
      );
    }
    if (userInfo?.type === "wholesaler") {
      dispatch(
        registerWholesalerDetails(user?._id, {
          homeBannerImages: banner,
          profileBannerImage: image,
        })
      );
    }
    alert("Images Submitted");
  };

  const uploadFilesHandler = (e) => {
    for (let i = 0; i < 3; i++) {
      if (e.target.files[i]) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);
        formData.append("upload_preset", "pinoy-can-group");

        Axios.post(
          "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
          formData
        ).then((res) => {
          setBanner((prevState) => [...prevState, res.data.url]);
        });
      }
    }
  };

  const uploadImageHandler = (e) => {
    for (let i = 0; i < 3; i++) {
      if (e.target.files[i]) {
        const formData = new FormData();
        formData.append("file", e.target.files[i]);
        formData.append("upload_preset", "pinoy-can-group");

        Axios.post(
          "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
          formData
        ).then((res) => {
          setImage((prevState) => [...prevState, res.data.url]);
        });
      }
    }
  };
  const deleteHandlerImage = (e, i, link) => {
    e.preventDefault();
    if (window.confirm("Are you sure")) {
      setRemoved(image?.splice(i, 1));
      setImage(image);
      setDeletedImage([...deletedImage, link]);
    }
  };
  const deleteHandlerBanner = (e, i, link) => {
    e.preventDefault();
    if (window.confirm("Are you sure")) {
      setRemoved(banner?.splice(i, 1));
      setBanner(banner);
      setDeletedImage([...deletedImage, link]);
    }
  };
  const [removed, setRemoved] = useState("");

  useEffect(() => {
    if (user) {
      setBanner(user.homeBannerImages);
      setImage(user.profileBannerImages);
    }
    if (detailsUser) {
      setBanner(detailsUser.homeBannerImages);
      setImage(detailsUser.profileBannerImages);
    }
  }, [user, detailsUser]);

  useEffect(() => {
    if (successVendor || successWholesaler) {
      dispatch({ type: VENDOR_DETAILS_REGISTRATION_RESET });
      dispatch({ type: WHOLESALER_DETAILS_REGISTRATION_RESET });
    }
  }, [dispatch, successVendor, successWholesaler]);

  return (
    <div>
      <Row className="shopBannerScreenContent">
        <h1 className="shopBannerScreenH1"> Gallery </h1>
        <h3 className="shopBannerScreenH3"> Photos </h3>
        <Col>
          <Form onSubmit={submitBannerHandler}>
            <Form.Group>
              <Form.Label className="shopBannerLabel">
                Home Banner Images
              </Form.Label>
              <input
                className="mt-2"
                id="image-file"
                label="Choose File"
                type="file"
                multiple
                onChange={(e) => uploadFilesHandler(e)}
              ></input>
              <p>
                <small>
                  (Recommend size for Banner Image - 1512px X 300px)
                </small>
              </p>
            </Form.Group>
            <Row>
              {banner &&
                banner.map((b, aindex) => (
                  <Col key={aindex} md={2}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <img
                        className="mt-2"
                        src={b}
                        alt="bannerimage"
                        style={{
                          width: "160px",
                          objectFit: "cover",
                        }}
                      />
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={(e) => deleteHandlerBanner(e, aindex, b)}
                        style={{
                          fontSize: "16px",
                          color: "red",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          right: 4,
                          top: 2,
                        }}
                      ></i>
                    </div>
                  </Col>
                ))}
            </Row>

            <Col className="mt-5">
              <Form.Group>
                <Form.Label className="shopBannerLabel">
                  Profile Images
                </Form.Label>
                <input
                  className="mt-2"
                  id="image-file"
                  label="Choose File"
                  type="file"
                  multiple
                  onChange={(e) => uploadImageHandler(e)}
                ></input>
                <p>
                  <small>
                    (Recommend size for Profile Image - 708px X 300px)
                  </small>
                </p>
              </Form.Group>
            </Col>
            <Row>
              {image &&
                image.map((i, dindex) => (
                  <Col key={dindex} md={1}>
                    <div
                      style={{
                        width: "100px",
                        height: "100px",
                        position: "relative",
                      }}
                    >
                      <img
                        className="mt-2"
                        src={i}
                        alt="bannerimage"
                        style={{
                          width: "100px",
                          objectFit: "cover",
                        }}
                      />
                      <i
                        className="fa fa-times"
                        aria-hidden="true"
                        onClick={(e) => deleteHandlerImage(e, dindex, i)}
                        style={{
                          fontSize: "16px",
                          color: "red",
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          right: 4,
                          top: 2,
                        }}
                      ></i>
                    </div>
                  </Col>
                ))}
            </Row>
            <Button
              type="submit"
              className="vendorRegistrationBtn mt-5"
              onClick={submitBannerHandler}
            >
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default ShopBannerScreen;
