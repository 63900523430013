import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Col, Form } from "react-bootstrap";
import { createAddressBook } from "../../actions/addressBookAction";
import "../../index.css";
import { USER_ADDRESSBOOK_RESET } from "../../constants/addressConstants";
import "./AddressCreateScreen.css";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../actions/philipinesAddressAction";

const AddressCreateScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { ref } = queryString.parse(search);
  // const autocomplete = null;

  // dispatch({ type: USER_CREATE_ADDRESSBOOK_RESET });
  const addressCreate = useSelector((state) => state.addressCreate);
  const { success: successCreate } = addressCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [addressLine1, setAddressLine1] = useState("");
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philipines");
  const [postalCode, setPostalCode] = useState("");
  const [place, setPlace] = useState("");
  const [ward, setWard] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [isShipping, setIsShipping] = useState(true);
  const [additional, setAdditional] = useState("");
  const [validated, setValidated] = useState(false);

  const [option, setOption] = useState([]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(
        createAddressBook({
          addressLine1,
          muncipality,
          province,
          district,
          country,
          postalCode,
          place,
          ward,
          additional,
          isBilling,
          isShipping,
        })
      );
      setAddressLine1("");
      setAdditional("");
      setMuncipality("");
      setProvince("");
      setDistrict("");
      setCountry("Philipines");
      setPostalCode("");
      setPlace("");
      setWard("");
      setIsBilling(true);
      setIsShipping(true);
      history.push(`/address/book/${userInfo._id}`);
    }
  };

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }
  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  useEffect(() => {
    loadRegion();
    dispatch({ type: USER_ADDRESSBOOK_RESET });

    if (successCreate) {
      if (ref === "ship") {
        history.push("/customer/shipping/detail");
      } else {
        history.push(`/address/book/${userInfo._id}`);
      }
    }
  }, [history, dispatch, userInfo, successCreate, ref]);

  return (
    <div
      style={{
        boxShadow: "0 0 25px rgba(0, 0, 0, 0.25)",
        marginLeft: "auto",
        paddingTop: "5%",
        paddingBottom: "10%",
        marginTop: "5%",
        marginBottom: "10%",
        paddingLeft: "20%",
        height: "auto",
      }}
    >
      <Col md={9} sm={12} className="">
        <h3>Add New Address</h3>

        <Form noValidate validated={validated} onSubmit={submitHandler}>
          <Form.Group controlId="country">
            <Form.Label style={{ fontSize: "13px" }}>Country</Form.Label>

            <Form.Control
              type="text"
              value={country}
              disabled
              onChange={(e) => setCountry(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="province">
            <Form.Label style={{ fontSize: "13px" }}>Region</Form.Label>

            <Select
              className="orderCreateInputField"
              options={option}
              onChange={(e) => {
                setProvince(e.value);
              }}
              placeholder="Choose your province"
            />
            {/* <Form.Control
              as="select"
              aria-label="Default select example"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            >
              <option value="">Please Select Province</option>
              <option value="Province 1">Province 1</option>
              <option value="Province 2">Province 2</option>
              <option value="Bagmati Province">Bagmati Province</option>
              <option value="Gandaki Province">Gandaki Province</option>
              <option value="Lumbini Province">Lumbini Province</option>
              <option value="Karnali Province">Karnali Province</option>
              <option value="Sudurpaschim Province">
                Sudurpaschim Province
              </option>
            </Form.Control> */}
          </Form.Group>
          <Form.Group controlId="district">
            <Form.Label style={{ fontSize: "13px" }}>Province</Form.Label>
            <AsyncSelect
              className="orderCreateInputField"
              loadOptions={loadProvince}
              onChange={(e) => {
                setDistrict(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />
            {/* <Form.Control
              type="text"
              value={district}
              onChange={(e) => setDistrict(e.target.value)}
            ></Form.Control> */}
          </Form.Group>
          <Form.Group controlId="muncipality">
            <Form.Label style={{ fontSize: "13px" }}>Municipality</Form.Label>

            <AsyncSelect
              className="orderCreateInputField"
              cacheOptions
              defaultOptions
              loadOptions={loadMunicipality}
              onChange={(e) => {
                setMuncipality(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />
            {/* <Form.Control
              type="text"
              value={muncipality}
              onChange={(e) => setMuncipality(e.target.value)}
            ></Form.Control> */}
          </Form.Group>
          <Form.Group controlId="ward">
            <Form.Label style={{ fontSize: "13px" }}>Barangay</Form.Label>

            <AsyncSelect
              className="orderCreateInputField"
              cacheOptions
              defaultOptions
              loadOptions={loadBarangay}
              onChange={(e) => {
                setWard(e.value);
              }}
              placeholder="Type characters for dropdown to appear"
            />

            {/* <Form.Control
              type="number"
              className="wardNumberAddressField"
              value={ward}
              onChange={(e) => setWard(e.target.value)}
            ></Form.Control> */}
          </Form.Group>
          <Form.Group controlId="postalcode">
            <Form.Label style={{ fontSize: "13px" }}>ZIP Code</Form.Label>

            <Form.Control
              type="text"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="">
            <Form.Label style={{ fontSize: "13px" }}>Street Address</Form.Label>

            <Form.Control
              type="text"
              value={addressLine1}
              onChange={(e) => setAddressLine1(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="">
            <Form.Label style={{ fontSize: "13px" }}>
              Additional Address Information
            </Form.Label>

            <Form.Control
              type="text"
              value={additional}
              onChange={(e) => setAdditional(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="place">
            <Form.Label style={{ fontSize: "13px" }}>Address Type</Form.Label>

            <Form.Control
              as="select"
              aria-label="Default select example"
              value={place}
              onChange={(e) => setPlace(e.target.value)}
            >
              <option value="">Please Select the Address Type</option>
              <option value="Home">Home</option>
              <option value="Office">Office</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="defaultfor">
            <Form.Label style={{ fontSize: "13px" }}>Default For</Form.Label>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Shipping Address"
              defaultChecked
              value={isShipping}
              onChange={(e) => setIsShipping(e.target.checked)}
            />
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Billing Address"
              defaultChecked
              value={isBilling}
              onChange={(e) => setIsBilling(e.target.checked)}
            />
          </Form.Group>
          <button
            type="submit"
            className="btn btn-outline-primary "
            style={{ float: "right", marginTop: "2rem", width: "200px" }}
          >
            Submit <i className="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </Form>
      </Col>
    </div>
  );
};

export default AddressCreateScreen;
