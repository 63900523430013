import {
  STAFF_CREATE_FAIL,
  STAFF_CREATE_REQUEST,
  STAFF_CREATE_RESET,
  STAFF_CREATE_SUCCESS,
  STAFF_DELETE_FAIL,
  STAFF_DELETE_REQUEST,
  STAFF_DELETE_RESET,
  STAFF_DELETE_SUCCESS,
  STAFF_DETAILS_BY_STAFF_ID_FAIL,
  STAFF_DETAILS_BY_STAFF_ID_REQUEST,
  STAFF_DETAILS_BY_STAFF_ID_SUCCESS,
  STAFF_DETAILS_FAIL,
  STAFF_DETAILS_REQUEST,
  STAFF_DETAILS_SUCCESS,
  STAFF_GET_FAIL,
  STAFF_GET_REQUEST,
  STAFF_GET_SUCCESS,
  STAFF_UPDATE_FAIL,
  STAFF_UPDATE_REQUEST,
  STAFF_UPDATE_RESET,
  STAFF_UPDATE_SUCCESS,
} from "../constants/staffConstants";

export const staffCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STAFF_CREATE_REQUEST:
      return {
        loading: true,
      };

    case STAFF_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case STAFF_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case STAFF_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const staffListReducer = (state = { staff: [] }, action) => {
  switch (action.type) {
    case STAFF_GET_REQUEST:
      return {
        loading: true,
      };

    case STAFF_GET_SUCCESS:
      return {
        loading: false,
        staffs: action.payload,
      };
    case STAFF_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const staffDetailsReducer = (state = { staff: {} }, action) => {
  switch (action.type) {
    case STAFF_DETAILS_REQUEST:
      return { loading: true, ...state };
    case STAFF_DETAILS_SUCCESS:
      return { loading: false, staff: action.payload };
    case STAFF_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const staffDetailsByStaffIdReducer = (state = { staff: {} }, action) => {
  switch (action.type) {
    case STAFF_DETAILS_BY_STAFF_ID_REQUEST:
      return { loading: true, ...state };
    case STAFF_DETAILS_BY_STAFF_ID_SUCCESS:
      return { loading: false, staff: action.payload };
    case STAFF_DETAILS_BY_STAFF_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const staffUpdateReducer = (state = { staff: {} }, action) => {
  switch (action.type) {
    case STAFF_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case STAFF_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        staff: action.payload,
      };
    case STAFF_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case STAFF_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const staffDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STAFF_DELETE_REQUEST:
      return { loading: true };
    case STAFF_DELETE_SUCCESS:
      return { loading: false, success: true };
    case STAFF_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case STAFF_DELETE_RESET:
      return {};
    default:
      return state;
  }
};
