import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../../components/Meta";
import { wholesalerOrderAddress } from "../../../actions/addressBookAction";
import { getWholesalerDetails } from "../../../actions/userActions";

export const WholesalerShippingScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: detailUser } = wholesalerDetails;

  const addressWholesalerOrder = useSelector(
    (state) => state.addressWholesalerOrder
  );
  const { success: successButton } = addressWholesalerOrder;

  const submitPaymentHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      e.preventDefault();
      history.push("/payment");
    }
  };

  const selectWholesalerAddress = (e, id, i) => {
    e.preventDefault();
    const wholesalerId = id;
    const key = i;
    if (
      detailUser.branchAddress[key] &&
      wholesalerId === detailUser.branchAddress[key]._id
    ) {
      dispatch(
        wholesalerOrderAddress({
          wholesalerId,
          name: detailUser.branchAddress[key].name,
          addressLine1: detailUser.branchAddress[key].addressLine1,
          province: detailUser.branchAddress[key].province,
          district: detailUser.branchAddress[key].district,
          muncipality: detailUser.branchAddress[key].muncipality,
          country: detailUser.branchAddress[key].country,
          phone: detailUser.branchAddress[key].phone,
          postalCode: detailUser.branchAddress[key].postalCode,
        })
      );
    } else if (wholesalerId === detailUser.warehouseAddress[key]._id) {
      dispatch(
        wholesalerOrderAddress({
          wholesalerId,
          name: detailUser.warehouseAddress[key].name,
          addressLine1: detailUser.warehouseAddress[key].addressLine1,
          province: detailUser.warehouseAddress[key].province,
          district: detailUser.warehouseAddress[key].district,
          muncipality: detailUser.warehouseAddress[key].muncipality,
          country: detailUser.warehouseAddress[key].country,
          phone: detailUser.warehouseAddress[key].phone,
          postalCode: detailUser.warehouseAddress[key].postalCode,
        })
      );
    }
    // history.push("/payment");
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.type === "wholesaler") {
        dispatch(getWholesalerDetails(userInfo?._id));
      }
      if (successButton) {
        history.push("/payment");
      }
    }
  }, [history, dispatch, userInfo, successButton]);
  return (
    <>
      <Meta title="Shipping Details" />

      <h1 className="mt-5">Shipping</h1>
      <Container>
        {detailUser && (
          <Form
            noValidate
            validated={validated}
            onSubmit={submitPaymentHandler}
          >
            <Row>
              {detailUser?.branchAddress?.length === 0 &&
                detailUser?.warehouseAddress?.length === 0 && (
                  <Col md={4}>
                    <Button
                      onClick={() => {
                        history.push("/wholesaler/registration?ref=ship");
                      }}
                    >
                      Add Address
                    </Button>
                  </Col>
                )}
              {detailUser?.branchAddress?.map((addressData, index) => {
                if (!addressData.inactive)
                  return (
                    <Col md={6}>
                      <Card
                        key={index}
                        style={{
                          boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
                        }}
                        className="p-4 m-4"
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                          }}
                          className="bg-dark"
                        >
                          Branch Address
                        </span>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: 18, fontWeight: 900 }}>
                            <b>Branch Name: </b>
                            {addressData.name}
                          </li>
                          <li>
                            <b>Street Address: </b>
                            {addressData.addressLine1}
                          </li>
                          <li>
                            <b>Province: </b>
                            {addressData.province}
                          </li>
                          <li>
                            <b>District: </b>
                            {addressData.district}
                          </li>
                          <li>
                            <b>Country: </b>
                            {addressData.country}
                          </li>
                          <li>
                            <b>Phone Number: </b>
                            {addressData.phone}
                          </li>
                          <li>
                            <b>Postal Code: </b>
                            {addressData.postalCode}
                          </li>
                        </ul>
                        <Button
                          type="button"
                          className="btn btn-primary"
                          onClick={(e) =>
                            selectWholesalerAddress(e, addressData._id, index)
                          }
                        >
                          Select Address
                        </Button>
                      </Card>
                    </Col>
                  );
              })}
            </Row>

            <Row className="my-4">
              {detailUser?.warehouseAddress?.map((addressData, index) => {
                if (!addressData.inactive)
                  <Col md={6}>
                    <Card
                      key={index}
                      style={{
                        boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
                      }}
                      className="p-4 m-4"
                    >
                      <span
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          color: "#ffffff",
                          padding: "2px 8px",
                          textTransform: "uppercase",
                          fontSize: "0.85rem",
                        }}
                        className="bg-info"
                      >
                        Warehouse Address
                      </span>
                      <ul style={{ listStyle: "none" }}>
                        <li style={{ fontSize: 18, fontWeight: 900 }}>
                          <b>Warehouse Name: </b>
                          {addressData.name}
                        </li>
                        <li>
                          <b>Street Address: </b>
                          {addressData.addressLine1}
                        </li>
                        <li>
                          <b>Province: </b>
                          {addressData.province}
                        </li>
                        <li>
                          <b>District: </b>
                          {addressData.district}
                        </li>
                        <li>
                          <b>Country: </b>
                          {addressData.country}
                        </li>

                        <li>
                          <b>Phone Number: </b>
                          {addressData.phone}
                        </li>
                        <li>
                          <b>Postal Code: </b>
                          {addressData.postalCode}
                        </li>
                      </ul>
                      <Button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) =>
                          selectWholesalerAddress(e, addressData._id, index)
                        }
                      >
                        Select Address
                      </Button>
                    </Card>
                  </Col>;
              })}
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default WholesalerShippingScreen;

// import React, { useEffect } from "react";
// import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import WholesalerCheckoutSteps from "../../../components/WholesalerChectoutSteps";
// import Meta from "../../../components/Meta";
// import { wholesalerOrderAddress } from "../../../actions/addressBookAction";
// import { getWholesalerDetails } from "../../../actions/userActions";

// export const WholesalerShippingScreen = ({ history, match }) => {
//   const dispatch = useDispatch();

//   const userLogin = useSelector((state) => state.userLogin);
//   const { userInfo } = userLogin;

//   const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
//   const { user: detailUser } = wholesalerDetails;

//   const addressWholesalerOrder = useSelector(
//     (state) => state.addressWholesalerOrder
//   );
//   const { success: successButton } = addressWholesalerOrder;

//   const submitPaymentHandler = (e) => {
//     e.preventDefault();
//     history.push("/payment");
//   };

//   const selectWholesalerAddress = (e, id, i) => {
//     e.preventDefault();
//     const wholesalerId = id;
//     const key = i;
//     if (
//       detailUser.branchAddress[key] &&
//       wholesalerId === detailUser.branchAddress[key]._id
//     ) {
//       dispatch(
//         wholesalerOrderAddress({
//           wholesalerId,
//           name: detailUser.branchAddress[key].name,
//           addressLine1: detailUser.branchAddress[key].addressLine1,
//           province: detailUser.branchAddress[key].province,
//           district: detailUser.branchAddress[key].district,
//           muncipality: detailUser.branchAddress[key].muncipality,
//           country: detailUser.branchAddress[key].country,
//           phone: detailUser.branchAddress[key].phone,
//           postalCode: detailUser.branchAddress[key].postalCode,
//         })
//       );
//     } else if (wholesalerId === detailUser.warehouseAddress[key]._id) {
//       dispatch(
//         wholesalerOrderAddress({
//           wholesalerId,
//           name: detailUser.warehouseAddress[key].name,
//           addressLine1: detailUser.warehouseAddress[key].addressLine1,
//           province: detailUser.warehouseAddress[key].province,
//           district: detailUser.warehouseAddress[key].district,
//           muncipality: detailUser.warehouseAddress[key].muncipality,
//           country: detailUser.warehouseAddress[key].country,
//           phone: detailUser.warehouseAddress[key].phone,
//           postalCode: detailUser.warehouseAddress[key].postalCode,
//         })
//       );
//     }
//     // history.push("/payment");
//   };

//   useEffect(() => {
//     if (userInfo) {
//       if (userInfo?.type === "wholesaler") {
//         dispatch(getWholesalerDetails(userInfo?._id));
//       }
//       if (successButton) {
//         history.push("/payment");
//       }
//     }
//   }, [history, dispatch, userInfo, successButton]);
//   return (
//     <>
//       <Meta title="Shipping Details" />
//       <WholesalerCheckoutSteps step1 step2 />
//       <h1>Shipping</h1>
//       <Container>
//         {detailUser && (
//           <Form onSubmit={submitPaymentHandler}>
//             <Row>
//               {detailUser?.branchAddress?.length === 0 &&
//                 detailUser?.warehouseAddress?.length === 0 && (
//                   <Col md={4}>
//                     <Button
//                       onClick={() => {
//                         history.push("/wholesaler/registration?ref=ship");
//                       }}
//                     >
//                       Add Address
//                     </Button>
//                   </Col>
//                 )}
//               {detailUser?.branchAddress?.map((addressData, index) => (
//                 <Col md={6}>
//                   <Card
//                     key={index}
//                     style={{
//                       boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
//                     }}
//                     className="p-4"
//                   >
//                     <span
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         color: "#ffffff",
//                         padding: "2px 8px",
//                         textTransform: "uppercase",
//                         fontSize: "0.85rem",
//                       }}
//                       className="bg-dark"
//                     >
//                       Branch Address
//                     </span>
//                     <ul style={{ listStyle: "none" }}>
//                       <li style={{ fontSize: 18, fontWeight: 900 }}>
//                         <b>Branch Name: </b>
//                         {addressData.name}
//                       </li>
//                       <li>
//                         <b>Street Address: </b>
//                         {addressData.addressLine1}
//                       </li>
//                       <li>
//                         <b>Region: </b>
//                         {addressData.province}
//                       </li>
//                       <li>
//                         <b>Province: </b>
//                         {addressData.district}
//                       </li>
//                       <li>
//                         <b>Country: </b>
//                         {addressData.country}
//                       </li>
//                       <li>
//                         <b>Phone Number: </b>
//                         {addressData.phone}
//                       </li>
//                       <li>
//                         <b>Postal Code: </b>
//                         {addressData.postalCode}
//                       </li>
//                     </ul>
//                     <Button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={(e) =>
//                         selectWholesalerAddress(e, addressData._id, index)
//                       }
//                     >
//                       Select Address
//                     </Button>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>

//             <Row className="my-4">
//               {detailUser?.warehouseAddress?.map((addressData, index) => (
//                 <Col md={6}>
//                   <Card
//                     key={index}
//                     style={{
//                       boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
//                     }}
//                     className="p-4"
//                   >
//                     <span
//                       style={{
//                         position: "absolute",
//                         top: 0,
//                         left: 0,
//                         color: "#ffffff",
//                         padding: "2px 8px",
//                         textTransform: "uppercase",
//                         fontSize: "0.85rem",
//                       }}
//                       className="bg-info"
//                     >
//                       Warehouse Address
//                     </span>
//                     <ul style={{ listStyle: "none" }}>
//                       <li style={{ fontSize: 18, fontWeight: 900 }}>
//                         <b>Warehouse Name: </b>
//                         {addressData.name}
//                       </li>
//                       <li>
//                         <b>Street Address: </b>
//                         {addressData.addressLine1}
//                       </li>
//                       <li>
//                         <b>Region: </b>
//                         {addressData.province}
//                       </li>
//                       <li>
//                         <b>Province: </b>
//                         {addressData.district}
//                       </li>
//                       <li>
//                         <b>Country: </b>
//                         {addressData.country}
//                       </li>

//                       <li>
//                         <b>Phone Number: </b>
//                         {addressData.phone}
//                       </li>
//                       <li>
//                         <b>Postal Code: </b>
//                         {addressData.postalCode}
//                       </li>
//                     </ul>
//                     <Button
//                       type="button"
//                       className="btn btn-primary"
//                       onClick={(e) =>
//                         selectWholesalerAddress(e, addressData._id, index)
//                       }
//                     >
//                       Select Address
//                     </Button>
//                   </Card>
//                 </Col>
//               ))}
//             </Row>
//           </Form>
//         )}
//       </Container>
//     </>
//   );
// };

// export default WholesalerShippingScreen;
