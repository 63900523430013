import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const DashboardCart = () => {
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const items = cartItems?.length;
  return (
    <>
      <Link className="dashboardCartICon py-3 px-3" to="/cart">
        <i className="bi bi-cart3"></i>
        {items ? items : ""}
      </Link>
    </>
  );
};

export default DashboardCart;
