import React from "react";
import { Route } from "react-router-dom";
import Promotion from "../screens/Promotion/Promotion";
import PromotionRequests from "../screens/Promotion/PromotionRequests";
import Promotions from "../screens/Promotion/Promotions";
import PromotionListScreen from "../screens/Promotion/PromotionsListScreen";

const PromotionRoutes = () => {
  return (
    <div>
      <Route path="/admin/promotions" component={PromotionListScreen} exact />
      <Route path="/vendor/promotions" component={Promotions} />
      <Route path="/wholesaler/promotions" component={Promotions} />
      <Route path="/promotions/:id" component={Promotion} />
      <Route path="/promotion/requests/:id" component={PromotionRequests} />
    </div>
  );
};

export default PromotionRoutes;
