import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import "../index.css";
import {
  addToWishlist,
  removeFromWishlist,
} from "../actions/productActions.js";

import TagManager from "react-gtm-module";

const AddToWishListButton = ({ product, history }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const wishlistList = useSelector((state) => state.wishlistList);
  const { products } = wishlistList;

  const [checked, setChecked] = useState(false);

  const wishListHandler = (e) => {
    if (!userInfo) {
      history.push("/login");
      // }
    } else {
      if (checked) {
        dispatch(removeFromWishlist(product?._id));
      } else {
        const tagManagerArgs = {
          gtmId: "GTM-P27XCP3",
          dataLayer: {
            event: "Add to wishlist",
            productId: product?._id || "",
            name: product?.name || "",
            category: product?.category || "",
            brand: product?.brand || "",
            price: product?.price || "",
            userId: userInfo?._id || "",
            time: Date.now(),
          },
        };

        TagManager.dataLayer(tagManagerArgs);
        dispatch(addToWishlist(product));
      }
      setChecked(!checked);
    }
  };
  let wishlisted;

  useEffect(() => {
    if (products) {
      wishlisted = products.findIndex(
        (item) => item.productId?._id === product?._id
      );
    }
    if (wishlisted !== -1) {
      setChecked(true);
    }
  }, [dispatch, products]);

  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip>
          {checked ? (
            <span className="tooltipText">Remove from Wishlist</span>
          ) : (
            <span className="tooltipText">Add to Wishlist</span>
          )}
        </Tooltip>
      }
    >
      <Button
        style={{
          padding: "10px",
          background: "transparent",
          border: "none",

          outline: "none !important",
        }}
        onClick={wishListHandler}
      >
        {checked ? (
          <i
            style={{ color: "#dc1e3e", fontSize: "18px" }}
            className="bi bi-heart-fill"
          ></i>
        ) : (
          <i
            style={{ color: "#000000", fontSize: "18px" }}
            className="bi bi-heart addToWishWishlistIcon"
          ></i>
        )}
      </Button>
    </OverlayTrigger>
  );
};

export default AddToWishListButton;
