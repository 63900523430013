import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../../../components/Meta";
import { vendorOrderAddress } from "../../../actions/addressBookAction";
import { getVendorDetails } from "../../../actions/userActions";

export const VendorShippingScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: detailsUser } = vendorDetails;

  const addressVendorOrder = useSelector((state) => state.addressVendorOrder);
  const { success: successButton } = addressVendorOrder;

  const submitPaymentHandler = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      e.preventDefault();
      history.push("/payment");
    }
  };

  const selectVendorAddress = (e, id, i) => {
    // event.preventDefault();
    const vendorId = id;
    const key = i;

    if (
      detailsUser.branchAddress[key] &&
      vendorId === detailsUser.branchAddress[key]._id
    ) {
      dispatch(
        vendorOrderAddress({
          vendorId,
          name: detailsUser.branchAddress[key].name,
          addressLine1: detailsUser.branchAddress[key].addressLine1,
          province: detailsUser.branchAddress[key].province,
          district: detailsUser.branchAddress[key].district,
          muncipality: detailsUser.branchAddress[key].muncipality,
          country: detailsUser.branchAddress[key].country,
          phone: detailsUser.branchAddress[key].phone,
          postalCode: detailsUser.branchAddress[key].postalCode,
        })
      );
    } else if (vendorId === detailsUser.warehouseAddress[key]._id) {
      dispatch(
        vendorOrderAddress({
          vendorId,
          name: detailsUser.warehouseAddress[key].name,
          addressLine1: detailsUser.warehouseAddress[key].addressLine1,
          province: detailsUser.warehouseAddress[key].province,
          district: detailsUser.warehouseAddress[key].district,
          muncipality: detailsUser.warehouseAddress[key].muncipality,
          country: detailsUser.warehouseAddress[key].country,
          phone: detailsUser.warehouseAddress[key].phone,
          postalCode: detailsUser.warehouseAddress[key].postalCode,
        })
      );
    }
    // history.push("/payment");
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo?.type === "vendor") {
        dispatch(getVendorDetails(userInfo?._id));
        if (successButton) {
          history.push("/payment");
        }
      }
    }
  }, [history, dispatch, userInfo, successButton]);
  return (
    <>
      <Meta title="Shipping Details" />

      <h1 className="mt-5">Shipping</h1>
      <Container>
        {detailsUser && (
          <Form
            noValidate
            validated={validated}
            onSubmit={submitPaymentHandler}
          >
            <Row>
              {detailsUser?.branchAddress?.length === 0 &&
                detailsUser?.warehouseAddress?.length === 0 && (
                  <Col md={4}>
                    <Button
                      onClick={() => {
                        history.push("/vendor/registration?ref=ship");
                      }}
                    >
                      Add Address
                    </Button>
                  </Col>
                )}
              {detailsUser?.branchAddress?.map((addressData, index) => {
                if (!addressData.inactive)
                  return (
                    <Col md={6}>
                      <Card
                        key={index}
                        style={{
                          boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
                        }}
                        className="p-4 mx-4"
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                          }}
                          className="bg-dark"
                        >
                          Branch Address
                        </span>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: 18, fontWeight: 900 }}>
                            <b>Branch Name: </b>
                            {addressData.name}
                          </li>
                          <li>
                            <b>Street Address: </b>
                            {addressData.addressLine1}
                          </li>
                          <li>
                            <b>Province: </b>
                            {addressData.province}
                          </li>
                          <li>
                            <b>District: </b>
                            {addressData.district}
                          </li>
                          <li>
                            <b>Country: </b>
                            {addressData.country}
                          </li>
                          <li>
                            <b>Phone Number: </b>
                            {addressData.phone}
                          </li>
                          <li>
                            <b>Postal Code: </b>
                            {addressData.postalCode}
                          </li>
                        </ul>
                        <Button
                          className="btn btn-primary"
                          onClick={(e) =>
                            selectVendorAddress(e, addressData._id, index)
                          }
                        >
                          Select Address
                        </Button>
                      </Card>
                    </Col>
                  );
              })}
            </Row>

            <Row className="my-4">
              {detailsUser?.warehouseAddress?.map((addressData, index) => {
                if (!addressData.inactive)
                  return (
                    <Col md={6}>
                      <Card
                        key={index}
                        style={{
                          boxShadow: "5px 10px 25px rgba(0, 0, 0, 0.25)",
                        }}
                        className="p-4 m-4"
                      >
                        <span
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            color: "#ffffff",
                            padding: "2px 8px",
                            textTransform: "uppercase",
                            fontSize: "0.85rem",
                          }}
                          className="bg-info"
                        >
                          Warehouse Address
                        </span>
                        <ul style={{ listStyle: "none" }}>
                          <li style={{ fontSize: 18, fontWeight: 900 }}>
                            <b>Warehouse Name: </b>
                            {addressData.name}
                          </li>
                          <li>
                            <b>Address: </b>
                            {addressData.addressLine1}
                          </li>
                          <li>
                            <b>Province: </b>
                            {addressData.province}
                          </li>
                          <li>
                            <b>District: </b>
                            {addressData.district}
                          </li>
                          <li>
                            <b>Country: </b>
                            {addressData.country}
                          </li>

                          <li>
                            <b>Phone Number: </b>
                            {addressData.phone}
                          </li>
                          <li>
                            <b>Postal Code: </b>
                            {addressData.postalCode}
                          </li>
                        </ul>
                        <Button
                          className="btn btn-primary"
                          onClick={(e) =>
                            selectVendorAddress(e, addressData._id, index)
                          }
                        >
                          Select Address
                        </Button>
                      </Card>
                    </Col>
                  );
              })}
            </Row>
          </Form>
        )}
      </Container>
    </>
  );
};

export default VendorShippingScreen;
