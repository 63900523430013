import React, { useState, useEffect } from "react";
import { Form, Button, Table, Row, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { staffDelete, getStaff, createStaff } from "../../actions/staffActions";
import {
  STAFF_CREATE_RESET,
  STAFF_DELETE_RESET,
  STAFF_UPDATE_RESET,
} from "../../constants/staffConstants";

import "./ManageStaffsComponent.css";

const ManageStaffsComponent = ({ user }) => {
  const dispatch = useDispatch();

  dispatch({ type: STAFF_UPDATE_RESET });
  const staffList = useSelector((state) => state.staffList);
  const { staffs } = staffList;
  const staffCreate = useSelector((state) => state.staffCreate);
  const { success } = staffCreate;
  const deleteStaff = useSelector((state) => state.deleteStaff);
  const { success: deleteSuccess } = deleteStaff;

  const staffDetails = useSelector((state) => state.staffDetails);
  const { staff } = staffDetails;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState("user");
  const [id, setId] = useState("");
  const [entityType, setEntityType] = useState("");
  const [entityName, setEntityName] = useState("");
  const [position, setPosition] = useState("");
  const [validated, setValidated] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(
        createStaff({
          name,
          email,
          password,
          confirmPassword,
          phone,
          role,
          id: user?._id,
          staffId: id,
          entityType,
          entityName,
          position,
        })
      );
      setName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPhone("");
      setRole("");
      handleClose();
    }
  };
  const deleteStaffHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this staff")) {
      dispatch(staffDelete(id));
    }
  };
  useEffect(() => {
    if (success || deleteSuccess) {
      if (user) {
        dispatch(getStaff(user?._id));
      }
      dispatch({ type: STAFF_CREATE_RESET });
      dispatch({ type: STAFF_DELETE_RESET });
    }
  }, [dispatch, success, deleteSuccess, user?._id, user]);
  useEffect(() => {
    if (user) {
      dispatch(getStaff(user._id));
    }
  }, [dispatch, user?._id, user]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className="container">
      <Row className="staffListRow mt-3">
        <Col md={6}>
          <h1 className="staffListHeading mt-3">Staff List </h1>
        </Col>
        <Col md={6}>
          {staff?.role === "admin" && (
            <Button className="staffListAddStaffBtn" onClick={handleShow}>
              <i className="far fa-plus-square"></i> Create New Staffs
            </Button>
          )}
        </Col>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create new Staff</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              noValidate
              validated={validated}
              onSubmit={(e) => submitHandler(e)}
            >
              <Row className="p-2">
                <Col md={6} className="p-2">
                  <Form.Group className="manageStaffFormGroup" controlId="id">
                    <Form.Label className="manageStaffFormLabel">Id</Form.Label>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Id"
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group className="manageStaffFormGroup" controlId="name">
                    <Form.Label className="manageStaffFormLabel">
                      Name
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="email"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Email
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="email"
                      placeholder="Enter Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="password"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Password
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="confirmPassword"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6} className="p-2">
                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="phone"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Phone
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="number"
                      placeholder="Phone Number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="entityType"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Entity Type
                    </Form.Label>

                    <Form.Control
                      className="inputField"
                      as="select"
                      value={entityType}
                      onChange={(e) => setEntityType(e.target.value)}
                    >
                      <option value="">
                        Choose Branch that handles the product
                      </option>
                      <option value="warehouse">Warehouse</option>
                      <option value="branch">Branch</option>
                      <option value="headOffice">Head Office</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="entityName"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Entity Name
                    </Form.Label>

                    <Form.Control
                      className="inputField"
                      as="select"
                      value={entityName}
                      onChange={(e) => setEntityName(e.target.value)}
                    >
                      {entityType === "warehouse" &&
                        user?.warehouseAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}
                      {entityType === "branch" &&
                        user?.branchAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}

                      {entityType === "headOffice" && (
                        <option value="headOffice">HeadOffice</option>
                      )}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    className="manageStaffFormGroup"
                    controlId="position"
                  >
                    <Form.Label className="manageStaffFormLabel">
                      Position
                    </Form.Label>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Position"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="manageStaffFormGroup" controlId="type">
                    <Form.Label className="manageStaffFormLabel">
                      Account Type
                    </Form.Label>

                    <Col>
                      <Form.Check
                        type="radio"
                        label="User"
                        name="type"
                        value="user"
                        onChange={(e) => setRole(e.target.value)}
                      ></Form.Check>
                      <Form.Check
                        type="radio"
                        label="Moderator"
                        name="type"
                        value="moderator"
                        onChange={(e) => setRole(e.target.value)}
                      ></Form.Check>
                      <Form.Check
                        type="radio"
                        label="Admin"
                        name="type"
                        value="admin"
                        onChange={(e) => setRole(e.target.value)}
                      ></Form.Check>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Button
                className="addStaffBtn mx-3"
                type="submit"
                variant="primary"
              >
                Insert
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </Row>

      <Row className="mt-3 mb-5">
        <Table responsive>
          <thead>
            <tr className="productListRowHeading">
              <th>ID</th>
              <th>NAME</th>
              <th>ROLE</th>
              <th>EMAIL</th>
              <th>PHONE</th>
              <th>ENTITY</th>
              <th className="StaffListTdCenter">EDIT </th>
              <th className="StaffListTdCenter">DELETE</th>
            </tr>
          </thead>
          <tbody>
            {staffs?.map((staff) => (
              <tr key={staff._id}>
                <td></td>
                <td>{staff?.userId?.name}</td>
                <td>{staff.role}</td>
                <td>{staff?.userId?.email}</td>
                <td>{staff?.userId?.phone}</td>
                <td></td>
                <td className="StaffListTdCenter">
                  <Link to={`/vendor/staff/${staff._id}/edit`}>
                    <i className="fas fa-edit text-secondary"></i>
                  </Link>
                </td>

                <td className="StaffListTdCenter">
                  <Link onClick={() => deleteStaffHandler(staff._id)}>
                    <i className="fas fa-trash text-danger"></i>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Row>
    </div>
  );
};

export default ManageStaffsComponent;
