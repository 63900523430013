import axios from "axios";
import {
  USER_ADDRESSBOOK_SUCCESS,
  USER_ADDRESSBOOK_REQUEST,
  USER_ADDRESSBOOK_FAIL,
  USER_CREATE_ADDRESSBOOK_REQUEST,
  USER_CREATE_ADDRESSBOOK_SUCCESS,
  USER_CREATE_ADDRESSBOOK_FAIL,
  USER_UPDATE_ADDRESSBOOK_REQUEST,
  USER_UPDATE_ADDRESSBOOK_SUCCESS,
  USER_UPDATE_ADDRESSBOOK_FAIL,
  USER_DETAILS_ADDRESSBOOK_REQUEST,
  USER_DETAILS_ADDRESSBOOK_SUCCESS,
  USER_DETAILS_ADDRESSBOOK_FAIL,
  ORDER_USER_ADDRESS_FAIL,
  ORDER_USER_ADDRESS_SUCCESS,
  ORDER_USER_ADDRESS_REQUEST,
  VENDOR_ORDER_ADDRESS_SUCCESS,
  VENDOR_ORDER_ADDRESS_REQUEST,
  VENDOR_ORDER_ADDRESS_FAIL,
  VENDOR_ORDER_ADDRESS_GET_SUCCESS,
  VENDOR_ORDER_ADDRESS_GET_REQUEST,
  VENDOR_ORDER_ADDRESS_GET_FAIL,
  WHOLESELLER_ORDER_ADDRESS_GET_FAIL,
  WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS,
  WHOLESELLER_ORDER_ADDRESS_GET_REQUEST,
  WHOLESELLER_ORDER_ADDRESS_FAIL,
  WHOLESELLER_ORDER_ADDRESS_SUCCESS,
  WHOLESELLER_ORDER_ADDRESS_REQUEST,
} from "../constants/addressConstants";

export const createAddressBook = (address) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_CREATE_ADDRESSBOOK_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/users/address/book`,
      address,
      config
    );
    dispatch({
      type: USER_CREATE_ADDRESSBOOK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_CREATE_ADDRESSBOOK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getAddressBook = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_ADDRESSBOOK_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`/api/users/address/book/${id}`, config);
    dispatch({
      type: USER_ADDRESSBOOK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ADDRESSBOOK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateAddress = (address) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_ADDRESSBOOK_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(
      `/api/users/address/book/${address.id}/edit`,
      address,
      config
    );
    dispatch({
      type: USER_UPDATE_ADDRESSBOOK_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_ADDRESSBOOK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailAddress = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_ADDRESSBOOK_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/users/address/book/${id}/edit`,
      config
    );

    dispatch({ type: USER_DETAILS_ADDRESSBOOK_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_ADDRESSBOOK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailAddressOrder = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_USER_ADDRESS_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/users/customer/shipping/detail`,
      config
    );

    dispatch({ type: ORDER_USER_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORDER_USER_ADDRESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const vendorOrderAddress =
  (addressVendor) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VENDOR_ORDER_ADDRESS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/users/vendor/address/${addressVendor.vendorId}`,
        addressVendor,
        config
      );
      dispatch({
        type: VENDOR_ORDER_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VENDOR_ORDER_ADDRESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getVendorOrderAddress =
  (vendorId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VENDOR_ORDER_ADDRESS_GET_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users/vendor/shipping/address/${vendorId}`,
        config
      );
      dispatch({
        type: VENDOR_ORDER_ADDRESS_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VENDOR_ORDER_ADDRESS_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const wholesalerOrderAddress =
  (addressWholesaler) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/users/wholesaler/address/${addressWholesaler.wholesalerId}`,
        addressWholesaler,
        // userInfo,
        config
      );
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getWholesalerOrderAddress =
  (wholesalerId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_GET_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users/wholesaler/shipping/address/${wholesalerId}`,
        config
      );
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHOLESELLER_ORDER_ADDRESS_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
