import React from "react";

const Loader = () => {
  return (
    <img
      style={{
        width: "50px",
        height: "50px",
        margin: "auto",
        display: "block",
      }}
      src="/images/spi.gif"
      alt="Loading..."
    />
  );
};

export default Loader;
