import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { Button, Row, Col, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../../components/Message";
import {
  createOrder,
  createVendorOrder,
  createWholesalerOrder,
} from "../../../actions/orderActions";
import { getVendorOrderAddress } from "../../../actions/addressBookAction";
import "./VendorPlaceOrderScreen.css";
import { customAlphabet } from "nanoid";
import {
  getCheckedCartFromServer,
  removeCheckedCartItemsFromServer,
} from "../../../actions/cartActions";
import { VENDOR_ORDER_ADDRESS_RESET } from "../../../constants/addressConstants";
import TagManager from "react-gtm-module";

const VendorPlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const { paymentMethod: addr } = queryString.parse(search);

  let seperatedVendorProducts;
  let seperatedWholesalerProducts;

  // const cart = useSelector((state) => state.cart);
  const getCheckedCartItems = useSelector((state) => state.getCheckedCartItems);
  const { cart } = getCheckedCartItems;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addressVendorOrder = useSelector((state) => state.addressVendorOrder);
  const { addressVendor } = addressVendorOrder;

  const addressVendorOrderGet = useSelector(
    (state) => state.addressVendorOrderGet
  );
  const { addressVendorData } = addressVendorOrderGet;

  const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNPQRSTUVWXYZ", 8);
  const identifier = nanoid();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;

  const orderCreateVendor = useSelector((state) => state.orderCreateVendor);
  const { success: successVendor } = orderCreateVendor;

  const orderCreateWholesaler = useSelector(
    (state) => state.orderCreateWholesaler
  );
  const { success: successWholesaler } = orderCreateWholesaler;
  //uploaderTypewise order seperation
  function mainGroupArrayOfObjects(list, key) {
    return list.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  const uploaderTypeWiseProducts = mainGroupArrayOfObjects(
    cart,
    "uploaderType"
  );
  const vendorProducts = uploaderTypeWiseProducts.vendor;
  const wholesalerProducts = uploaderTypeWiseProducts.wholesaler;
  if (vendorProducts) {
    // vendorwise order seperation
    function groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
    const vendorWiseProducts = groupArrayOfObjects(
      vendorProducts,
      "updatedById"
    );

    const venderWiseProductArray = (vendorWiseProducts) => {
      const res = Object.values(vendorWiseProducts);
      return res;
    };
    seperatedVendorProducts = venderWiseProductArray(vendorWiseProducts);
  }

  // Prices calculations
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart?.reduce(
      (acc, item) => acc + (item.sellingPrice || item.price) * item.qty,
      0
    )
  );

  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 100 : 0);

  cart.taxPrice = addDecimals(Number(0.15 * cart.itemsPrice));

  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  if (wholesalerProducts) {
    function groupArrayOfObjects(list, key) {
      return list.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
    const WholesalerWiseProducts = groupArrayOfObjects(
      wholesalerProducts,
      "updatedById"
    );

    const wholesalerWiseProductArray = (WholesalerWiseProducts) => {
      const res = Object.values(WholesalerWiseProducts);
      return res;
    };
    seperatedWholesalerProducts = wholesalerWiseProductArray(
      WholesalerWiseProducts
    );
  }

  const placeOrderHandler = () => {
    let shipp = [];
    if (addressVendor.length !== 0) {
      shipp = {
        shipping_addressLine1: addressVendor.address.addressLine1,
        shipping_province: addressVendor.address.province,
        shipping_district: addressVendor.address.district,
        shipping_country: addressVendor.address.country,
        shipping_muncipality: addressVendor.address.muncipality,
        shipping_postalCode: addressVendor.address.postalCode,
        shipping_phone: addressVendor.address.phone,
        shipping_place: addressVendor.address.name,
        billing_addressLine1: addressVendor.address.addressLine1,
        billing_province: addressVendor.address.province,
        billing_district: addressVendor.address.district,
        billing_country: addressVendor.address.country,
        billing_muncipality: addressVendor.address.muncipality,
        billing_postalCode: addressVendor.address.postalCode,
        billing_phone: addressVendor.address.phone,
        billing_place: addressVendor.address.name,
      };
    }

    seperatedVendorProducts?.forEach((list) => {
      const addDecimals = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
      };

      const vendorTotal = addDecimals(
        list?.reduce((acc, item) => acc + item.price * item.qty, 0)
      );

      const vendorShippingPrice = addDecimals(vendorTotal > 100 ? 100 : 0);

      const vendorTaxPrice = addDecimals(Number(0.15 * vendorTotal));

      const vendorTotalPrice = (
        Number(vendorTotal) +
        Number(vendorShippingPrice) +
        Number(vendorTaxPrice)
      ).toFixed(2);
      dispatch(
        createVendorOrder({
          identifier,
          orderItems: list,
          address: shipp,
          paymentMethod: addr,
          shippingPrice: vendorShippingPrice,
          taxPrice: vendorTaxPrice,
          totalPrice: vendorTotalPrice,
        })
      );
    });
    seperatedWholesalerProducts?.forEach((list) => {
      const addDecimals = (num) => {
        return (Math.round(num * 100) / 100).toFixed(2);
      };

      const wholesalerTotal = addDecimals(
        list?.reduce((acc, item) => acc + item.price * item.qty, 0)
      );

      const wholesalerShippingPrice = addDecimals(
        wholesalerTotal > 100 ? 100 : 0
      );

      const wholesalerTaxPrice = addDecimals(Number(0.15 * wholesalerTotal));

      const wholesalerTotalPrice = (
        Number(wholesalerTotal) +
        Number(wholesalerShippingPrice) +
        Number(wholesalerTaxPrice)
      ).toFixed(2);

      dispatch(
        createWholesalerOrder({
          identifier,
          orderItems: list,
          address: shipp,
          paymentMethod: addr,
          shippingPrice: wholesalerShippingPrice,
          taxPrice: wholesalerTaxPrice,
          totalPrice: wholesalerTotalPrice,
        })
      );
    });
    dispatch(
      createOrder({
        identifier,
        userId: userInfo?._id,
        userName: userInfo?.name,
        orderItems: cart,
        address: shipp,
        paymentMethod: addr,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );

    const tagManagerArgs = {
      gtmId: "GTM-P27XCP3",
      dataLayer: {
        event: "Order Created", // event to be picked up by GTM
        userId: userInfo?._id,
        orderItems: cart,
        address: shipp,
        paymentMethod: addr,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        time: Date.now(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);
  };

  const placeOrderDisabler =
    cart?.length === 0 || !addressVendor?.address?.addressLine1;

  useEffect(() => {
    dispatch(getVendorOrderAddress(addressVendor?.address?.vendorId));

    if (success && successVendor) {
      dispatch(removeCheckedCartItemsFromServer());

      history.push(`/order/${order._id}`);
    }
    if (success && successWholesaler) {
      dispatch(removeCheckedCartItemsFromServer());

      history.push(`/order/${order._id}`);
    }
  }, [
    history,
    dispatch,
    success,
    successVendor,
    successWholesaler,
    order?._id,
    addressVendor?.address?.vendorId,
  ]);
  const changeShipping = async () => {
    dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
    history.push("/retail/shipping/detail");
  };

  const changePayment = async () => {
    history.push("/payment");
  };

  useEffect(() => {
    dispatch(getCheckedCartFromServer());
  }, [dispatch]);

  return (
    <>
      {!addressVendor?.address?.addressLine1 && (
        <Message variant="danger">
          You need to select a shipping address{" "}
        </Message>
      )}
      <h4 className="placeorder-heading mt-4">Place Order</h4>
      <Row className="mt-5 placeorder-row py-4 mb-4">
        {addressVendorData && (
          <Row className="mb-5 ">
            <Col md={6}>
              <h5 className="placeorder-address-heading mx-5">
                Shipping Address
              </h5>

              <Row className="placeorder-address-box mx-5 mt-4">
                <Col md={12} className="mt-3">
                  <i className="fas fa-map-marker-alt me-3 placeorder-fa"></i>
                  {addressVendor?.address?.addressLine1},
                  {addressVendor?.address?.province},
                  {addressVendor?.address?.district},
                  {addressVendor?.address?.country},
                  {addressVendor?.address?.muncipality},
                  {addressVendor?.address?.postalCode}
                </Col>
                <Col md={12} className="mt-3">
                  <i className="fas fa-envelope me-3 placeorder-fa"></i>
                  {addressVendor?.vendorData?.email}
                </Col>
                <Col md={12} className="my-3">
                  <i className="fas fa-phone-alt me-3 placeorder-fa"></i>
                  {addressVendor?.address?.phone}
                </Col>
                <div
                  onClick={() => changeShipping()}
                  className="address-edit-text"
                >
                  Edit
                </div>
              </Row>
            </Col>
          </Row>
        )}

        <Row className="my-4">
          <h5 className="placeorder-address-heading mx-5 mb-3">Order Items</h5>
          {cart.length === 0 ? (
            <Message>Your Cart is Empty</Message>
          ) : (
            <Col md={12}>
              {cart.map((item, index) => (
                <Row className="ms-5 my-4">
                  <Col md={1}>
                    <Image src={item.image} alt={item.name} fluid rounded />
                  </Col>
                  <Col md={5} className="pl-3">
                    <Link to={`/product/${item.product}`}>{item.name}</Link>
                    <br />
                    {item.uploaderType === "wholesaler" ? (
                      <i className="fas fa-industry me-2 mb-1 vendor-icon"></i>
                    ) : (
                      <i className="fas fa-store me-2 mb-1 vendor-icon"></i>
                    )}
                    {item.shopdetails.shopName}
                  </Col>
                  <Col md={2}>₱{item.price}</Col>
                  <Col md={2}>Qty : {item.qty}</Col>
                  <Col md={2}>
                    <p style={{ color: "#FF0000" }}>
                      ₱ {(item.qty * item.price).toFixed(2)}
                    </p>
                  </Col>
                </Row>
              ))}
            </Col>
          )}
        </Row>
        <hr />
        <Row className="my-4">
          <Col md={6}>
            <h5 className="placeorder-address-heading mx-5">
              Payment Method
              <div
                onClick={() => changePayment()}
                className="address-edit-text"
              >
                Edit
              </div>
            </h5>
            <Row>
              <Col md={2}>
                <h6 className="placeorder-address-heading mt-3 mx-5">
                  Method:
                </h6>
              </Col>
              <Col md={9}>
                <h6 className="mt-4 mx-5"> {addr} </h6>
              </Col>
            </Row>
            {/* <Link to="/payment">Edit</Link> */}
          </Col>
          <Col md={6}>
            <h5 className="placeorder-address-heading mx-5">Order Summary</h5>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Items</h6>
              </Col>
              <Col>₱ {cart.itemsPrice}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Shipping</h6>
              </Col>
              <Col>₱ {cart.shippingPrice}</Col>
            </Row>
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Tax</h6>
              </Col>
              <Col>₱ {cart.taxPrice}</Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <h6 className="placeorder-order-summary">Total</h6>
              </Col>
              <Col style={{ color: "#FF0000" }}>₱ {cart.totalPrice}</Col>
            </Row>
          </Col>
        </Row>
        <Col md={12} className="my-4">
          <Button
            type="button"
            className="btn-info place-order-button mr-3"
            style={{ float: "right" }}
            disabled={placeOrderDisabler}
            onClick={placeOrderHandler}
          >
            Place Order
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default VendorPlaceOrderScreen;
