import React from "react";
import { Route } from "react-router-dom";
import AddressBookScreen from "../screens/User/AddressBookScreen";
import AddressCreateScreen from "../screens/User/AddressCreateScreen";
import AddressEditScreen from "../screens/User/AddressEditScreen";

const AddressRoutes = () => {
  return (
    <div>
      <Route path="/address/book/:id" component={AddressBookScreen} exact />
      <Route path="/create/address/book" component={AddressCreateScreen} />
      <Route path="/address/book/:id/edit" component={AddressEditScreen} />
    </div>
  );
};

export default AddressRoutes;
