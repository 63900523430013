import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, NavDropdown } from "react-bootstrap";
import "./Navbar.css";

const DashboardProfile = ({ name, logoutHandler }) => {
  const staffDetails = useSelector((state) => state.staffDetails);
  const { staff } = staffDetails;

  return (
    <>
      <Navbar className="dashboardNavDropdown">
        <NavDropdown
          title={`Hello ${name}`}
          className="dashboardNavDropdownTitle"
          id="basic-nav-dropdown-dashboard"
        >
          <NavDropdown.Item>
            <Link to="/vendor/shopprofile" className="dashboardNavlink">
              Shop Profile
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <Link to="/vendor/profile">Profile</Link>
          </NavDropdown.Item>
          {staff?.role === "admin" && (
            <NavDropdown.Item>
              <Link to="/vendor/dashboard/managestaffs">Manage Staff</Link>
            </NavDropdown.Item>
          )}

          {/* <NavDropdown.Item>
            <Link to="/vendor/settings">Settings</Link>
          </NavDropdown.Item> */}
          <NavDropdown.Item>
            <Link onClick={logoutHandler}>Logout</Link>
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </>
  );
};

export default DashboardProfile;
