import React, { useEffect } from "react";
import { Carousel, Row } from "react-bootstrap";
import Loader from "./Loader";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayPromotion } from "../actions/promotionActions";

const ProductCarousel = () => {
  const dispatch = useDispatch();

  const displayPromotionList = useSelector(
    (state) => state.displayPromotionList
  );
  const { promotions, error, loading } = displayPromotionList;

  useEffect(() => {
    dispatch(getDisplayPromotion());
  }, [dispatch]);
  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Row style={{ width: "100%" }}>
      <Carousel>
        {promotions.map((promotion) => (
          <Carousel.Item key={promotion._id}>
            <a href={`${promotion.linkTo}`}>
              <img
                className="d-block w-100 image-top-carousel"
                src={promotion.promotionBanner}
                alt="First slide"
                style={{
                  borderRadius: 0,

                  margin: 0,
                  padding: 0,

                  height: "25.726rem",

                  top: "5.044rem",
                }}
              />
            </a>
          </Carousel.Item>
        ))}
      </Carousel>
    </Row>
  );
};

export default ProductCarousel;
