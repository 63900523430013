import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import Axios from "axios";
import { customAlphabet } from "nanoid";
import "./OrderCreateScreen.css";
import {
  createOrder,
  createVendorOrder,
  createWholesalerOrder,
} from "../../actions/orderActions";

import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../../actions/philipinesAddressAction";
import { CUSTOMER_REGISTER_RESET } from "../../constants/userConstants";

const OrderCreateScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [customer, setCustomer] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [sourceRef, setSourceRef] = useState("");

  const [option, setOption] = useState([]);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [itemsPrice, setItemsPrice] = useState("");
  const [shippingPrice, setShippingPrice] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState("");
  const [source, setSource] = useState("");

  const [addressLine1, setAddressLine1] = useState("");
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philipines");
  const [postalCode, setPostalCode] = useState("");
  const [place, setPlace] = useState("");
  const [ward, setWard] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalAddressInfo, setAdditionalAddressInfo] = useState("");

  const [billing_addressLine1, setBillingAddressLine1] = useState("");
  const [billing_muncipality, setBillingMuncipality] = useState("");
  const [billing_province, setBillingProvince] = useState("");
  const [billing_district, setBillingDistrict] = useState("");
  const [billing_country, setBillingCountry] = useState("Philipines");
  const [billing_postalCode, setBillingPostalCode] = useState("");
  const [billing_place, setBillingPlace] = useState("");
  const [billing_ward, setBillingWard] = useState("");
  const [billing_phone, setBillingPhone] = useState("");
  const [additionalBillingAddressInfo, setAdditionalBillingAddressInfo] =
    useState("");
  const [additionalShipping, setAdditionalShipping] = useState("");
  const [feesAndCharges, setFeesAndCharges] = useState("");
  const [receiver, setReceiver] = useState("");
  const [isBilling, setIsBilling] = useState(true);
  const [showAddressField, setShowAddressField] = useState(false);
  const [showBillingAddressField, setShowBillingAddressField] = useState(false);
  const [validated, setValidated] = useState(false);

  const [sellerVoucher, setSellerVoucher] = useState(0);
  const [platformVoucher, setPlatformVoucher] = useState(0);
  const [platformCoins, setplatformCoins] = useState(0);

  const nanoid = customAlphabet("123456789ABCDEFGHIJKLMNPQRSTUVWXYZ", 10);
  const identifier = nanoid();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderCreate = useSelector((state) => state.orderCreate);
  const { success } = orderCreate;

  const orderCreateVendor = useSelector((state) => state.orderCreateVendor);
  const { success: successVendor } = orderCreateVendor;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopDetails } = wholesalerDetails;

  // const addressOrderDetails = useSelector((state) => state.addressOrderDetails);
  // const { address } = addressOrderDetails;

  const orderCreateWholesaler = useSelector(
    (state) => state.orderCreateWholesaler
  );
  const { success: successWholesaler } = orderCreateWholesaler;

  const [orderItems, setOrderItems] = useState([
    {
      image: "",
      category: "",
      brand: "",
      name: "",
      product: "",
      price: "",
      shopId: shop ? shop._id : shopDetails?._id,
      updatedById: userInfo?._id,
      qty: 1,
      discount: 0,
      discountedPrice: 0,
    },
  ]);

  const addProducts = () => {
    setOrderItems((prevProducts) => [
      ...prevProducts,
      {
        image: "",
        name: "",
        product: "",
        price: "",
        shopId: shop ? shop?._id : shopDetails?._id,
        updatedById: userInfo?._id,
        qty: 1,
        discount: 0,
        discountedPrice: 0,
      },
    ]);
  };
  const removeProduct = (index) => {
    const _tempProducts = [...orderItems];
    _tempProducts.splice(index, 1);
    setOrderItems(_tempProducts);

    setItemsPrice(
      _tempProducts.reduce(
        (acc, item) => acc + item.discountedPrice * item.qty,
        0
      )
    );
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      var shipp;
      if (isBilling) {
        shipp = {
          receiver,
          shipping_addressLine1: addressLine1,
          shipping_province: province,
          shipping_district: district,
          shipping_country: country,
          shipping_muncipality: muncipality,
          shipping_postalCode: postalCode,
          shipping_phone: phone,
          shipping_place: place,
          shipping_ward: ward,
          shipping_additional: additionalAddressInfo,
          billing_addressLine1: addressLine1,
          billing_province: province,
          billing_district: district,
          billing_country: country,
          billing_muncipality: muncipality,
          billing_postalCode: postalCode,
          billing_phone: phone,
          billing_place: place,
          billing_ward: ward,
          billing_additional: additionalAddressInfo,
        };
      }
      if (!isBilling) {
        shipp = {
          receiver,
          shipping_additional: additionalAddressInfo,
          shipping_addressLine1: addressLine1,
          shipping_province: province,
          shipping_district: district,
          shipping_country: country,
          shipping_muncipality: muncipality,
          shipping_postalCode: postalCode,
          shipping_phone: phone,
          shipping_place: place,
          shipping_ward: ward,

          billing_addressLine1,
          billing_province,
          billing_district,
          billing_country,
          billing_muncipality,
          billing_postalCode,
          billing_phone,
          billing_place,
          billing_ward,
          billing_additional: additionalBillingAddressInfo,
        };
      }

      dispatch(
        createOrder({
          source: source,
          sourceRef,
          userId: customer,
          userName: customerName,
          identifier,
          orderItems,
          address: shipp,
          paymentMethod,
          itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          additionalShipping,
          feesAndCharges,
          sellerVoucher,
          platformVoucher,
          platformCoins,
        })
      );
      if (userInfo?.type === "vendor") {
        dispatch(
          createVendorOrder({
            source: source,
            sourceRef,
            userId: customer,
            identifier,
            orderItems,
            address: shipp,
            paymentMethod,
            itemsPrice,
            shippingPrice,
            taxPrice,
            totalPrice,
            additionalShipping,
            feesAndCharges,
            sellerVoucher,
            platformVoucher,
            platformCoins,
          })
        );
      }
      if (userInfo?.type === "wholesaler") {
        dispatch(
          createWholesalerOrder({
            source: source,
            sourceRef,
            userId: customer,
            identifier,
            orderItems,
            address: shipp,
            paymentMethod,
            itemsPrice,
            shippingPrice,
            taxPrice,
            totalPrice,
            additionalShipping,
            feesAndCharges,
            sellerVoucher,
            platformVoucher,
            platformCoins,
          })
        );
      }
    }
  };

  async function loadCustomer(value) {
    if (value.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const cust = await Axios.get(`/api/users/existingUsers/${value}`, config);
      return cust.data.users;
    }
  }

  async function loadProducts(value) {
    if (value.length > 0) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const prod = await Axios.get(`/api/products/list/${value}`, config);
      return prod.data.products;
    }
  }

  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }

  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  useEffect(() => {
    dispatch({ type: CUSTOMER_REGISTER_RESET });
    loadRegion();

    if (success && successVendor) {
      history.push("/vendor/orderlist");
    }
    if (success && successWholesaler) {
      history.push("/wholesaler/orderlist");
    }
  }, [successVendor, success, successWholesaler, history]);

  useEffect(() => {
    setTotalPrice(
      Number(itemsPrice) +
        Number(shippingPrice) +
        Number(taxPrice) -
        Number(sellerVoucher) -
        Number(platformVoucher) -
        Number(platformCoins)
    );
  }, [
    itemsPrice,
    shippingPrice,
    taxPrice,
    sellerVoucher,
    platformVoucher,
    platformCoins,
  ]);

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col xs={12} md={9}>
          <Button
            className="bg-white text-secondary p-0 mt-2"
            onClick={() => {
              if (userInfo?.type === "wholesaler") {
                history.push("/wholesaler/orderlist");
              } else if (userInfo?.type === "vendor") {
                history.push("/vendor/orderlist");
              }
            }}
          >
            <i
              className="fas fa-angle-left p-2 mr-1"
              style={{ border: "1px solid whitesmoke" }}
            ></i>
            Go Back
          </Button>
          <Container className="mt-3 mb-3">
            <Row className="h-100">
              {/* <Col className='align-items-center registerForm text-center'> */}
              <Col className="orderScreenregisterForm text-center">
                <Col>
                  <h1 className="registerHeader text-center">Create Order</h1>
                </Col>
                <Form
                  onSubmit={(e) => submitHandler(e)}
                  noValidate
                  validated={validated}
                >
                  <div className="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={12}>
                        <Form.Group
                          className="productRatingFormGroup"
                          controlId="rating"
                        >
                          <Form.Label className="orderCreateLabel">
                            Source
                          </Form.Label>
                          <Form.Control
                            className="orderCreateInputField"
                            as="select"
                            value={source}
                            onChange={(e) => setSource(e.target.value)}
                            required
                          >
                            <option value="">Select...</option>
                            <option value="Shopee">Shopee</option>
                            <option value="Lazada">Lazada</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Tiktok">Tiktok</option>
                            <option value="Other">Other</option>
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Group controlId="customer">
                          <Form.Label className="orderCreateLabel">
                            SourceRef
                          </Form.Label>

                          <Form.Control
                            className="orderCreateInputField"
                            type="text"
                            value={sourceRef}
                            onChange={(e) => setSourceRef(e.target.value)}
                            required
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            This field is required
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8}>
                        <Form.Group controlId="customer">
                          <Form.Label className="orderCreateLabel">
                            Customer
                          </Form.Label>
                          <AsyncSelect
                            className="orderCreateInputField"
                            cacheOptions
                            defaultOptions
                            getOptionLabel={(e) => `${e?.name} ${e?.email}`}
                            getOptionValue={(e) => e?._id}
                            loadOptions={loadCustomer}
                            onChange={(e) => {
                              setCustomer(e?._id);
                              setCustomerName(e?.name);
                            }}
                            placeholder="Type characters for dropdown to appear"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={2}></Col>
                      <Col sm={2}>
                        <p className="noUserFound mb-2">
                          <small> No user Found? </small>
                        </p>
                        <Button
                          onClick={() => history.push("/create/customer")}
                          className="orderCreateRoundBtn"
                        >
                          <i className="fas fa-user-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                  <Form.Group controlId="products">
                    <div className="orderCreateFieldBox p-3">
                      <Row>
                        <Col sm={3}>
                          <Form.Label className="orderCreateLabel">
                            Products
                          </Form.Label>
                        </Col>
                        <Col sm={2} className="px-2">
                          <Form.Label className="orderCreateLabel">
                            Qty
                          </Form.Label>
                        </Col>
                        <Col sm={2} className="px-2">
                          <Form.Label className="orderCreateLabel">
                            Price
                          </Form.Label>
                        </Col>
                        <Col sm={2} className="px-2">
                          <Form.Label className="orderCreateLabel">
                            Discount
                          </Form.Label>
                        </Col>
                        <Col sm={2} className="px-2">
                          <Form.Label className="orderCreateLabel">
                            Price A/D
                          </Form.Label>
                        </Col>
                      </Row>
                      {orderItems?.map((p, index) => (
                        <>
                          <Row>
                            <Col sm={3}>
                              <AsyncSelect
                                className="orderCreateInputField"
                                cacheOptions
                                data-id={index}
                                defaultOptions
                                getOptionLabel={(e) => e?.name}
                                getOptionValue={(e) => e}
                                loadOptions={loadProducts}
                                onChange={(e) => {
                                  let tempProduct = orderItems;
                                  tempProduct[index].name = e?.name;
                                  tempProduct[index].image = e?.image;
                                  tempProduct[index].brand = e?.brand;
                                  tempProduct[index].category = e?.category;
                                  tempProduct[index].product = e?._id;
                                  tempProduct[index].price = e?.price;
                                  tempProduct[index].shopId = shop
                                    ? shop?._id
                                    : shopDetails?._id;
                                  tempProduct[index].discount = e?.discount
                                    ? Number(e.discount)
                                    : 0;
                                  tempProduct[index].discountedPrice = e
                                    ? p.price - Number(p.discount)
                                    : 0;

                                  setOrderItems(tempProduct);
                                  setItemsPrice(
                                    tempProduct.reduce(
                                      (acc, item) =>
                                        acc + item.discountedPrice * item.qty,
                                      0
                                    )
                                  );
                                }}
                                placeholder="Search products"
                              />
                            </Col>
                            <Col sm={2} className="px-2">
                              <Form.Control
                                className="orderCreateInputField"
                                required
                                data-id={index}
                                type="number"
                                placeholder="Enter Quantity"
                                value={p.qty}
                                min={1}
                                onChange={(e) => {
                                  let tempProduct = [...orderItems];
                                  tempProduct[index].qty = e.target.value;
                                  tempProduct[index].discountedPrice =
                                    tempProduct[index].price -
                                    tempProduct[index].discount;
                                  setOrderItems(tempProduct);

                                  setItemsPrice(
                                    tempProduct.reduce(
                                      (acc, item) =>
                                        acc + item.discountedPrice * item.qty,
                                      0
                                    )
                                  );
                                }}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                This field is required
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm={2} className="px-2">
                              <Form.Control
                                type="number"
                                required
                                placeholder="Price"
                                value={p.price}
                                onChange={(e) => {
                                  let tempProduct = [...orderItems];
                                  tempProduct[index].price = e.target.value;
                                  tempProduct[index].discountedPrice =
                                    tempProduct[index].price -
                                    tempProduct[index].discount;
                                  setOrderItems(tempProduct);
                                  setItemsPrice(
                                    tempProduct.reduce(
                                      (acc, item) =>
                                        acc + item.discountedPrice * item.qty,
                                      0
                                    )
                                  );
                                }}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                This field is required
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm={2} className="px-2">
                              <Form.Control
                                type="number"
                                required
                                placeholder="Discount"
                                value={p.discount ? Number(p.discount) : 0}
                                onChange={(e) => {
                                  let tempProduct = [...orderItems];
                                  tempProduct[index].discount = e.target.value;
                                  tempProduct[index].discountedPrice =
                                    tempProduct[index].price -
                                    tempProduct[index].discount;
                                  setOrderItems(tempProduct);
                                  setItemsPrice(
                                    tempProduct.reduce(
                                      (acc, item) =>
                                        acc + item.discountedPrice * item.qty,
                                      0
                                    )
                                  );
                                }}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                This field is required
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm={2} className="px-2">
                              <Form.Control
                                type="number"
                                required
                                placeholder="Price A/D"
                                value={p.discountedPrice}
                                onChange={(e) => {
                                  let tempProduct = [...orderItems];
                                  tempProduct[index].discountedPrice =
                                    e.target.value;
                                  setOrderItems(tempProduct);

                                  setItemsPrice(
                                    tempProduct.reduce(
                                      (acc, item) =>
                                        acc + item.discountedPrice * item.qty,
                                      0
                                    )
                                  );
                                }}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                This field is required
                              </Form.Control.Feedback>
                            </Col>
                            <Col sm={1} className="orderCreateAddProduct px-2">
                              {orderItems.length !== 0 && index !== 0 && (
                                <Button
                                  className="orderCreateRoundBtn pt-2"
                                  onClick={() => removeProduct(index)}
                                >
                                  <i className="fas fa-minus"></i>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </>
                      ))}
                      <Button
                        className="orderCreateRoundBtn pt-2 m-1"
                        onClick={addProducts}
                      >
                        <i className="fas fa-plus"></i>
                      </Button>
                    </div>
                  </Form.Group>

                  <div className="orderCreateFieldBox p-3">
                    <Row>
                      <Col sm={10}>
                        <Form.Label className="orderCreateLabel">
                          Address
                        </Form.Label>
                        <p className="float-left">Shipping Address</p>
                      </Col>
                      <Col sm={2}>
                        <Button
                          onClick={() => setShowAddressField(true)}
                          className="orderCreateRoundBtn pt-2"
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </Col>
                    </Row>
                    <div
                      className="addressInformation"
                      style={{ display: showAddressField ? "block" : "none" }}
                    >
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="country" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Country
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={country}
                              disabled
                              onChange={(e) => setCountry(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="province" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Region
                            </Form.Label>
                            <Select
                              className="orderCreateInputField"
                              options={option}
                              onChange={(e) => {
                                setProvince(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="district" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Province
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              loadOptions={loadProvince}
                              onChange={(e) => {
                                setDistrict(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group
                            controlId="muncipality"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Municipality
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadMunicipality}
                              onChange={(e) => {
                                setMuncipality(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="ward" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Barangay
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadBarangay}
                              onChange={(e) => {
                                setWard(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Street Address
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={addressLine1}
                              onChange={(e) => setAddressLine1(e.target.value)}
                              required
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Additional Address Information
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={additionalAddressInfo}
                              onChange={(e) =>
                                setAdditionalAddressInfo(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6}>
                          <Form.Group
                            controlId="postalcode"
                            className="mt-2 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              ZIP Code
                            </Form.Label>
                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={postalCode}
                              onChange={(e) => setPostalCode(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="phone" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Phone
                            </Form.Label>
                            <Form.Control
                              type="number"
                              className="wardNumberAddressField orderCreateInputField"
                              value={phone}
                              required
                              onChange={(e) => setPhone(e.target.value)}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={6}>
                          <Form.Group controlId="place" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Address Type
                            </Form.Label>
                            <Form.Control
                              className="orderCreateInputField"
                              as="select"
                              aria-label="Default select example"
                              value={place}
                              onChange={(e) => setPlace(e.target.value)}
                              required
                            >
                              <option value="">
                                Please Select the Address Type
                              </option>
                              <option value="Home">Home</option>
                              <option value="Office">Office</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              This field is required
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={6}>
                          <Form.Group controlId="phone" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Receiver
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="orderCreateInputField"
                              value={receiver}
                              onChange={(e) => setReceiver(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <div className="mb-3 mt-3">
                        <Row>
                          <Col xs={1}>
                            <Form.Check
                              defaultChecked
                              value={isBilling}
                              onChange={(e) => {
                                setIsBilling(e.target.checked);
                                setShowBillingAddressField(
                                  !showBillingAddressField
                                );
                              }}
                            />
                          </Col>
                          <Col xs={11}>
                            <Form.Label className="orderCreateLabel">
                              Same as Shipping Address
                            </Form.Label>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: showBillingAddressField ? "block" : "none",
                    }}
                  >
                    <div className="orderCreateFieldBox p-3">
                      <Row>
                        <Col sm={10}>
                          <Form.Label className="orderCreateLabel">
                            Address
                          </Form.Label>
                          <p className="float-left">Billing Address</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="country" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Country
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_country}
                              diasbled
                              onChange={(e) =>
                                setBillingCountry(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="province" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Region
                            </Form.Label>
                            <Select
                              className="orderCreateInputField"
                              options={option}
                              onChange={(e) => {
                                setBillingProvince(e.value);
                              }}
                              placeholder="Choose your region"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="district" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Province
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              loadOptions={loadProvince}
                              onChange={(e) => {
                                setBillingDistrict(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group
                            controlId="muncipality"
                            className="mt-3 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              Municipality
                            </Form.Label>
                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadMunicipality}
                              onChange={(e) => {
                                setBillingMuncipality(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="ward" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Barangay
                            </Form.Label>

                            <AsyncSelect
                              className="orderCreateInputField"
                              cacheOptions
                              defaultOptions
                              loadOptions={loadBarangay}
                              onChange={(e) => {
                                setBillingWard(e.value);
                              }}
                              placeholder="Type for dropdown to appear"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={12}>
                          <Form.Group controlId="" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Street Address
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_addressLine1}
                              onChange={(e) =>
                                setBillingAddressLine1(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12}>
                          <Form.Group controlId="" className="mt-2 p-2">
                            <Form.Label className="orderCreateLabel">
                              Additional Address Information
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={additionalBillingAddressInfo}
                              onChange={(e) =>
                                setAdditionalBillingAddressInfo(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group controlId="place" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Address Type
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              as="select"
                              aria-label="Default select example"
                              value={billing_place}
                              onChange={(e) => setBillingPlace(e.target.value)}
                            >
                              <option value="">
                                Please Select the Address Type
                              </option>
                              <option value="Home">Home</option>
                              <option value="Office">Office</option>
                              <option value="Other">Other</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                          <Form.Group
                            controlId="postalcode"
                            className="mt-3 p-2"
                          >
                            <Form.Label className="orderCreateLabel">
                              ZIP Code
                            </Form.Label>

                            <Form.Control
                              className="orderCreateInputField"
                              type="text"
                              value={billing_postalCode}
                              onChange={(e) =>
                                setBillingPostalCode(e.target.value)
                              }
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col sm={6}>
                          <Form.Group controlId="phone" className="mt-3 p-2">
                            <Form.Label className="orderCreateLabel">
                              Phone
                            </Form.Label>

                            <Form.Control
                              type="number"
                              className="wardNumberAddressField orderCreateInputField"
                              value={billing_phone}
                              onChange={(e) => setBillingPhone(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="paymentMethod">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Payment Method
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        as="select"
                        required
                        value={paymentMethod}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <option value="">Please Select Payment Method</option>
                        <option value="COD">COD</option>
                        <option value="GCash">GCash</option>
                        <option value="Bank transfer">Bank transfer</option>
                        <option value="Paid via platform">
                          Paid via platform
                        </option>

                        <option value="Credit/Debit Card" disabled>
                          Credit/Debit Card{" "}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        This field is required
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="itemsPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Items Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Items Price"
                        value={itemsPrice}
                        disabled
                        onChange={(e) => {
                          setItemsPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>
                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="taxPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Tax Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Tax Price"
                        value={taxPrice}
                        onChange={(e) => {
                          setTaxPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="shippingPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Shipping Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Shipping Price"
                        value={shippingPrice}
                        onChange={(e) => {
                          setShippingPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="sellerVoucher">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Seller Voucher
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={sellerVoucher}
                        onChange={(e) => {
                          setSellerVoucher(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="platformVoucher">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Platform Voucher
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={platformVoucher}
                        onChange={(e) => {
                          setPlatformVoucher(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="platformCoins">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Shopee Coins
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Seller Voucher"
                        value={platformCoins}
                        onChange={(e) => {
                          setplatformCoins(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Total
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Total Price"
                        value={totalPrice}
                        disabled
                        onChange={(e) => {
                          setTotalPrice(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Additional Shipping Price
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Additional Shipping Price"
                        value={additionalShipping}
                        onChange={(e) => {
                          setAdditionalShipping(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="orderCreateFieldBox p-3">
                    <Form.Group controlId="totalPrice">
                      <Row>
                        <Col sm={9}>
                          <Form.Label className="orderCreateLabel">
                            Additional Fees and Charges
                          </Form.Label>
                        </Col>
                      </Row>

                      <Form.Control
                        className="orderCreateInputField"
                        type="number"
                        placeholder="Enter Fees and Charges Price"
                        value={feesAndCharges}
                        onChange={(e) => {
                          setFeesAndCharges(e.target.value);
                        }}
                      ></Form.Control>
                    </Form.Group>
                  </div>

                  <div className="mt-3 p-4">
                    <div className="d-grid gap-2">
                      <Button type="submit" className="registerBtn mt-2 mb-2">
                        Create
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default OrderCreateScreen;
