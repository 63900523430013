import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button } from "react-bootstrap";
import "../../components/ShopProfile.css";
import {
  approveForSelling,
  disapproveForSelling,
  getVendorDetailsByShopName,
  getWholesalerDetailsByShopName,
} from "../../actions/userActions";
import {
  APPROVE_FOR_SELLING_RESET,
  DISAPPROVE_FOR_SELLING_RESET,
} from "../../constants/userConstants";

const ShopDetailsScreen = ({ history, match }) => {
  const name = match.params.id;
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetailsByShopName = useSelector(
    (state) => state.vendorDetailsByShopName
  );
  const { user } = vendorDetailsByShopName;

  const wholesalerDetailsByShopName = useSelector(
    (state) => state.wholesalerDetailsByShopName
  );
  const { user: wholesaler } = wholesalerDetailsByShopName;

  const sellerStatusUpdate = useSelector((state) => state.sellerStatusUpdate);
  const { success: approveSuccess } = sellerStatusUpdate;
  const sellerStatusDisapprove = useSelector(
    (state) => state.sellerStatusDisapprove
  );
  const { success: disapproveSuccess } = sellerStatusDisapprove;

  const approveAsSeller = (status) => {
    if (user) {
      if (status === "approved") {
        dispatch(
          approveForSelling({
            type: "vendor",
            status,
            id: user._id,
          })
        );
      }
      if (status === "disapproved") {
        let message = prompt(
          "Please enter the reason why shop is being disapproved",
          "Please dont spam"
        );
        if (message !== null || message !== "") {
          dispatch(
            disapproveForSelling({
              type: "vendor",
              status,
              id: user._id,
              message,
            })
          );
        }
      }
    } else if (wholesaler) {
      if (status === "approved") {
        dispatch(
          approveForSelling({
            type: "wholesaler",
            status,
            id: wholesaler._id,
          })
        );
      }
      if (status === "disapproved") {
        let message = prompt("Please enter your name", "Please dont spam");
        if (message !== null || message !== "") {
          dispatch(
            disapproveForSelling({
              type: "wholesaler",
              status,
              id: wholesaler._id,
              message,
            })
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!userInfo.isAdmin) {
      history.push("/");
    }
    if (approveSuccess || disapproveSuccess) {
      dispatch(getVendorDetailsByShopName(name));
      dispatch(getWholesalerDetailsByShopName(name));
      dispatch({ type: APPROVE_FOR_SELLING_RESET });
      dispatch({ type: DISAPPROVE_FOR_SELLING_RESET });
    }
  }, [history, dispatch, userInfo, name, approveSuccess, disapproveSuccess]);

  useEffect(() => {
    dispatch(getVendorDetailsByShopName(name));
    dispatch(getWholesalerDetailsByShopName(name));
  }, [history, dispatch, userInfo, name]);

  return (
    <>
      {user && (
        <div>
          <Row className="rowLine mt-3">
            <Col md={4}>
              <h1 className="shopProfileHeading mt-3 mb-2"> Shop Details </h1>
            </Col>

            <Col md={{ span: 4, offset: 4 }} className="shopProfileBtnCol">
              <div className="shopProfileHeading mt-3">
                <div className="accountProfileButtons">
                  <Button
                    className="mr-3"
                    onClick={() => {
                      if (user)
                        history.push(`/registration/vendor/${user?.vendorId}`);
                      if (wholesaler)
                        history.push(
                          `/registration/wholesaler/${wholesaler?.vendorId}`
                        );
                    }}
                  >
                    Edit
                  </Button>
                  {user?.status !== "approved" ? (
                    <Button onClick={() => approveAsSeller("approved")}>
                      Approve
                    </Button>
                  ) : (
                    <Button onClick={() => approveAsSeller("disapproved")}>
                      Disapprove
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row className="shopProfileContent">
            <h5 to="#" className="shopProfileStoreName">
              <i className="fas fa-store storeIcon"></i> {user?.shopName}
            </h5>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={6} className="p-3">
              <h5 className="headning5"> Bank Information</h5>
              <div className="bankBox">
                <p> {user?.bankInformation?.accountName} </p>
                <p className="shopProfileAcNo">
                  {user?.bankInformation?.accountNumber}
                </p>
                <h5 className="shopProfileBankName">
                  {user?.bankInformation?.bankName} ,
                  {user?.bankInformation?.branchName}
                </h5>
                <img
                  src={user?.bankInformation?.uploadCheck}
                  alt="cheque"
                ></img>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <h5 className="headning5"> Head Office Address</h5>
              <div className="addressBox">
                <Row className="shopProfileAddressInfo">
                  <Col md={1}>
                    <i className="fas fa-map-marker-alt shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>
                      {user?.headOffice?.addressLine1}{" "}
                      {user?.headOffice?.postalCode}
                    </p>
                    <p>
                      {user?.headOffice?.muncipality}{" "}
                      {user?.headOffice?.district} {user?.headOffice?.province}{" "}
                      {user?.headOffice?.country}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>
                    <i className="fas fa-home shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>{user?.headOffice?.district}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>
                    <i className="fas fa-phone shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>{user?.headOffice?.phone}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Branch Address </h5>
              <div className="BranchAddressBox">
                {user?.branchAddress?.map((branch) => {
                  return (
                    <Row>
                      <Col md={9}>
                        <ol>
                          <li>
                            {branch.name}, {branch.addressLine1}{" "}
                            {branch.muncipality} {branch.province}{" "}
                            {branch.district} {branch.country}{" "}
                            {branch.postalCode}
                          </li>
                        </ol>
                      </Col>
                      <Col md={3}>
                        <i className="fas fa-phone"> </i> {branch.phone}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Warehouse Address </h5>
              <div className="BranchAddressBox">
                {user?.warehouseAddress?.map((warehouse) => (
                  <Row>
                    <Col md={9}>
                      <ol>
                        <li>
                          {warehouse.name}, {warehouse.addressLine1}{" "}
                          {warehouse.muncipality} {warehouse.district}{" "}
                          {warehouse.province} {warehouse.country}{" "}
                          {warehouse.postalCode}
                        </li>
                      </ol>
                    </Col>
                    <Col md={3}>
                      <i className="fas fa-phone"></i>
                      {warehouse.phone}
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Return Address </h5>
              <div className="BranchAddressBox">
                {user?.returnAddress?.map((ret) => {
                  return (
                    <Row>
                      <Col md={9}>
                        <ol>
                          <li>
                            {ret.name}, {ret.id} {ret.address} {ret.city}{" "}
                            {ret.country} {ret.postalCode}
                          </li>
                        </ol>
                      </Col>
                      <Col md={3}>
                        <i className="fas fa-phone"> </i>
                        {ret.phone}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      )}

      {wholesaler && (
        <div>
          <Row className="rowLine mt-3">
            <Col md={4}>
              <h1 className="shopProfileHeading mt-3 mb-2"> Shop Details </h1>
            </Col>

            <Col md={{ span: 4, offset: 4 }} className="shopProfileBtnCol">
              <div className="shopProfileHeading mt-3">
                <div className="accountProfileButtons">
                  {wholesaler?.status !== "approved" ? (
                    <Button onClick={() => approveAsSeller("approved")}>
                      Approve
                    </Button>
                  ) : (
                    <Button onClick={() => approveAsSeller("disapproved")}>
                      Disapprove
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
          <Row className="shopProfileContent">
            <h5 to="#" className="shopProfileStoreName">
              <i className="fas fa-store storeIcon"></i> {wholesaler?.shopName}
            </h5>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={6} className="p-3">
              <h5 className="headning5"> Bank Information</h5>
              <div className="bankBox">
                <p> {wholesaler?.bankInformation?.accountName} </p>
                <p className="shopProfileAcNo">
                  {wholesaler?.bankInformation?.accountNumber}
                </p>
                <h5 className="shopProfileBankName">
                  {wholesaler?.bankInformation?.bankName} ,
                  {wholesaler?.bankInformation?.branchName}
                </h5>
                <img
                  src={wholesaler?.bankInformation?.uploadCheck}
                  alt="cheque"
                ></img>
              </div>
            </Col>
            <Col md={6} className="p-3">
              <h5 className="headning5"> Head Office Address</h5>
              <div className="addressBox">
                <Row className="shopProfileAddressInfo">
                  <Col md={1}>
                    <i className="fas fa-map-marker-alt shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>
                      {wholesaler?.headOffice?.addressLine1}{" "}
                      {wholesaler?.headOffice?.postalCode}
                    </p>
                    <p>
                      {wholesaler?.headOffice?.muncipality}{" "}
                      {wholesaler?.headOffice?.district}{" "}
                      {wholesaler?.headOffice?.province}{" "}
                      {wholesaler?.headOffice?.country}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>
                    <i className="fas fa-home shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>{wholesaler?.headOffice?.district}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={1}>
                    <i className="fas fa-phone shopProfileIcons"></i>
                  </Col>
                  <Col md={11}>
                    <p>{wholesaler?.headOffice?.phone}</p>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Branch Address </h5>
              <div className="BranchAddressBox">
                {wholesaler?.branchAddress?.map((branch) => {
                  return (
                    <Row>
                      <Col md={9}>
                        <ol>
                          <li>
                            {branch.name}, {branch.addressLine1}{" "}
                            {branch.muncipality} {branch.province}{" "}
                            {branch.district} {branch.country}{" "}
                            {branch.postalCode}
                          </li>
                        </ol>
                      </Col>
                      <Col md={3}>
                        <i className="fas fa-phone"> </i> {branch.phone}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Warehouse Address </h5>
              <div className="BranchAddressBox">
                {wholesaler?.warehouseAddress?.map((warehouse) => (
                  <Row>
                    <Col md={9}>
                      <ol>
                        <li>
                          {warehouse.name}, {warehouse.addressLine1}{" "}
                          {warehouse.muncipality} {warehouse.district}{" "}
                          {warehouse.province} {warehouse.country}{" "}
                          {warehouse.postalCode}
                        </li>
                      </ol>
                    </Col>
                    <Col md={3}>
                      <i className="fas fa-phone"></i>
                      {warehouse.phone}
                    </Col>
                  </Row>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="shopProfileDetails">
            <Col md={12} className="p-3">
              <h5 className="headning5"> Return Address </h5>
              <div className="BranchAddressBox">
                {wholesaler?.returnAddress?.map((ret) => {
                  return (
                    <Row>
                      <Col md={9}>
                        <ol>
                          <li>
                            {ret.name}, {ret.id} {ret.address} {ret.city}{" "}
                            {ret.country} {ret.postalCode}
                          </li>
                        </ol>
                      </Col>
                      <Col md={3}>
                        <i className="fas fa-phone"> </i>
                        {ret.phone}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default ShopDetailsScreen;
