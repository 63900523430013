import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, NavDropdown } from "react-bootstrap";

const DashboardProfile = ({ name, logoutHandler }) => {
  const staffDetails = useSelector((state) => state.staffDetails);
  const { staff } = staffDetails;
  return (
    <>
      <Navbar className="dashboardNavDropdown">
        <NavDropdown
          title={`Hello ${name}`}
          className="dashboardNavDropdownTitle"
          id="basic-nav-dropdown-dashboard"
        >
          <NavDropdown.Item>
            <Link to="/wholesaler/shopprofile" className="dashboardNavlink">
              Shop Profile
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <Link to="/wholesaler/profile"> Profile</Link>
          </NavDropdown.Item>
          {staff?.role === "admin" && (
            <NavDropdown.Item>
              <Link to="/wholesaler/dashboard/managestaffs">Manage Staff</Link>
            </NavDropdown.Item>
          )}

          {/* <NavDropdown.Item>
            <Link to="/wholesaler/settings">Settings</Link>
          </NavDropdown.Item> */}
          <NavDropdown.Item>
            <Link onClick={logoutHandler}>Logout</Link>
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </>
  );
};

export default DashboardProfile;

// import React from "react";
// import { Link } from "react-router-dom";
// import { createPopper } from "@popperjs/core";

// const DashboardProfile = ({ name, logoutHandler }) => {
//   const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
//   const btnDropdownRef = React.createRef();
//   const popoverDropdownRef = React.createRef();
//   const openDropdownPopover = () => {
//     createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
//       placement: "bottom-start",
//     });
//     setDropdownPopoverShow(true);
//   };
//   const closeDropdownPopover = () => {
//     setDropdownPopoverShow(false);
//   };
//   return (
//     <>
//       <Link
//         className="text-blueGray-500 block py-1 px-3"
//         ref={btnDropdownRef}
//         onClick={(e) => {
//           e.preventDefault();
//           dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
//         }}
//       >
//         <div className="items-center flex w-40">
//           <span className="w-20 h-20 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
//             <img
//               alt="..."
//               className="w-full rounded-full align-middle border-none shadow-lg"
//               src="/images/logo.jpg"
//             />
//           </span>
//           <p className="ml-2 text-black inline-flex">{`Hello ${name}`}</p>
//         </div>
//       </Link>
//       <div
//         ref={popoverDropdownRef}
//         className={
//           (dropdownPopoverShow ? "block " : "hidden ") +
//           "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
//         }
//       >
//         <Link
//           to="/wholesaler/shopprofile"
//           className={
//             "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
//           }
//         >
//           Shop Profile
//         </Link>
//         <Link
//           to="/wholesaler/profile"
//           className={
//             "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
//           }
//         >
//           Profile
//         </Link>
//         <Link
//           to="/wholesaler/dashboard/managestaffs"
//           className={
//             "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
//           }
//         >
//           Manage Staff
//         </Link>
//         <Link
//           to="/wholesaler/settings"
//           className={
//             "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
//           }
//         >
//           Settings
//         </Link>
//         <Link
//           onClick={logoutHandler}
//           className={
//             "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
//           }
//           style={{ cursor: "pointer" }}
//         >
//           Logout
//         </Link>
//       </div>
//     </>
//   );
// };

// export default DashboardProfile;
