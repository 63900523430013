import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { listVendorOrders } from "../../actions/orderActions";
import Paginate from "../../components/Paginate";
import "./VendorOrderListScreen.css";

const VendorOrderListScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;

  const vendorOrderList = useSelector((state) => state.vendorOrderList);
  const { loading, error, orders, pages, page } = vendorOrderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  useEffect(() => {
    if (userInfo && userInfo?.type === "vendor") {
      if (shop) {
        dispatch(listVendorOrders(pageNumber, shop._id));
      }
    } else {
      history.push("/login");
    }
  }, [history, dispatch, userInfo, pageNumber, shop]);
  return (
    <>
      <h1 className="VendorOrderListHeading">Orders</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table hover responsive className="VendorOrderListTable table-sm">
          <thead className="VendorOrderTableHeading">
            <tr className="VendorOrderTableRow">
              <th>ORDER LIST</th>
              <th>CUSTOMER</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th className="VendorOrderItemCenter">INVOICE</th>
              <th className="VendorOrderItemCenter">RECEIPT</th>

              <th className="VendorOrderItemCenter">DELIVERY LABEL</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {orders?.map((order) => (
              <tr key={order._id} className="VendorOrderData">
                <td className="VendorOrderNameImage">
                  {order.orderItems.map((item) => (
                    <p>
                      <img
                        className="VendorOrderImage"
                        style={{
                          marginRight: "10px",
                          height: "40px",
                          width: "40px",
                          objectFit: "contain",
                        }}
                        src={item.image}
                        alt={item.name}
                      />
                      {item.name}
                    </p>
                  ))}
                </td>
                <td>{order?.user?.name}</td>
                <td>{new Date(order.createdAt).toLocaleString()}</td>
                <td>₱ {order.totalPrice}</td>
                <td className="VendorOrderItemCenter">
                  <Link
                    onClick={() => {
                      history.push(`/invoice/${order._id}`);
                    }}
                  >
                    <i className="fas fa-file-invoice-dollar VendorOrderIcons"></i>
                  </Link>
                </td>
                <td className="VendorOrderItemCenter">
                  {order?.isPaid === "completed" && (
                    <Link
                      onClick={() => {
                        history.push(`/receipt/${order?._id}`);
                      }}
                    >
                      <i className="fas fa-file-invoice-dollar VendorOrderIcons"></i>
                    </Link>
                  )}
                </td>
                <td className="VendorOrderItemCenter">
                  <Link
                    onClick={() => {
                      history.push(`/deliverylabel/${order?._id}`);
                    }}
                  >
                    <i className="fas fa-print VendorOrderIcons"></i>
                  </Link>
                </td>
                <td>
                  <LinkContainer to={`/order/vendor/${order._id}`}>
                    <Button variant="light" className="VendorOrderBtn btn-sm">
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Paginate url="/vendor/orderlist" pages={pages} page={page} />
    </>
  );
};

export default VendorOrderListScreen;
