import {
  DISPLAY_PROMOTION_GET_FAIL,
  DISPLAY_PROMOTION_GET_REQUEST,
  DISPLAY_PROMOTION_GET_SUCCESS,
  DISPLAY_WHOLESALER_PROMOTION_GET_FAIL,
  DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST,
  DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS,
  PROMOTION_CREATE_FAIL,
  PROMOTION_CREATE_REQUEST,
  PROMOTION_CREATE_RESET,
  PROMOTION_CREATE_SUCCESS,
  PROMOTION_DELETE_FAIL,
  PROMOTION_DELETE_REQUEST,
  PROMOTION_DELETE_RESET,
  PROMOTION_DELETE_SUCCESS,
  PROMOTION_DETAILS_FAIL,
  PROMOTION_DETAILS_REQUEST,
  PROMOTION_DETAILS_SUCCESS,
  PROMOTION_GET_FAIL,
  PROMOTION_GET_REQUEST,
  PROMOTION_GET_SUCCESS,
  PROMOTION_JOIN_FAIL,
  PROMOTION_JOIN_REQUEST,
  PROMOTION_JOIN_RESET,
  PROMOTION_JOIN_SUCCESS,
  PROMOTION_UPDATE_FAIL,
  PROMOTION_UPDATE_REQUEST,
  PROMOTION_UPDATE_RESET,
  PROMOTION_UPDATE_SUCCESS,
} from "../constants/promotionConstants";

export const promotionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PROMOTION_CREATE_REQUEST:
      return {
        loading: true,
      };

    case PROMOTION_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PROMOTION_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PROMOTION_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const promotionListReducer = (state = { promotions: [] }, action) => {
  switch (action.type) {
    case PROMOTION_GET_REQUEST:
      return {
        loading: true,
      };

    case PROMOTION_GET_SUCCESS:
      return {
        loading: false,
        promotions: action.payload,
      };
    case PROMOTION_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const displayPromotionListReducer = (
  state = { promotions: [] },
  action
) => {
  switch (action.type) {
    case DISPLAY_PROMOTION_GET_REQUEST:
      return {
        loading: true,
      };

    case DISPLAY_PROMOTION_GET_SUCCESS:
      return {
        loading: false,
        promotions: action.payload,
      };
    case DISPLAY_PROMOTION_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const displayWholesalerPromotionListReducer = (
  state = { promotions: [] },
  action
) => {
  switch (action.type) {
    case DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST:
      return {
        loading: true,
      };

    case DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS:
      return {
        loading: false,
        promotions: action.payload,
      };
    case DISPLAY_WHOLESALER_PROMOTION_GET_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const promotionDetailsReducer = (state = { promotion: {} }, action) => {
  switch (action.type) {
    case PROMOTION_DETAILS_REQUEST:
      return { loading: true, ...state };
    case PROMOTION_DETAILS_SUCCESS:
      return { loading: false, promotion: action.payload };
    case PROMOTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const promotionUpdateReducer = (state = { promotion: {} }, action) => {
  switch (action.type) {
    case PROMOTION_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case PROMOTION_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case PROMOTION_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case PROMOTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const promotionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PROMOTION_DELETE_REQUEST:
      return { loading: true };
    case PROMOTION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case PROMOTION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PROMOTION_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const promotionJoinReducer = (state = {}, action) => {
  switch (action.type) {
    case PROMOTION_JOIN_REQUEST:
      return { loading: true };
    case PROMOTION_JOIN_SUCCESS:
      return { loading: false, success: true };
    case PROMOTION_JOIN_FAIL:
      return { loading: false, error: action.payload };
    case PROMOTION_JOIN_RESET:
      return {};

    default:
      return state;
  }
};
