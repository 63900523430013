import axios from "axios";
import {
  DISPLAY_PROMOTION_GET_FAIL,
  DISPLAY_PROMOTION_GET_REQUEST,
  DISPLAY_PROMOTION_GET_SUCCESS,
  DISPLAY_WHOLESALER_PROMOTION_GET_FAIL,
  DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST,
  DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS,
  PROMOTION_CREATE_FAIL,
  PROMOTION_CREATE_REQUEST,
  PROMOTION_CREATE_SUCCESS,
  PROMOTION_DELETE_FAIL,
  PROMOTION_DELETE_REQUEST,
  PROMOTION_DELETE_SUCCESS,
  PROMOTION_DETAILS_FAIL,
  PROMOTION_DETAILS_REQUEST,
  PROMOTION_DETAILS_SUCCESS,
  PROMOTION_GET_FAIL,
  PROMOTION_GET_REQUEST,
  PROMOTION_GET_SUCCESS,
  PROMOTION_JOIN_FAIL,
  PROMOTION_JOIN_REQUEST,
  PROMOTION_JOIN_SUCCESS,
  PROMOTION_UPDATE_FAIL,
  PROMOTION_UPDATE_REQUEST,
  PROMOTION_UPDATE_SUCCESS,
} from "../constants/promotionConstants";

export const createPromotion = (promotion) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMOTION_CREATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`/api/promotion`, promotion, config);
    dispatch({
      type: PROMOTION_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getPromotion = () => async (dispatch) => {
  try {
    dispatch({ type: PROMOTION_GET_REQUEST });
    const { data } = await axios.get("/api/promotion");
    dispatch({
      type: PROMOTION_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getDisplayPromotion = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    if (userInfo?.type === "wholesaler" || userInfo?.type === "vendor") {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      dispatch({ type: DISPLAY_PROMOTION_GET_REQUEST });

      const { data } = await axios.get(`/api/promotion/display`, config);
      dispatch({
        type: DISPLAY_PROMOTION_GET_SUCCESS,
        payload: data,
      });
    } else if (!userInfo || userInfo?.type === "customer") {
      const { data } = await axios.get(`/api/promotion/display/vendor`);

      dispatch({
        type: DISPLAY_PROMOTION_GET_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: DISPLAY_PROMOTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWholesalerDisplayPromotion = () => async (dispatch) => {
  try {
    dispatch({ type: DISPLAY_WHOLESALER_PROMOTION_GET_REQUEST });
    const { data } = await axios.get("/api/promotion/display/wholesaler");
    dispatch({
      type: DISPLAY_WHOLESALER_PROMOTION_GET_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DISPLAY_WHOLESALER_PROMOTION_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const detailsPromotion = (id) => async (dispatch) => {
  try {
    dispatch({ type: PROMOTION_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/promotion/${id}`);

    dispatch({ type: PROMOTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: PROMOTION_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const updatePromotion = (promotion) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMOTION_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.put(`/api/promotion/${promotion.id}`, promotion, config);
    dispatch({
      type: PROMOTION_UPDATE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const joinThePromotion = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMOTION_JOIN_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.get(`/api/promotion/register/${id}`, config);
    dispatch({
      type: PROMOTION_JOIN_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_JOIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const promotionDelete = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROMOTION_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/promotion/${id}`, config);
    dispatch({
      type: PROMOTION_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: PROMOTION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
