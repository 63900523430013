import React from "react";
import { Route } from "react-router-dom";
import BlogCreate from "../screens/Home/BlogCreate";
import BlogEdit from "../screens/Home/BlogEdit";
import BlogList from "../screens/Home/BlogList";

const BlogRoutes = () => {
  return (
    <div>
      <Route path="/blog-create" component={BlogCreate} exact />
      <Route path="/blog-list" component={BlogList} exact />
      <Route path="/edit-blog/:id" component={BlogEdit} />
    </div>
  );
};

export default BlogRoutes;
