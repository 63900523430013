import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { NavDropdown } from "react-bootstrap";

const CustomerHeader = ({ history, name, logoutHandler, id }) => {
  const wholesalerRegister = useSelector((state) => state.wholesalerRegister);
  const { success: wholesalerRegistrationSuccess } = wholesalerRegister;

  const vendorRegister = useSelector((state) => state.vendorRegister);
  const { success: vendorRegistrationSuccess } = vendorRegister;
  // const sellProducts = (type) => {
  //   if (type === "retail") {
  //     if (window.confirm("Are you sure you want to register as a Retailer?")) {
  //       dispatch(registerVendor());
  //     }
  //   } else {
  //     if (
  //       window.confirm("Are you sure you want to register as a wholesaler?")
  //     ) {
  //       dispatch(registerWholesaler());
  //     }
  //   }
  // };
  useEffect(() => {
    if (vendorRegistrationSuccess || wholesalerRegistrationSuccess)
      history.push("/");
  }, [vendorRegistrationSuccess, wholesalerRegistrationSuccess, history]);

  return (
    <>
      <NavDropdown title={name} id="username" className="navTopHeaderDropdown">
        <LinkContainer to="/profile">
          <NavDropdown.Item>Profile</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to={`/address/book/${id}`}>
          <NavDropdown.Item>Address Book</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/myorders">
          <NavDropdown.Item>My Orders</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/followed">
          <NavDropdown.Item>Followed Shops</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/mywishlist">
          <NavDropdown.Item>My Wishlist</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
      </NavDropdown>
    </>
  );
};

export default CustomerHeader;
