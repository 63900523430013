export const CATEGORY_CREATE_REQUEST = "CATEGORY_CREATE_REQUEST";
export const CATEGORY_CREATE_SUCCESS = "CATEGORY_CREATE_SUCCESS";
export const CATEGORY_CREATE_FAIL = "CATEGORY_CREATE_FAIL";

export const CATEGORY_GET_REQUEST = "CATEGORY_GET_REQUEST";
export const CATEGORY_GET_SUCCESS = "CATEGORY_GET_SUCCESS";
export const CATEGORY_GET_FAIL = "CATEGORY_GET_FAIL";

export const VENDORSPECIFIC_CATEGORY_GET_REQUEST =
  "VENDORSPECIFIC_CATEGORY_GET_REQUEST";
export const VENDORSPECIFIC_CATEGORY_GET_SUCCESS =
  "VENDORSPECIFIC_CATEGORY_GET_SUCCESS";
export const VENDORSPECIFIC_CATEGORY_GET_FAIL =
  "VENDORSPECIFIC_CATEGORY_GET_FAIL";

export const SUB_CATEGORY_GET_REQUEST = "SUB_CATEGORY_GET_REQUEST";
export const SUB_CATEGORY_GET_SUCCESS = "SUB_CATEGORY_GET_SUCCESS";
export const SUB_CATEGORY_GET_FAIL = "SUB_CATEGORY_GET_FAIL";

export const CATEGORY_HEADINGS_GET_REQUEST = "CATEGORY_HEADINGS_GET_REQUEST";
export const CATEGORY_HEADINGS_GET_SUCCESS = "CATEGORY_HEADINGS_GET_SUCCESS";
export const CATEGORY_HEADINGS_GET_FAIL = "CATEGORY_HEADINGS_GET_FAIL";

export const CATEGORY_UPDATE_REQUEST = "CATEGORY_UPDATE_REQUEST";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAIL = "CATEGORY_UPDATE_FAIL";
export const CATEGORY_UPDATE_RESET = "CATEGORY_UPDATE_RESET";

export const CATEGORY_DELETE_REQUEST = "CATEGORY_DELETE_REQUEST";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAIL = "CATEGORY_DELETE_FAIL";

export const CATEGORY_DETAILS_REQUEST = "CATEGORY_DETAILS_REQUEST";
export const CATEGORY_DETAILS_SUCCESS = "CATEGORY_DETAILS_SUCCESS";
export const CATEGORY_DETAILS_FAIL = "CATEGORY_DETAILS_FAIL";
