import React from "react";
import { Route } from "react-router-dom";
import AllCategoryScreen from "../screens/category/AllCategoryScreen";
import CategoryFormScreen from "../screens/category/CategoryFormScreen";
import CategoryEditScreen from "../screens/category/CategoryEditScreen";
import CategoryWiseProductScreen from "../screens/Product/CategoryWiseProductScreen";

const CategoryRoutes = () => {
  return (
    <div>
      <Route
        path="/category/:category"
        component={CategoryWiseProductScreen}
        exact
      />
      <Route
        path="/category/:category/min/:min/max/:max"
        component={CategoryWiseProductScreen}
        exact
      />
      <Route
        path="/category/:category/search/:keyword"
        component={CategoryWiseProductScreen}
        exact
      />
      <Route
        path="/category/:category/page/:pageNumber"
        component={CategoryWiseProductScreen}
        exact
      />
      <Route
        path="/category/:category/search/:keyword/page/:pageNumber"
        component={CategoryWiseProductScreen}
        exact
      />
      <Route path="/allcategories" component={AllCategoryScreen} exact />

      <Route
        path="/create/category/page/:pageNumber"
        component={CategoryFormScreen}
        exact
      />
      <Route path="/edit/category/:id" component={CategoryEditScreen} exact />
    </div>
  );
};

export default CategoryRoutes;
