import React, { useEffect, useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Form, Button, Nav, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createProduct } from "../../../../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../../../../constants/productConstants";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Axios from "axios";
import "./ProductCreateScreen.css";
import parse from "html-react-parser";
import Message from "../../../../components/Message";
import Meta from "../../../../components/Meta";

const _defaultFAQ = [];

const ProductCreateScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [unit, setUnit] = useState("");
  const [colour, setColour] = useState("");
  const [brand, setBrand] = useState("");
  const [image, setImage] = useState("");
  const [images, setImages] = useState([]);
  const [category, setCategory] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [minOrderQuantity, setMinOrderQuantity] = useState();
  const [discount, setDiscount] = useState("");
  const [vendorCategory, setVendorCategory] = useState("");
  const [discountType, setDiscountType] = useState("Amount");
  const [netweightType, setNetWeightType] = useState("Kilogram");
  const [requestPublish, setRequestPublish] = useState(false);
  const [FAQ, setFAQ] = useState(_defaultFAQ);
  const [guarantee, setGuarantee] = useState("");
  const [warranty, setWarranty] = useState("");
  const [returnScheme, setReturnScheme] = useState("");
  const [warehouseLocation, setWarehouseLocation] = useState("");
  const [branchLocation, setBranchLocation] = useState("");
  const [externalCheckoutLink, setExternalCheckoutLink] = useState("");
  const [categoryErrrorMessage, setCategoryErrorMessage] = useState("");
  const editorRef = useRef(null);

  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");

  const [netWeight, setNetWeight] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [size, setSize] = useState([]);

  const [removed, setRemoved] = useState("");
  const [customSize, setCustomSize] = useState("");

  const [validated, setValidated] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const vendorDetails = useSelector((state) => state.vendorDetails);
  const { user: shop } = vendorDetails;

  const wholesalerDetails = useSelector((state) => state.wholesalerDetails);
  const { user: shopInfo } = wholesalerDetails;

  const productCreate = useSelector((state) => state.productCreate);
  const { success: successCreate, product: createdProduct } = productCreate;

  const categoryList = useSelector((state) => state.categoryList);
  const { categories } = categoryList;

  const publishButtonDisabler =
    shop?.status === "approved" || shopInfo?.status === "approved";

  const addFAQ = () => {
    setFAQ((prevFAQ) => [
      ...prevFAQ,
      {
        question: "",
        answer: "",
      },
    ]);
  };

  const removeFAQ = () => {
    setFAQ(FAQ.slice(0, FAQ.length - 1));
  };
  const handleFAQChange = (e, inputField) => {
    let _tempFAQ = [...FAQ];
    let value = e.target.value;
    _tempFAQ[e.target.dataset.id][inputField] = value;
    setFAQ(_tempFAQ);
  };

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (successCreate) {
      if (userInfo?.isAdmin) {
        if (requestPublish) history.push(`/admin/productlist`);
        else {
          history.push("/admin/productList/unpublished");
        }
      }
      if (userInfo?.type === "wholesaler") {
        if (requestPublish) history.push(`/wholesaler/productlist`);
        else {
          history.push("/wholesaler/productList/unpublished");
        }
      }

      if (userInfo?.type === "vendor") {
        if (requestPublish) history.push(`/vendor/productlist`);
        else {
          history.push("/vendor/productList/unpublished");
        }
      }
    }
  }, [
    history,
    dispatch,
    userInfo,
    successCreate,
    createdProduct,
    requestPublish,
  ]);
  let productCategoryList = [];

  categories?.forEach((element) => {
    productCategoryList.push({
      label: element.name,
      value: element.name,
    });
  });
  const discountTypes = [
    { label: "amt", value: "Amount" },
    { label: "%", value: "Percent" },
  ];

  const netweightTypes = [
    { label: "K.G", value: "Kilogram" },
    { label: "GRAM", value: "Gram" },
  ];

  const unitTypes = [
    { label: "K.G", value: "Kilogram" },
    { label: "GRAM", value: "Gram" },
    { label: "PIECE", value: "Piece" },
    { label: "LITER", value: "Liter" },
    { label: "Pair", value: "Pair" },
  ];

  const netweight = `${netWeight} ${netweightType.value}`;

  const grossweight = `${grossWeight} ${netweightType.value}`;

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      if (res.data.width > 512 && res.data.height > 512) {
        setImage(res.data.url);
      } else {
        setImage(
          res.data.url.split("upload")[0] +
            `/upload/b_white,c_pad,h_1024,w_1024` +
            res.data.url.split("upload")[1]
        );
      }
    });
  };

  const uploadFilesHandler = (e) => {
    for (let i = 0; i < 6; i++) {
      const formData = new FormData();
      formData.append("file", e.target.files[i]);
      formData.append("upload_preset", "pinoy-can-group");

      Axios.post(
        "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
        formData
      ).then((res) => {
        if (res.data.width > 512 && res.data.height > 512) {
          setImages((prevState) => [...prevState, res.data.url]);
        } else {
          setImages((prevState) => [
            ...prevState,
            res.data.url.split("upload")[0] +
              `/upload/b_white,c_pad,h_1024,w_1024` +
              res.data.url.split("upload")[1],
          ]);
        }
      });
    }
  };

  async function loadCategory(value) {
    if (value.length > 2) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const cat = await Axios.get(`/api/category/search/${value}`, config);
      return cat.data;
    }
  }
  const deleteHandlerImage = async (im) => {
    if (window.confirm("Are you sure")) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const files = [im];

      const cat = await Axios.post(
        `/api/products/image/delete/`,
        { files },
        config
      );

      setImage("");
    }
  };
  const deleteHandlerImages = async (e, i, link) => {
    e.preventDefault();
    if (window.confirm("Are you sure")) {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const files = [link];

      const cat = await Axios.post(
        `/api/products/image/delete/`,
        { files },
        config
      );

      setRemoved(images?.splice(i, 1));
      setImages(images);
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (category === "") {
        setCategoryErrorMessage("This field is required");
      } else {
        setCategoryErrorMessage("");

        var discountAmount;
        if (discountType === "Percent") {
          (((discountAmount = (discount * price) / 100) * 100) / 100).toFixed(
            2
          );
        } else if (discountType === "Amount") {
          discountAmount = discount;
        }
        dispatch(
          createProduct({
            name,
            price,
            discount: discountAmount,
            image,
            images,
            unit,
            colour,
            brand,
            dimension: { length, breadth, height },
            customSize,
            size,
            netWeight: netweight,
            grossWeight: grossweight,
            category: category,
            description: editorRef.current.getContent(),
            countInStock,
            minOrderQuantity,
            updatedById: userInfo?._id,
            uploaderType: userInfo?.type,
            vendorShop: shop?._id,
            wholesalerShop: shopInfo?._id,
            guarantee,
            warranty,
            returnScheme,
            warehouseLocation,
            branchLocation,
            vendorCategory,
            FAQ,
            requestPublish,
            externalCheckoutLink,
          })
        );
        setFAQ(_defaultFAQ);
      }
    }
  };
  return (
    <>
      <Meta title="Create Product" />
      {!publishButtonDisabler && (
        <Message variant="info">
          Your account needs to be approved to publish the products.
        </Message>
      )}
      <div>
        <h1 className="productCreateHeading">Create Product</h1>
        <Row className="mt-2">
          <Nav className="nav nav-justified product-nav mt-5">
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-basic-information"
            >
              Basic Information
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-additional-information"
            >
              Additional Information
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-measurements"
            >
              Measurements
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-category-specific"
            >
              Category Specific
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-guarantee-warranty"
            >
              Guarantee/ Warranty
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-local-management"
            >
              Location Management
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-faqs"
            >
              FAQs
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-product-details"
            >
              Product Details
            </Nav.Link>
            <Nav.Link
              className="nav-link my-nav-product-create"
              href="#nav-submit"
            >
              Submit
            </Nav.Link>
          </Nav>
        </Row>
        <Form noValidate validated={validated} onSubmit={submitHandler}>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div
                id="nav-basic-information"
                className="product-tabs my-4 ps-4"
              >
                <h4 className="productCreateSubHeading mb-3">
                  Basic Information
                </h4>
                <Row className="inputBox">
                  <Col sm={2}>
                    {" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    Name
                  </Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter name"
                      value={name}
                      required
                      onChange={(e) =>
                        setName(
                          e.target.value
                            .replaceAll("/", "-")
                            .replaceAll("?", "-")
                            .replaceAll("#", "-")
                            .replaceAll("%", "-")
                        )
                      }
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>
                    {" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    Category
                  </Col>
                  <Col sm={10}>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      getOptionLabel={(e) => e}
                      getOptionValue={(e) => e[1]}
                      className="basic-single"
                      loadOptions={loadCategory}
                      onChange={(e) => {
                        setCategory(e[1]);
                      }}
                      placeholder="Type 3 characters for dropdown to appear"
                    />
                  </Col>
                  <Row>
                    <Col md={2}></Col>
                    <Col md={10}>
                      {categoryErrrorMessage && (
                        <span style={{ color: "red" }}>
                          {categoryErrrorMessage}
                        </span>
                      )}
                    </Col>
                  </Row>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>Vendor Category</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Vendor Category"
                      value={vendorCategory}
                      onChange={(e) => setVendorCategory(e.target.value)}
                    ></Form.Control>

                    <span>
                      ( Here you can specify your own category which can be used
                      as filter in your store )
                    </span>
                  </Col>
                </Row>

                <Row className="inputBox mt-3">
                  <Col sm={2}>
                    {" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    Price
                  </Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="number"
                      placeholder="Enter Price"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                      required
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Discount</Col>

                  <Col sm={6}>
                    <Form.Control
                      className="inputField"
                      type="number"
                      placeholder="Enter Discount "
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm={4} className="discountType">
                    <Select
                      onChange={(e) => {
                        setDiscountType(e.value);
                      }}
                      options={discountTypes}
                    />
                  </Col>
                </Row>
                <Row className="imageBox mb-3">
                  <Col sm={2}>
                    {" "}
                    <span style={{ color: "red", fontSize: "20px" }}>*</span>
                    Feature Image
                  </Col>
                  <Col sm={10}>
                    <input
                      className="imageField"
                      id="image-file"
                      label="Choose File"
                      type="file"
                      custom
                      onChange={uploadFileHandler}
                      required
                    ></input>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                    <div>
                      {image && (
                        <div
                          style={{
                            width: "100px",
                            height: "100px",
                            position: "relative",
                          }}
                        >
                          <img
                            className="imagesBoxes mb-3"
                            alt=""
                            src={image}
                            style={{
                              width: "100px",
                              height: "100px",
                              objectFit: "cover",
                              border: "1px solid black",
                            }}
                          />
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            onClick={(e) => deleteHandlerImage(image)}
                            style={{
                              fontSize: "16px",
                              color: "red",
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "column",
                              position: "absolute",
                              right: 4,
                              top: 2,
                            }}
                          ></i>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>
                    {" "}
                    <small>
                      Additional Images <span>(Max 6)</span>{" "}
                    </small>
                  </Col>
                  <Col sm={10}>
                    <input
                      id="image-files"
                      label="Choose Files"
                      type="file"
                      custom
                      multiple
                      onChange={uploadFilesHandler}
                    ></input>
                    <Row>
                      {images.map((im, index) => (
                        <Col md={2} className="mt-3">
                          <div
                            style={{
                              width: "100px",
                              height: "100px",
                              position: "relative",
                            }}
                          >
                            <img
                              alt=""
                              src={im}
                              style={{
                                width: "100px",
                                height: "100px",
                                objectFit: "contain",
                              }}
                            />
                            <i
                              className="fa fa-times"
                              aria-hidden="true"
                              onClick={(e) => deleteHandlerImages(e, index, im)}
                              style={{
                                fontSize: "16px",
                                color: "red",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                position: "absolute",
                                right: 4,
                                top: 2,
                              }}
                            ></i>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Row className="ml-2">
                  <Col sm={2}>Description</Col>
                  <Col sm={10} className="DescriptionField">
                    <Editor
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
                      initialValue={"<p>Describe your product here.</p>"}
                      init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | " +
                          "bold italic backcolor | alignleft aligncenter " +
                          "alignright alignjustify | bullist numlist outdent indent | " +
                          "removeformat | help",
                        content_style:
                          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div
                id="nav-additional-information"
                className="product-tabs my-5 ps-4"
              >
                <h4 className="productCreateSubHeading mb-3">
                  Additional Information
                </h4>
                <Row className="inputBox">
                  <Col sm={2}>Unit</Col>

                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      as="select"
                      value={unit}
                      onChange={(e) => setUnit(e.target.value)}
                    >
                      <option value="">Choose Unit</option>
                      {unitTypes?.map((w) => (
                        <option value={w.value}>{w.label}</option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>Color/Variant</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter colour (e.g. red,blue,green..)"
                      value={colour}
                      onChange={(e) => setColour(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Brands</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Brand"
                      value={brand}
                      onChange={(e) => setBrand(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Minimun Order Qty</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="number"
                      placeholder="Enter Minimum Order Quantity"
                      value={minOrderQuantity}
                      onChange={(e) => setMinOrderQuantity(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Count In Stock</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="number"
                      placeholder="Enter Count In Stock"
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="inputBox">
            <Col sm={2}>External Checkout Link</Col>
            <Col sm={10}>
              <Form.Control
                className="inputField"
                type="text"
                placeholder="Enter external link"
                value={externalCheckoutLink}
                onChange={(e) => setExternalCheckoutLink(e.target.value)}
              ></Form.Control>
            </Col>
          </Row>

          <Row className="my-3">
            <div id="nav-tabContent">
              <div id="nav-measurements" className="product-tabs my-5 ps-4">
                <h4 className="productCreateSubHeading mb-3">Measurements</h4>

                <Row className="inputBox">
                  <Col sm={2}>Dimension</Col>
                  <Col sm={10} style={{ display: "flex" }}>
                    <Form.Control
                      className="createProductNumberField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                        width: "200px",
                        borderRadius: "10px",
                        marginRight: "10px",
                      }}
                      type="number"
                      placeholder="length cm"
                      value={length}
                      onChange={(e) => setLength(e.target.value)}
                    ></Form.Control>
                    <Form.Control
                      className="createProductNumberField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                        width: "200px",
                        borderRadius: "10px",
                        marginRight: "10px",
                      }}
                      type="number"
                      placeholder="width cm"
                      value={breadth}
                      onChange={(e) => setBreadth(e.target.value)}
                    ></Form.Control>
                    <Form.Control
                      className="createProductNumberField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                        width: "200px",
                        borderRadius: "10px",
                        marginRight: "10px",
                      }}
                      type="number"
                      placeholder="height cm"
                      value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>Net Weight</Col>
                  <Col sm={6}>
                    <Form.Control
                      className="createProductNumberField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                      }}
                      type="number"
                      placeholder="Product weight without packaging"
                      value={netWeight}
                      onChange={(e) => setNetWeight(e.target.value)}
                    ></Form.Control>
                  </Col>

                  <Col sm={4} className="discountType">
                    <Select
                      value={netweightType}
                      onChange={(e) => {
                        setNetWeightType(e);
                      }}
                      options={netweightTypes}
                    />
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Gross Weight</Col>
                  <Col sm={6}>
                    <Form.Control
                      className="createProductNumberField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                      }}
                      type="number"
                      placeholder="Product weight with packaging"
                      value={grossWeight}
                      onChange={(e) => setGrossWeight(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm={4} className="discountType">
                    <Select
                      value={netweightType}
                      onChange={(e) => {
                        setNetWeightType(e);
                      }}
                      options={netweightTypes}
                    />
                  </Col>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>Size</Col>
                  <Col sm={10}>
                    <Form.Group
                      className="mb-3"
                      controlId="formBasicCheckbox"
                      style={{ display: "flex" }}
                    >
                      <Form.Check
                        value="free"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("free");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      Free
                      <Form.Check
                        value="sm"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("sm");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      SM
                      <Form.Check
                        value="md"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("md");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      MD
                      <Form.Check
                        value="lg"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("lg");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      LG
                      <Form.Check
                        value="xl"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("xl");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      XL
                      <Form.Check
                        value="xxl"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("xxl");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      XXL
                      <Form.Check
                        value="xxxl"
                        type="checkbox"
                        style={{ marginLeft: "30px" }}
                        onClick={(e) => {
                          let i = size.indexOf("xxxl");
                          if (i === -1) {
                            setSize([e.target.value, ...size]);
                          } else {
                            setRemoved(size.splice(i, 1));
                            setSize(size);
                          }
                        }}
                      />
                      XXXL
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="inputBox">
                  <Col sm={2}>Custom Size</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      style={{
                        backgroundColor: "#fff",
                        margin: "0.5rem 0rem",
                        border: "2px solid rgb(236, 235, 235)",
                      }}
                      type="text"
                      placeholder="Custom size"
                      value={customSize}
                      onChange={(e) => setCustomSize(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div
                id="nav-category-specific"
                className="product-tabs my-5 ps-4"
              >
                <h4 className="productCreateSubHeading mb-3">
                  Category Specific
                </h4>
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div
                id="nav-guarantee-warranty"
                className="product-tabs my-5 ps-4"
              >
                <h4 className="productCreateSubHeading mb-3">
                  Guarantee/Warranty
                </h4>

                <Row className="inputBox">
                  <Col sm={2}>Return Scheme</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Return Scheme"
                      value={returnScheme}
                      onChange={(e) => setReturnScheme(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Guarantee</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Gurantee Scheme"
                      value={guarantee}
                      onChange={(e) => setGuarantee(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Warranty</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      type="text"
                      placeholder="Enter Warranty Scheme"
                      value={warranty}
                      onChange={(e) => setWarranty(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div id="nav-local-management" className="product-tabs my-5 ps-4">
                <h4 className="productCreateSubHeading mb-3">
                  Location Management
                </h4>
                <Row className="inputBox">
                  <Col sm={2}>Warehouse</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      as="select"
                      value={warehouseLocation}
                      onChange={(e) => setWarehouseLocation(e.target.value)}
                    >
                      <option value="">
                        Choose Warehouse that contains the product
                      </option>
                      {userInfo?.type === "vendor" &&
                        shop?.warehouseAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}
                      {userInfo?.type === "wholesaler" &&
                        shopInfo?.warehouseAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="inputBox">
                  <Col sm={2}>Branch</Col>
                  <Col sm={10}>
                    <Form.Control
                      className="inputField"
                      as="select"
                      value={branchLocation}
                      onChange={(e) => setBranchLocation(e.target.value)}
                    >
                      <option value="">
                        Choose Branch that handles the product
                      </option>
                      {userInfo?.type === "vendor" &&
                        shop?.branchAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}
                      {userInfo?.type === "wholesaler" &&
                        shopInfo?.branchAddress?.map((w) => (
                          <option value={w.name}>{w.name}</option>
                        ))}
                    </Form.Control>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="my-3 align-items-center">
            <div id="nav-tabContent">
              <div id="nav-faqs" className="product-tabs my-5 ps-4 pe-5">
                <h4 className="productCreateSubHeading mb-3">FAQs</h4>
                {FAQ.map((add, index) => (
                  <Row className="faqBox mb-5">
                    <Row className="align-items-center">
                      <Col sm={2}>Question</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField "
                          type="text"
                          data-id={index}
                          placeholder="Enter Question"
                          value={add.question}
                          onChange={(e) => handleFAQChange(e, "question")}
                        ></Form.Control>
                      </Col>
                    </Row>

                    <Row className="align-items-center">
                      <Col sm={2}>Answer</Col>
                      <Col sm={10}>
                        <Form.Control
                          className="inputField "
                          type="text"
                          data-id={index}
                          placeholder="Enter Answer"
                          value={add.answer}
                          onChange={(e) => handleFAQChange(e, "answer")}
                        ></Form.Control>
                      </Col>
                    </Row>
                  </Row>
                ))}
                <Button className="addFaqBtn" onClick={addFAQ}>
                  + Add
                </Button>
                {FAQ.length > 0 && (
                  <Button className="addFaqBtn" onClick={removeFAQ}>
                    Remove FAQ
                  </Button>
                )}
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent">
              <div id="nav-product-details" className="product-tabs my-5 ps-4">
                <h4 className="productCreateSubHeading mb-3">
                  Product Details
                </h4>
                <Row>
                  <Col md={2}>Name</Col>
                  <Col md={10}>{name}</Col>
                </Row>
                <Row>
                  <Col md={2}>Price</Col>
                  <Col md={10}>{price}</Col>
                </Row>
                <Row>
                  <Col md={2}>Discount</Col>
                  <Col md={10}>{discount}</Col>
                </Row>
                <Row>
                  <Col md={2}>Unit</Col>
                  <Col md={10}>{unit}</Col>
                </Row>

                <Row>
                  <Col md={2}>Color/Variant</Col>
                  <Col md={10}>{colour}</Col>
                </Row>
                <Row>
                  <Col md={2}>Brand</Col>
                  <Col md={10}>{brand}</Col>
                </Row>
                <Row>
                  <Col md={2}>Category</Col>
                  <Col md={10}>{category}</Col>
                </Row>
                <Row>
                  <Col md={2}>Description</Col>
                  <Col md={10}>
                    {editorRef?.current?.getContent() &&
                      parse(editorRef?.current?.getContent())}
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>Count In Stock</Col>
                  <Col md={10}>{countInStock}</Col>
                </Row>
                <Row>
                  <Col md={2}>Min Order Qty</Col>
                  <Col md={10}>{minOrderQuantity}</Col>
                </Row>
                <Row>
                  <Col md={2}>Guarantee</Col>
                  <Col md={10}>{guarantee}</Col>
                </Row>
                <Row>
                  <Col md={2}>Warranty</Col>
                  <Col md={10}>{warranty}</Col>
                </Row>
                <Row>
                  <Col md={2}>Return Scheme</Col>
                  <Col md={10}>{returnScheme}</Col>
                </Row>
                <Row>
                  <Col md={2}>WareHouse Location</Col>
                  <Col md={10}>{warehouseLocation}</Col>
                </Row>
                <Row>
                  <Col md={2}>Branch Location</Col>
                  <Col md={10}>{branchLocation}</Col>
                </Row>
                <Row>
                  <Col md={2}>Vendor Category</Col>
                  <Col md={10}>{vendorCategory}</Col>
                </Row>
                <Row>
                  <Col md={2}>Request Publish</Col>
                  <Col md={10}>{requestPublish}</Col>
                </Row>
              </div>
            </div>
          </Row>
          <Row className="my-3">
            <div id="nav-tabContent white-background">
              <div id="nav-submit" className="product-tabs my-5 ps-4"></div>
              <Row>
                <Col>
                  <Button
                    disabled={!publishButtonDisabler}
                    variant="light"
                    className="ProductSubmitBtn btn-sm"
                    onClick={() => setRequestPublish(true)}
                    type="submit"
                  >
                    Submit for Approval
                  </Button>
                  <Button
                    variant="light"
                    className="ProductSaveBtn btn-sm"
                    type="submit"
                  >
                    <i className="far fa-copy"> </i> Save as Draft
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
        </Form>
      </div>
    </>
    // <>
    //   <Meta title='Create Product' />
    //   {userInfo?.isAdmin && (
    //     <Link to='/admin/productlist' className='btn btn-light my-3'>
    //       Go Back
    //     </Link>
    //   )}
    //   {userInfo?.type === 'vendor' && (
    //     <Link to='/vendor/productlist' className='btn btn-light my-3'>
    //       Go Back
    //     </Link>
    //   )}

    //   {userInfo?.type === 'wholesaler' && (
    //     <Link to='/wholesaler/productlist' className='btn btn-light my-3'>
    //       Go Back
    //     </Link>
    //   )}

    //   <div>
    //     <h1>Create Product</h1>

    //     <>
    //       <Tab.Container id='left-tabs-example' defaultActiveKey='first'>
    //         <Row>
    //           <Col sm={3}>
    //             <Nav variant='pills' className='flex-column'>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='first'>Basic Information</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='second'>
    //                   Additional Information
    //                 </Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='third'>FAQS</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='fourth'>Category Specific</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='fifth'>Guarantee/Warranty</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='sixth'>Location Management</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='seventh'>Product Details</Nav.Link>
    //               </Nav.Item>
    //               <Nav.Item>
    //                 <Nav.Link eventKey='eighth'>Submit</Nav.Link>
    //               </Nav.Item>
    //             </Nav>
    //           </Col>
    //           <Col sm={9}>
    //             <Form onSubmit={submitHandler}>
    //               <Tab.Content>
    //                 <Tab.Pane eventKey='first'>
    //                   <Form.Label>Name</Form.Label>
    //                   <Form.Group controlId='name'>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter name'
    //                       value={name}
    //                       onChange={(e) => setName(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   {/* <Form.Group controlId="maincategory">
    //                       <Form.Label>
    //                         <span style={{ color: "red" }}>*</span>Main Category
    //                       </Form.Label>

    //                       <Select
    //                         value={mainCategory}
    //                         onChange={(e) => {
    //                           setMainCategory(e);
    //                         }}
    //                         options={unique}
    //                       />
    //                     </Form.Group> */}

    //                   <Form.Group controlId='subcategory'>
    //                     <Form.Label>
    //                       <span style={{ color: 'red' }}>*</span>Category
    //                     </Form.Label>
    //                     <AsyncSelect
    //                       cacheOptions
    //                       defaultOptions
    //                       getOptionLabel={(e) => `${e.name}`}
    //                       getOptionValue={(e) => e.name}
    //                       className='basic-single'
    //                       loadOptions={loadCategory}
    //                       onChange={(e) => setCategory(e.name)}
    //                       placeholder='Type 3 characters for dropdown to appear'
    //                     />
    //                     {/* <Select
    //                       value={category}
    //                       onChange={(e) => {
    //                         setCategory(e);
    //                       }}
    //                       options={productCategoryList}
    //                     /> */}

    //                     <Form.Label>Vendor Specific Category</Form.Label>
    //                     <br />
    //                     <span>
    //                       Here you can specify your own category which can be
    //                       used as filter in your store
    //                     </span>
    //                     <Form.Group controlId='vendorcategory'>
    //                       <Form.Control
    //                         type='text'
    //                         placeholder='Enter Vendor Category'
    //                         value={vendorCategory}
    //                         onChange={(e) => setVendorCategory(e.target.value)}
    //                       ></Form.Control>
    //                     </Form.Group>
    //                   </Form.Group>
    //                   <Form.Group controlId='price'>
    //                     <Form.Label>Price</Form.Label>

    //                     <Form.Control
    //                       type='number'
    //                       placeholder='Enter Price'
    //                       value={price}
    //                       onChange={(e) => setPrice(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>

    //                   <Form.Group controlId='discount'>
    //                     <Form.Label>Discount</Form.Label>
    //                     <div style={{ display: 'flex' }}>
    //                       <Form.Control
    //                         style={{ width: '70%' }}
    //                         type='number'
    //                         placeholder='Enter Discount '
    //                         value={discount}
    //                         onChange={(e) => setDiscount(e.target.value)}
    //                       ></Form.Control>
    //                       <div style={{ width: '30%' }}>
    //                         <Select
    //                           value={discountType}
    //                           onChange={(e) => {
    //                             setDiscountType(e);
    //                           }}
    //                           options={discountTypes}
    //                         />
    //                       </div>
    //                     </div>
    //                   </Form.Group>

    //                   <Form.Label>Image</Form.Label>

    //                   <Form.Group controlId='image'>
    //                     <span style={{ color: 'red' }}>*</span>
    //                     {image && (
    //                       <div style={{ width: '100px', height: '100px' }}>
    //                         <img
    //                           alt=''
    //                           src={image}
    //                           style={{
    //                             width: '100px',
    //                             height: '100px',
    //                             objectFit: 'cover',
    //                           }}
    //                         />
    //                       </div>
    //                     )}

    //                     <input
    //                       id='image-file'
    //                       label='Choose File'
    //                       type='file'
    //                       custom
    //                       onChange={uploadFileHandler}
    //                     ></input>
    //                   </Form.Group>
    //                   <Form.Label>Descriptive Image</Form.Label>

    //                   <Form.Group
    //                     controlId='imageCollection'
    //                     style={{ display: 'flex', flexWrap: 'wrap' }}
    //                   >
    //                     {images.map((im) => (
    //                       <div style={{ width: '100px', height: '100px' }}>
    //                         <img
    //                           alt=''
    //                           src={im}
    //                           style={{
    //                             width: '100px',
    //                             height: '100px',
    //                             objectFit: 'contain',
    //                           }}
    //                         />
    //                       </div>
    //                     ))}

    //                     <input
    //                       id='image-files'
    //                       label='Choose Files'
    //                       type='file'
    //                       custom
    //                       multiple
    //                       onChange={uploadFilesHandler}
    //                     ></input>
    //                   </Form.Group>

    //                   <Form.Group controlId='countInStock'>
    //                     <Form.Label>Count In Stock</Form.Label>

    //                     <Form.Control
    //                       type='number'
    //                       placeholder='Enter CountInStock'
    //                       value={countInStock}
    //                       onChange={(e) => setCountInStock(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   <Form.Group>
    //                     <Form.Label>Minimum Order Quantity</Form.Label>

    //                     <Form.Control
    //                       type='number'
    //                       placeholder='Enter Minimum Order Quantity'
    //                       value={minOrderQuantity}
    //                       onChange={(e) => setMinOrderQuantity(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>

    //                   <Form.Label>Description</Form.Label>

    //                   <Editor
    //                     onInit={(evt, editor) => (editorRef.current = editor)}
    //                     apiKey='vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z'
    //                     initialValue={'<p>Describe your product here.</p>'}
    //                     init={{
    //                       height: 500,
    //                       menubar: false,
    //                       plugins: [
    //                         'advlist autolink lists link image charmap print preview anchor',
    //                         'searchreplace visualblocks code fullscreen',
    //                         'insertdatetime media table paste code help wordcount',
    //                       ],
    //                       toolbar:
    //                         'undo redo | formatselect | ' +
    //                         'bold italic backcolor | alignleft aligncenter ' +
    //                         'alignright alignjustify | bullist numlist outdent indent | ' +
    //                         'removeformat | help',
    //                       content_style:
    //                         'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
    //                     }}
    //                   />
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey='second'>
    //                   <Form.Group controlId='unit'>
    //                     <Form.Label>Unit</Form.Label>

    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Unit (e.g. kg, piece)'
    //                       value={unit}
    //                       onChange={(e) => setUnit(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   <Form.Group controlId='measurements'>
    //                     <Form.Label>Measurements</Form.Label>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Measurements (e.g. sm, lg, x, xl) '
    //                       value={measurements}
    //                       onChange={(e) => setMeasurements(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   <Form.Group controlId='colour'>
    //                     <Form.Label>Colour</Form.Label>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Colours (e.g. blue,red,green) '
    //                       value={colour}
    //                       onChange={(e) => setColour(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                   <Form.Group controlId='brand'>
    //                     <Form.Label>Brand</Form.Label>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Brand'
    //                       value={brand}
    //                       onChange={(e) => setBrand(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey='third'>
    //                   {FAQ.map((add, index) => (
    //                     <div
    //                       style={{
    //                         margin: '10px',
    //                         padding: '10px',
    //                         border: '1px solid whitesmoke',
    //                         boxShadow: '0 0 2px',
    //                       }}
    //                     >
    //                       <Form.Group controlId='name'>
    //                         <div
    //                           style={{ display: 'flex', alignItems: 'center' }}
    //                         >
    //                           <Form.Label style={{ width: '200px' }}>
    //                             Question
    //                           </Form.Label>
    //                           <Form.Control
    //                             type='text'
    //                             data-id={index}
    //                             placeholder='Enter Question'
    //                             value={add.question}
    //                             onChange={(e) => handleFAQChange(e, 'question')}
    //                           ></Form.Control>
    //                         </div>
    //                       </Form.Group>

    //                       <Form.Group controlId='id'>
    //                         <div
    //                           style={{ display: 'flex', alignItems: 'center' }}
    //                         >
    //                           <Form.Label style={{ width: '200px' }}>
    //                             Answer
    //                           </Form.Label>
    //                           <Form.Control
    //                             type='text'
    //                             data-id={index}
    //                             placeholder='Enter Answer'
    //                             value={add.answer}
    //                             onChange={(e) => handleFAQChange(e, 'answer')}
    //                           ></Form.Control>
    //                         </div>
    //                       </Form.Group>
    //                     </div>
    //                   ))}
    //                   <Button onClick={addFAQ}>Another FAQ</Button>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey='fourth'></Tab.Pane>
    //                 <Tab.Pane eventKey='fifth'>
    //                   <Form.Group controlId='guarantee'>
    //                     <Form.Label>Guarantee/Warranty</Form.Label>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Guarantee Scheme'
    //                       value={guarantee}
    //                       onChange={(e) => setGuarantee(e.target.value)}
    //                     ></Form.Control>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Warranty Scheme'
    //                       value={warranty}
    //                       onChange={(e) => setWarranty(e.target.value)}
    //                     ></Form.Control>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Return Scheme'
    //                       value={returnScheme}
    //                       onChange={(e) => setReturnScheme(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey='sixth'>
    //                   <Form.Group controlId='location'>
    //                     <Form.Label>Location Management</Form.Label>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Warehouse where the product is stored in'
    //                       value={warehouseLocation}
    //                       onChange={(e) => setWarehouseLocation(e.target.value)}
    //                     ></Form.Control>
    //                     <Form.Control
    //                       type='text'
    //                       placeholder='Enter Branch which handles the product'
    //                       value={branchLocation}
    //                       onChange={(e) => setBranchLocation(e.target.value)}
    //                     ></Form.Control>
    //                   </Form.Group>
    //                 </Tab.Pane>

    //                 <Tab.Pane eventKey='seventh'>
    //                   name: {name.replace('/', ' ')}
    //                   <br />
    //                   price:{price}
    //                   <br />
    //                   discount: {discount}
    //                   <br />
    //                   image:{image}
    //                   <br />
    //                   images:{images}
    //                   <br />
    //                   unit:{unit}
    //                   <br />
    //                   measurements:{measurements}
    //                   <br />
    //                   colour:{colour}
    //                   <br />
    //                   brand:{brand}
    //                   <br />
    //                   category: {category.label}
    //                   <br />
    //                   description: {editorRef?.current?.getContent()}
    //                   <br />
    //                   countInStock:{countInStock}
    //                   <br />
    //                   minOrderQuantity:{minOrderQuantity}
    //                   <br />
    //                   updatedById: {userInfo?._id}
    //                   <br />
    //                   uploaderType: {userInfo?.type}
    //                   <br />
    //                   vendorShop: {shop?._id}
    //                   <br />
    //                   wholesalerShop: {shopInfo?._id}
    //                   <br />
    //                   guarantee:{guarantee}
    //                   <br />
    //                   warranty:{warranty}
    //                   <br />
    //                   returnScheme:{returnScheme}
    //                   <br />
    //                   warehouseLocation:{warehouseLocation}
    //                   <br />
    //                   branchLocation:{branchLocation}
    //                   <br />
    //                   vendorCategory:{vendorCategory}
    //                   <br />
    //                   requestPublish:{requestPublish}
    //                   <br />
    //                 </Tab.Pane>
    //                 <Tab.Pane eventKey='eighth'>
    //                   <Button type='submit'>Create and Save as Draft</Button>
    //                   <Button
    //                     onClick={() => setRequestPublish(true)}
    //                     type='submit'
    //                   >
    //                     Create and Submit fot approval
    //                   </Button>
    //                 </Tab.Pane>
    //               </Tab.Content>
    //             </Form>
    //           </Col>
    //         </Row>
    //       </Tab.Container>
    //     </>
    //   </div>
    // </>
  );
};

export default ProductCreateScreen;
