import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_UPDATE_PROFILE_REQUEST,
  USER_UPDATE_PROFILE_FAIL,
  USER_UPDATE_PROFILE_SUCCESS,
  USER_DETAILS_RESET,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_RESET,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  VENDOR_REGISTRATION_REQUEST,
  VENDOR_REGISTRATION_SUCCESS,
  VENDOR_REGISTRATION_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  WHOLESALER_REGISTRATION_REQUEST,
  WHOLESALER_REGISTRATION_SUCCESS,
  WHOLESALER_REGISTRATION_FAIL,
  USER_WHOLESALER_DETAILS_REQUEST,
  USER_WHOLESALER_DETAILS_SUCCESS,
  USER_WHOLESALER_DETAILS_FAIL,
  USER_VENDOR_DETAILS_REQUEST,
  USER_VENDOR_DETAILS_SUCCESS,
  USER_VENDOR_DETAILS_FAIL,
  USER_REGISTER_RESET,
  SELLER_CREATE_REVIEW_REQUEST,
  SELLER_CREATE_REVIEW_SUCCESS,
  SELLER_CREATE_REVIEW_FAIL,
  VENDOR_NOUSER_REGISTRATION_REQUEST,
  VENDOR_NOUSER_REGISTRATION_SUCCESS,
  VENDOR_NOUSER_REGISTRATION_FAIL,
  WHOLESALER_NOUSER_REGISTRATION_REQUEST,
  WHOLESALER_NOUSER_REGISTRATION_SUCCESS,
  WHOLESALER_NOUSER_REGISTRATION_FAIL,
  VENDOR_DETAILS_REGISTRATION_REQUEST,
  VENDOR_DETAILS_REGISTRATION_SUCCESS,
  VENDOR_DETAILS_REGISTRATION_FAIL,
  WHOLESALER_DETAILS_REGISTRATION_REQUEST,
  WHOLESALER_DETAILS_REGISTRATION_SUCCESS,
  WHOLESALER_DETAILS_REGISTRATION_FAIL,
  VENDOR_LIST_REQUEST,
  VENDOR_LIST_SUCCESS,
  VENDOR_LIST_FAIL,
  WHOLESALER_LIST_REQUEST,
  WHOLESALER_LIST_SUCCESS,
  WHOLESALER_LIST_FAIL,
  APPROVE_FOR_SELLING_REQUEST,
  APPROVE_FOR_SELLING_SUCCESS,
  APPROVE_FOR_SELLING_FAIL,
  DISAPPROVE_FOR_SELLING_REQUEST,
  DISAPPROVE_FOR_SELLING_SUCCESS,
  DISAPPROVE_FOR_SELLING_FAIL,
  USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST,
  USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS,
  USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS,
  USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL,
  USER_SPECIFIC_DETAILS_REQUEST,
  USER_SPECIFIC_DETAILS_SUCCESS,
  USER_SPECIFIC_DETAILS_FAIL,
  VENDOR_SHOP_LIST_REQUEST,
  VENDOR_SHOP_LIST_SUCCESS,
  VENDOR_SHOP_LIST_FAIL,
  WHOLESALER_SHOP_LIST_REQUEST,
  WHOLESALER_SHOP_LIST_SUCCESS,
  WHOLESALER_SHOP_LIST_FAIL,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAIL,
  VENDOR_ADDRESS_DEACTIVATE_REQUEST,
  VENDOR_ADDRESS_DEACTIVATE_SUCCESS,
  VENDOR_ADDRESS_DEACTIVATE_FAIL,
  WHOLESALER_ADDRESS_DEACTIVATE_REQUEST,
  WHOLESALER_ADDRESS_DEACTIVATE_SUCCESS,
  WHOLESALER_ADDRESS_DEACTIVATE_FAIL,
} from "../constants/userConstants";
import axios from "axios";
import { ORDER_LIST_MY_RESET } from "../constants/orderConstants";
import { CART_RESET } from "../constants/cartConstants";
import { PRODUCT_WISHLIST_RESET } from "../constants/productConstants";

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config
    );
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.setItem("cartItems", []);

  dispatch({ type: USER_LOGOUT });
  dispatch({ type: USER_REGISTER_RESET });
  dispatch({ type: USER_DETAILS_RESET });
  dispatch({ type: ORDER_LIST_MY_RESET });
  dispatch({ type: USER_LIST_RESET });
  dispatch({ type: CART_RESET });
  dispatch({ type: PRODUCT_WISHLIST_RESET });
  dispatch({ type: USER_DETAILS_RESET });
  localStorage.setItem("paymentMethod", "");
};

export const register = (name, email, password, phone) => async (dispatch) => {
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      "/api/users",
      { name, email, password, phone },
      config
    );
    dispatch({
      type: USER_REGISTER_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerCustomer =
  (name, email, password, phone) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CUSTOMER_REGISTER_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        "/api/users/customerRegistration",
        { name, email, password, phone },
        config
      );
      dispatch({
        type: CUSTOMER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const registerVendor = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VENDOR_REGISTRATION_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post("/api/users/vendor", {}, config);
    dispatch({
      type: VENDOR_REGISTRATION_SUCCESS,
      payload: data.vendor,
    });

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data.user,
    });
    localStorage.setItem("userInfo", JSON.stringify(data.user));
  } catch (error) {
    dispatch({
      type: VENDOR_REGISTRATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerWholesaler = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: WHOLESALER_REGISTRATION_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post("/api/users/wholesaler", {}, config);
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data.user,
    });
    dispatch({
      type: WHOLESALER_REGISTRATION_SUCCESS,
      payload: data.wholesaler,
    });

    localStorage.setItem("userInfo", JSON.stringify(data.user));
  } catch (error) {
    dispatch({
      type: WHOLESALER_REGISTRATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const registerVendorWithNoLogIn =
  ({ name, email, password, phone }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: VENDOR_NOUSER_REGISTRATION_REQUEST,
      });
      const { data } = await axios.post("/api/users/vendornew", {
        name,
        email,
        password,
        phone,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.newUser,
      });
      dispatch({
        type: VENDOR_NOUSER_REGISTRATION_SUCCESS,
        payload: data.vendor,
      });

      localStorage.setItem("userInfo", JSON.stringify(data.newUser));
    } catch (error) {
      dispatch({
        type: VENDOR_NOUSER_REGISTRATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const registerWholesalerWithNoLogIn =
  ({ name, email, phone, password }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: WHOLESALER_NOUSER_REGISTRATION_REQUEST,
      });

      const { data } = await axios.post("/api/users/wholesalernew", {
        name,
        email,
        phone,
        password,
      });
      dispatch({
        type: WHOLESALER_NOUSER_REGISTRATION_SUCCESS,
        payload: data.wholesaler,
      });
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.newUser,
      });
      localStorage.setItem("userInfo", JSON.stringify(data.use));
    } catch (error) {
      dispatch({
        type: WHOLESALER_NOUSER_REGISTRATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const registerVendorDetails =
  (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VENDOR_DETAILS_REGISTRATION_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(`/api/users/vendordetails/${id}`, { details }, config);
      dispatch({
        type: VENDOR_DETAILS_REGISTRATION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: VENDOR_DETAILS_REGISTRATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const registerWholesalerDetails =
  (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESALER_DETAILS_REGISTRATION_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/users/wholesalerdetails/${id}`,
        { details },
        config
      );
      dispatch({
        type: WHOLESALER_DETAILS_REGISTRATION_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: WHOLESALER_DETAILS_REGISTRATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deactivateVendorAddress =
  (shopId, type, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: VENDOR_ADDRESS_DEACTIVATE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/users/vendordetails/deactivate/${shopId}`,
        { type, id },
        config
      );
      dispatch({
        type: VENDOR_ADDRESS_DEACTIVATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: VENDOR_ADDRESS_DEACTIVATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const deactivateWholesalerAddress =
  (shopId, type, id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESALER_ADDRESS_DEACTIVATE_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/users/wholesalerdetails/deactivate/${shopId}`,
        { type, id },
        config
      );
      dispatch({
        type: WHOLESALER_ADDRESS_DEACTIVATE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: WHOLESALER_ADDRESS_DEACTIVATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const approveForSelling =
  ({ type, status, id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: APPROVE_FOR_SELLING_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post("/api/users/approve", { type, status, id }, config);
      dispatch({
        type: APPROVE_FOR_SELLING_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: APPROVE_FOR_SELLING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const disapproveForSelling =
  ({ type, status, id, message }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: DISAPPROVE_FOR_SELLING_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        "/api/users/disapprove",
        { type, status, id, message },
        config
      );
      dispatch({
        type: DISAPPROVE_FOR_SELLING_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: DISAPPROVE_FOR_SELLING_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/profile`, config);
    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSpecificUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_SPECIFIC_DETAILS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };

    const { data } = await axios.get(`/api/users/profile/${id}`, config);
    dispatch({
      type: USER_SPECIFIC_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_SPECIFIC_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getVendorDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_VENDOR_DETAILS_REQUEST,
    });

    const { data } = await axios.get(`/api/users/vendor/${id}`);
    dispatch({
      type: USER_VENDOR_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_VENDOR_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getVendorDetailsByShopName = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_VENDOR_DETAILS_BY_SHOPNAME_REQUEST,
    });
    const { data } = await axios.get(`/api/users/vendor/shop/${id}`);
    dispatch({
      type: USER_VENDOR_DETAILS_BY_SHOPNAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_VENDOR_DETAILS_BY_SHOPNAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWholesalerDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_WHOLESALER_DETAILS_REQUEST,
    });
    const { data } = await axios.get(`/api/users/wholesaler/${id}`);
    dispatch({
      type: USER_WHOLESALER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_WHOLESALER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const getWholesalerDetailsByShopName = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_WHOLESALER_DETAILS_BY_SHOPNAME_REQUEST,
    });

    const { data } = await axios.get(`/api/users/wholesaler/shop/${id}`);

    dispatch({
      type: USER_WHOLESALER_DETAILS_BY_SHOPNAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_WHOLESALER_DETAILS_BY_SHOPNAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUserProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_PROFILE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(`/api/users/profile`, { user }, config);
    dispatch({
      type: USER_UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const listUsers = (pageNumber) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/users?pageNumber=${pageNumber}`,
      config
    );
    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listVendorShops =
  (keyword = "", pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: VENDOR_SHOP_LIST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users/search-vendor-shops?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: VENDOR_SHOP_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: VENDOR_SHOP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const listWholesalerShops =
  (keyword = "", pageNumber = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESALER_SHOP_LIST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users/search-wholesaler-shops?keyword=${keyword}&pageNumber=${pageNumber}`,
        config
      );
      dispatch({
        type: WHOLESALER_SHOP_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHOLESALER_SHOP_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getVendorRegistrationList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VENDOR_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `/api/users/vendor/registrationlist`,
      config
    );
    dispatch({
      type: VENDOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VENDOR_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWholesalerRegistrationList =
  () => async (dispatch, getState) => {
    try {
      dispatch({
        type: WHOLESALER_LIST_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `/api/users/wholesaler/registrationlist`,
        config
      );
      dispatch({
        type: WHOLESALER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: WHOLESALER_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/users/${id}`, config);
    dispatch({
      type: USER_DELETE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(`/api/users/${user._id}`, user, config);
    dispatch({
      type: USER_UPDATE_SUCCESS,
    });
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const createSellerReview =
  (sellerId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_CREATE_REVIEW_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/users/${sellerId}/reviews`,
        review,

        config
      );
      dispatch({
        type: SELLER_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SELLER_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const createWholesalerReview =
  (sellerId, review) => async (dispatch, getState) => {
    try {
      dispatch({
        type: SELLER_CREATE_REVIEW_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.post(
        `/api/users/wholesaler/${sellerId}/reviews`,
        review,

        config
      );
      dispatch({
        type: SELLER_CREATE_REVIEW_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: SELLER_CREATE_REVIEW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
