import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Tabs,
  Tab,
  Nav,
  Card,
  Accordion,
  Table,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import "./HelpCenterScreen.css";

const HelpCenterScreen = () => {
  const [key, setKey] = useState("home");

  const boxClickHandler = () => {
    setKey("faq");
  };

  return (
    <Container className="mb-5">
      <Row>
        <Col className="helpCenterImageContainer">
          <h1 className="helpCenterQst"> Hello, How can we help? </h1>
          <div className="helpCentersearchBar">
            <Col md={{ span: 6, offset: 3 }}>
              <Form className="d-flex">
                <FormControl
                  type="search"
                  placeholder="Enter your questions"
                  className="helpCenterSearch"
                  aria-label="Search"
                />
                <Button variant="primary" className="helpCenterSearchBtn">
                  <i className="fas fa-search fa-lg"></i>
                </Button>
              </Form>
            </Col>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <Tabs
          defaultActiveKey="home"
          id="uncontrolled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="home" title="Home" className="helpCenterTab">
            <div>
              <Row className="mt-5 mb-5">
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-user-cog fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">
                        Account Safety and Others
                      </p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-hand-holding-usd fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">Payments</p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-undo-alt fa-3x"></i>

                      <p className="helpCenterIconBoxText my-2">Orders</p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-sync-alt fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">
                        Returns and Refunds{" "}
                      </p>
                    </div>
                  </LinkContainer>
                </Col>

                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-clipboard-list fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">Buying</p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-shopping-cart fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">Selling</p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-money-check-alt fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">
                        Shipping & Delivery
                      </p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-truck fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">
                        Shipping & Delivery
                      </p>
                    </div>
                  </LinkContainer>
                </Col>
                <Col md={4} className="p-2">
                  <LinkContainer
                    role="button"
                    to="#"
                    onClick={boxClickHandler}
                    className="helpCenterIconBox"
                  >
                    <div>
                      <i className="fas fa-map-marked-alt fa-3x"></i>
                      <p className="helpCenterIconBoxText my-2">Track Order</p>
                    </div>
                  </LinkContainer>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="faq" title="FAQ" className="helpCenterTab">
            <div className="container-fluid helpCenterContainerFluid">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first1.1">
                <Row>
                  <Col sm={3} className="helpCenterCol3">
                    <Nav variant="pills" className="flex-column">
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="first1.1"
                            className="helpCenterNavLink"
                          >
                            Account Safety & Other
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="second1.1"
                            className="helpCenterNavLink"
                          >
                            Payment method
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="third1.1"
                            className="helpCenterNavLink"
                          >
                            Orders
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fourth1.1"
                            className="helpCenterNavLink"
                          >
                            Returns & Refunds
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="fifth1.1"
                            className="helpCenterNavLink"
                          >
                            Buying
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="sixth1.1"
                            className="helpCenterNavLink"
                          >
                            Selling
                          </Nav.Link>
                        </Nav.Item>
                      </div>

                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="seventh1.1"
                            className="helpCenterNavLink"
                          >
                            Shipping & Delivery
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="eighth1.1"
                            className="helpCenterNavLink"
                          >
                            Track Orders
                          </Nav.Link>
                        </Nav.Item>
                      </div>
                      <div className="helpCenterFAQTitleList"></div>
                    </Nav>
                  </Col>
                  <Col sm={9}>
                    <Tab.Content className="helpCenterTabContent">
                      <Tab.Pane
                        eventKey="first1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">
                          Account safety and others
                        </h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                How to register?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Go to Pinoy Can Buy App at
                                    www.pinoycanbuy.com.{" "}
                                  </li>
                                  <li>
                                    Click “Sign up”, complete the “Create
                                    account” form and register. Please don’t
                                    forget your email and password credentials.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How to login?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Once registered, you can now login to Pinoy
                                    Can Buy App at www.pinoycanbuy.com.{" "}
                                  </li>
                                  <li>
                                    Click “Login” and provide your login
                                    credentials in the “Sign In” page.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              <p className="helpCenterQuestion">
                                How to change login password?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Go to “User Profile”, change the login
                                    password field and click “Submit”.{" "}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                              <p className="helpCenterQuestion">
                                How to login if I forget my password?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    In the login page, click “forget password?”
                                    Link will be sent to your registered email.{" "}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                              <p className="helpCenterQuestion">
                                How to update my user/account profile?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Go to “User Profile”, and edit the fields
                                    you want to update and click “Submit”.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                              <p className="helpCenterQuestion">
                                How to update my shop details?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    To update the shop details for registered
                                    retailer and wholesaler, go to “Shop
                                    Profile” and click “Shop Details Edit” and
                                    update.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                              <p className="helpCenterQuestion">
                                Is Pinoy Can Buy App available in mobile?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Mobile application for Pinoy Can Buy App
                                    will be available soon.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="second1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Payments</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                What payment methods we accept?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Orders that are process directly through
                                    Pinoy Can Buy App can only accept cash on
                                    delivery. Other payment channels will be
                                    available in the future.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                Can I pay through Debit/Credit Card, GCash or
                                other payment channels?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Orders that are processed through other
                                    selling platforms such as Shopee and Lazada
                                    will accept other payment methods such as by
                                    debit/credit cards, GCash, etc.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="third1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Order</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How to make an order?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Register and login. Add the selected product
                                    in the cart and proceed to checkout.
                                  </li>
                                  <li>
                                    Should you wish to add more products, click
                                    “continue shopping” before proceeding to
                                    checkout.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              <p className="helpCenterQuestion">
                                Can I make an order in Pinoy Can Buy App without
                                registering?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    No, registration in Pinoy Can Buy App is
                                    required to make an order.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                              <p className="helpCenterQuestion">
                                Can I order Pinoy Can Buy products through other
                                selling platforms?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Yes, Pinoy Can Buy retails products are
                                    available in other online platforms.
                                  </li>
                                  <li>
                                    Please refer to the below links: <br />{" "}
                                    Facebook:{" "}
                                    <a
                                      href="www.facebook.com/pinoycanbuy"
                                      style={{ color: "blue" }}
                                    >
                                      www.facebook.com/pinoycanbuy
                                    </a>
                                    <br />
                                    Shopee:{" "}
                                    <a
                                      href="www.shopee.ph/pinoycanbuy"
                                      style={{ color: "blue" }}
                                    >
                                      www.shopee.ph/pinoycanbuy{" "}
                                    </a>
                                    <br />
                                    Lazada:{" "}
                                    <a
                                      href="www.lazada.com.ph/shop/pinoycanbuy/"
                                      style={{ color: "blue" }}
                                    >
                                      www.lazada.com.ph/shop/pinoycanbuy/
                                    </a>
                                    <br />
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                              <p className="helpCenterQuestion">
                                How can I use the product warranty/guarantee?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    You can directly contact the vendor to use
                                    or avail the product warranty/guarantee.
                                  </li>
                                  <li>
                                    Product warranty/guarantee are shown on each
                                    product.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                              <p className="helpCenterQuestion">
                                How can I change the delivery address?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Upon checkout, in the payment page you can
                                    click “back to shipping” or before placing
                                    the order to confirm, you can edit the
                                    shipping address.{" "}
                                  </li>
                                  <li>
                                    For orders that are already placed, changing
                                    the shipping address is not available.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="fourth1.1"
                        className="helpCenterTabPane"
                      >
                        {" "}
                        <h4 className="helpCenterH4">Return & Refunds</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                What are the terms and conditions for order
                                cancellation?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Orders that are not yet dispatched are
                                    allowed for cancellation.
                                  </li>
                                  <li>
                                    Cancellation fees may apply depending on the
                                    vendor’s policy.
                                  </li>
                                  <li>
                                    Orders that are already processed and
                                    dispatched are not allowed to be cancelled.
                                    In this case, should you prefer to return
                                    the order or any products, please refer to
                                    Pinoy Can Buy Return and Refund Policy.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How can I cancel my order that are not yet
                                dispatched?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    You should directly contact the vendor.
                                    Vendor’s contact details are available in
                                    their shop page.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              <p className="helpCenterQuestion">
                                What are the terms and conditions for returning
                                products and request for refund?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Return of products are subject to the return
                                    scheme of each product of the vendor. The
                                    return period is available on each product.
                                    Any return request beyond the return period
                                    is not allowed.{" "}
                                  </li>
                                  <li>
                                    For detailed requirements on return/refund
                                    request, please refer to Pinoy Can Buy
                                    Return and Refund Policy. Requests that do
                                    not meet Pinoy Can Buy Return and Refund
                                    Policy are ineligible for return/refund.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                              <p className="helpCenterQuestion">
                                Who will pay for the delivery charges of my
                                returned products?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Shipping fees on the returned products will
                                    be charged either on the customer or will be
                                    borne by the vendor. For further details,
                                    please refer to Pinoy Can Buy Return and
                                    Refund Policy.{" "}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                              <p className="helpCenterQuestion">
                                What if the item is already open, can I still
                                request for refund?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Items that are opened, use or appears to be
                                    used or partially consumed by the customer
                                    is ineligible for return and refund.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="fifth1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Buying</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                How to buy retail products?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    All retail products are available for
                                    purchase in Pinoy Can Buy App. Select and
                                    add the desired product to the cart to
                                    proceed for checkout.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How to buy wholesale products?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Wholesale products are only available for
                                    users registered as vendor and are not
                                    available for retail customers.
                                  </li>
                                  <li>
                                    All wholesale products are labeled as “W”
                                    and are available for purchase in Pinoy Can
                                    Buy App. Select and add the desired product
                                    to the cart to proceed for checkout.
                                  </li>
                                  <li>
                                    Purchase of the wholesale products should
                                    not be less than the required minimum order
                                    quantity, which is indicated in each
                                    product.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="sixth1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Selling</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                How can I register as a vendor?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Go to Pinoy Can Buy App at{" "}
                                    <a href="www.pinoycanbuy.com/register">
                                      www.pinoycanbuy.com/register
                                    </a>{" "}
                                    and provide all the requirements to Sign up.
                                  </li>
                                  <li>
                                    Login and click “Sell on Pinoy Can Buy” &gt;
                                    “Retail Products” and register.
                                  </li>
                                  <li>
                                    Your registration will be subject to PINOY
                                    CAN BUY review for approval.
                                  </li>
                                  <li>
                                    Once your registration is approved, you can
                                    now list your products and start selling.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How can I register as a wholesaler?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Go to Pinoy Can Buy App at{" "}
                                    <a href="www.pinoycanbuy.com/register">
                                      www.pinoycanbuy.com/register
                                    </a>{" "}
                                    and provide all the requirements to Sign up.
                                  </li>
                                  <li>
                                    Login and click “Sell on Pinoy Can Buy" &gt;
                                    Wholesale Products” and register.
                                  </li>
                                  <li>
                                    Your registration will be subject to PINOY
                                    CAN BUY review for approval.
                                  </li>
                                  <li>
                                    Once your registration is approved, you can
                                    now list your products and start selling.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="2">
                              <p className="helpCenterQuestion">
                                Can I register both as retailer and wholesaler?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="2">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    No, you can only register as either retailer
                                    or wholesaler.{" "}
                                  </li>
                                  <li>
                                    Should you wish to sell both as retailer and
                                    wholesaler, you need to register separately
                                    with different email address.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="3">
                              <p className="helpCenterQuestion">
                                How can I list/register my products?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="3">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Once your registration is approved, you can
                                    start listing your products.{" "}
                                  </li>
                                  <li>
                                    Login and go to “My Products”&gt;”Create
                                    Product”.
                                  </li>
                                  <li>
                                    You need to provide the correct product
                                    information and submit for publishing.{" "}
                                  </li>
                                  <li>
                                    All products listed will be subject to Pinoy
                                    Can Buy review for approval.
                                  </li>
                                  <li>
                                    Once approved, the product is published and
                                    available to the Pinoy Can Buy App website.
                                  </li>
                                  <li>
                                    You can also edit the unpublished/published
                                    product.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="4">
                              <p className="helpCenterQuestion">
                                As a vendor, how should I pack my products for
                                shipment??
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="4">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    All vendors should adhere Pinoy Can Buy
                                    Packaging Guidelines.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="5">
                              <p className="helpCenterQuestion">
                                As a vendor, how can I manage and track my
                                inventories?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="5">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Pinoy Can Buy App have “Management” menu,
                                    wherein you can manage your inventories,
                                    promotions, banner and images.{" "}
                                  </li>
                                  <li>
                                    This menu page will be available soon.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="6">
                              <p className="helpCenterQuestion">
                                As a vendor, I have also listed my products to
                                other online selling platforms, how can I track
                                them through Pinoy Can Buy App?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="6">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    All product quantity sold outside Pinoy Can
                                    Buy App can still be monitored within the
                                    app.
                                  </li>
                                  <li>
                                    Vendor will just create and add all the
                                    orders made outside the app to update the
                                    inventory sold quantities.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="7">
                              <p className="helpCenterQuestion">
                                Who are Pinoy Can Buy courier partners?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="7">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Currently, Pinoy Can Buy courier partners
                                    are J&T Express and Lalamove.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="8">
                              <p className="helpCenterQuestion">
                                What are the shipping charges?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="8">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Shipping charges are delivery cost for the
                                    shipment of products to the customer. It
                                    will be paid either by the customer or the
                                    vendor.{" "}
                                  </li>
                                  <li>
                                    Shipping fees depends on the product
                                    dimension and delivery location. Actual fees
                                    depend on Pinoy Can Buy courier partner’s
                                    shipping rates.{" "}
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="9">
                              <p className="helpCenterQuestion">
                                How to contact the Pinoy Can Buy courier
                                partners?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="9">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Please refer to the below information:
                                    <div className="table-responsive">
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>
                                              Pinoy Can Buy Courier Partners{" "}
                                            </th>
                                            <th>Websites</th>
                                            <th>Contact Details</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>J&T Express</td>
                                            <td>
                                              <a href="https://www.jtexpress.ph/index/query/gzquery.html">
                                                https://www.jtexpress.ph/index/query/gzquery.html{" "}
                                              </a>
                                            </td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/ ">
                                                https://www.lalamove.com/en-ph/{" "}
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Lalamove</td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/">
                                                https://www.lalamove.com/en-ph/
                                              </a>
                                            </td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/contact">
                                                https://www.lalamove.com/en-ph/contact
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="seventh1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Shipping & Delivery</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                How long is the delivery period?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Estimated delivery period will be 1 to 3
                                    days for locations near/within cities (urban
                                    areas). Provinces (rural areas) will be
                                    delivered in 3 to 5 days.
                                  </li>
                                  <li>
                                    Actual delivery period will be confirmed by
                                    Pinoy Can Buy delivery partners.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>

                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="1">
                              <p className="helpCenterQuestion">
                                How can I file complains for my lost or damaged
                                orders/products?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="1">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    For items lost or damaged during in-transit,
                                    the courier company is liable for such lost
                                    or damage. Please directly contact the
                                    vendor.
                                  </li>
                                  <li>
                                    For items lost or damaged for other reasons,
                                    please check Pinoy Can Buy Filing for Claims
                                    for more information.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>

                      <Tab.Pane
                        eventKey="eighth1.1"
                        className="helpCenterTabPane"
                      >
                        <h4 className="helpCenterH4">Track Order</h4>
                        <Accordion>
                          <Card className="helpCenterCard">
                            <Accordion.Toggle as={Card.Header} eventKey="0">
                              <p className="helpCenterQuestion">
                                How can i track my order ?
                                <span className="float-right">
                                  <i className="fas fa-chevron-down"></i>
                                </span>
                              </p>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                              <Card.Body className="helpCenterCardBody">
                                <ul>
                                  <li>
                                    Orders can be tracked online or via call
                                    through the respective websites of the
                                    chosen Pinoy Can Buy courier partner.
                                    <div className="table-responsive">
                                      <Table striped bordered hover>
                                        <thead>
                                          <tr>
                                            <th>
                                              Pinoy Can Buy Courier Partners{" "}
                                            </th>
                                            <th>Websites</th>
                                            <th>Contact Details</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>J&T Express</td>
                                            <td>
                                              <a href="https://www.jtexpress.ph/index/query/gzquery.html">
                                                https://www.jtexpress.ph/index/query/gzquery.html{" "}
                                              </a>
                                            </td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/ ">
                                                https://www.lalamove.com/en-ph/{" "}
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Lalamove</td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/">
                                                https://www.lalamove.com/en-ph/{" "}
                                              </a>
                                            </td>
                                            <td>
                                              <a href="https://www.lalamove.com/en-ph/contact">
                                                https://www.lalamove.com/en-ph/contact
                                              </a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  </li>
                                  <li>
                                    Order tracking through Pinoy Can Buy App is
                                    available at www.pinoycanbuy.com/track .
                                    This page will be available soon.
                                  </li>
                                </ul>
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        </Accordion>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Tab>
        </Tabs>
      </div>
    </Container>
  );
};

export default HelpCenterScreen;
