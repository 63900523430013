import React, { useState, useEffect, useRef } from "react";
import { Form, Button, Table, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";

import FormContainer from "../../components/FormContainer";
import {
  promotionDelete,
  createPromotion,
  getPromotion,
} from "../../actions/promotionActions";
import {
  PROMOTION_CREATE_RESET,
  PROMOTION_DELETE_RESET,
  PROMOTION_UPDATE_RESET,
} from "../../constants/promotionConstants";
import Axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PromotionListScreen = ({ history }) => {
  const dispatch = useDispatch();

  dispatch({ type: PROMOTION_UPDATE_RESET });
  const promotionList = useSelector((state) => state.promotionList);
  const { promotions } = promotionList;
  const promotionCreate = useSelector((state) => state.promotionCreate);
  const { success } = promotionCreate;
  const deletePromotion = useSelector((state) => state.deletePromotion);
  const { success: deleteSuccess } = deletePromotion;

  const [title, setTitle] = useState("");
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [promotionBanner, setPromotionBanner] = useState("");
  const [to, setTo] = useState(new Date());
  const [from, setFrom] = useState(new Date());
  const [linkTo, setLinkTo] = useState("");
  const [customerType, setCustomerType] = useState("");
  const [promotionType, setPromotionType] = useState("");
  const [campaignType, setCampaignType] = useState("");
  const [registrationDeadline, setRegistrationDeadline] = useState(new Date());
  const [validated, setValidated] = useState(false);

  const criteriaRef = useRef(null);
  const guideRef = useRef(null);
  const categoriesPreferredRef = useRef(null);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(
        createPromotion({
          title,
          criterias: criteriaRef.current?.getContent(),
          guides: guideRef.current?.getContent(),
          categoriesPreferred: categoriesPreferredRef.current.getContent(),
          promotionBanner,
          thumbnailImage,
          registrationDeadline,
          to,
          from,
          linkTo,
          customerType,
          promotionType,
          campaignType,
        })
      );
      setPromotionBanner("");
      setTo("");
      setFrom("");
      setLinkTo("");
    }
  };
  const deletePromotionHandler = (id) => {
    dispatch(promotionDelete(id));
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      setPromotionBanner(res.data.url);
    });
  };
  const uploadThumbnailImageHandler = async (e) => {
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      setThumbnailImage(res.data.url);
    });
  };
  useEffect(() => {
    if (success || deleteSuccess) {
      dispatch(getPromotion());
      dispatch({ type: PROMOTION_CREATE_RESET });
      dispatch({ type: PROMOTION_DELETE_RESET });
    }
  }, [dispatch, success, deleteSuccess]);
  useEffect(() => {
    dispatch(getPromotion());
  }, [dispatch]);
  return (
    <Row>
      <FormContainer>
        <h3>Promotion Registration</h3>

        <Form noValidate validated={validated} onSubmit={submitHandler}>
          <Form.Label>Banner Image</Form.Label>

          <Form.Group controlId="image">
            {promotionBanner && (
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  alt=""
                  src={promotionBanner}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}

            <input
              id="image-file"
              label="Choose File"
              type="file"
              custom
              onChange={uploadFileHandler}
            ></input>
          </Form.Group>

          <Form.Label>Thumbnail Image</Form.Label>

          <Form.Group controlId="image">
            {thumbnailImage && (
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  alt=""
                  src={thumbnailImage}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}

            <input
              id="image-file"
              label="Choose File"
              type="file"
              custom
              onChange={uploadThumbnailImageHandler}
            ></input>
          </Form.Group>

          <Form.Group controlId="from">
            <Form.Label>From</Form.Label>

            <DatePicker selected={from} onChange={(date) => setFrom(date)} />
          </Form.Group>
          <Form.Group controlId="to">
            <Form.Label>To</Form.Label>

            <DatePicker selected={to} onChange={(date) => setTo(date)} />
          </Form.Group>

          <Form.Group controlId="title">
            <Form.Label>Title</Form.Label>

            <Form.Control
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="registrationdeadline">
            <Form.Label>Registration Deadline</Form.Label>

            <DatePicker
              selected={registrationDeadline}
              onChange={(date) => setRegistrationDeadline(date)}
            />
          </Form.Group>

          <Form.Group controlId="linkto">
            <Form.Label>Link To</Form.Label>

            <Form.Control
              type="text"
              value={linkTo}
              onChange={(e) => setLinkTo(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="customertype">
            <Form.Label>Targeted Customer</Form.Label>

            <Col>
              <Form.Check
                type="radio"
                label="All"
                name="type"
                value="all"
                onChange={(e) => setCustomerType(e.target.value)}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Customer"
                name="type"
                value="vendor"
                onChange={(e) => setCustomerType(e.target.value)}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Retailer"
                name="type"
                value="wholesaler"
                onChange={(e) => setCustomerType(e.target.value)}
              ></Form.Check>
            </Col>
          </Form.Group>

          <Form.Group controlId="campaigntype">
            <Form.Label>Campaign Type</Form.Label>

            <Col>
              <Form.Check
                type="radio"
                label="Mega"
                name="campaignType"
                value="mega"
                onChange={(e) => setCampaignType(e.target.value)}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Daily Sales"
                name="campaignType"
                value="dailysales"
                onChange={(e) => setCampaignType(e.target.value)}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Special Promotion"
                name="campaignType"
                value="specialpromotion"
                onChange={(e) => setCampaignType(e.target.value)}
              ></Form.Check>
            </Col>
          </Form.Group>

          <Form.Group controlId="promotiontype">
            <Form.Label>Promotion Type</Form.Label>

            <Col>
              <Form.Check
                type="radio"
                label="Voucher"
                name="promotionType"
                value="voucher"
                onChange={(e) => setPromotionType(e.target.value)}
              ></Form.Check>
              <Form.Check
                type="radio"
                label="Flash Sale"
                name="promotionType"
                value="flashsale"
                onChange={(e) => setPromotionType(e.target.value)}
              ></Form.Check>
            </Col>
          </Form.Group>

          <Form.Label>Participation Guide</Form.Label>

          <Editor
            onInit={(evt, editor) => (guideRef.current = editor)}
            apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
            initialValue="<p>Participation Guide</p>"
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <Form.Label>Participation criteria</Form.Label>

          <Editor
            onInit={(evt, editor) => (criteriaRef.current = editor)}
            apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
            initialValue="<p>Participation criteria</p>"
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
          <Form.Label>Categories Preferred</Form.Label>

          <Editor
            onInit={(evt, editor) => (categoriesPreferredRef.current = editor)}
            apiKey="vfxq7kcd084xjuzjxu81tbqxeilyd9j2b2bo0ni486a0we8z"
            initialValue="<p>List the categories preferred.</p>"
            init={{
              height: 500,
              menubar: false,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | " +
                "bold italic backcolor | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />

          <Button type="submit" variant="primary">
            Insert
          </Button>
        </Form>
      </FormContainer>

      <h3>Promotion List</h3>

      <Table
        striped
        bordered
        hover
        responsive
        size="sm"
        style={{ fontSize: "13px" }}
      >
        <thead>
          <tr>
            <th>ID</th>
            <th>Banner</th>
            <th>Link To</th>
            <th>To</th>
            <th>From</th>
          </tr>
        </thead>
        <tbody>
          {promotions?.map((promotion) => (
            <tr key={promotion._id}>
              <td>
                {" "}
                <Link
                  to={`/promotion/requests/${promotion._id}`}
                  style={{ background: "none", textDecoration: "none" }}
                >
                  {promotion._title}{" "}
                </Link>
              </td>
              <td style={{ height: "100px", width: "100px" }}>
                <Link to={`/promotion/requests/${promotion._id}`}>
                  <img
                    src={promotion.promotionBanner}
                    alt={promotion.title}
                    style={{
                      height: "100px",
                      width: "100px",
                      objectFit: "contain",
                    }}
                  />
                </Link>
              </td>
              <td>{promotion.linkTo}</td>
              <td>{new Date(promotion.to).toLocaleString()}</td>
              <td>{new Date(promotion.from).toLocaleString()}</td>
              <td>
                <Link to={`/edit/promotion/${promotion._id}`}>Edit</Link>
              </td>
              <td>
                <Button onClick={() => deletePromotionHandler(promotion._id)}>
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Row>
  );
};

export default PromotionListScreen;
