import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./Rating";
import { Row, Col } from "react-bootstrap";
import "./Product.css";
import AddToWishListButton from "./AddToWishListButton";
import { addCartItemToServer } from "../actions/cartActions";

//google tag manager
import TagManager from "react-gtm-module";

const Product = ({ product, history, toastToggler }) => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cartButtonHandler = () => {
    const tagManagerArgs = {
      gtmId: "GTM-P27XCP3",
      dataLayer: {
        event: "Add to cart", // event to be picked up by GTM
        productId: product?._id || "", // anything you want here
        name: product?.name || "",
        category: product?.category || "",
        brand: product?.brand || "",
        price: product?.price || "",
        userId: userInfo?._id || "",
        time: Date.now(),
      },
    };
    TagManager.dataLayer(tagManagerArgs);

    if (!userInfo) {
      history.push("/login");
    }

    if (product?.countInStock < product?.minOrderQuantity) {
      toastToggler(true);
    } else {
      toastToggler(false);
      dispatch(
        addCartItemToServer(product?._id, product?.minOrderQuantity || 1)
      );
    }
  };

  return (
    <Row className="cardContainer">
      <div
        className={
          product?.uploaderType === "vendor" ? `cardVendor` : "cardWholesale"
        }
      >
        <div
          className="AddToWishListBtn"
          style={{
            display: "flex",
            flexDirection: "column",
            position: "absolute",
            right: "10px",
            top: "0px",
          }}
        >
          <AddToWishListButton history={history} product={product} />
          <div onClick={() => cartButtonHandler()} className="addToCartBtn">
            <i className="bi bi-cart"></i>
          </div>
        </div>
        <div className="imgContainer">
          {/* <Link to={`/product/${product?.name}/${product?._id} `}> */}
          <img
            onClick={() => {
              history.push(`/product/${product?.name}/${product?._id}`);
              const tagManagerArgs = {
                gtmId: "GTM-P27XCP3",
                dataLayer: {
                  event: "View Product", // event to be picked up by GTM
                  productId: product?._id || "", // anything you want here
                  name: product?.name || "",
                  category: product?.category || "",
                  brand: product?.brand || "",
                  price: product?.price || "",
                  userId: userInfo?._id || "",
                  time: Date.now(),
                },
              };

              TagManager.dataLayer(tagManagerArgs);
            }}
            src={
              product?.image?.split("upload")[0] +
              "/upload/w_200,h_200,c_scale" +
              product?.image?.split("upload")[1]
            }
            style={{
              height: "200px",
              width: "100%",
              objectFit: "cover",
              cursor: "pointer",
            }}
            alt={product?.name}
          />
          {/* </Link> */}
        </div>
        <Row>
          <div className="ProductcardBody px-3 py-2">
            <Col>
              {/* <Link to={`/product/${product?.name}/${product?._id} `}> */}
              <div
                className="productTitle"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push(`/product/${product?.name}/${product?._id}`);
                  const tagManagerArgs = {
                    gtmId: "GTM-P27XCP3",
                    dataLayer: {
                      event: "View Product", // event to be picked up by GTM
                      productId: product?._id || "", // anything you want here
                      name: product?.name || "",
                      category: product?.category || "",
                      brand: product?.brand || "",
                      price: product?.price || "",
                      userId: userInfo?._id || "",
                      time: Date.now(),
                    },
                  };

                  TagManager.dataLayer(tagManagerArgs);
                }}
              >
                {product?.name.substring(0, 46)}
              </div>
              {/* </Link> */}
              <span>
                {product?.uploaderType === "wholesaler" ? (
                  <i className="fas fa-industry me-2 mb-1 vendor-icon"></i>
                ) : (
                  <i className="fas fa-store me-2 mb-1 vendor-icon"></i>
                )}
                {product?.vendorShop?.shopName?.substring(0, 18) ||
                  product?.wholesalerShop?.shopName?.substring(0, 18)}
              </span>
              {!product?.discount && (
                <Row className="sellingPriceSellingNo">
                  <Col sm={7}>
                    <Col className="col-md-12 cardSellingPrice">
                      ₱{product.sellingPrice || product?.price}{" "}
                    </Col>
                  </Col>
                  <Col sm={5} className="soldNumber">
                    {`(${
                      Number(product?.initialStock) -
                      Number(product?.countInStock)
                    })`}
                    Sold
                  </Col>
                </Row>
              )}
              {product?.discount > 0 && (
                <Row className="sellingPriceSellingNo">
                  <Col sm={7}>
                    <Col className="col-md-12 cardSellingPrice">
                      ₱{product?.price - product?.discount}{" "}
                      <span className="col-sm-6 text-end cardMarkedPrice">
                        ₱{product?.price}
                      </span>
                    </Col>
                    <div className="cardMarkedPrice">
                      <span className="discount">
                        <span className="discountPercent">
                          {Math.floor(
                            (product?.discount / product?.price) * 100
                          )}
                          %
                        </span>
                        <span> </span>
                        Off
                      </span>
                    </div>
                  </Col>
                  <Col sm={5} className="soldNumber">
                    {`(${
                      Number(product?.initialStock) -
                      Number(product?.countInStock)
                    })`}
                    Sold
                  </Col>
                </Row>
              )}
            </Col>
            <Row>
              <Col>
                <Rating
                  value={product?.rating}
                  text={`(${
                    Number(product?.initialStock) -
                    Number(product?.countInStock)
                  })`}
                  uploaderType={product?.uploaderType}
                  minOrderQuantity={product?.minOrderQuantity}
                />
              </Col>
            </Row>
          </div>
        </Row>
      </div>
    </Row>
  );
};

export default Product;
