import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import { listProductCategoryWise } from "../../actions/productActions";
import Paginate from "../../components/Paginate.js";
import Meta from "../../components/Meta.js";
import { listSubCategories } from "../../actions/categoryActions.js";

const CategoryWiseProductScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const category = match.params.category;
  const { search } = useLocation();
  const {
    pageNum,
    ratingStars,
    productBrand,
    productServices,
    productWarrantyType,
    productMinPrice,
    productMaxPrice,
  } = queryString.parse(search);

  const pageNumber = pageNum ? pageNum : 1;
  const stars = ratingStars ? ratingStars : 0;
  const brand = productBrand ? productBrand : "";
  const services = productServices ? productServices : "";
  const warrantyType = productWarrantyType ? productWarrantyType : "";
  const minPrice = productMinPrice ? productMinPrice : 0;
  const maxPrice = productMaxPrice ? productMaxPrice : 99999;

  const [categoryItems, setCategoryItems] = useState(5);
  const [brandItems, setBrandItems] = useState(5);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);

  const categoryWiseProductList = useSelector(
    (state) => state.categoryWiseProductList
  );
  const { loading, error, products, pages, page, brands } =
    categoryWiseProductList;

  const subCategories = useSelector((state) => state.subCategories);
  const { categories } = subCategories;
  useEffect(() => {
    dispatch(listSubCategories(category));
    dispatch(
      listProductCategoryWise(
        category,
        pageNumber,
        minValue,
        maxValue,
        stars,
        warrantyType,
        services,
        brand
      )
    );
  }, [
    dispatch,
    pageNumber,
    category,
    minValue,
    maxValue,
    stars,
    warrantyType,
    services,
    brand,
  ]);
  return (
    <>
      <Meta title={category} />
      <div className="container px-0 py-3">
        <Row>
          <Col md="2" sm="4">
            <h5> Sub Categories</h5>

            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              <ul>
                {categories?.slice(0, categoryItems).map((cat, index) => {
                  return (
                    <li>
                      <Link
                        to={`/category/${cat}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                        key={index}
                        style={{
                          margin: "5px 0",
                          fontSize: "15px",
                          color: "#55595c",
                        }}
                      >
                        {cat}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setCategoryItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Brands</h5>
            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              {brands?.slice(0, brandItems).map((br, index) => {
                return (
                  <>
                    <Link
                      to={`/category/${category}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${br}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                      key={index}
                      style={{
                        margin: "5px 0",
                        fontSize: "15px",
                        color: "#55595c",
                      }}
                    >
                      {br}
                    </Link>
                    <br />
                  </>
                );
              })}
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setBrandItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Price </h5>
            <Row>
              <Col md="6" className="px-1">
                <Form.Control
                  type="number"
                  placeholder="MIN"
                  value={minValue}
                  onChange={(e) => setMinValue(e.target.value)}
                />
              </Col>
              <Col md="6">
                <Form.Control
                  type="number"
                  placeholder="MAX"
                  value={maxValue}
                  onChange={(e) => setMaxValue(e.target.value)}
                />
              </Col>
              <Col classname="centerAlign">
                <div className="d-grid gap-2">
                  <Button
                    type="button"
                    className="priceFilterButtons mt-2"
                    onClick={() =>
                      history.push(
                        `/category/${category}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minValue}&productMaxPrice=${maxValue}`
                      )
                    }
                  >
                    Apply
                  </Button>
                </div>
              </Col>
            </Row>

            <hr></hr>

            <h5> Rating </h5>
            <Row>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/category/${category}?pageNum=${pageNumber}&ratingStars=5&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/category/${category}?pageNum=${pageNumber}&ratingStars=4&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/category/${category}?pageNum=${pageNumber}&ratingStars=3&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/category/${category}?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/category/${category}?pageNum=${pageNumber}&ratingStars=1&productBrand=${brand}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
            </Row>
            <hr></hr>

            <h5> Warranty Type</h5>
            <Row>
              <Form.Group
                className="RelatedCategory"
                controlId="formBasicCheckbox"
              >
                <Link to={"#"}>No Warranty</Link>
                <Link to={"#"}>Brand Warranty</Link>
                <Link to={"#"}>Seller Warranty</Link>
              </Form.Group>
            </Row>
            <hr></hr>
            <Row>
              <Button
                className="priceFilterButtons mt-2"
                onClick={() => history.push(`/category/${category}`)}
              >
                Clear All Filters
              </Button>
            </Row>
          </Col>
          <Col md="10" sm="6" className="leftBlock">
            <h1 style={{ fontSize: "32px", fontWeight: "500" }}>{category}</h1>
            <hr></hr>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <>
                {products?.length === 0 && <h2>No products found</h2>}
                <Row>
                  {products?.map((product) => {
                    return (
                      <Col md="3" key={product._id}>
                        <Product history={history} product={product} />
                      </Col>
                    );
                  })}
                </Row>
              </>
            )}
          </Col>
        </Row>
        <Paginate url={`/category/${category}`} pages={pages} page={page} />
      </div>
    </>
  );
};

export default CategoryWiseProductScreen;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Row, Col } from "react-bootstrap";
// import Product from "../../components/Product.js";
// import Message from "../../components/Message.js";
// import Loader from "../../components/Loader.js";
// import { listProductCategoryWise } from "../../actions/productActions";
// import Paginate from "../../components/Paginate.js";
// import Meta from "../../components/Meta.js";
// import SearchBox from "../../components/SearchBox.js";

// const CategoryWiseProductScreen = ({ match, history }) => {
// 	const dispatch = useDispatch();
// 	const min = match.params.min;
// 	const max = match.params.max;
// 	const category = match.params.category;
// 	const keyword = match.params.keyword || "";
// 	const pageNumber = match.params.pageNumber || 1;

// 	const categoryWiseProductList = useSelector(
// 		(state) => state.categoryWiseProductList
// 	);
// 	const { loading, error, products, pages, page, count } =
// 		categoryWiseProductList;
// 	useEffect(() => {
// 		dispatch(listProductCategoryWise(category, keyword, pageNumber, min, max));
// 	}, [dispatch, keyword, pageNumber, category, min, max]);
// 	return (
// 		<div className="p-3">
// 			<Meta title={`Reseller-${category}`} />

// 			<SearchBox
// 				placing="categoryScreen"
// 				history={history}
// 				url={`category/${category}`}
// 				placeHolder="In Category"
// 			/>

// 			<h1>{category}</h1>

// 			<h6>
// 				{loading
// 					? `Loading category products`
// 					: count
// 					? `Displaying ${count} products in ${category}`
// 					: `No products found`}
// 			</h6>
// 			{loading ? (
// 				<Loader />
// 			) : error ? (
// 				<Message variant="danger">{error}</Message>
// 			) : (
// 				<Row>
// 					<Col md={"2"} style={{ background: "whitesmoke" }}>
// 					</Col>
// 					<Col md={"10"}>
// 						<Row className="container">
// 							{products?.map((product) => {
// 								return (
// 									<Col key={product._id} sm={6} md={3} xl={2}>
// 										<Product product={product} />
// 									</Col>
// 								);
// 							})}
// 						</Row>
// 						<Paginate
// 							url={`/category/${category}`}
// 							pages={pages}
// 							page={page}
// 							keyword={keyword ? keyword : ""}
// 						/>
// 					</Col>
// 				</Row>
// 			)}
// 		</div>
// 	);
// };

// export default CategoryWiseProductScreen;
