import React from "react";
import { Route } from "react-router-dom";
import OrderCreateScreen from "../screens/Dashboard/OrderCreateScreen";
import OrderEditScreen from "../screens/Dashboard/OrderEditScreen";
import CartScreen from "../screens/Order/CartScreen";
import PlaceOrderScreen from "../screens/Order/customer/PlaceOrderScreen";
import ShippingScreen from "../screens/Order/customer/ShippingScreen";
import MyOrderListScreen from "../screens/Order/MyOrderListScreen";
import OrderListScreen from "../screens/Order/OrderLstScreen";
import OrderScreen from "../screens/Order/OrderScreen";
import PaymentScreen from "../screens/Order/PaymentScreen";
import VendorPlaceOrderScreen from "../screens/Order/vendor/VendorPlaceOrderScreen";
import VendorShippingScreen from "../screens/Order/vendor/VendorShippingScreen";
import VendorOrderListScreen from "../screens/Order/VendorOrderListScreen";
import VendorWiseOrderScreen from "../screens/Order/VendorWiseOrderScreen";
import WholesalerPlaceOrderScreen from "../screens/Order/wholesaler/WholesalerPlaceOrderScreen";
import WholesalerShippingScreen from "../screens/Order/wholesaler/WholesalerShippingScreen";

const OrderRoutes = () => {
  return (
    <div>
      <Route path="/order/:id" component={OrderScreen} exact />
      <Route path="/create/order" component={OrderCreateScreen} />
      <Route path="/edit/order/:id" component={OrderEditScreen} />

      <Route path="/admin/orderlist" component={OrderListScreen} exact />

      <Route
        path="/admin/orderlist/page/:pageNumber"
        component={OrderListScreen}
      />

      <Route path="/vendor/orderlist" component={VendorOrderListScreen} exact />

      <Route
        path="/vendor/orderlist/page/:pageNumber"
        component={VendorOrderListScreen}
      />

      <Route path="/myorders" component={MyOrderListScreen} exact />

      <Route path="/myorders/page/:pageNumber" component={MyOrderListScreen} />
      <Route path="/cart/:id?" component={CartScreen} />
      <Route path="/shipping" component={ShippingScreen} />

      <Route path="/customer/shipping/detail" component={ShippingScreen} />
      <Route path="/retail/shipping/detail" component={VendorShippingScreen} />
      <Route
        path="/wholesale/shipping/detail"
        component={WholesalerShippingScreen}
      />

      <Route path="/payment" component={PaymentScreen} />
      <Route path="/customer/placeorder" component={PlaceOrderScreen} />
      <Route
        path="/vendor-order/placeorder"
        component={VendorPlaceOrderScreen}
      />
      <Route
        path="/wholesaler-order/placeorder"
        component={WholesalerPlaceOrderScreen}
      />
      <Route path="/order/vendor/:id" component={VendorWiseOrderScreen} />
    </div>
  );
};

export default OrderRoutes;
