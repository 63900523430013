import React, { useEffect, useState } from "react";
import { Row, Col, Carousel, ProgressBar } from "react-bootstrap";
import moment from "moment";
import "./StoreProfilePage.css";

const StoreProfilePage = ({ id, seller, type }) => {
  const [displayReviews, setDisplayReviews] = useState(seller?.reviews);

  const totalRatings = seller?.reviews?.length;
  const total5Stars = seller?.reviews?.filter((five) => five.rating === 5);
  const total4Stars = seller?.reviews?.filter((four) => four.rating === 4);
  const total3Stars = seller?.reviews?.filter((three) => three.rating === 3);
  const total2Stars = seller?.reviews?.filter((two) => two.rating === 2);
  const total1Stars = seller?.reviews?.filter((one) => one.rating === 1);

  const positiveRating =
    total5Stars?.length + total4Stars?.length + total3Stars?.length;

  useEffect(() => {
    if (seller) {
      setDisplayReviews(seller.reviews);
    }
  }, [seller]);

  return (
    <>
      <Row className="profileContainer mb-3 p-5">
        <h3>About us</h3>
        <Col sm={12} md={6}>
          <Carousel>
            {seller?.profileBannerImages?.length === 0 && (
              <Carousel.Item interval={5000}>
                <img
                  className="carouselImage"
                  src="https://freedesignfile.com/upload/2020/07/Online-Shopping-Banner-Mobile-App-Vector.jpg"
                  alt="Second slide"
                />
              </Carousel.Item>
            )}

            {seller?.profileBannerImages?.map((image) => {
              return (
                <Carousel.Item key={image} interval={5000}>
                  <img
                    className="carouselImage"
                    src={image}
                    alt="First slide"
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </Col>
        <Col sm={12} md={6} className="px-5">
          <div className="storeDescription my-5">
            Welcome to {seller?.shopName} Shop. Here you get the products as
            your requirements. Products are as same as it is in the picture.
            <p className="my-3">
              <strong>Joined Since: </strong>
              {new Date(seller?.createdAt).toLocaleString()}
            </p>
          </div>
        </Col>
      </Row>
      <hr className="whiteSpace"></hr>
      <Row className="profileContainer mb-3 p-5">
        <Col sm={12} md={4}>
          <h5 className="ratingHeading">Average Seller Ratings </h5>
          <p className="sellerRating">
            {" "}
            {positiveRating ? (positiveRating / totalRatings) * 100 : "0"}%
          </p>
          <h6 className="positiveRating">Positive Ratings</h6>
          <Row>
            <Col md={3} className="fiveRating">
              Excited
            </Col>
            <Col md={7}>
              <ProgressBar
                className="sellerProgressBar"
                now={total5Stars?.length / totalRatings?.length}
                label={total5Stars?.length}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="fiveRating">
              Happy
            </Col>
            <Col md={7}>
              <ProgressBar
                now={total4Stars?.length / totalRatings?.length}
                label={total4Stars?.length}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="fiveRating">
              Neutral
            </Col>
            <Col md={7}>
              <ProgressBar
                now={total3Stars?.length / totalRatings?.length}
                label={total3Stars?.length}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="fiveRating">
              Sad
            </Col>
            <Col md={7}>
              <ProgressBar
                now={total2Stars?.length / totalRatings?.length}
                label={total2Stars?.length}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3} className="fiveRating">
              Angry
            </Col>
            <Col md={7}>
              <ProgressBar
                className="text-warning"
                now={total1Stars?.length / totalRatings?.length}
                label={total1Stars?.length}
              />
            </Col>
          </Row>
        </Col>
        <Col sm={12} md={8}>
          <h5 className="ratingHeading">Seller Ratings and Reviews</h5>
          <p>
            {" "}
            <span
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setDisplayReviews(total5Stars)}
            >
              😀
            </span>
            <span
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setDisplayReviews(total4Stars)}
            >
              🙂
            </span>
            <span
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setDisplayReviews(total3Stars)}
            >
              😐
            </span>
            <span
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setDisplayReviews(total2Stars)}
            >
              🙁
            </span>
            <span
              style={{ fontSize: "40px", cursor: "pointer" }}
              onClick={() => setDisplayReviews(total1Stars)}
            >
              😡
            </span>
          </p>
          {displayReviews?.map((r) => (
            <Row className="mt-5">
              <Col md={6}>
                <p className="customerRate">
                  {r.rating === 5
                    ? "😀 Excited"
                    : r.rating === 4
                    ? "🙂 Happy"
                    : r.rating === 3
                    ? "😐 Neutral"
                    : r.rating === 2
                    ? "🙁 Sad"
                    : r.rating === 1
                    ? "😡 Angry"
                    : ""}
                </p>
                <p className="customerName">
                  @{r.name} <i>{moment(r.createdAt).fromNow()}</i>
                </p>
              </Col>
              <Col md={6}>
                <p className="varified">
                  <i className="fas fa-shield-alt text-success"></i> verified
                  purchase
                </p>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};
export default StoreProfilePage;
