import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { register } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import "./RegisterScreen.css";

const RegisterScreen = ({ history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [phone, setPhone] = useState("");
  const [validated, setValidated] = useState("");

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, userInfo: userRegInfo, error } = userRegister;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (error) {
      setMessage(error);
    }
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo, error]);
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (password !== confirmPassword) {
        setMessage("Passwords do not Match");
      } else {
        dispatch(register(name, email, password, phone));
      }
    }
  };
  return (
    <FormContainer>
      <Container className="mt-5 mb-5">
        <Row className="h-100">
          <Col className="align-items-center registerForm text-center">
            {userRegInfo && (
              <Message variant="info">
                Registration successfull. Please check you email for
                verification link...
              </Message>
            )}
            <Col>
              <h1 className="registerHeader mt-5">Create account</h1>
            </Col>
            {message && <Message variant="danger">{message}</Message>}
            {/* {error && <Message variant="danger">{error}</Message>} */}
            {loading && <Loader />}
            <Form
              noValidate
              validated={validated}
              onSubmit={submitHandler}
              className="col-sm-9 mx-auto"
            >
              <Form.Group controlId="name">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Name</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mb-2"
                  type="name"
                  placeholder="Enter Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="email">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Email</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Password</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Confirm</Form.Label>
                  </Col>
                </Row>
                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="phone">
                <Row>
                  <Col sm={1}>
                    <Form.Label>Phone</Form.Label>
                  </Col>
                </Row>

                <Form.Control
                  className="registerFields mt-2 mb-2"
                  type="number"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <div className="d-grid gap-2">
                <Button type="submit" className="registerBtn mt-5 mb-3">
                  Register
                </Button>
              </div>
            </Form>
            <Row className="py-3">
              <Col className="haveAnAccount mb-5">
                Have an Account? <span> </span>
                <Link className="loginLink" to="/login">
                  Login
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default RegisterScreen;
