import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import FormContainer from "../../components/FormContainer";
import { updateCategory, detailsCategory } from "../../actions/categoryActions";

import Axios from "axios";

const CategoryFormScreen = ({ history, match }) => {
  const id = match.params.id;
  const dispatch = useDispatch();

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const { success: successUpdate } = categoryUpdate;

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { category } = categoryDetails;

  const [level1, setLevel1] = useState(category?.level1);
  const [level2, setLevel2] = useState("");
  const [level3, setLevel3] = useState("");
  const [level4, setLevel4] = useState("");
  const [level5, setLevel5] = useState("");
  const [level6, setLevel6] = useState("");
  const [image, setImage] = useState("");
  const [validated, setValidated] = useState(false);

  const uploadFileHandler = async (e) => {
    // setImageAsFile(e.target.files[0]);

    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    formData.append("upload_preset", "pinoy-can-group");

    Axios.post(
      "https://api.cloudinary.com/v1_1/pinoy-can-group/image/upload",
      formData
    ).then((res) => {
      setImage(res.data.url);
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(
        updateCategory({
          id,
          level1,
          level2,
          level3,
          level4,
          level5,
          level6,
          image,
        })
      );

      history.push("/create/category/page/1");
    }
  };
  useEffect(() => {
    if (!successUpdate) {
      dispatch(detailsCategory(id));
      setLevel1(category?.level1);
      setLevel2(category?.level2);
      setLevel3(category?.level3);
      setLevel4(category?.level4);
      setLevel5(category?.level5);
      setLevel6(category?.level6);
      setImage(category?.image);
    } else {
      history.push("/create/category/page/1");
    }
  }, [
    dispatch,
    history,
    category?.level1,
    category?.level2,
    category?.level3,
    category?.level4,
    category?.level5,
    category?.level6,
    category?.image,
    id,
    successUpdate,
  ]);

  return (
    <>
      <FormContainer style={{}}>
        <h3>Category Registration</h3>

        <Form onSubmit={submitHandler} noValidate validated={validated}>
          <Form.Group controlId="">
            <Form.Label>level1</Form.Label>

            <Form.Control
              type="text"
              value={level1}
              onChange={(e) => setLevel1(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="">
            <Form.Label>level2</Form.Label>

            <Form.Control
              type="text"
              value={level2}
              onChange={(e) => setLevel2(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="">
            <Form.Label>level3</Form.Label>

            <Form.Control
              type="text"
              value={level3}
              onChange={(e) => setLevel3(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="">
            <Form.Label>level4</Form.Label>

            <Form.Control
              type="text"
              value={level4}
              onChange={(e) => setLevel4(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="">
            <Form.Label>level5</Form.Label>

            <Form.Control
              type="text"
              value={level5}
              onChange={(e) => setLevel5(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="">
            <Form.Label>level6</Form.Label>

            <Form.Control
              type="text"
              value={level6}
              onChange={(e) => setLevel6(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Label>Image</Form.Label>

          <Form.Group controlId="image">
            <span style={{ color: "red" }}>*</span>
            {image && (
              <div style={{ width: "100px", height: "100px" }}>
                <img
                  alt=""
                  src={image}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            )}

            <input
              id="image-file"
              label="Choose File"
              type="file"
              custom
              onChange={uploadFileHandler}
            ></input>
          </Form.Group>

          <Button type="submit" variant="primary">
            Update
          </Button>
        </Form>
      </FormContainer>
    </>
  );
};

export default CategoryFormScreen;
