import React from "react";
import { Route } from "react-router-dom";
import AdminShopEditScreen from "../screens/Register/AdminShopEditScreen";
import StorePageScreen from "../screens/Store/StorePageScreen";

const StoreRoutes = () => {
  return (
    <div>
      <Route
        path="/shop/:shopname/search/:keyword/page/:pageNumber"
        component={StorePageScreen}
        exact
      />
      <Route
        path="/shop/:shopname/search/:keyword"
        component={StorePageScreen}
        exact
      />
      <Route
        path="/shop/:shopname/page/:pageNumber"
        component={StorePageScreen}
        exact
      />
      <Route
        path="/shop/:shopname/:vendorCategory"
        component={StorePageScreen}
        exact
      />
      <Route path="/shop/:shopname" component={StorePageScreen} exact />
      <Route
        path="/registration/:type/:name"
        component={AdminShopEditScreen}
        exact
      />
    </div>
  );
};

export default StoreRoutes;
