import React, { useEffect, useState } from "react";
import { Button, Col, Row, Badge, Form, Modal } from "react-bootstrap";
import {
  deactivateVendorAddress,
  deactivateWholesalerAddress,
  registerVendorDetails,
  registerWholesalerDetails,
} from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import {
  loadBarangayList,
  loadRegionList,
  loadProvinceList,
  loadMunicipalityList,
} from "../actions/philipinesAddressAction";

const VendorAddress = ({ detailsUser }) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [option, setOption] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  //const for edit of address
  const [addType, setAddType] = useState("");
  const [addId, setAddId] = useState("");
  const [name, setName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [muncipality, setMuncipality] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [country, setCountry] = useState("Philippines");
  const [ward, setWard] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [phone, setPhone] = useState("");
  const [additionalAddress, setAdditionalAddress] = useState("");
  const [validated, setValidated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (address, type) => {
    setAddType(type);
    setAddId(address._id);
    setName(address.name);
    setAddressLine1(address.addressLine1);
    setMuncipality(address.muncipality);
    setProvince(address.province);
    setDistrict(address.district);
    setCountry("Philipines");
    setWard(address.ward);
    setPostalCode(address.postalCode);
    setPhone(address.phone);
    setShow(true);
  };
  let headOffice = detailsUser?.headOffice;
  let warehouseAddress = detailsUser?.warehouseAddress;
  let returnAddress = detailsUser?.returnAddress;
  let branchAddress = detailsUser?.branchAddress;

  const handleDeactivateAddress = (type, id) => {
    if (userInfo?.type === "vendor") {
      dispatch(deactivateVendorAddress(detailsUser._id, type, id));
    } else if (userInfo?.type === "wholesaler") {
      dispatch(deactivateWholesalerAddress(detailsUser._id, type, id));
    }
  };

  const updateAddress = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      if (addType === "HeadOffice") {
        if (userInfo?.type === "vendor") {
          dispatch(
            registerVendorDetails(detailsUser._id, {
              headOfficeCountry: country,
              headOfficeMuncipality: muncipality,
              headOfficeAddress: addressLine1,
              headOfficeDistrict: district,
              headOfficeProvince: province,
              headOfficePostalCode: postalCode,
              headOfficeAddressPhone: phone,
              headOfficeAdditional: additionalAddress,
            })
          );
        } else if (userInfo?.type === "wholesaler") {
          dispatch(
            registerWholesalerDetails(detailsUser._id, {
              headOfficeCountry: country,
              headOfficeMuncipality: muncipality,
              headOfficeAddress: addressLine1,
              headOfficeDistrict: district,
              headOfficeProvince: province,
              headOfficePostalCode: postalCode,
              headOfficeAddressPhone: phone,
              headOfficeAdditional: additionalAddress,
            })
          );
        }
      } else {
        let _tempAddress;
        if (addType === "Warehouse") {
          _tempAddress = [...warehouseAddress];
        } else if (addType === "Branch") {
          _tempAddress = [...branchAddress];
        } else {
          _tempAddress = [...returnAddress];
        }
        let getAddIndex = _tempAddress.findIndex((temp) => temp._id === addId);
        _tempAddress[getAddIndex].name = name;
        _tempAddress[getAddIndex].addressLine1 = addressLine1;
        _tempAddress[getAddIndex].muncipality = muncipality;
        _tempAddress[getAddIndex].province = province;
        _tempAddress[getAddIndex].district = district;
        _tempAddress[getAddIndex].country = country;
        _tempAddress[getAddIndex].ward = ward;
        _tempAddress[getAddIndex].postalCode = postalCode;
        _tempAddress[getAddIndex].phone = phone;

        if (addType === "Warehouse") {
          warehouseAddress = _tempAddress;
        } else if (addType === "Branch") {
          branchAddress = _tempAddress;
        } else {
          returnAddress = _tempAddress;
        }
        if (userInfo.type === "vendor") {
          dispatch(
            registerVendorDetails(detailsUser._id, {
              warehouseAddressContainer: warehouseAddress,
              returnAddressContainer: returnAddress,
              branchAddressContainer: branchAddress,
            })
          );
        } else if (userInfo.type === "wholesaler") {
          dispatch(
            registerWholesalerDetails(detailsUser._id, {
              warehouseAddressContainer: warehouseAddress,
              returnAddressContainer: returnAddress,
              branchAddressContainer: branchAddress,
            })
          );
        }
      }
    }
  };
  async function loadRegion() {
    const data = await loadRegionList();
    setOption(data);
  }

  async function loadProvince(value) {
    const data = loadProvinceList(value, province);
    return data;
  }
  async function loadMunicipality(value) {
    const data = loadMunicipalityList(value, district);
    return data;
  }

  async function loadBarangay(value) {
    const data = loadBarangayList(value, muncipality);
    return data;
  }

  useEffect(() => {
    loadRegion();
  }, []);
  return (
    <>
      {detailsUser && headOffice && (
        <Col md={6}>
          <div className="addAddressBox p-3 ml-2">
            <Row className="addressInfoBox">
              <Row>
                <Col>
                  <Badge pill bg="primary" className="addressBadge mb-2">
                    Head Office
                  </Badge>
                </Col>
              </Row>
              <Col md={1}>
                <i className="fas fa-map-marker-alt fa-lg text-info"></i>
              </Col>
              <Col md={10}>
                <p>
                  {headOffice.addressLine1 && `${headOffice.addressLine1},`}
                  {headOffice.province}, {headOffice.district},
                  {headOffice.country}, {headOffice.postalCode}
                </p>
              </Col>
            </Row>
            <Row className="addressInfoBox">
              <Col md={1}>
                <i className="fas fa-home fa-lg text-info"></i>
              </Col>
              <Col md={10}>
                <p>
                  {headOffice.muncipality} {headOffice.ward}
                </p>
              </Col>
            </Row>
            <Row className="addressInfoBox">
              <Col md={1}>
                <i className="fas fa-phone-alt fa-lg text-info"></i>
              </Col>
              <Col md={10}>
                <p>{headOffice.phone}</p>
              </Col>
            </Row>
            <div className="addressBoxEditBtn">
              <Button
                type="submit"
                className="editAddressRoundBtn"
                onClick={() => handleShow(headOffice, "HeadOffice")}
              >
                <i className="fas fa-edit fa-lg"></i>
              </Button>
            </div>
          </div>
        </Col>
      )}
      {detailsUser &&
        warehouseAddress.map((ware) => {
          if (!ware.inactive)
            return (
              <Col md={6}>
                <div className="addAddressBox p-3 ml-2">
                  <Row className="addressInfoBox">
                    <Row>
                      <Col>
                        <Badge pill bg="success" className="addressBadge mb-2">
                          WareHouse Address
                        </Badge>
                      </Col>
                    </Row>
                    <Col md={1}>
                      <i className="fas fa-map-marker-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {ware.name},{ware.addressLine1},{ware.province},{" "}
                        {ware.district},{ware.country}, {ware.postalCode}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-home fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {ware.muncipality} {ware.ward}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-phone-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>{ware.phone}</p>
                    </Col>
                  </Row>
                  <div className="addressBoxEditBtn">
                    <Button
                      type="submit"
                      className="editAddressRoundBtn"
                      onClick={() => handleShow(ware, "Warehouse")}
                    >
                      <i className="fas fa-edit fa-lg"></i>
                    </Button>

                    <Button
                      className="editAddressRoundBtn bg-red"
                      onClick={() =>
                        handleDeactivateAddress("Warehouse", ware._id)
                      }
                    >
                      <i className="fas fa-trash fa-lg"></i>
                    </Button>
                  </div>
                </div>
              </Col>
            );
        })}
      {detailsUser &&
        returnAddress.map((ret) => {
          if (!ret.inactive) {
            return (
              <Col md={6}>
                <div className="addAddressBox p-3 ml-2">
                  <Row className="addressInfoBox">
                    <Row>
                      <Col>
                        <Badge pill bg="success" className="addressBadge mb-2">
                          Return Address
                        </Badge>
                      </Col>
                    </Row>
                    <Col md={1}>
                      <i className="fas fa-map-marker-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {ret.name},{ret.addressLine1},{ret.province},{" "}
                        {ret.district},{ret.country}, {ret.postalCode}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-home fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {ret.muncipality} {ret.ward}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-phone-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>{ret.phone}</p>
                    </Col>
                  </Row>
                  <div className="addressBoxEditBtn">
                    <Button
                      type="submit"
                      className="editAddressRoundBtn"
                      onClick={() => handleShow(ret, "Return")}
                    >
                      <i className="fas fa-edit fa-lg"></i>
                    </Button>

                    <Button
                      className="editAddressRoundBtn "
                      onClick={() => handleDeactivateAddress("Return", ret._id)}
                    >
                      <i className="fas fa-trash fa-lg"></i>
                    </Button>
                  </div>
                </div>
              </Col>
            );
          }
        })}
      {detailsUser &&
        branchAddress.map((brn) => {
          if (!brn.inactive) {
            return (
              <Col md={6}>
                <div className="addAddressBox p-3 ml-2">
                  <Row className="addressInfoBox">
                    <Row>
                      <Col>
                        <Badge pill bg="success" className="addressBadge mb-2">
                          Branch Address
                        </Badge>
                      </Col>
                    </Row>
                    <Col md={1}>
                      <i className="fas fa-map-marker-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {brn.name},{brn.addressLine1},{brn.province},{" "}
                        {brn.district},{brn.country}, {brn.postalCode}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-home fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>
                        {brn.muncipality} {brn.ward}
                      </p>
                    </Col>
                  </Row>
                  <Row className="addressInfoBox">
                    <Col md={1}>
                      <i className="fas fa-phone-alt fa-lg text-info"></i>
                    </Col>
                    <Col md={10}>
                      <p>{brn.phone}</p>
                    </Col>
                  </Row>
                  <div className="addressBoxEditBtn">
                    <Button
                      type="submit"
                      className="editAddressRoundBtn"
                      onClick={() => handleShow(brn, "Branch")}
                    >
                      <i className="fas fa-edit fa-lg"></i>
                    </Button>

                    <Button
                      className="editAddressRoundBtn bg-red"
                      onClick={() => handleDeactivateAddress("Branch", brn._id)}
                    >
                      <i className="fas fa-trash fa-lg"></i>
                    </Button>
                  </div>
                </div>
              </Col>
            );
          }
        })}
      <Row>
        <Modal show={show} onHide={handleClose}>
          <Row>
            <Col md={6}>
              <h1 className="addAddressTitle">Edit Address </h1>
            </Col>
            <Col md={6}>
              <Button className="modelCloseBtn" onClick={handleClose}>
                <i className="fas fa-times fa-lg"></i>
              </Button>
            </Col>
          </Row>
          <Modal.Body className="p-5">
            <Row>
              <Col md={10} className="py-2">
                <Form
                  onSubmit={(e) => updateAddress(e)}
                  noValidate
                  validated={validated}
                >
                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Name
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter Name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Country
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter your country"
                      required
                      disabled
                      value={country}
                      onChange={(e) => setCountry(e.target.country)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Region
                    </Form.Label>
                    <Select
                      className="orderCreateInputField"
                      options={option}
                      onChange={(e) => {
                        setProvince(e.value);
                      }}
                      placeholder="Choose your region"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Province
                    </Form.Label>

                    <AsyncSelect
                      className="orderCreateInputField"
                      loadOptions={loadProvince}
                      onChange={(e) => {
                        setDistrict(e.value);
                      }}
                      placeholder="Type characters for dropdown to appear"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Municipality
                    </Form.Label>

                    <AsyncSelect
                      className="orderCreateInputField"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadMunicipality}
                      onChange={(e) => {
                        setMuncipality(e.value);
                      }}
                      placeholder="Type characters for dropdown to appear"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Barangay
                    </Form.Label>

                    <AsyncSelect
                      className="orderCreateInputField"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadBarangay}
                      onChange={(e) => {
                        setWard(e.value);
                      }}
                      placeholder="Type characters for dropdown to appear"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Street Address
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter your Street Address"
                      required
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Additional address information
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter your Street Address"
                      value={additionalAddress}
                      onChange={(e) => setAdditionalAddress(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      ZIP Code
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter your Postal Code"
                      required
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <Form.Label className="registrationFormLabel">
                      Phone
                    </Form.Label>
                    <Form.Control
                      className="registerFormInput"
                      type="text"
                      placeholder="Enter your Address"
                      required
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      This field is required
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button type="submit" className="vendorRegistrationBtn">
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </Row>
    </>
  );
};

export default VendorAddress;
