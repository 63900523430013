import Axios from "axios";

export const loadRegionList = async (value) => {
  const reg = await Axios.get(`/api/philipinesAddress/region`);
  return reg.data;
};

export const loadProvinceList = async (value, newProvince) => {
  if (value.length > 0) {
    const reg = await Axios.get(
      `/api/philipinesAddress/province/${value}/${newProvince}`
    );
    return reg.data;
  }
};
export const loadMunicipalityList = async (value, newDistrict) => {
  if (value.length > 0) {
    const reg = await Axios.get(
      `/api/philipinesAddress/municipality/${value}/${newDistrict}`
    );
    return reg.data;
  }
};

export const loadBarangayList = async (value, newMuncipality) => {
  if (value.length > 0) {
    const reg = await Axios.get(
      `/api/philipinesAddress/barangay/${value}/${newMuncipality}`
    );
    return reg.data;
  }
};
