import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Chart from "chart.js";

const CardLineChart = () => {
  const dashboardCard = useSelector((state) => state.dashboardCard);
  const { sdata, daysAgo } = dashboardCard;

  useEffect(() => {
    if (sdata) {
      var config = {
        type: "line",
        data: {
          labels: [
            daysAgo[6],
            daysAgo[5],
            daysAgo[4],
            daysAgo[3],
            daysAgo[2],
            daysAgo[1],
            daysAgo[0],
          ],
          datasets: [
            {
              label: "Sales Data",
              backgroundColor: "#4c51bf",
              borderColor: "#4c51bf",
              data: [
                sdata[6],
                sdata[5],
                sdata[4],
                sdata[3],
                sdata[2],
                sdata[1],
                sdata[0],
              ],
              fill: false,
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: false,
            text: "Sales Charts",
            fontColor: "rgba(0,0,0,.4)",
          },
          legend: {
            labels: {
              fontColor: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  fontColor: "rgba(0,0,0,.4)",
                },
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Last 7 Days",
                  fontColor: "rgba(0,0,0,.4)",
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.2)",
                  zeroLineColor: "rgba(33, 37, 41, 0.15)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: "rgba(0,0,0,.4)",
                },
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: "Sales Amount",
                  fontColor: "rgba(0,0,0,.4)",
                },
                gridLines: {
                  borderDash: [2],
                  drawBorder: false,
                  borderDashOffset: [2],
                  color: "rgba(33, 37, 41, 0.2)",
                  zeroLineColor: "rgba(33, 37, 41, 0.15)",
                  zeroLineBorderDash: [2],
                  zeroLineBorderDashOffset: [2],
                },
              },
            ],
          },
        },
      };
      var ctx = document.getElementById("line-chart").getContext("2d");
      window.myLine = new Chart(ctx, config);
    }
  }, [sdata, daysAgo]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
        <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full max-w-full flex-grow flex-1">
              <h6 className="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
                Overview
              </h6>
              <h2 className="text-blueGray-100 text-xl font-semibold">
                Sales value
              </h2>
            </div>
          </div>
        </div>
        <div className="p-4 flex-auto">
          {/* Chart */}
          <div className="relative h-350-px">
            <canvas
              id="line-chart"
              style={{ width: "971px", height: "250px" }}
            ></canvas>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardLineChart;
