import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Toast, Alert } from "react-bootstrap";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import { listWishlist, removeFromWishlist } from "../../actions/productActions";
import Meta from "../../components/Meta.js";
import "./MyWishlistScreen.css";
import { REMOVE_WISHLIST_RESET } from "../../constants/productConstants.js";
import { Link } from "react-router-dom";
import { addCartItemToServer } from "../../actions/cartActions.js";

//google tag manager
import TagManager from "react-gtm-module";

const MyWishlistScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const wishlistList = useSelector((state) => state.wishlistList);
  const { products, error, loading } = wishlistList;

  const cartButtonHandler = (product) => {
    const tagManagerArgs = {
      gtmId: "GTM-P27XCP3",
      dataLayer: {
        event: "Add to cart", // event to be picked up by GTM
        productId: product?._id, // anything you want here
        userId: userInfo?._id,
        name: product?.name,
        category: product?.category,
        brand: product?.brand,
        price: product?.price,
        time: Date.now(),
      },
    };

    TagManager.dataLayer(tagManagerArgs);
    setShowToast(true);
    dispatch(
      addCartItemToServer(
        product?.productId?._id,
        product?.productId?.minOrderQuantity || 1
      )
    );
    dispatch(removeFromWishlist(product.productId._id));
  };

  const wishlistDelete = useSelector((state) => state.wishlistDelete);
  const { success: deleteSuccess } = wishlistDelete;

  const removeWishlistHandler = (product) => {
    dispatch(removeFromWishlist(product.productId._id));
  };
  useEffect(() => {
    if (deleteSuccess) {
      window.location.reload();
      dispatch({ type: REMOVE_WISHLIST_RESET });
    }
  }, [dispatch, deleteSuccess]);

  useEffect(() => {
    dispatch(listWishlist());
  }, [dispatch]);

  return (
    <div>
      <Meta title="My Wishlist" />
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                <Alert
                  variant="success"
                  style={{
                    margin: "0px",
                  }}
                >
                  Your Product has been added.
                </Alert>
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}

      <h1 className="wishListHeading">My Wishlist</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          {products.length === 0 && <h3>Your wishlist is empty</h3>}
          {products?.map((product) => (
            <Row className="wishListContent">
              <a
                href={
                  product?.vendorShop
                    ? `/shop/${product?.vendorShop?.shopName}`
                    : `/wholesaleShop/${product?.wholesalerShop?.shopName}`
                }
                className="sellerlink mb-3"
              >
                {product?.vendorShop?.shopName ||
                  product?.wholesalerShop?.shopName}
              </a>
              <Row className="wishList">
                <Col sm={8}>
                  <Row className="imageNameRow">
                    <Col sm={2}>
                      <img
                        className="img-thumbnail col-md-4"
                        src={product?.productId?.image}
                        alt={product?.productId?.name}
                        style={{ width: 75 }}
                      />
                    </Col>
                    <Col sm={10} className="wishListProductName">
                      <Link
                        to={`/product/${product?.productId?.name}/${product?.productId?._id}`}
                      >
                        {product?.productId?.name}
                      </Link>
                    </Col>
                  </Row>
                </Col>
                <Col sm={2}> ₱ {product?.productId?.price} </Col>
                <Col sm={1}>
                  <i
                    className="fas fa-shopping-cart wishListIcon"
                    onClick={() => cartButtonHandler(product)}
                  ></i>
                </Col>
                <Col sm={1}>
                  <i
                    className="fas fa-trash wishListIcon"
                    onClick={() => removeWishlistHandler(product)}
                  ></i>
                </Col>
              </Row>
              <hr></hr>
            </Row>
          ))}
        </>
      )}
    </div>
  );
};

export default MyWishlistScreen;
