import React from "react";
import { Route } from "react-router-dom";
import AdminProductListScreen from "../screens/Product/AdminProductListScreen";
import FollowedProductsProductScreen from "../screens/Product/FollowedProductsProductScreen";
import MyWishlistScreen from "../screens/Product/MyWishlistScreen";
import ProductCreateScreen from "../screens/Product/ProductCreateScreen";
import ProductEditScreen from "../screens/Product/ProductEditScreen";
import ProductListScreen from "../screens/Product/ProductlistScreen";
import ProductScreen from "../screens/Product/ProductScreen";
import StorePageScreen from "../screens/Store/StorePageScreen";
import WholesaleProductScreen from "../screens/Product/WholesaleProductScreen";
import CustomerCreateScreen from "../screens/Dashboard/CustomerCreateScreen";

const ProductRoutes = () => {
  return (
    <div>
      <Route path="/create/customer" component={CustomerCreateScreen}></Route>
      <Route
        path="/admin/productlist"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/search/:keyword"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/page/:pageNumber"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/search/:keyword/page/:pageNumber"
        component={AdminProductListScreen}
        exact
      />

      <Route
        path="/admin/productlist/:unpublished"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/:unpublished/search/:keyword"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/:unpublished/page/:pageNumber"
        component={AdminProductListScreen}
        exact
      />
      <Route
        path="/admin/productlist/:unpublished/search/:keyword/page/:pageNumber"
        component={AdminProductListScreen}
        exact
      />

      <Route path="/admin/search/:keyword" component={StorePageScreen} exact />
      <Route
        path="/admin/page/:pageNumber"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/admin/search/:keyword/page/:pageNumber"
        component={ProductListScreen}
        exact
      />

      <Route path="/vendor/productlist" component={ProductListScreen} exact />
      <Route
        path="/vendor/productlist/search/:keyword"
        component={ProductListScreen}
      />
      <Route
        path="/vendor/productlist/page/:pageNumber"
        component={ProductListScreen}
      />
      <Route
        path="/vendor/productlist/search/:keyword/page/:pageNumber"
        component={ProductListScreen}
      />

      <Route
        path="/vendor/productlist/:unpublished"
        component={ProductListScreen}
        exact
      />
      <Route
        path="/vendor/productlist/:unpublished/search/:keyword"
        component={ProductListScreen}
      />
      <Route
        path="/vendor/productlist/:unpublished/page/:pageNumber"
        component={ProductListScreen}
      />
      <Route
        path="/vendor/productlist/:unpublished/search/:keyword/page/:pageNumber"
        component={ProductListScreen}
      />

      <Route path="/product/:name/:id" component={ProductScreen} />
      <Route path="/followed" component={FollowedProductsProductScreen} />

      <Route
        path="/admin/product/:id/:userId/edit"
        component={ProductEditScreen}
        exact
      />
      <Route
        path="/vendor/product/:id/:userId/edit"
        component={ProductEditScreen}
        exact
      />
      <Route path="/mywishlist" component={MyWishlistScreen} />

      <Route
        path="/vendor/wholesaleproducts"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/vendor/wholesaleproducts/search/:keyword"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/vendor/wholesaleproducts/page/:pageNumber"
        component={WholesaleProductScreen}
        exact
      />
      <Route
        path="/vendor/wholesaleproducts/search/:keyword/page/:pageNumber"
        component={WholesaleProductScreen}
        exact
      />

      <Route path="/vendor/product/create" component={ProductCreateScreen} />
      <Route
        path="/admin/product/create"
        component={ProductCreateScreen}
        exact
      />
    </div>
  );
};

export default ProductRoutes;
