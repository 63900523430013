import React from "react";
import { Route } from "react-router-dom";
import LoginScreen from "../screens/Login/LoginScreen";

const LoginRoutes = () => {
  return (
    <div>
      <Route path="/login" component={LoginScreen} />
    </div>
  );
};

export default LoginRoutes;
