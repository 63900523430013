export const STAFF_CREATE_REQUEST = "STAFF_CREATE_REQUEST";
export const STAFF_CREATE_SUCCESS = "STAFF_CREATE_SUCCESS";
export const STAFF_CREATE_FAIL = "STAFF_CREATE_FAIL";
export const STAFF_CREATE_RESET = "STAFF_CREATE_RESET";

export const STAFF_GET_REQUEST = "STAFF_GET_REQUEST";
export const STAFF_GET_SUCCESS = "STAFF_GET_SUCCESS";
export const STAFF_GET_FAIL = "STAFF_GET_FAIL";

export const STAFF_UPDATE_REQUEST = "STAFF_UPDATE_REQUEST";
export const STAFF_UPDATE_SUCCESS = "STAFF_UPDATE_SUCCESS";
export const STAFF_UPDATE_FAIL = "STAFF_UPDATE_FAIL";
export const STAFF_UPDATE_RESET = "STAFF_UPDATE_RESET";

export const STAFF_DELETE_REQUEST = "STAFF_DELETE_REQUEST";
export const STAFF_DELETE_SUCCESS = "STAFF_DELETE_SUCCESS";
export const STAFF_DELETE_FAIL = "STAFF_DELETE_FAIL";
export const STAFF_DELETE_RESET = "STAFF_DELETE_RESET";

export const STAFF_DETAILS_REQUEST = "STAFF_DETAILS_REQUEST";
export const STAFF_DETAILS_SUCCESS = "STAFF_DETAILS_SUCCESS";
export const STAFF_DETAILS_FAIL = "STAFF_DETAILS_FAIL";

export const STAFF_DETAILS_BY_STAFF_ID_REQUEST =
  "STAFF_DETAILS_BY_STAFF_ID_REQUEST";
export const STAFF_DETAILS_BY_STAFF_ID_SUCCESS =
  "STAFF_DETAILS_BY_STAFF_ID_SUCCESS";
export const STAFF_DETAILS_BY_STAFF_ID_FAIL = "STAFF_DETAILS_BY_STAFF_ID_FAIL";
