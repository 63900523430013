import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, desc, keywords, image, url, name }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="product" />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={image} />
      <meta property="og:site_name" content="Pinoy Can Buy" />
      <meta property="twitter:title" content="Pinoy Can Buy" />
      <meta property="twitter:site" content="@pinoycanbuy" />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:description" content={desc} />
      <meta property="twitter:image" content={image} />
      <meta property="twitter:url" content={url} />
      <meta name="twitter:image:alt" content={name} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Pinoy Can Buy",
  keywords: "electronics, buy electronics,cheap electronics",
  description: "We sell the best products for cheap",
  name: "Product",
  image: "/images/spi.jpg",
  url: "pinoycanbuy.com",
};

export default Meta;
