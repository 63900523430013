import axios from "axios";
import {
  DASHBOARD_DATA_FAIL,
  DASHBOARD_DATA_REQUEST,
  DASHBOARD_DATA_SUCCESS,
} from "../constants/dashboardConstants";

export const dashboardCardStats = (id) => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    dispatch({ type: DASHBOARD_DATA_REQUEST });
    const { data } = await axios.get(`/api/dashboard/${id}/data`, config);

    dispatch({ type: DASHBOARD_DATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DASHBOARD_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
