import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { login } from "../../actions/userActions";
import FormContainer from "../../components/FormContainer";
import "./LoginScreen.css";
import queryString from "query-string";
import axios from "axios";

const LoginScreen = ({ location, history }) => {
  const { search } = useLocation();
  const { verified, passwordChanged } = queryString.parse(search);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, userInfo, error } = userLogin;

  // const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
  }, [history, userInfo]);
  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(login(email, password));
    }
  };

  const verifyEmail = () => {
    axios.post("/api/users/verifyemailfromloginpage", { email }).then((res) => {
      if (res.status === 200) {
        setMessage("Please check your email for verification link...");
      } else {
        setMessage("Oops! something went wrong");
      }
    });
  };
  useEffect(() => {
    if (error) {
      setMessage(error);
    }
    if (passwordChanged === "successfull") {
      setMessage(
        "Password has been changed successfully. Please use your newcredentials to log in."
      );
      if (verified === "true") {
        setMessage("Your account has been verified.");
      }
    }
  }, [verified, passwordChanged, error]);

  return (
    <FormContainer>
      <Container className="mt-5 mb-5">
        <Row className="h-100">
          <Col className="align-items-center loginForm text-center">
            <Col>
              <h1 className="signInHeader mt-5">Sign In</h1>
            </Col>
            {/* {error && <Message variant="danger">{error}</Message>}
            {error === "User is not verified" && (
              <Button onClick={verifyEmail}>Verify email</Button>
            )} */}
            {message && <Message variant="info">{message}</Message>}
            {loading && <Loader />}
            <Form
              noValidate
              validated={validated}
              onSubmit={submitHandler}
              className="col-sm-9 mx-auto"
            >
              <Form.Group controlId="email">
                <Form.Label
                  className="mt-5"
                  style={{ color: "black", marginRight: "87%" }}
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  autoComplete="on"
                  placeholder="Enter Email"
                  className="loginFields  mb-5"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label style={{ color: "black", marginRight: "80%" }}>
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  className="loginFields mb-5"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  This field is required
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col>
                  <Link className="forgotPassword mb-5" to="/enteremail">
                    Forgot Password?
                  </Link>
                </Col>
              </Row>
              <div className="d-grid gap-2 ml-3 mr-3">
                <Button type="submit" className="loginSubmitBtn mt-5 mb-5">
                  Sign In
                </Button>
              </div>
            </Form>
            <Row className="notRegisterLine mt-5 mb-5">
              <Col>
                Not Registred yet? <span> </span>
                <Link className="createAccount" to="/register">
                  Create an account
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </FormContainer>
  );
};

export default LoginScreen;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Form, Button, Row, Col, Card } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import Message from "../../components/Message";
// import Loader from "../../components/Loader";
// import { login } from "../../actions/userActions";
// import FormContainer from "../../components/FormContainer";

// const LoginScreen = ({ location, history }) => {
// 	const [email, setEmail] = useState("");
// 	const [password, setPassword] = useState("");
// 	const dispatch = useDispatch();

// 	const userLogin = useSelector((state) => state.userLogin);
// 	const { loading, userInfo, error } = userLogin;

// 	const redirect = location.search ? location.search.split("=")[1] : "/";

// 	useEffect(() => {
// 		if (userInfo) {
// 			history.push(redirect);
// 		}
// 	}, [history, userInfo, redirect]);
// 	const submitHandler = (e) => {
// 		e.preventDefault();
// 		dispatch(login(email, password));
// 	};
// 	return (
// 		<div style={{ marginTop: "5%", paddingBottom: "10%" }}>
// 			<h3
// 				className="text-center"
// 				style={{
// 					fontFamily: "Montserrat",
// 					fontSize: 36,
// 					color: "#003893",
// 				}}
// 			>
// 				Welcome Back. Login To Continue
// 			</h3>
// 			<Card
// 				style={{
// 					boxShadow: "0 0 25px rgba(0, 0, 0, 0.25)",
// 					marginLeft: "20%",
// 					width: "40vw",
// 					paddingTop: "5%",
// 					paddingBottom: "5%",
// 				}}
// 			>
// 				<Col md={8} sm={12} className="mx-auto">
// 					<h3 className="text-center">Sign In</h3>
// 					{error && <Message variant="danger">{error}</Message>}
// 					{loading && <Loader />}
// 					<Form onSubmit={submitHandler}>
// 						<Form.Group controlId="email">
// 							<Form.Label>Email Address</Form.Label>
// 							<Form.Control
// 								type="email"
// 								autocomplete
// 								placeholder="Enter Email"
// 								value={email}
// 								onChange={(e) => setEmail(e.target.value)}
// 							></Form.Control>
// 						</Form.Group>
// 						<Form.Group controlId="password">
// 							<Form.Label>Password</Form.Label>
// 							<Form.Control
// 								type="password"
// 								placeholder="Enter Password"
// 								value={password}
// 								onChange={(e) => setPassword(e.target.value)}
// 							></Form.Control>
// 						</Form.Group>
// 						<div className="float-right mt-3" style={{ textAlign: "end" }}>
// 							<Link
// 								to={redirect ? `/register?redirect=${redirect}` : "/register"}
// 							>
// 								Forget Password?
// 							</Link>
// 						</div>
// 						<div className="text-center mt-3">
// 							<button
// 								type="submit"
// 								style={{ width: 250, backgroundColor: "#DC1E3E" }}
// 								className="btn btn-outline-primary"
// 							>
// 								Sign In
// 							</button>
// 						</div>
// 					</Form>
// 					<Row className="py-3">
// 						<Col className="text-center my-3">
// 							<h5
// 								style={{
// 									fontSize: 16,
// 									fontWeight: 500,
// 								}}
// 							>
// 								No account yet?
// 							</h5>
// 							<Link
// 								to={redirect ? `/register?redirect=${redirect}` : "/register"}
// 								style={{ width: 250 }}
// 								className="btn btn-outline-primary"
// 							>
// 								Register
// 							</Link>
// 						</Col>
// 					</Row>
// 				</Col>
// 			</Card>
// 		</div>
// 	);
// };

// export default LoginScreen;
