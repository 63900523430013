import React, { useState, useEffect } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { savePaymentMethod } from "../../actions/cartActions";
import { USER_DETAILS_ADDRESSBOOK_RESET } from "../../constants/addressConstants";
import {
  WHOLESELLER_ORDER_ADDRESS_RESET,
  VENDOR_ORDER_ADDRESS_RESET,
} from "../../constants/addressConstants";

const PaymentScreen = ({ history }) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [paymentMethod, setPaymentMethod] = useState("Cash On Delivery");
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const changeShipping = async () => {
    if (userInfo.type === "wholesaler") {
      dispatch({ type: WHOLESELLER_ORDER_ADDRESS_RESET });
      history.push("/wholesale/shipping/detail");
    } else if (userInfo.type === "vendor") {
      dispatch({ type: VENDOR_ORDER_ADDRESS_RESET });
      history.push("/retail/shipping/detail");
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
    } else {
      dispatch(savePaymentMethod(paymentMethod));
      localStorage.setItem("paymentMethod", JSON.stringify(paymentMethod));

      if (userInfo.type === "customer") {
        history.push({
          pathname: "/customer/placeorder",
          search: `?paymentMethod=${paymentMethod}`,
        });
      } else if (userInfo.type === "vendor") {
        history.push({
          pathname: "/vendor-order/placeorder",
          search: `?paymentMethod=${paymentMethod}`,
        });
      } else if (userInfo.type === "wholesaler") {
        history.push({
          pathname: "/wholesaler-order/placeorder",
          search: `?paymentMethod=${paymentMethod}`,
        });
      }
    }
  };
  useEffect(() => {
    dispatch({ type: USER_DETAILS_ADDRESSBOOK_RESET });
  }, [dispatch]);
  return (
    <>
      <Row>
        <Col md={{ span: 8, offset: 2 }} className="mt-5">
          {/* {userInfo.type === "customer" ? (
            <ChectoutSteps step1 step2 step3 />
          ) : (
            <></>
          )} */}

          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <h1>Payment Method</h1>
              <Form
                noValidate
                validated={validated}
                className="paymentMethodBox"
                onSubmit={submitHandler}
              >
                <Form.Group>
                  <Form.Label as="legend" className="mb-3">
                    Select Method
                  </Form.Label>
                  <Col>
                    <Form.Check
                      className="mt-5"
                      type="radio"
                      label="Credit/Debit Card"
                      id="Paypal"
                      name="paymentMethod"
                      value="PayPal"
                      disabled
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    ></Form.Check>
                    <Form.Check
                      className="mt-3"
                      type="radio"
                      label="Cash On Delivery"
                      id="cashondelivery"
                      name="paymentMethod"
                      value="Cash On Delivery"
                      onChange={(e) => setPaymentMethod(e.target.value)}
                    ></Form.Check>
                  </Col>
                </Form.Group>
                <Row>
                  {userInfo.type === "customer" ? (
                    <Col md={8}></Col>
                  ) : (
                    <Col md={8}>
                      <Button
                        type="button"
                        className="paymentMethodBtn mt-5"
                        onClick={() => changeShipping()}
                      >
                        Back to Shipping
                      </Button>
                    </Col>
                  )}

                  <Col md={4}>
                    <Button type="submit" className="paymentMethodBtn mt-5">
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PaymentScreen;
