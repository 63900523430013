import {
  FOLLOW_A_SHOP_FAIL,
  FOLLOW_A_SHOP_REQUEST,
  FOLLOW_A_SHOP_SUCCESS,
  UNFOLLOW_A_SHOP_FAIL,
  UNFOLLOW_A_SHOP_REQUEST,
  UNFOLLOW_A_SHOP_SUCCESS,
} from "../constants/userConstants";
import axios from "axios";
import {
  LIST_THE_FOLLOWERS_FAIL,
  LIST_THE_FOLLOWERS_REQUEST,
  LIST_THE_FOLLOWERS_SUCCESS,
  LIST_THE_FOLLOWINGS_FAIL,
  LIST_THE_FOLLOWINGS_REQUEST,
  LIST_THE_FOLLOWINGS_SUCCESS,
  LIST_THE_FOLLOWING_PRODUCTS_FAIL,
  LIST_THE_FOLLOWING_PRODUCTS_REQUEST,
  LIST_THE_FOLLOWING_PRODUCTS_SUCCESS,
} from "../constants/followConstants";

export const followAShop = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FOLLOW_A_SHOP_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/follow`, { id }, config);
    dispatch({
      type: FOLLOW_A_SHOP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: FOLLOW_A_SHOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const unfollowAShop = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UNFOLLOW_A_SHOP_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.post(`/api/follow/unfollow`, { id }, config);
    dispatch({
      type: UNFOLLOW_A_SHOP_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: UNFOLLOW_A_SHOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFollowers = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LIST_THE_FOLLOWERS_REQUEST });
    const { data } = await axios.get(`/api/follow/followers/${id}`);
    dispatch({ type: LIST_THE_FOLLOWERS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LIST_THE_FOLLOWERS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFollowings = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    dispatch({ type: LIST_THE_FOLLOWINGS_REQUEST });
    const { data } = await axios.get(`/api/follow/followings`, config);
    dispatch({ type: LIST_THE_FOLLOWINGS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LIST_THE_FOLLOWINGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listFollowingProducts = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    dispatch({ type: LIST_THE_FOLLOWING_PRODUCTS_REQUEST });
    const { data } = await axios.get(
      `/api/follow/followings/productlist`,
      config
    );
    dispatch({ type: LIST_THE_FOLLOWING_PRODUCTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: LIST_THE_FOLLOWING_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
