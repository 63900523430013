import {
  BLOG_CREATE_FAIL,
  BLOG_CREATE_REQUEST,
  BLOG_CREATE_RESET,
  BLOG_CREATE_SUCCESS,
  BLOG_DETAILS_FAIL,
  BLOG_DETAILS_REQUEST,
  BLOG_DETAILS_SUCCESS,
  BLOG_EDIT_FAIL,
  BLOG_EDIT_REQUEST,
  BLOG_EDIT_RESET,
  BLOG_EDIT_SUCCESS,
  BLOG_VIEW_FAIL,
  BLOG_VIEW_FEATURED_FAIL,
  BLOG_VIEW_FEATURED_REQUEST,
  BLOG_VIEW_FEATURED_RESET,
  BLOG_VIEW_FEATURED_SUCCESS,
  BLOG_VIEW_NEW_FAIL,
  BLOG_VIEW_NEW_REQUEST,
  BLOG_VIEW_NEW_RESET,
  BLOG_VIEW_NEW_SUCCESS,
  BLOG_VIEW_REQUEST,
  BLOG_VIEW_RESET,
  BLOG_VIEW_SUCCESS,
  BLOG_VIEW_UNPUBLISHED_FAIL,
  BLOG_VIEW_UNPUBLISHED_REQUEST,
  BLOG_VIEW_UNPUBLISHED_RESET,
  BLOG_VIEW_UNPUBLISHED_SUCCESS,
} from "../constants/blogConstants";

export const blogCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_CREATE_REQUEST:
      return { loading: true };

    case BLOG_CREATE_SUCCESS:
      return { loading: false, success: true, createdBlog: action.payload };

    case BLOG_CREATE_FAIL:
      return { loading: false, error: action.payload };

    case BLOG_CREATE_RESET:
      return {};

    default:
      return state;
  }
};

export const blogListReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_VIEW_REQUEST:
      return { loading: true };

    case BLOG_VIEW_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload.blog,
        pages: action.payload.pages,
        page: action.payload.page,
      };

    case BLOG_VIEW_FAIL:
      return { loading: false, error: action.payload };

    case BLOG_VIEW_RESET:
      return {};

    default:
      return state;
  }
};

export const blogListUnpublishedReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_VIEW_UNPUBLISHED_REQUEST:
      return { loading: true };

    case BLOG_VIEW_UNPUBLISHED_SUCCESS:
      return {
        loading: false,
        success: true,
        blog: action.payload.blog,
        pages: action.payload.pages,
        page: action.payload.page,
      };

    case BLOG_VIEW_UNPUBLISHED_FAIL:
      return { loading: false, error: action.payload };

    case BLOG_VIEW_UNPUBLISHED_RESET:
      return {};

    default:
      return state;
  }
};

export const blogListNewReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_VIEW_NEW_REQUEST:
      return { loading: true };

    case BLOG_VIEW_NEW_SUCCESS:
      return { loading: false, success: true, blog: action.payload };

    case BLOG_VIEW_NEW_FAIL:
      return { loading: false, error: action.payload };

    case BLOG_VIEW_NEW_RESET:
      return {};

    default:
      return state;
  }
};

export const blogListFeaturedReducer = (state = {}, action) => {
  switch (action.type) {
    case BLOG_VIEW_FEATURED_REQUEST:
      return { loading: true };

    case BLOG_VIEW_FEATURED_SUCCESS:
      return { loading: false, success: true, blog: action.payload };

    case BLOG_VIEW_FEATURED_FAIL:
      return { loading: false, error: action.payload };

    case BLOG_VIEW_FEATURED_RESET:
      return {};

    default:
      return state;
  }
};

export const blogUpdateReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_EDIT_REQUEST:
      return {
        loading: true,
      };
    case BLOG_EDIT_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case BLOG_EDIT_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case BLOG_EDIT_RESET:
      return { blog: [] };
    default:
      return state;
  }
};

export const blogDetailsReducer = (state = { blog: {} }, action) => {
  switch (action.type) {
    case BLOG_DETAILS_REQUEST:
      return { loading: true, ...state };
    case BLOG_DETAILS_SUCCESS:
      return { loading: false, success: true, blog: action.payload };
    case BLOG_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
