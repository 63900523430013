import {
  ORDER_CREATE_FAIL,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_REQUEST,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_RESET,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_VENDOR_SUCCESS,
  ORDER_VENDOR_REQUEST,
  ORDER_VENDOR_FAIL,
  ORDER_CREATE_RESET,
  ORDER_VENDOR_RESET,
  ORDER_VENDOR_DELIVER_REQUEST,
  ORDER_VENDOR_DELIVER_SUCCESS,
  ORDER_VENDOR_DELIVER_FAIL,
  ORDER_VENDOR_DETAILS_REQUEST,
  ORDER_VENDOR_DETAILS_SUCCESS,
  ORDER_VENDOR_DETAILS_FAIL,
  ORDER_WHOLESALE_LIST_REQUEST,
  ORDER_WHOLESALE_LIST_SUCCESS,
  ORDER_WHOLESALE_LIST_FAIL,
  ORDER_wholesaler_REQUEST,
  ORDER_wholesaler_SUCCESS,
  ORDER_wholesaler_FAIL,
  ORDER_wholesaler_RESET,
  ORDER_wholesaler_DETAILS_REQUEST,
  ORDER_wholesaler_DETAILS_SUCCESS,
  ORDER_wholesaler_DETAILS_FAIL,
  ORDER_wholesaler_DELIVER_REQUEST,
  ORDER_wholesaler_DELIVER_SUCCESS,
  ORDER_wholesaler_DELIVER_FAIL,
  ORDER_VENDOR_PAY_REQUEST,
  ORDER_VENDOR_PAY_SUCCESS,
  ORDER_VENDOR_PAY_FAIL,
  ORDER_VENDOR_PAY_RESET,
  ORDER_wholesaler_PAY_REQUEST,
  ORDER_wholesaler_PAY_SUCCESS,
  ORDER_wholesaler_PAY_FAIL,
  ORDER_wholesaler_PAY_RESET,
  INVOICE_INFORMATION_REQUEST,
  INVOICE_INFORMATION_SUCCESS,
  INVOICE_INFORMATION_FAIL,
  DELIVERY_LABEL_DETAILS_REQUEST,
  DELIVERY_LABEL_DETAILS_SUCCESS,
  DELIVERY_LABEL_DETAILS_FAIL,
  VENDORORDER_UPDATE_REQUEST,
  VENDORORDER_UPDATE_SUCCESS,
  VENDORORDER_UPDATE_FAIL,
  VENDORORDER_UPDATE_RESET,
  WHOLESALERORDER_UPDATE_REQUEST,
  WHOLESALERORDER_UPDATE_SUCCESS,
  WHOLESALERORDER_UPDATE_FAIL,
  WHOLESALERORDER_UPDATE_RESET,
  DELETE_ORDER_REQUEST,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  DELETE_ORDER_RESET,
  ADD_ORDERITEM_RESET,
  ADD_ORDERITEM_REQUEST,
  ADD_ORDERITEM_SUCCESS,
  ADD_ORDERITEM_FAIL,
  DELETE_ORDERITEM_REQUEST,
  DELETE_ORDERITEM_SUCCESS,
  DELETE_ORDERITEM_RESET,
  DELETE_ORDERITEM_FAIL,
} from "../constants/orderConstants";

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };

    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderCreateVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_VENDOR_REQUEST:
      return {
        loading: true,
      };

    case ORDER_VENDOR_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_VENDOR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_VENDOR_RESET:
      return {};
    default:
      return state;
  }
};
export const orderCreateWholesalerReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_wholesaler_REQUEST:
      return {
        loading: true,
      };

    case ORDER_wholesaler_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_wholesaler_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_wholesaler_RESET:
      return {};
    default:
      return state;
  }
};

export const orderEditVendorReducer = (state = {}, action) => {
  switch (action.type) {
    case VENDORORDER_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case VENDORORDER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case VENDORORDER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case VENDORORDER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ORDER_REQUEST:
      return {
        loading: true,
      };

    case DELETE_ORDER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case DELETE_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_ORDER_RESET:
      return {};
    default:
      return state;
  }
};

export const orderItemDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_ORDERITEM_REQUEST:
      return {
        loading: true,
      };

    case DELETE_ORDERITEM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case DELETE_ORDERITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case DELETE_ORDERITEM_RESET:
      return {};
    default:
      return state;
  }
};

export const addOrderItemReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ORDERITEM_REQUEST:
      return {
        loading: true,
      };

    case ADD_ORDERITEM_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ADD_ORDERITEM_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ADD_ORDERITEM_RESET:
      return {};
    default:
      return state;
  }
};

export const orderEditWholesalerReducer = (state = {}, action) => {
  switch (action.type) {
    case WHOLESALERORDER_UPDATE_REQUEST:
      return {
        loading: true,
      };

    case WHOLESALERORDER_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case WHOLESALERORDER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case WHOLESALERORDER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], address: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const orderVendorDetailsReducer = (
  state = { loading: true, orderItems: [], address: {} },
  action
) => {
  switch (action.type) {
    case ORDER_VENDOR_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_VENDOR_DETAILS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_VENDOR_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderWholesalerDetailsReducer = (
  state = { loading: true, orderItems: [], address: {} },
  action
) => {
  switch (action.type) {
    case ORDER_wholesaler_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ORDER_wholesaler_DETAILS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_wholesaler_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deliveryLabelDetailsReducer = (
  state = { details: {} },
  action
) => {
  switch (action.type) {
    case DELIVERY_LABEL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELIVERY_LABEL_DETAILS_SUCCESS:
      return {
        loading: false,
        details: action.payload.details,
      };
    case DELIVERY_LABEL_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      };

    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PAY_RESET:
      return {};
    default:
      return state;
  }
};
export const orderVendorPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_VENDOR_PAY_REQUEST:
      return {
        loading: true,
      };

    case ORDER_VENDOR_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_VENDOR_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_VENDOR_PAY_RESET:
      return {};
    default:
      return state;
  }
};
export const orderWholesalerPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_wholesaler_PAY_REQUEST:
      return {
        loading: true,
      };

    case ORDER_wholesaler_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_wholesaler_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_wholesaler_PAY_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return {
        loading: true,
      };

    case ORDER_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderVendorDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_VENDOR_DELIVER_REQUEST:
      return {
        loading: true,
      };

    case ORDER_VENDOR_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_VENDOR_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const orderWholesalerDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_wholesaler_DELIVER_REQUEST:
      return {
        loading: true,
      };

    case ORDER_wholesaler_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_wholesaler_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const orderListMyReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_MY_REQUEST:
      return {
        loading: true,
      };

    case ORDER_LIST_MY_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ORDER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_LIST_MY_RESET:
      return { orders: [] };
    default:
      return state;
  }
};

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };

    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        order: action.payload.order,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const vendorOrderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };

    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
export const wholesalerOrderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_WHOLESALE_LIST_REQUEST:
      return {
        loading: true,
      };

    case ORDER_WHOLESALE_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload.orders,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case ORDER_WHOLESALE_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const invoiceInformationReducer = (
  state = { loading: true, orderInfo: {}, shopInfo: {} },
  action
) => {
  switch (action.type) {
    case INVOICE_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INVOICE_INFORMATION_SUCCESS:
      return {
        loading: false,
        orderInfo: action.payload.orderInfo,
        shopInfo: action.payload.shopInfo,
      };
    case INVOICE_INFORMATION_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
