import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import queryString from "query-string";
import Product from "../../components/Product.js";
import Message from "../../components/Message.js";
import Loader from "../../components/Loader.js";
import {
  listSearchVendorProducts,
  listSearchWholesalerProducts,
  listWishlist,
} from "../../actions/productActions";
import "./SearchHomeScreen.css";

import {
  Button,
  Row,
  Col,
  Form,
  Pagination,
  Toast,
  Alert,
} from "react-bootstrap";

const SearchHomeScreen = ({ match, history }) => {
  const dispatch = useDispatch();
  const keyword = match.params.keyword;
  const { search } = useLocation();
  const {
    pageNum,
    ratingStars,
    productBrand,
    productCategory,
    productServices,
    productWarrantyType,
    productMinPrice,
    productMaxPrice,
  } = queryString.parse(search);
  const pageNumber = pageNum ? pageNum : 1;
  const stars = ratingStars ? ratingStars : 0;
  const brand = productBrand ? productBrand : "";
  const category = productCategory ? productCategory : "";
  const services = productServices ? productServices : "";
  const warrantyType = productWarrantyType ? productWarrantyType : "";
  const minPrice = productMinPrice ? productMinPrice : 0;
  const maxPrice = productMaxPrice ? productMaxPrice : 99999;

  const [searchedValue, setSearchedValue] = useState("vendor");
  const [categoryItems, setCategoryItems] = useState(5);
  const [brandItems, setBrandItems] = useState(5);
  const [minValue, setMinValue] = useState(null);
  const [maxValue, setMaxValue] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [outOfStockToastMessage, setOutOfStockToastMessage] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const searchVendorProductsList = useSelector(
    (state) => state.searchVendorProductsList
  );
  const { loading, error, products, shops, pages, page, categories, brands } =
    searchVendorProductsList;

  const searchWholesalerProductsList = useSelector(
    (state) => state.searchWholesalerProductsList
  );

  const {
    products: wproducts,
    shops: wshops,
    categories: wcategories,
    brands: wbrands,
  } = searchWholesalerProductsList;

  let paginationClickedValue;
  const test = (e) => {
    paginationClickedValue = e.target.text;
    if (searchedValue === "vendor") {
      history.push(
        `/search/${keyword}?pageNum=${paginationClickedValue}&ratingStars=${stars}&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
      );
    }
    if (searchedValue === "wholesaler") {
      history.push(
        `/search/${keyword}/wholesaler?pageNum=${paginationClickedValue}&ratingStars=${stars}&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
      );
    }
  };

  let items = [];
  for (let num = 1; num <= pages; num++) {
    items.push(
      <Pagination.Item key={num} active={num === page} onClick={test}>
        {num}
      </Pagination.Item>
    );
  }

  const selectShop = (e, name) => {
    setSearchedValue(name);
  };
  const toastToggler = (productOutOfStock) => {
    setShowToast(true);
    setOutOfStockToastMessage(productOutOfStock);
  };

  useEffect(() => {
    dispatch(listWishlist());
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      listSearchVendorProducts(
        keyword,
        pageNumber,
        minPrice,
        maxPrice,
        warrantyType,
        services,
        stars,
        brand,
        category
      )
    );

    if (userInfo?.type === "vendor" || userInfo?.type === "wholesaler") {
      dispatch(
        listSearchWholesalerProducts(
          keyword,
          pageNumber,
          minPrice,
          maxPrice,
          warrantyType,
          services,
          stars,
          brand,
          category
        )
      );
    }
  }, [
    userInfo?.type,
    dispatch,
    keyword,
    pageNumber,
    minPrice,
    maxPrice,
    warrantyType,
    services,
    stars,
    brand,
    category,
  ]);
  return (
    <div className="container  py-3 px-4 px-md-0">
      <Button
        className="bg-white text-secondary p-0 mt-2"
        onClick={() => {
          history.push("/");
        }}
      >
        <i
          className="fas fa-angle-left p-2 mr-1"
          style={{ border: "1px solid whitesmoke" }}
        ></i>
        Go Back
      </Button>
      {showToast && (
        <div
          position="top-right"
          style={{
            position: "relative",
            zIndex: 2222,
            float: "right",
            right: "250px",
            top: "-75px",
          }}
        >
          <Toast
            position="top-end"
            onClose={() => setShowToast(false)}
            delay={3000}
            autohide
            style={{
              position: "fixed",
              float: "right",
            }}
          >
            <Toast.Body
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <div>
                {outOfStockToastMessage ? (
                  <Alert
                    variant="danger"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Oops! Product is out of Stock
                  </Alert>
                ) : (
                  <Alert
                    variant="success"
                    style={{
                      margin: "0px",
                    }}
                  >
                    Your Product has been added.
                  </Alert>
                )}
              </div>
            </Toast.Body>
          </Toast>
        </div>
      )}
      <Row>
        {userInfo && userInfo.type !== "customer" && (
          <Row>
            <div className="col-md-10"></div>
            {searchedValue !== "wholesaler" && (
              <div
                className="mb-4 col-md-2"
                style={{
                  float: "right",
                }}
              >
                <LinkContainer to={`/search/${keyword}`}>
                  <Button
                    role="Button"
                    className="adminProductListBtnActive"
                    onClick={(e) => selectShop(e, "vendor")}
                  >
                    Vendor
                  </Button>
                </LinkContainer>
                <LinkContainer to={`/search/${keyword}/wholesaler`}>
                  <Button
                    role="Button"
                    className="adminProductListBtn"
                    onClick={(e) => selectShop(e, "wholesaler")}
                  >
                    Wholesaler
                  </Button>
                </LinkContainer>
              </div>
            )}
            {searchedValue === "wholesaler" && (
              <div
                className="mb-4 col-md-2"
                style={{
                  float: "right",
                }}
              >
                <LinkContainer to={`/search/${keyword}`}>
                  <Button
                    role="Button"
                    className="adminProductListBtn"
                    onClick={(e) => selectShop(e, "vendor")}
                  >
                    Vendor
                  </Button>
                </LinkContainer>
                <LinkContainer to={`/search/${keyword}/wholesaler`}>
                  <Button
                    role="Button"
                    className="adminProductListBtnActive"
                    onClick={(e) => selectShop(e, "wholesaler")}
                  >
                    Wholesaler
                  </Button>
                </LinkContainer>
              </div>
            )}
          </Row>
        )}
        {searchedValue === "vendor" && (
          <Col md="2" sm="4">
            <h5> Related Categories</h5>

            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              {categories?.slice(0, categoryItems).map((cat, index) => {
                return (
                  <Link
                    to={`/search/${keyword}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productCategory=${cat}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                    key={index}
                    style={{
                      margin: "5px 0",
                      fontSize: "15px",
                      color: "#55595c",
                    }}
                  >
                    {cat}
                    <br />
                  </Link>
                );
              })}
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setCategoryItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Brands</h5>
            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              {brands?.slice(0, brandItems).map((br, index) => {
                return (
                  <Link
                    to={`/search/${keyword}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${br}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                    key={index}
                    style={{
                      margin: "5px 0",
                      fontSize: "15px",
                      color: "#55595c",
                    }}
                  >
                    {br}
                    <br />
                  </Link>
                );
              })}
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setBrandItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Price </h5>
            <Row>
              <Col md="6" className="px-1">
                <Form.Control
                  type="number"
                  placeholder="MIN"
                  value={minValue}
                  onChange={(e) => setMinValue(e.target.value)}
                />
              </Col>
              <Col md="6">
                <Form.Control
                  type="number"
                  placeholder="MAX"
                  value={maxValue}
                  onChange={(e) => setMaxValue(e.target.value)}
                />
              </Col>
              <Col classname="centerAlign">
                <div className="d-grid gap-2">
                  <Button
                    type="button"
                    className="priceFilterButtons mt-2"
                    onClick={() =>
                      history.push(
                        `/search/${keyword}?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minValue}&productMaxPrice=${maxValue}`
                      )
                    }
                  >
                    Apply
                  </Button>
                </div>
              </Col>
            </Row>

            <hr></hr>

            <h5> Rating </h5>
            <Row>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}?pageNum=${pageNumber}&ratingStars=5&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}?pageNum=${pageNumber}&ratingStars=4&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}?pageNum=${pageNumber}&ratingStars=3&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}?pageNum=${pageNumber}&ratingStars=1&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
            </Row>
            <hr></hr>

            <h5> Warranty Type </h5>
            <Row>
              <Form.Group
                className="RelatedCategory"
                controlId="formBasicCheckbox"
              >
                <Link
                  to={`/search/${keyword}?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=NoWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  No Warranty
                </Link>
                <Link
                  to={`/search/${keyword}?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=BrandWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  Brand Warranty
                </Link>
                <Link
                  to={`/search/${keyword}?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=SellerWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  Seller Warranty
                </Link>
              </Form.Group>
            </Row>
            <hr></hr>
            <Row>
              <Button
                className="priceFilterButtons mt-2"
                onClick={() => history.push(`/search/${keyword}`)}
              >
                Clear All Filters
              </Button>
            </Row>
          </Col>
        )}
        {searchedValue === "wholesaler" && (
          <Col md="2" sm="4">
            <h5> Related Categories</h5>

            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              {wcategories?.slice(0, categoryItems).map((cat, index) => {
                return (
                  <Link
                    to={`/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productCategory=${cat}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                    key={index}
                    style={{
                      margin: "5px 0",
                      fontSize: "15px",
                      color: "#55595c",
                    }}
                  >
                    {cat}
                    <br />
                  </Link>
                );
              })}
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setCategoryItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Brands</h5>
            <Form.Group
              className="RelatedCategory"
              controlId="formBasicCheckbox"
            >
              {wbrands?.slice(0, brandItems).map((br, index) => {
                return (
                  <Link
                    to={`/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${br}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                    key={index}
                    style={{
                      margin: "5px 0",
                      fontSize: "15px",
                      color: "#55595c",
                    }}
                  >
                    {br}
                  </Link>
                );
              })}
              <br />
              <Button
                variant="link"
                className="seeMore"
                onClick={() => setBrandItems(10)}
              >
                See more
              </Button>
            </Form.Group>

            <hr></hr>

            <h5> Price </h5>
            <Row>
              <Col md="6" className="px-1">
                <Form.Control
                  type="number"
                  placeholder="MIN"
                  value={minValue}
                  onChange={(e) => setMinValue(e.target.value)}
                />
              </Col>
              <Col md="6">
                <Form.Control
                  type="number"
                  placeholder="MAX"
                  value={maxValue}
                  onChange={(e) => setMaxValue(e.target.value)}
                />
              </Col>
              <Col classname="centerAlign">
                <div className="d-grid gap-2">
                  <Button
                    type="button"
                    className="priceFilterButtons mt-2"
                    onClick={() =>
                      history.push(
                        `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=${stars}&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minValue}&productMaxPrice=${maxValue}`
                      )
                    }
                  >
                    Apply
                  </Button>
                </div>
              </Col>
            </Row>

            <hr></hr>

            <h5> Rating </h5>
            <Row>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=5&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=4&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=3&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
              <Col
                md="12"
                className="ratingStar RelatedCategory px-1"
                onClick={() =>
                  history.push(
                    `/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=1&productBrand=${brand}&productCategory=${category}&productWarrantyType=${warrantyType}&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`
                  )
                }
              >
                <i className="fas fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
                <i className="far fa-star"></i>
              </Col>
            </Row>
            <hr></hr>

            <h5> Warranty Type </h5>
            <Row>
              <Form.Group
                className="RelatedCategory"
                controlId="formBasicCheckbox"
              >
                <Link
                  to={`/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=NoWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  No Warranty
                </Link>
                <Link
                  to={`/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=BrandWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  Brand Warranty
                </Link>
                <Link
                  to={`/search/${keyword}/wholesaler?pageNum=${pageNumber}&ratingStars=2&productBrand=${brand}&productCategory=${category}&productWarrantyType=SellerWarranty&productMinPrice=${minPrice}&productMaxPrice=${maxPrice}`}
                >
                  Seller Warranty
                </Link>
              </Form.Group>
            </Row>
            <hr></hr>
            <Row>
              <Button
                className="priceFilterButtons mt-2"
                onClick={() => history.push(`/search/${keyword}/wholesaler`)}
              >
                Clear All Filters
              </Button>
            </Row>
          </Col>
        )}
        <Col md="10" sm="6" xs="12" className="leftBlock">
          {searchedValue === "" && (
            <>
              {shops?.length === 0 && <h2>No shops found</h2>}
              {shops?.slice(0, 2).map((s) => (
                <>
                  <Row className="storeJumbotron ml-md-5 my-2 align-items-center">
                    <Col md="2" sm="6" className="storeIcon">
                      <i className="fas fa-store fa-3x"></i>
                    </Col>
                    <Col md="10" sm="6" className="storeInfo">
                      <Link to={`/shop/${s.shopName}`}>
                        <h3> {s.shopName} </h3>
                      </Link>
                      <p> {s.tagLine}</p>
                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                {shops?.length > 2 && (
                  <Row>
                    {" "}
                    <Col md="12" sm="12" className="moreShop mb-5">
                      <Link
                        to={`/search-shop?keyword=${keyword}`}
                        className="moreShopRightArrow"
                      >
                        More Shop <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </Col>
                  </Row>
                )}
                <Col md="12" sm="12">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <>
                      {products?.length === 0 && <h2>No products found</h2>}
                      <Row>
                        {products?.map((product) => {
                          return (
                            <Col md="3" key={product._id}>
                              <Product history={history} product={product} />
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Pagination>{items}</Pagination>
            </>
          )}
          {searchedValue === "vendor" && (
            <>
              {shops?.length === 0 && <h2>No shops found</h2>}
              {shops?.slice(0, 2).map((s) => (
                <>
                  <Row className="storeJumbotron ml-md-5 my-2 align-items-center">
                    <Col md="2" sm="6" className="storeIcon">
                      <i className="fas fa-store fa-3x"></i>
                    </Col>
                    <Col md="10" sm="6" className="storeInfo">
                      <Link to={`/shop/${s.shopName}`}>
                        <h3> {s.shopName} </h3>
                      </Link>
                      <p> {s.tagLine}</p>
                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                {shops?.length > 2 && (
                  <Row>
                    {" "}
                    <Col md="12" sm="12" className="moreShop mb-5">
                      <Link
                        to={`/search-shop?keyword=${keyword}`}
                        className="moreShopRightArrow"
                      >
                        More Shop <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </Col>
                  </Row>
                )}
                <Col md="12" sm="12">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <>
                      {products?.length === 0 && <h2>No products found</h2>}
                      <Row>
                        {products?.map((product) => {
                          return (
                            <Col md="3" key={product._id}>
                              <Product
                                history={history}
                                product={product}
                                toastToggler={toastToggler}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Pagination>{items}</Pagination>
            </>
          )}
          {searchedValue === "wholesaler" && (
            <>
              {wshops?.length === 0 && <h2>No shops found</h2>}
              {wshops?.slice(0, 2).map((s) => (
                <>
                  <Row className="storeJumbotron ml-md-5 my-2 align-items-center">
                    <Col md="2" sm="6" className="storeIcon">
                      <i className="fas fa-store fa-3x"></i>
                    </Col>
                    <Col md="10" sm="6" className="storeInfo">
                      <Link to={`/wholesaleshop/${s.shopName}`}>
                        <h3> {s.shopName} </h3>
                      </Link>
                      <p> {s.tagLine}</p>
                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                {wshops?.length > 2 && (
                  <Row>
                    {" "}
                    <Col md="12" sm="12" className="moreShop mb-5">
                      <Link
                        to={`/search-shop?keyword=${keyword}`}
                        className="moreShopRightArrow"
                      >
                        More Shop <i className="fas fa-arrow-circle-right"></i>
                      </Link>
                    </Col>
                  </Row>
                )}
                <Col md="12" sm="12">
                  {loading ? (
                    <Loader />
                  ) : error ? (
                    <Message variant="danger">{error}</Message>
                  ) : (
                    <>
                      {wproducts?.length === 0 && <h2>No products found</h2>}
                      <Row>
                        {wproducts?.map((product) => {
                          return (
                            <Col md="3" key={product?._id}>
                              <Product
                                history={history}
                                product={product}
                                toastToggler={toastToggler}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Pagination>{items}</Pagination>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default SearchHomeScreen;
